import React, { FC, useState } from 'react'
import { IAccountActiveComponent } from '../Account'
import { PageContainer, PageContent, PageMain } from './AccountPageLayout.style'
import AccountPageLayoutNav from './AccountPageLayoutNav'

const AccountPageLayout: FC<{ ActiveComponent: IAccountActiveComponent }> = ({
  ActiveComponent,
}) => {
  const [isNavShown, setNavShown] = useState<boolean>(true)

  return (
    <PageContainer>
      <PageContent>
        <AccountPageLayoutNav isShown={isNavShown} />

        <PageMain>
          <ActiveComponent
            onSetAccountNavDisplay={(value) => setNavShown(value)}
          />
        </PageMain>
      </PageContent>
    </PageContainer>
  )
}

export default AccountPageLayout
