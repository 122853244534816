import { Box, Skeleton } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { SkeletonTheme } from 'react-loading-skeleton'

interface IProps {}
const NavigationBarSkeletonLogo = styled('div', {
  name: 'NavigationBarSkeleton',
  slot: 'Logo',
})(({ theme }) => ({
  '& > svg': {
    width: '95px',
    height: '24px',
    [theme.breakpoints.up('md')]: {
      width: '127px',
      height: '32px',
    },
  },
}))
const NavigationBarSkeletonWrapper = styled(Box, {
  name: 'NavigationBarSkeleton',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: theme.spacing(0, 4),
  minHeight: 48,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
    minHeight: 64,
  },
}))
const NavigationBarSkeletonMenu = styled(Box, {
  name: 'NavigationBarSkeleton',
  slot: 'Menu',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))
const NavigationBarSkeletonIcons = styled(Box, {
  name: 'NavigationBarSkeleton',
  slot: 'Icons',
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& span:last-child': {
      display: 'none',
    },
  },
}))
const NavigationBarSkeleton: React.FC<IProps> = () => {
  return (
    <SkeletonTheme
      baseColor="transparent"
      highlightColor={'rgba(0, 0, 0, 0.1)'}
      height={'100%'}
    >
      <NavigationBarSkeletonWrapper
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <NavigationBarSkeletonLogo>
          <svg
            width="127"
            height="32"
            viewBox="0 0 534 135"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M450.85 51.53a12.711 12.711 0 1 1 3.74-9 12.344 12.344 0 0 1-3.74 9zm-9-30.24a21.189 21.189 0 0 0-18.657 31.194A21.19 21.19 0 1 0 456.85 27.49a20.425 20.425 0 0 0-15-6.2zM410.14.05h-8.5v63.72h8.5V.05zM524.52 21.28l-13.76 31.78-13.85-31.78h-9.26l7.39 17.17-6.29 14.61-13.85-31.78h-9.26l18.44 42.49h9.26l6.37-14.7 6.37 14.7h9.26l18.36-42.49h-9.18zM295.55 8.54a22.304 22.304 0 0 1 16.48 6.88l6-6a31.839 31.839 0 1 0-22.51 54.38 30.672 30.672 0 0 0 22.51-9.35l-6-6A23.31 23.31 0 1 1 295.55 8.6M335.66.05h-8.5v63.72h8.5V.05zM381.23 43.04a12.782 12.782 0 0 1-12.75 12.23 12.772 12.772 0 0 1-12.74-12.74V21.29h-8.5v21.24a21.216 21.216 0 0 0 6.209 15.03 21.203 21.203 0 0 0 15.031 6.21 21.13 21.13 0 0 0 12.75-4.25l4.25 4.25h4.2V21.29h-8.49l.04 21.75zM217.47 51.53a12.711 12.711 0 1 1 3.74-9 12.36 12.36 0 0 1-3.74 9zm3.74-26a21.14 21.14 0 0 0-12.75-4.24 21.24 21.24 0 1 0 0 42.48 20.77 20.77 0 0 0 12.75-4.25l4.24 4.25h4.23V.05h-8.49l.02 25.48zM177.89 21.29h-8.5v42.48h8.5V21.29zM173.68.04a4.636 4.636 0 0 0-3.56 1.53 5.004 5.004 0 0 0-1.53 3.66 5.228 5.228 0 0 0 5.09 5.1 4.998 4.998 0 0 0 3.66-1.53 4.635 4.635 0 0 0 1.53-3.57 5.219 5.219 0 0 0-5.19-5.19zM39.93 48.39a22.58 22.58 0 0 1-16.57 6.88H8.5V8.54h14.86a23.22 23.22 0 0 1 23.37 23.33 22.502 22.502 0 0 1-6.8 16.48v.04zM23.36 0H0v63.77h23.36a31.87 31.87 0 0 0 0-63.73M92.79 51.53a12.709 12.709 0 1 1 3.74-9 12.35 12.35 0 0 1-3.74 9zm3.74-26a21.1 21.1 0 0 0-12.74-4.25 21.25 21.25 0 1 0 0 42.49 20.76 20.76 0 0 0 12.74-4.25l4.25 4.25h4.25V21.28h-8.5v4.25zM137.19 53.06l-13.85-31.78h-9.26l18.44 42.49h9.26l18.35-42.49h-9.18l-13.76 31.78z"
              fill="#00303C"
            ></path>
            <path
              d="M154.94 122.87a8.325 8.325 0 0 1-2.4 6 8.2 8.2 0 0 1-5.89 2.491 8.204 8.204 0 0 1-5.89-2.491 8.752 8.752 0 0 1 0-12 8.21 8.21 0 0 1 11.78 0 8.334 8.334 0 0 1 2.4 6zm4.06 0a11.61 11.61 0 0 0-3.56-8.66 12.87 12.87 0 0 0-17.58 0 12.312 12.312 0 0 0 0 17.32 12.87 12.87 0 0 0 17.58 0 11.62 11.62 0 0 0 3.56-8.66zm22.81-11.56h-8.06v23.46h4v-6.9h4.16c3.453 0 6.01-.667 7.67-2 1.66-1.333 2.493-3.417 2.5-6.25 0-2.84-.81-4.94-2.45-6.31-1.64-1.37-4.23-2-7.78-2h-.04zm.54 13h-4.67v-9.44h4a8.726 8.726 0 0 1 4.73 1 3.836 3.836 0 0 1 1.51 3.44 5.208 5.208 0 0 1-1.19 3.7c-.79.84-2.29 1.25-4.48 1.25l.1.05zm40.24-9.44v-3.62H204.4v3.62h7.12v19.84h4v-19.84h7.07zm13.7 19.84h4v-23.45h-4v23.45zm25.35-6a8.132 8.132 0 0 1-2.4-6 7.732 7.732 0 0 1 2.44-5.84 8.357 8.357 0 0 1 6-2.3 8.739 8.739 0 0 1 3.5.64 10.756 10.756 0 0 1 3.18 2.25l2.51-2.76a11.819 11.819 0 0 0-9.11-4 12.417 12.417 0 0 0-9 3.49 12.44 12.44 0 0 0-.06 17.38 12.12 12.12 0 0 0 8.82 3.42 11.753 11.753 0 0 0 9.05-4.12l-2.55-2.62a12.28 12.28 0 0 1-3.09 2.2 8.283 8.283 0 0 1-3.52.68 7.765 7.765 0 0 1-5.69-2.39l-.08-.03zm29.26 6.03h4v-23.48h-4v23.48zm35.48-5.34 2.35 5.34h4.23l-10.34-23.46h-4.23l-10.41 23.44h4.23l2.34-5.34 11.83.02zm-10.13-3.66 4.26-9.66 4.26 9.66h-8.52zm47.43 3.04-13.63-17.52h-4v23.46h4V117.6l13.29 17.12h4.34v-23.46h-4v17.52zm30.87-13.83a8.174 8.174 0 0 1 2.82 1.54l2.11-3a11.461 11.461 0 0 0-3.83-2.08 13.757 13.757 0 0 0-4.46-.74 9.466 9.466 0 0 0-5.85 1.76 5.878 5.878 0 0 0-2.37 5 5.663 5.663 0 0 0 2.08 4.83 9 9 0 0 0 2.38 1.36c1.284.463 2.597.844 3.93 1.14 1.287.237 2.52.704 3.64 1.38a2.449 2.449 0 0 1 1.06 2.11 2.572 2.572 0 0 1-1.13 2.18 4.998 4.998 0 0 1-3 .82 11.19 11.19 0 0 1-7.39-3.09l-2.48 3a14.26 14.26 0 0 0 9.77 3.79 9.188 9.188 0 0 0 6.07-1.91 6.152 6.152 0 0 0 2.32-5 5.806 5.806 0 0 0-1.83-4.65 13.005 13.005 0 0 0-5.44-2.45 16.239 16.239 0 0 1-4.71-1.63 2.477 2.477 0 0 1-1.11-2.18 2.346 2.346 0 0 1 1.11-2.13 5.162 5.162 0 0 1 2.83-.7c1.195.008 2.38.211 3.51.6"
              fill="#83C8BB"
            ></path>
          </svg>
        </NavigationBarSkeletonLogo>

        <NavigationBarSkeletonMenu
          display={'flex'}
          flexDirection={'row'}
          gap={4}
        >
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
          <Skeleton variant="rectangular" width={60} height={20} />
        </NavigationBarSkeletonMenu>
        <NavigationBarSkeletonIcons
          display={'flex'}
          flexDirection={'row'}
          gap={4}
        >
          <Skeleton variant="rectangular" width={16} height={16} />
          <Skeleton variant="rectangular" width={16} height={16} />
          <Skeleton variant="rectangular" width={16} height={16} />
        </NavigationBarSkeletonIcons>
      </NavigationBarSkeletonWrapper>
    </SkeletonTheme>
  )
}

export default NavigationBarSkeleton
