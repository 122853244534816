import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import isArray from 'lodash/isArray'
import union from 'lodash/union'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import Log from '../../services/Log'
import {
  isDynamicContent,
  IPlacement,
  DynamicContentKey,
  DynamicRule,
  dynamicContentKeys,
  LXDynamicContent,
  ILXTeaser,
  ICMCollection,
  IPlacementItem,
  isCMCollection,
} from '../../types/teaser'
import { orderItemsSelector } from '../../features/order/selector'

import { usePageType } from './usePageType'
import { getProductAttribute } from '../../utils/productAttributes'
import { ServerProduct, ServerProductAttribute } from '../../types/product'
import { IPlpProduct } from '../../features/plp/query'
import { loginStatusSelector } from 'src/redux/selectors/user'
import { getCookieByName } from 'src/utils/cookie'

type DynamicContentKeyMapping = Record<DynamicContentKey, string[]> | {}
type Product = Pick<ServerProduct, 'attributes' | 'cluster'>

/**
 * Create product attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapItemAttributes = (
  pdpData: ServerProduct
): DynamicContentKeyMapping => {
  const productAttributesMap: DynamicContentKeyMapping = {}
  try {
    dynamicContentKeys.map((key) => {
      if (!productAttributesMap[key]) {
        productAttributesMap[key] = []
      }
      let mapElement = productAttributesMap[key]
      let attrValue = getProductAttribute(pdpData, key)
      if (attrValue && attrValue !== '') {
        if (isArray(attrValue)) {
          attrValue.map((v) => {
            mapElement && mapElement.push(`${key}_${v}`)
          })
        } else {
          mapElement && mapElement.push(`${key}_${attrValue}`)
        }
      }
      mapElement = union(mapElement)
    })
  } catch (e: any) {
    Log.error('DYNAMIC PDP CONTENT MAPPING ERROR', e)
  }
  Log.info('DYNAMIC PDP CONTENT MAPPING', JSON.stringify(productAttributesMap))
  return productAttributesMap
}

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapCartItemsAttributes = (
  orderItems: Product[]
): DynamicContentKeyMapping => {
  let orderItemAttributes: ServerProductAttribute[] = []
  const orderItemAttributesMap: DynamicContentKeyMapping = {}
  try {
    orderItems.map((item) => {
      concat(orderItemAttributes, item.attributes)
        .filter((attr) => {
          return (
            attr.identifier &&
            dynamicContentKeys.includes(attr.identifier as DynamicContentKey)
          )
        })
        .map((attr) => {
          if (attr?.name && !orderItemAttributesMap[attr?.name]) {
            orderItemAttributesMap[attr?.name] = []
          }
          let attrValue = attr?.name
            ? getProductAttribute(item, attr?.name)
            : null
          if (attr?.name) {
            if (isArray(attrValue)) {
              attrValue.map((v) => {
                attr?.name &&
                  orderItemAttributesMap[attr?.name] &&
                  orderItemAttributesMap[attr?.name].push(`${attr?.name}_${v}`)
              })
            } else {
              orderItemAttributesMap[attr?.name] &&
                orderItemAttributesMap[attr?.name].push(
                  `${attr?.name}_${attrValue}`
                )
            }
            orderItemAttributesMap[attr?.name] = union(
              orderItemAttributesMap[attr?.name]
            )
          }
        })
    })
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT MAPPING ERROR', e)
  }
  Log.info('DYNAMIC CONTENT MAPPING', JSON.stringify(orderItemAttributesMap))
  return orderItemAttributesMap
}

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapAttributesFromFacets = (
  selectedFacets,
  facets
): DynamicContentKeyMapping => {
  let facetsMap: DynamicContentKeyMapping = {}
  try {
    forEach(selectedFacets, (_, selectedFacetKey) => {
      forEach(facets, (facet, _) => {
        facet.entry?.map((entry) => {
          if (entry.value === selectedFacetKey) {
            const facetName = facet.name
            if (!facetsMap[facetName]) {
              facetsMap[facetName] = []
              facetsMap[facetName].push(entry.attributeValueIdentifier)
            } else if (facetName && facetsMap[facetName]) {
              facetsMap[facetName].push(entry.attributeValueIdentifier)
            }
          }
        })
      })
    })
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT FACETS MAPPING', e)
  }
  Log.info('DYNAMIC CONTENT FACETS MAPPING', JSON.stringify(facetsMap))
  return facetsMap
}

/**
 * get dynamic cms content
 * @param dynamicContentKeyMapping dynamic content attributes map
 * @param dynamicRules dynamic rules from cms to be matched with attributes
 * @param dynamicItem dynamic cms entity
 */
const getDynamicContentByRules = (
  dynamicContentKeyMapping: DynamicContentKeyMapping,
  dynamicRules: DynamicRule[],
  dynamicItem: LXDynamicContent
): ILXTeaser | ICMCollection | null => {
  let matchedRule: DynamicRule | null = null
  try {
    matchedRule = dynamicRules?.filter((dynamicRule) => {
      const match = dynamicRule?.rules.find((rule) => {
        const attributesValues: string[] =
          dynamicContentKeyMapping[rule?.key] || []
        return !!rule.value && attributesValues?.includes(rule?.value)
      })
      return !!match
    })[0]
    Log.info('DYNAMIC CONTENT RULE MATCH', JSON.stringify(matchedRule))
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT MATCH ERROR', e)
  }
  if (matchedRule !== null && !!matchedRule?.target) {
    return matchedRule.target
  } else {
    Log.info('DYNAMIC CONTENT DEFAULT MATCH', JSON.stringify(matchedRule))
    return dynamicItem.dynamicDefault
  }
}

const processPlacementItem = (
  item: IPlacementItem,
  dynamicContentMap: DynamicContentKeyMapping
): IPlacementItem => {
  if (item && isDynamicContent(item)) {
    const dynamicContentByRules = getDynamicContentByRules(
      dynamicContentMap,
      item.dynamicRules || [],
      item
    )
    return dynamicContentByRules!
  } else {
    return item
  }
}

export const getUpdatedCmsContent = (
  cmsBannerData: IPlacement[],
  dynamicContentMap: DynamicContentKeyMapping
): IPlacement[] => {
  try {
    return (cmsBannerData || [])
      ?.map((placement) => {
        const items = placement.items
          .map((placementItem) => {
            if (placementItem && isCMCollection(placementItem)) {
              return {
                ...placementItem,
                teasableItems: placementItem.teasableItems.map((item) =>
                  processPlacementItem(item, dynamicContentMap)
                ),
              }
            }

            const formattedPlacementItem = processPlacementItem(
              placementItem,
              dynamicContentMap
            )

            return formattedPlacementItem
          })
          .filter((item) => !!item)

        if (items.length === 0) return

        return {
          ...placement,
          items,
        }
      })
      .filter((placement) => !!placement) as IPlacement[]
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT FETCH ERROR', e)

    return []
  }
}

export const useDynamicCmsContent = (
  cmsBannerData: IPlacement[] | undefined,
  pdpData?: ServerProduct | null,
  facets?: IPlpProduct['facets'] | undefined,
  selectedFacets?: IPlpProduct['selectedFacets'] | undefined
) => {
  const pageType = usePageType()
  const orderItems: Product[] = useSelector(orderItemsSelector)

  const [dynamicContentBanners, setDynamiContentBanners] = useState<
    IPlacement[] | undefined
  >([])

  const userLoggedIn = useSelector(loginStatusSelector)
  const userLogged = userLoggedIn.toString()
  const earlyAccessContentEnabled = getCookieByName('eaccess') || 'false'
  const storeConfMap = { earlyAccessContentEnabled, userLogged }

  useEffect(() => {
    if (cmsBannerData && cmsBannerData.length > 0) {
      switch (pageType.pageType) {
        case 'pdp':
          const pdpContentMap =
            pdpData && pdpData !== null ? mapItemAttributes(pdpData) : {}

          const pdpDynamicContentMap = {
            ...pdpContentMap,
            ...storeConfMap,
          }

          const pdpBannersData =
            cmsBannerData &&
            pdpDynamicContentMap &&
            getUpdatedCmsContent(cmsBannerData, pdpDynamicContentMap)

          setDynamiContentBanners(pdpBannersData)

          break
        case 'plp':
          const plpDynamicFacetMap =
            mapAttributesFromFacets(selectedFacets, facets) || {}
          const plpDynamicContentMap = {
            ...plpDynamicFacetMap,
            ...storeConfMap,
          }
          const plpBannersData =
            cmsBannerData &&
            plpDynamicContentMap &&
            getUpdatedCmsContent(cmsBannerData, plpDynamicContentMap)
          setDynamiContentBanners(plpBannersData)
          break
        case 'cart':
          const cartContentMap = mapCartItemsAttributes(orderItems) || {}
          const cartDynamicContentMap = {
            ...cartContentMap,
            ...storeConfMap,
          }
          const cartBannersData =
            cmsBannerData &&
            cartDynamicContentMap &&
            getUpdatedCmsContent(cmsBannerData, cartDynamicContentMap)
          setDynamiContentBanners(cartBannersData)
          break
        case 'home':
          const homeBannersData =
            cmsBannerData && getUpdatedCmsContent(cmsBannerData, storeConfMap)
          setDynamiContentBanners(homeBannersData)
          break
        case 'otherPage':
          const otherPage =
            cmsBannerData && getUpdatedCmsContent(cmsBannerData, storeConfMap)
          setDynamiContentBanners(otherPage)
          break
        default:
          setDynamiContentBanners(cmsBannerData)
      }
    }
  }, [orderItems, selectedFacets, facets, pdpData, cmsBannerData, userLogged])

  return { dynamicContentBanners }
}
