//Redux
import * as WORKERS from '../workers/context'

import {
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_SUCCESS_ACTION,
} from '../../actions/user'

import { USER_CONTEXT_REQUEST_ACTION } from '../../actions/context'
//Standard libraries
import { takeLatest } from 'redux-saga/effects'

/**
 *
 */
export function* watchSaga() {
  yield takeLatest(
    [
      USER_CONTEXT_REQUEST_ACTION,
      LOGIN_SUCCESS_ACTION,
      LOGOUT_SUCCESS_ACTION,
      GUEST_LOGIN_SUCCESS_ACTION,
      REGISTRATION_SUCCESS_ACTION,
    ],
    WORKERS.getUserContext
  )
}
