import groupBy from 'lodash/groupBy'
import ProductService from '../services/ProductService'
import {
  Order,
  OrderItem,
  OrderItemWithRoxProps,
  RoxableAttributes,
} from './../types/order'
import {
  getIsPolarized,
  getLensesColor,
  getProductAttribute,
  getProductType,
} from './productAttributes'
import {
  PRODUCT_PRICE_USAGE_NAMES,
  ServerProductPrice,
  DetailedRxPrice,
} from '../types/product'
import { orderHasPrescriptionUploaded } from './order'
import Log from '../services/Log'

export const getRxAttributes = (lensObject): RoxableAttributes => {
  // TODO: Woulnd't it be better to incapsulate 'getProductAttribute' alongside all other getters?
  return {
    correctionType: getProductAttribute(lensObject, 'CORRECTION_TYPE'),
    lensBrand: getProductAttribute(lensObject, 'BRAND'),
    lensType:
      getProductAttribute(lensObject, 'ANTI_BLUE') ||
      getProductAttribute(lensObject, 'TRANSITION_TYPE') ||
      'Clear',
    lensColor: getLensesColor(lensObject),
    lensThickness: getProductAttribute(lensObject, 'LENS_THICKNESS'),
    lensTreatment: getProductAttribute(lensObject, 'LENS_TREATMENT'),
    polarized: getIsPolarized(lensObject) === 'True',
  }
}

export const isRxCart = (
  orderExtendAttribute: Order['orderExtendAttribute']
): boolean => {
  return !!orderExtendAttribute?.find(
    (a) => a.attributeName === 'IS_ROX_ORDER' && a.attributeValue === 'true'
  )
}

export const isRxProduct = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find((a) => {
    return a.attributeName === 'IsRoxLens' && a.attributeValue === 'true'
  })
}

export const isRox = (
  orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']
): boolean => {
  try {
    return !!orderItemExtendAttribute?.find((a) => a.attributeName === 'IsRox')
  } catch (e) {
    return false
  }
}

export const isRxFrame = (
  orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']
): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(
      (a) => a.attributeName === 'RxLensId'
    )
  } catch (e) {
    return false
  }
}

export const isRxLens = (
  orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']
): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(
      (a) => a.attributeName === 'IsRoxLens'
    )
  } catch (e) {
    return false
  }
}

export const getGroupedFrames = (orderItems: OrderItem[]) => {
  return groupBy(
    orderItems || [],
    (item) => item.xitem_field1 || item.orderItemId
  )
}

export const parseRxOrderItems = (
  items: OrderItem[]
): OrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map((framesArray) => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(
        (item) => item?.orderItemId !== frame?.orderItemId
      )
      const isPrescriptionUploadedForLens = roxableServices
        ? orderHasPrescriptionUploaded(roxableServices[0])
        : false
      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        prescriptionUploaded: isPrescriptionUploadedForLens,
        roxableAttributes,
        roxableServices,
        prescriptionDetails: lens?.prescriptionDetails || null,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const getRxPrice = (
  rxServices: any[],
  rxFramePrice: string | undefined
): number | undefined => {
  try {
    return (
      rxServices?.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          parseFloat(
            currentValue?.x_offerpriceRx || currentValue?.orderItemPrice || 0
          )
        )
      }, 0) + parseFloat(rxFramePrice || '0')
    )
  } catch (e) {
    return undefined
  }
}

export const getRxLensPrice = (
  rxServices: OrderItemWithRoxProps['roxableServices']
): number | undefined => {
  try {
    return rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.x_offerpriceRx || 0)
    }, 0)
  } catch (e) {
    return undefined
  }
}

export const getRxLensDiscountedPrice = (
  rxServices: OrderItemWithRoxProps['roxableServices']
): number | undefined => {
  try {
    const withDiscount = rxServices.filter((rx) => rx.x_offerDiscountpriceRx)
    if (withDiscount.length === 0) return undefined
    return rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.x_offerDiscountpriceRx || 0)
    }, 0)
  } catch (e) {
    return undefined
  }
}

export const getRxDiscountedPrice = (
  rxServices: OrderItemWithRoxProps['roxableServices'] | undefined,
  rxFramePrice: string | undefined
) => {
  try {
    if (!rxServices || !rxFramePrice) return undefined

    return (
      rxServices?.reduce((accumulator, currentValue) => {
        return (
          accumulator +
          parseFloat(
            currentValue?.x_offerDiscountpriceRx ||
              currentValue?.orderItemPrice ||
              0
          )
        )
      }, 0) + parseFloat(rxFramePrice || '0')
    )
  } catch (e) {
    return undefined
  }
}

export const getDetailedRxPrice = (
  rxServices: OrderItem[]
): DetailedRxPrice[] | null => {
  try {
    return rxServices.map((service) => ({
      type: getProductType(service),
      currentPrice: {
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        currency: service?.currency ?? 'GBP',
        value: service?.orderItemPrice ?? '0',
      },
      initialPrice: {
        usage: PRODUCT_PRICE_USAGE_NAMES.INITIAL,
        currency: service?.currency ?? 'GBP',
        value: service?.x_offerpriceRx ?? '0',
      },
    }))
  } catch (e: any) {
    Log.error('Could not get detailed rx price', e)
    return null
  }
}

export const getFormattedTotalRxPrice = (
  rxPrices: DetailedRxPrice[]
): ServerProductPrice[] | null => {
  try {
    const { initialPrice, currentPrice } = rxPrices[0]
    return rxPrices.reduce(
      (total, price, index) => {
        const [initialPriceModel, currentPriceModel] = total
        return index
          ? [
              {
                ...initialPriceModel,
                value: String(
                  +initialPriceModel.value + +price.initialPrice.value
                ),
              },
              {
                ...currentPriceModel,
                value: String(
                  +currentPriceModel.value + +price.currentPrice.value
                ),
              },
            ]
          : total
      },
      [initialPrice, currentPrice]
    )
  } catch (e: any) {
    Log.error('Could not get formatted rx price', e)
    return null
  }
}

export const getDetailedPrices = (
  productPrices: ServerProductPrice[],
  rxPrices?: OrderItem[]
) => {
  try {
    const formattedPrice = ProductService.genPriceWithoutValue(productPrices)

    const detailedRxPrice = rxPrices ? getDetailedRxPrice(rxPrices) : null
    const totalRxPrice = detailedRxPrice
      ? getFormattedTotalRxPrice(detailedRxPrice)
      : null

    const initialFramePrice = ProductService.getInitialPriceModel(
      formattedPrice
    ) as ServerProductPrice
    const currentFramePrice = ProductService.getCurrentPriceModel(
      formattedPrice
    ) as ServerProductPrice

    const initialRxProductsPrice = totalRxPrice
      ? ProductService.getInitialPriceModel(totalRxPrice)
      : null
    const currentRxProductsPrice = totalRxPrice
      ? ProductService.getCurrentPriceModel(totalRxPrice)
      : null

    const totalCurrentPrice = ProductService.calculateTotalPriceModel(
      currentRxProductsPrice
        ? [currentFramePrice, currentRxProductsPrice]
        : [currentFramePrice]
    )
    const totalInitialPrice = ProductService.calculateTotalPriceModel(
      initialRxProductsPrice
        ? [initialFramePrice, initialRxProductsPrice]
        : [initialFramePrice]
    )

    return {
      initialFramePrice,
      currentFramePrice,
      initialRxProductsPrice,
      currentRxProductsPrice,
      totalInitialPrice,
      totalCurrentPrice,
    }
  } catch (e: any) {
    Log.error('Could not get detailed prices', e)
    return null
  }
}
