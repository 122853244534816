import { styled } from '@mui/material/styles'
import { FormGroup } from '@mui/material'

export const StyledFormGroup = styled(FormGroup)(({ row }) => ({
  '.MuiFormControlLabel-root': {
    alignItems: 'flex-start',
    marginBottom: !row ? 24 : 0,
    padding: 0,
  },
  '.MuiCheckbox-root': {
    padding: '4px 12px 0 0',
    margin: 0,
  },
  '.MuiSwitch-root': {
    marginRight: 12,
  },
  '.MuiBox-root': {
    marginLeft: 24,
  },
}))
