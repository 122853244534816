import { styled } from '@mui/material/styles'
import { AccordionDetails } from '@mui/material'

export const ContactLensDetailsContent = styled(AccordionDetails, {
  name: 'OrderRecapClItem',
  slot: 'ContactLensDetailsContent',
})(({ theme }) => ({
  padding: `${theme.spacing(3)} 0 0 0`,
  color: theme.palette.text.dark.primary,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ContactLensAttributesList = styled('ul', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  justifyContent: 'space-around',
  margin: '0',
  padding: '0',
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
}))

export const ContactLensAttributesListItem = styled('li', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})<{ isValue?: boolean }>(({ isValue, theme }) => ({
  fontSize: '12px',
  flex: '1 1 auto',
  maxWidth: '100%',
  width: '100%',
  minWidth: isValue ? '95px' : 'unset',
  minHeight: '32px',
  border: isValue ? `1px solid ${theme.palette.custom.alto}` : 'none',
  marginBottom: theme.spacing(2.125),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.light.secondary,
  fontWeight: 600,
  position: 'relative',

  '&:last-of-type': {
    marginTop: theme.spacing(6),
  },
}))

export const ContactLensEyeAttribute = styled('li', {
  name: 'OrderRecapClItem',
  slot: 'AttributesList',
})(({ theme }) => ({
  flex: '1 1 auto',
  minWidth: '95px',
  marginBottom: theme.spacing(2.125),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ContactLensAttributesListItemDividerMobile = styled('div', {
  name: 'OrderRecapClItem',
  slot: 'AttributesListItemDividerMobile',
})(({ theme }) => ({
  position: 'absolute',
  width: 280,
  height: 1,
  background: theme.palette.background.light.tertiary,
  bottom: 45,

  [theme.breakpoints.up('sm')]: {
    width: 575,
  },
}))
