import { GridContainer, GridItem, ButtonAsLink, TextField } from '../UI'
import { Z_INDEX_LV2 } from '../../constants/ui'
import { styled } from '@mui/material/styles'

export const AddressFormWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'AddressFormWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  '& :nth-of-type(7)': {
    marginTop: theme.spacing(5),
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
  },
}))

export const StyledAddressForm = styled('form', {
  name: 'AddressForm',
  slot: 'Form',
})(() => ({}))

export const StyledTextField = styled(TextField, {
  name: 'AddressForm',
  slot: 'TextField',
  shouldForwardProp: (prop) => prop !== 'gMapLoaded',
})(({ theme }) => ({
  '& .MuiFormHelperText-root.Mui-error': {
    background: 'transparent',
    margin: 0,
    paddingLeft: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.5),
    position: 'relative',
    bottom: 0,
  },
  '.MuiAutocomplete-endAdornment': {
    right: '0 !important',
  },
  '.MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
    flexDirection: 'row-reverse',
  },
}))

export const StyledGridContainer = styled(GridContainer, {
  name: 'AddressForm',
  slot: 'GridContainer',
})(({ theme }) => ({
  '& > div:nth-of-type(7)': {
    paddingTop: theme.spacing(5),
  },
}))

export const StyledGridItem = styled(GridItem, {
  name: 'AddressForm',
  slot: 'GridItem',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  width: 'auto',
  maxWidth,
}))

export const StyledGridItemPostcode = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemPostcode',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  maxWidth,
}))

export const StyledGridItemAddressLine1 = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemAddressLine1',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth }) => ({
  maxWidth,
}))

export const StyledGridItemPhone = styled(StyledGridItem, {
  name: 'AddressForm',
  slot: 'GridItemPhone',
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number | string }>(({ maxWidth, theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth,
  },
}))

export const StyledGridItemAdditionalText = styled('div', {
  name: 'AddressForm',
  slot: 'GridItemAdditionalText',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.light.secondary,
  width: '100%',
  whiteSpace: 'nowrap',
}))

export const WrapperCountry = styled('div', {
  name: 'AddressForm',
  slot: 'CountryWrapper',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(5)} 0 ${theme.spacing(5)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(6),
}))

export const CountryTitle = styled('div', {
  name: 'AddressForm',
  slot: 'TitleCountry',
})(({ theme }) => ({
  color: theme.palette.text.light.secondary,
}))

export const CountryText = styled('div', {
  name: 'AddressForm',
  slot: 'CountryText',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light.secondary,
}))

export const AddressLine2Button = styled(ButtonAsLink, {
  name: 'AddressForm',
  slot: 'AddressLine2Button',
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: isOpen ? theme.spacing(6) : 0,
}))

export const AddressSuggesterWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'SuggesterWrapper',
})(() => ({
  position: 'relative',
}))

export const SuggestionsWrapper = styled('ul', {
  name: 'AddressForm',
  slot: 'SuggestionsWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  position: 'absolute',
  zIndex: Z_INDEX_LV2,
  padding: 0,
  margin: 0,
  listStyleType: 'none',
  width: '100%',
  border: `2px solid ${theme.palette.background.dark.secondary}`,
  borderTop: 0,
  top: 48,
}))

export const SuggestionsElement = styled('li', {
  name: 'AddressForm',
  slot: 'SuggestionsElement',
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ selected, theme }) => ({
  color: 'inherit',
  cursor: 'pointer',
  padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
  margin: '0',
  background: selected
    ? theme.palette.background.dark.quaternary
    : theme.palette.background.light.primary,
  '&:hover': {
    background: theme.palette.background.dark.quaternary,
  },
}))
