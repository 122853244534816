import { isEmpty } from 'lodash-es'
import React from 'react'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { INVENTORY } from 'src/constants/common'
import { orderApi } from 'src/features/order/query'
import { usePageType } from 'src/foundation/hooks/usePageType'
import { useSite } from 'src/foundation/hooks/useSite'
import { currentContractIdSelector } from 'src/redux/selectors/contract'
import { wcTokenSelector } from 'src/redux/selectors/user'
import { CartPayload } from 'src/types/cart'

export const useGetCart = (): {
  isCartGetSuccess: boolean
  isCartUnitialized: boolean
  cartFetching: boolean
} => {
  const { pageType } = usePageType()
  const { mySite } = useSite()
  const timestampRef = React.useRef(Date.now()).current
  const contractId = useSelector(currentContractIdSelector)
  const wcToken = useSelector(wcTokenSelector)
  const defaultCurrencyID = useMemo<string>(
    () => mySite.defaultCurrencyID || '',
    [mySite]
  )
  const checkInventory = useMemo<boolean>(
    () => mySite.inventorySystem === INVENTORY.NON_ATP,
    [mySite.inventorySystem]
  )
  const payloadBase: CartPayload = {
    storeId: mySite.storeID,
    currency: defaultCurrencyID,
    contractId,
    checkInventory,
  }

  const [
    getCart,
    {
      isUninitialized: isCartUnitialized,
      isFetching: cartFetching,
      isSuccess: isCartGetSuccess,
    },
  ] = orderApi.endpoints.getCart.useLazyQuery()

  //const shippingInfoNeededPageTypes = ['cart', 'checkout']
  const prescriptionDetailsInfoNeededPageTypes = ['cart', 'checkout']
  const disabledPageTypes = ['cart', 'checkout', 'checkoutExternal']
  useEffect(() => {
    const isDisabled = !!pageType ? disabledPageTypes.includes(pageType) : false
    // const shouldGetShippingInfo = !!pageType
    //   ? shippingInfoNeededPageTypes.includes(pageType)
    //   : false
    const shouldGetPrescriptionDetailsInfo = !!pageType
      ? prescriptionDetailsInfoNeededPageTypes.includes(pageType)
      : false
    if (mySite && !isEmpty(wcToken) && !isDisabled) {
      getCart({
        ...payloadBase,
        fetchCatentries: true,
        //fetchShippingInfo: shouldGetShippingInfo,
        fetchPrescriptionDetails: shouldGetPrescriptionDetailsInfo,
        sessionId: timestampRef,
      })
    }
  }, [wcToken, mySite, pageType])

  return {
    isCartGetSuccess,
    isCartUnitialized,
    cartFetching,
  }
}
