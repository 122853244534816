const mockedData = {
  attachments: [
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_ea6e843a-2897-44ca-bcc9-addc0108abac/0AN3084__738_4V__STD__shad__bk',
      sequence: '18.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Back',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/ea6e843a-2897-44ca-bcc9-addc0108abac/0AN3084__738_4V__STD__shad__bk.png',
      attachmentAssetID: '3074457345616680507',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/ea6e843a-2897-44ca-bcc9-addc0108abac/0AN3084__738_4V__STD__shad__bk.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_0d5dbcf3-dc83-416b-93ad-addc0108aed5/0AN3084__738_4V__STD__shad__lt',
      sequence: '17.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Lateral',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/0d5dbcf3-dc83-416b-93ad-addc0108aed5/0AN3084__738_4V__STD__shad__lt.png',
      attachmentAssetID: '3074457345616680508',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/0d5dbcf3-dc83-416b-93ad-addc0108aed5/0AN3084__738_4V__STD__shad__lt.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_cc7bb5d2-4a15-414a-8d68-addc0108aa51/0AN3084__738_4V__STD__noshad__fr_PLP',
      sequence: '1.0',
      catentry_id: '3074457345616689332',
      usage: 'PLP',
      name: 'Front',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/cc7bb5d2-4a15-414a-8d68-addc0108aa51/0AN3084__738_4V__STD__noshad__fr.png',
      attachmentAssetID: '3074457345616680509',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/cc7bb5d2-4a15-414a-8d68-addc0108aa51/0AN3084__738_4V__STD__noshad__fr.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_1b43882a-e62f-48e9-94ad-addc0108a32f/0AN3084__738_4V__STD__shad__qt',
      sequence: '14.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Quarter',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/1b43882a-e62f-48e9-94ad-addc0108a32f/0AN3084__738_4V__STD__shad__qt.png',
      attachmentAssetID: '3074457345616680510',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/1b43882a-e62f-48e9-94ad-addc0108a32f/0AN3084__738_4V__STD__shad__qt.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_5d5c694a-688c-4036-8b65-addc0108a4df/0AN3084__738_4V__STD__shad__cfr',
      sequence: '19.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Closed Front',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/5d5c694a-688c-4036-8b65-addc0108a4df/0AN3084__738_4V__STD__shad__cfr.png',
      attachmentAssetID: '3074457345616680511',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/5d5c694a-688c-4036-8b65-addc0108a4df/0AN3084__738_4V__STD__shad__cfr.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_e82b1f3b-fb9a-40c9-903e-addc0108a6af/0AN3084__738_4V__STD__shad__al2',
      sequence: '16.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Alternative',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/e82b1f3b-fb9a-40c9-903e-addc0108a6af/0AN3084__738_4V__STD__shad__al2.png',
      attachmentAssetID: '3074457345616680512',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/e82b1f3b-fb9a-40c9-903e-addc0108a6af/0AN3084__738_4V__STD__shad__al2.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_5b6d9fbe-2ddc-4177-9178-addc0108ad2d/0AN3084__738_4V__STD__shad__fr',
      sequence: '15.0',
      catentry_id: '3074457345616689332',
      usage: 'PDP',
      name: 'Front',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/5b6d9fbe-2ddc-4177-9178-addc0108ad2d/0AN3084__738_4V__STD__shad__fr.png',
      attachmentAssetID: '3074457345616680513',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/5b6d9fbe-2ddc-4177-9178-addc0108ad2d/0AN3084__738_4V__STD__shad__fr.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt_PLP',
      sequence: '2.0',
      catentry_id: '3074457345616689332',
      usage: 'PLP',
      name: 'Quarter',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt.png',
      attachmentAssetID: '3074457345616680514',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '0AN3084__738_4V_2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt_Thumbnail',
      sequence: '1.0',
      catentry_id: '3074457345616689332',
      usage: 'Thumbnail',
      name: 'Quarter',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt.png',
      attachmentAssetID: '3074457345616680515',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/4d4e275e-5c6b-4bbd-96c4-addc0108a15b/2ea695fb-55bf-4fc0-81f1-addc0108a894/0AN3084__738_4V__STD__noshad__qt.png',
    },
  ],
}

export default mockedData
