import { useState, useEffect } from 'react'

import { getOrderItemContactLensesData } from '../../utils/order'
import { OrderItem } from '../../types/order'
import { OrderItemContactLensData } from '../../types/cart'
import sum from 'lodash/sum'
/**
 * Contact lens product info data hook
 * displays contact lens order item info,quantity
 * @param { OrderItem } orderItem current cart order item
 */

export const useContactLensCartItemData = ({
  orderItem,
}: {
  orderItem?: OrderItem
}) => {
  const [orderItemClData, setOrderItemClData] =
    useState<OrderItemContactLensData | null>({
      left: {
        data: null,
        quantity: null,
        orderItemId: null,
      },
      right: {
        data: null,
        quantity: null,
        orderItemId: null,
      },
    })

  const [totalBoxes, setTotalBoxes] = useState<number | null>(null)

  useEffect(() => {
    const orderItemClData: OrderItemContactLensData | null = !!orderItem
      ? getOrderItemContactLensesData(orderItem, [
          'x_productId',
          'x_eye',
          'x_partNumber',
          'x_dominance',
        ])
      : null

    const rightEyeQuantity = orderItemClData?.right.quantity
    const leftEyeQuantity = orderItemClData?.left.quantity

    const totalBoxes = sum([
      parseInt(leftEyeQuantity || '0'),
      parseInt(rightEyeQuantity || '0'),
    ])

    setOrderItemClData(orderItemClData)
    setTotalBoxes(totalBoxes)
  }, [orderItem])

  return {
    orderItemClData,
    setOrderItemClData,
    totalBoxes,
  }
}
