import React from 'react'
import { css, styled } from '@mui/material/styles'
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled'
import { StyledLoader } from '../Loader'

export interface GenericButtonProps {
  theme?: any
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  fillType?: 'fill' | 'outline'
  variant?: 'primary' | 'secondary' | 'tertiary'
  fullWidth?: boolean
  size?: 'medium' | 'big'
  loading?: boolean
}

export type ButtonProps = GenericButtonProps & ButtonUnstyledProps

export const variantMap = (
  theme: ButtonProps['theme'],
  variant: ButtonProps['variant'] = 'primary',
  fillType: ButtonProps['fillType'] = 'fill'
) => {
  const primaryFill = {
    background: theme.palette.custom.cyprus,
    hoverBackground: theme.palette.custom.sherpaBlue,
    color: theme.palette.custom.halfBaked,
    colorHover: theme.palette.custom.halfBaked,
    borderColor: theme.palette.custom.cyprus,
    borderColorhover: theme.palette.custom.sherpaBlue,
  }
  const secondaryFill = {
    background: theme.palette.custom.halfBaked,
    hoverBackground: theme.palette.custom.jaggedIce,
    color: theme.palette.custom.cyprus,
    colorHover: theme.palette.custom.cyprus,
    borderColor: theme.palette.custom.halfBaked,
    borderColorhover: theme.palette.custom.jaggedIce,
  }
  const tertiaryFill = {
    background: theme.palette.custom.wildSand,
    hoverBackground: theme.palette.custom.wildSand,
    color: theme.palette.custom.cyprus,
    colorHover: theme.palette.custom.cyprus,
    borderColor: theme.palette.custom.alto,
    borderColorhover: theme.palette.custom.alto,
  }

  const primaryOutline = {
    background: 'transparent',
    hoverBackground: 'transparent',
    color: theme.palette.custom.cyprus,
    colorHover: theme.palette.custom.cyprus,
    borderColor: theme.palette.custom.cyprus,
    borderColorhover: theme.palette.custom.halfBaked,
  }
  const secondaryOutline = {
    background: 'transparent',
    hoverBackground: 'transparent',
    color: theme.palette.custom.white,
    colorHover: theme.palette.custom.white,
    borderColor: theme.palette.custom.white,
    borderColorhover: theme.palette.custom.halfBaked,
  }

  return {
    fill: {
      primary: primaryFill,
      secondary: secondaryFill,
      tertiary: tertiaryFill,
    },
    outline: {
      primary: primaryOutline,
      secondary: secondaryOutline,
    },
  }[fillType][variant]
}

export const cssButton = ({
  theme,
  variant,
  fillType,
  fullWidth,
  size,
}: ButtonProps) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    appearance: 'none',
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: 14,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    minWidth: 120,
    width: '100%',
    //borderRadius: 1,
    lineHeight: 1.43,
    cursor: 'pointer',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: 'normal',
    height: size === 'big' ? 48 : 40,
    color: variantMap(theme, variant, fillType)?.color || 'inherit',
    background: variantMap(theme, variant, fillType)?.background || 'inherit',
    border: `1px solid${
      variantMap(theme, variant, fillType)?.borderColor || 'inherit'
    }`,

    '&:hover': {
      background:
        variantMap(theme, variant, fillType)?.hoverBackground || 'inherit',
      border: `1px solid
      ${variantMap(theme, variant, fillType)?.borderColorhover || 'inherit'}`,
    },

    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    svg: {
      width: '1rem',
      height: '1rem',
      rect: { fill: variantMap(theme, variant, fillType)?.color || 'inherit' },
    },

    [theme.breakpoints.up('sm')]: {
      width: fullWidth ? '100%' : 'auto',
    },
  })

export const Button = styled(
  ({
    labelText,
    startIcon,
    endIcon,
    children,
    loading,
    disabled,
    ...props
  }: ButtonProps) => (
    <ButtonUnstyled {...props} disabled={loading || disabled}>
      {loading ? (
        <StyledLoader size={16} extraStyle={{ margin: 0 }} />
      ) : (
        startIcon
      )}
      {labelText || children}
      {endIcon}
    </ButtonUnstyled>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'fillType',
  }
)(cssButton)
