import { useSite } from '../../foundation/hooks/useSite'
import { userDetailsSelector } from '../../redux/selectors/user'
import { useSelector } from 'react-redux'
import { PersonResponse } from '../../types/user'
import { useStores } from '../useStores'

export const getStoresByAttribute = (
  attributeName: string,
  storeId: string,
  userDetails?: PersonResponse | null
) => {
  return (
    (userDetails?.contextAttribute &&
      userDetails?.contextAttribute
        .find((attribute) => attribute.attributeName === attributeName)
        ?.attributeValue.find((value) => value.storeId === storeId)
        ?.value[0].split(',')) ||
    []
  )
}

export const useFavoriteStores = () => {
  const stores = useStores()
  const userDetails = useSelector(userDetailsSelector)
  const { mySite } = useSite()

  const favoriteStoreIds = getStoresByAttribute(
    'StoreLocator.favorites',
    `${mySite.storeID}`,
    userDetails
  )

  const defaultFavoriteStoreId = getStoresByAttribute(
    'StoreLocator.default',
    `${mySite.storeID}`,
    userDetails
  )[0]

  const favoriteStores = stores.filter((store) =>
    favoriteStoreIds?.includes(`${store.storeId}`)
  )

  return { favoriteStores, defaultFavoriteStoreId, favoriteStoreIds }
}
