import { createAsyncThunk } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import cartService from '../../../foundation/apis/transaction/cart.service'

export interface IFetchShipInfoArgs {
  widget?: string
  cancelToken?: CancelToken
  storeId?: string
  responseFormat?: string
  pageNumber?: number
  pageSize?: number
  orderId?: string
}

const fetchShipInfo = createAsyncThunk<any, IFetchShipInfoArgs>(
  'order/fetchShipInfo',
  async (args, { rejectWithValue }) => {
    try {
      const response = await cartService.getUsableShippingInfo(args)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchShipInfo
