import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Button, TextField } from '../../components/UI'

export const AccountSummaryWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.light.secondary,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    rowGap: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(14),
  },
}))

export const AccountSummaryHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const AccountSummaryTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  fontSize: theme.spacing(5),
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.5,
  },
}))

export const AccountSummaryFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(9),

  [theme.breakpoints.up('sm')]: {
    rowGap: theme.spacing(10),
  },
}))

export const AccountSummaryFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  rowGap: theme.spacing(6),
}))

export const AccountSummaryLastNameContainer = styled(
  AccountSummaryFieldContainer
)(() => ({
  justifyContent: 'end',
}))

export const AccountSummaryFieldLabel = styled('span')(({ theme }) => ({
  flexGrow: 0,
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}))

export const AccountSummaryContainerWrapper = styled('div')(({ theme }) => ({
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(12),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
    marginBottom: 0,
  },
}))

export const AccountSummaryContainer = styled('div')<{ nrOfColumns: number }>(
  ({ theme, nrOfColumns }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: theme.spacing(9),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `repeat(${nrOfColumns}, 1fr)`,
      columnGap: theme.spacing(10),
      rowGap: theme.spacing(10),
      width: `calc(100% - ${theme.spacing(6)})`,
    },
  })
)

export const AccountSummarySaveButton = styled(Button)(() => ({
  maxWidth: 163,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}))

export const AccountSummaryTextField = styled(TextField)(({ theme }) => ({
  '*::placeholder': {
    textTransform: 'uppercase',
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: 343,
  },
}))

export const AccountSummarySubmitSuccessMessage = styled(Typography)(
  ({ theme }) => ({
    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: 1.5,
    color: theme.palette.color.success,
  })
)

export const AccountSummarySubmitSuccessMessageWrapper = styled('div', {
  name: 'PersonalDetails',
  slot: 'SuccessMessageWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(2.5),
  },
}))

export const AccountSummarySaveChangesWrapper = styled('div', {
  name: 'PersonalDetails',
  slot: 'UpdateButtonWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(8),
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column-reverse',
    rowGap: theme.spacing(6),
    alignItems: 'flex-start',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(8),
  },
}))
