import { styled } from '@mui/material/styles'
import Alert from '@mui/lab/Alert'

export const StyledAlert = styled(Alert)`
  border-radius: 0;
  background-color: #9b0800;

  &.custom-content {
    background-color: transparent;
  }

  &.MuiAlert-filledSuccess {
    color: rgb(48, 48, 48);
    font-weight: 400;
    background-color: #ecfaeb;
    border: 1px solid #1a930f;
    .MuiAlert-icon {
      color: rgb(26, 147, 15);
    }
  }
`
