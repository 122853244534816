import { SuggestedFeatures } from '../../types/FrameGenius/frameGenius'
import { Size, Sizes } from '../../types/product'

interface ISizeAdvisorUtil {
  addSizeAdvisorHashPath: () => void
  removeSizeAdvisorHashPath: () => void
  doesUrlContainSizeAdvisorHashPath: () => boolean
  trackSizeAdvisorOpenEvent: () => void
  setSizeAdvisorPlpToggleStatus: (status: boolean) => void
  getSizeAdvisorPlpToggleStatus: () => boolean
  mapSuggestedSizes: (
    sizes: Size[],
    suggestedFeatures: SuggestedFeatures
  ) => SuggestedSizes | undefined
  // TODO: Map size interface
  isSizeRecommended: (size: any, suggestedSizes: SuggestedSizes) => boolean
  // TODO: Map size interface
  isSizeAlsoRightForYou: (size: any, suggestedSizes: SuggestedSizes) => boolean
  // TODO: Map size interface
  isSizeTooTight: (size: any, suggestedSizes: SuggestedSizes) => boolean
  // TODO: Map size interface
  isSizeNextBestSize: (size: any, suggestedSizes: SuggestedSizes) => boolean
  // TODO: Map size interface
  isSizeTooLoose: (size: any, suggestedSizes: SuggestedSizes) => boolean
  isSizeAdvisorExcludedInThisPage: (
    isSizeAdvisorFeatureEnabled: boolean
  ) => boolean
  mapSuggestionLabel: (
    sizeOrder: number,
    suggestedSizes: SuggestedSizes
  ) => string
}
export enum SizeAdvisorKeys {
  PLP_TOGGLE_STATUS = 'sizeAdvisorPlpToggleStatus',
  RECOMMENDED = 'recommended',
  ALSO_RIGHT_FOR_YOU = 'also-right-for-you',
  TIGHT = 'tight',
  NEXT_BEST_SIZE = 'next-best-size',
  LOOSE = 'loose',
}

export interface SuggestedSizes {
  [size: string]: string
}

class SizeAdvisorUtil implements ISizeAdvisorUtil {
  private SIZE_ADVISOR_SUGGESTED_SIZE_HASH = '#/suggested-size'

  trackSizeAdvisorOpenEvent = () => {}

  setSizeAdvisorPlpToggleStatus = (status: boolean) => {
    sessionStorage.setItem(SizeAdvisorKeys.PLP_TOGGLE_STATUS, `${status}`)
    localStorage.setItem(SizeAdvisorKeys.PLP_TOGGLE_STATUS, `${status}`)
  }

  getSizeAdvisorPlpToggleStatus = (): boolean => {
    const sessionStoragePlpToggleStatus = sessionStorage.getItem(
      SizeAdvisorKeys.PLP_TOGGLE_STATUS
    )
    const localStoragePlpToggleStatus = localStorage.getItem(
      SizeAdvisorKeys.PLP_TOGGLE_STATUS
    )
    const isToggleEnabled =
      sessionStoragePlpToggleStatus || localStoragePlpToggleStatus
    return isToggleEnabled === 'true'
  }

  mapSuggestedSizes = (
    sizes: Size[],
    suggestedFeatures: SuggestedFeatures
  ): SuggestedSizes | undefined => {
    const mappedSizes = {} as SuggestedSizes

    sizes.forEach((size: Size) => {
      const sizeLabel = Sizes[size.sizeOrder]
      // TODO: find better way to map support array
      const hinge = size?.hingeDistance
      mappedSizes[sizeLabel] = hinge
    })

    // TODO: Remove "as any" casting for window object
    const feelingWornSizes = (
      window as any
    ).frameAdvisor?.SizeAdvisorWidget?.getSuggestedSizes(
      suggestedFeatures?.size?.optimalHinge,
      mappedSizes
    )
    return feelingWornSizes
  }

  // TODO: map size interface
  private crossSizeByFitting = (
    size: any,
    suggestedSizes: SuggestedSizes,
    fitting: string
  ) => {
    if (!suggestedSizes) {
      return false
    }
    const entry = suggestedSizes[size.label]
    return !!entry && entry === fitting
  }
  // TODO: Map size interface
  isSizeRecommended = (size: any, suggestedSizes: SuggestedSizes) => {
    return this.crossSizeByFitting(
      size,
      suggestedSizes,
      SizeAdvisorKeys.RECOMMENDED
    )
  }
  // TODO: Map size interface
  isSizeAlsoRightForYou = (size: any, suggestedSizes: SuggestedSizes) => {
    return this.crossSizeByFitting(
      size,
      suggestedSizes,
      SizeAdvisorKeys.ALSO_RIGHT_FOR_YOU
    )
  }
  // TODO: Map size interface
  isSizeTooTight = (size: any, suggestedSizes: SuggestedSizes) => {
    return this.crossSizeByFitting(size, suggestedSizes, SizeAdvisorKeys.TIGHT)
  }
  // TODO: Map size interface
  isSizeNextBestSize = (size: any, suggestedSizes: SuggestedSizes) => {
    return this.crossSizeByFitting(
      size,
      suggestedSizes,
      SizeAdvisorKeys.NEXT_BEST_SIZE
    )
  }
  // TODO: Map size interface
  isSizeTooLoose = (size: any, suggestedSizes: SuggestedSizes) => {
    return this.crossSizeByFitting(size, suggestedSizes, SizeAdvisorKeys.LOOSE)
  }

  addSizeAdvisorHashPath = () => {
    window.location.hash = this.SIZE_ADVISOR_SUGGESTED_SIZE_HASH
  }

  removeSizeAdvisorHashPath = () => {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
  }

  doesUrlContainSizeAdvisorHashPath = (): boolean => {
    return window.location.hash === this.SIZE_ADVISOR_SUGGESTED_SIZE_HASH
  }

  isSizeAdvisorExcludedInThisPage = (isSizeAdvisorFeatureEnabled: boolean) => {
    // TODO: implement exclusion mechanism
    return false && isSizeAdvisorFeatureEnabled
  }

  mapSuggestionLabel = (sizeOrder: number, suggestedSizes: SuggestedSizes) => {
    const mappedCurrentSize = { label: Sizes[sizeOrder] }
    const isSizeRecommended =
      !!suggestedSizes &&
      this.isSizeRecommended(mappedCurrentSize, suggestedSizes)
    const isSizeAlsoRightForYou =
      !!suggestedSizes &&
      this.isSizeAlsoRightForYou(mappedCurrentSize, suggestedSizes)
    const isSizeTooTight =
      !!suggestedSizes && this.isSizeTooTight(mappedCurrentSize, suggestedSizes)
    const isSizeNextBestSize =
      !!suggestedSizes &&
      this.isSizeNextBestSize(mappedCurrentSize, suggestedSizes)
    const isSizeTooLoose =
      !!suggestedSizes && this.isSizeTooLoose(mappedCurrentSize, suggestedSizes)

    // TODO: Find better way for mapping suggestions label
    if (isSizeRecommended) {
      return 'Recommended'
    } else if (isSizeAlsoRightForYou) {
      return 'AlsoRightForYou'
    } else if (isSizeTooTight) {
      return 'SizeTooTight'
    } else if (isSizeNextBestSize) {
      return 'NextBestSize'
    } else if (isSizeTooLoose) {
      return 'SizeTooLoose'
    }
    return ''
  }
}
export default new SizeAdvisorUtil()
