import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { SiteInfo } from 'src/redux/reducers'
import { siteApi } from './query'
import initStates from 'src/redux/reducers/initStates'
import { CommerceEnvironment } from 'src/constants/common'
import { SiteInfoService } from 'src/foundation/hooks/useSite/SiteInfoService'
import { storageSessionHandler } from 'src/foundation/utils/storageUtil'
import i18n from 'src/i18n'
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from 'src/redux/actions/user'
import Log from 'src/services/Log'
import listenerMiddleware from 'src/redux/listener'
export type SiteInfoType = SiteInfo | Record<any, never>
export interface SiteReducerState {
  currentSite: SiteInfo | null
}

const initialState = initStates.site
export type SiteApiState = Partial<SiteReducerState>
const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      siteApi.endpoints.initializeSite.matchFulfilled,
      (state, { payload }: PayloadAction<SiteInfo>) => {
        Object.assign(state, { currentSite: { ...payload } })
      }
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(siteApi.endpoints.initializeSite.matchFulfilled),
  effect: async (action, listenerApi) => {
    const { payload: siteInfo } = action
    SiteInfoService.getSiteInfo().setSiteValue(siteInfo)
    localStorage.setItem('FORTER_SITE_ID', siteInfo.xStoreCfg?.FORTER_SITE_ID)
    listenerApi.dispatch(
      INIT_STATE_FROM_STORAGE_ACTION({
        widget: 'StoreAppContainer',
      })
    )

    storageSessionHandler.triggerUserStorageListener(() =>
      listenerApi.dispatch(
        LISTEN_USER_FROM_STORAGE_ACTION({
          widget: 'StoreAppContainer',
        })
      )
    )

    // SET TRANSLATE
    const locale =
      //localStorageUtil.get(LOCALE)?.split('_').join('-') ||
      CommerceEnvironment.languageMap[siteInfo?.defaultLanguageID]
        .split('_')
        .join('-')
    //Log.info('MYSITE LOCALE: ', mySite.locale)
    Log.info('LANGUAGEMAP LOCALE: ', locale)
    if (locale !== i18n.languages[0]) {
      i18n.changeLanguage(locale)
    }
    Log.info('SET LOCALE in storeAppContainer ', locale)
    listenerApi.unsubscribe()
  },
})

export default siteSlice.reducer
