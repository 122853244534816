//Standard libraries
import { Canceler } from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RESET_ERROR_ACTION } from '../../redux/actions/error'
import {
  AUTHENTICATION_ERROR,
  BAD_PARMS_ERROR,
  INVALID_NEW_PASSWORD,
  INVALID_PARAMETER_ERROR,
  LOCK_ORDER_ACTION,
  LOCK_ORDRE_ERROR,
  PARENT_IFRAME,
  SESSION_ERROR_ACTION,
} from '../constants/csr'

const useCSRForUser = () => {
  const dispatch = useDispatch()
  let cancels: Canceler[] = []
  const handleForUserSessionError = (errorMsgKey: string) => {
    const parentIFrame: any = window[PARENT_IFRAME]
    if (parentIFrame) {
      const sessionError = {
        msgKey: errorMsgKey,
        className: 'error',
      }
      parentIFrame.sendMessage(
        {
          type: SESSION_ERROR_ACTION,
          payload: { sessionError },
        },
        window.location.origin
      )
    }
  }
  const handleLockOrderError = (error: any) => {
    const parentIFrame: any = window[PARENT_IFRAME]
    if (parentIFrame) {
      dispatch(RESET_ERROR_ACTION())
      parentIFrame.sendMessage(
        {
          type: LOCK_ORDER_ACTION,
          payload: error,
        },
        window.location.origin
      )
    }
  }

  const isOrderLockError = (error: any) => {
    if (PARENT_IFRAME in window) {
      return LOCK_ORDRE_ERROR.includes(error.errorKey)
    } else {
      return false
    }
  }

  const isAuthenticationError = (error: any) => {
    return AUTHENTICATION_ERROR.includes(error.errorKey)
  }

  const isBadParmsError = (error: any) => {
    return BAD_PARMS_ERROR.includes(error.errorKey)
  }

  const isInvalidParameterError = (error: any) => {
    return INVALID_PARAMETER_ERROR.includes(error.errorKey)
  }

  const isInvalidResetPassword = (error: any) => {
    return INVALID_NEW_PASSWORD.includes(error.errorKey)
  }

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  return {
    handleForUserSessionError,
    handleLockOrderError,
    isOrderLockError,
    isAuthenticationError,
    isBadParmsError,
    isInvalidParameterError,
    isInvalidResetPassword,
  }
}

export { useCSRForUser }
