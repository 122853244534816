import { createAsyncThunk } from '@reduxjs/toolkit'
import orderService from '../../../foundation/apis/transaction/order.service'

const fetchPaypalExpressCheckStatus = createAsyncThunk<
  { orderId?: string; decision?: string },
  any
>('order/fetchPaypalExpressCheckStatus', async (args) => {
  const response = await orderService.paypalExpressCheckStatus(args)
  return response.data
})

export default fetchPaypalExpressCheckStatus
