const getQueryParams = (name: string) => {
  const url = new URL(window.location.href)
  return url.searchParams.get(name)
}

const enableAnalytics = () => {
  // enable/disable analytics by query string
  const flag = getQueryParams('analytics')
  if (flag) {
    return flag === 'true'
  }
  return boolean(process.env.REACT_APP_ENABLE_ANALYTICS) || true
}

const enableMonetate = () => {
  // enable/disable monetate by query string
  const flag = getQueryParams('monetate')
  if (flag) {
    return flag === 'true'
  }
  return boolean(process.env.REACT_APP_ENABLE_MONETATE) || false
}

const boolean = (value: string | undefined) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

const safeEnv = (key: string) => {
  const value = process.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

const config = {
  name: safeEnv('REACT_APP_STORENAME'),
  storeGroup: safeEnv('REACT_APP_STOREGROUP'),
  env: 'production',
  log2api: true, // used to trace logs to api server.
  cmsApiUrl: safeEnv('REACT_APP_API_URL'),
  logServerUrl: safeEnv('REACT_APP_LOG_SERVER_URL'),
  maintenanceServiceUrl: safeEnv('REACT_APP_MAINTENANCE_CHECK_URL'),
  maintenanceStatus: 418, // https://luxotticaretail.atlassian.net/browse/ARNETTE-1935
  cmsImageServerUrl: safeEnv('REACT_APP_CMS_IMAGES_URL'),
  defaultCountry: safeEnv('REACT_APP_DEFAULT_COUNTRY'),
  defaultLocale: safeEnv('REACT_APP_DEFAULT_LOCALE'),
  storePrefix: safeEnv('REACT_APP_STOREPREFIX'),
  defaultDamDomain: safeEnv('REACT_APP_DAM_DOMAIN'),
  useMockedAttachments: safeEnv('REACT_APP_USE_MOCKED_ATTACHMENTS'),
  isCmsPreviewEnabled: safeEnv('REACT_APP_PREVIEW_ENABLED'),
  permamentStoreDays: 30,
  apiCalltimeout: 500000,
  catalog: {
    topCategoriesDepthLimit: '11,11',
    productionListFields: 'facetView',
    keywordLimit: 4,
  },
  pagination: {
    pageLimit: 12,
    pageDefaultOffset: 0,
  },
  orders: {
    calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
    calculateOrder: '1',
    inventoryValidation: true,
    allocate: '***',
    backOrder: '***',
    remerge: '***',
    check: '*n',
    sortOrderItemBy: 'orderItemID',
    pagination: {
      pageLimit: 10,
      pageSizeOptions: [10, 25, 50],
    },
  },
  enableAnalytics: enableAnalytics(),
  tealiumAnalytics: {
    profile: '',
    shopperSlug: '',
    gtagId: '',
    isEnabled: false,
  },
  googleAnalytics: {
    id: '',
    digitalCatalogId: '',
    shopperSlug: '',
    gtagId: '',
  },
  frameGenius: {
    frameAdvKey: safeEnv('REACT_APP_FASA_KEY'),
    frameAdvisor: {
      initPositionFloatingBtn: {
        x: 50,
        y: 150,
      },
      multibrand: true,
      enableVideoMode: false,
      //TODO: cleanup text row in scope of DCW-5934
      productRequestRowsLimit: 24,
      //TODO: cleanup text row in scope of DCW-5934
      apiPrefix: 'DC',
      isDebuggingModeEnabled: false,
    },
    source: safeEnv('REACT_APP_FASA_SOURCE'),
  },
  enableMonetate: enableMonetate(),
}

export type AppConfig = typeof config

export default config
