//Standard libraries
import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTION_TYPES from '../action-types/orderDetails'
import { OrderDetailsReducerState } from '../reducers'

const {
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_FAIL,
  FETCH_ORDER_ITEMS_SUCCESS,
  FETCH_ORDER_ITEMS_FAIL,
  SET_ORDER_DETAILS_LOADINGS,
  RESET_ORDER_DETAILS,
} = ACTION_TYPES

const FETCH_ORDER_DETAILS_ACTION = createAction<any, string>(
  FETCH_ORDER_DETAILS
)

const FETCH_ORDER_DETAILS_SUCCESS_ACTION = createAction<any, string>(
  FETCH_ORDER_DETAILS_SUCCESS
)

const FETCH_ORDER_DETAILS_FAIL_ACTION = createAction<any, string>(
  FETCH_ORDER_DETAILS_FAIL
)

const FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION = createAction<any, string>(
  FETCH_ORDER_ITEMS_SUCCESS
)

const FETCH_ORDER_ITEM_FAIL_ACTION = createAction<any, string>(
  FETCH_ORDER_ITEMS_FAIL
)

const SET_ORDER_DETAILS_LOADINGS_ACTION = createAction<
  string | OrderDetailsReducerState['loadings'],
  typeof SET_ORDER_DETAILS_LOADINGS
>(SET_ORDER_DETAILS_LOADINGS)

const RESET_ORDER_DETAILS_ACTION = createAction(RESET_ORDER_DETAILS)

export {
  FETCH_ORDER_DETAILS_ACTION,
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_FAIL_ACTION as FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  SET_ORDER_DETAILS_LOADINGS_ACTION,
  RESET_ORDER_DETAILS_ACTION,
}
