import React, { FC, useCallback, useEffect, useState } from 'react'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { generateAccountPagePath } from '../Account/Account'
import { userIdSelector } from '../../redux/selectors/user'
import { Order } from '../../types/order'
import orderService from '../../foundation/apis/transaction/order.service'
import DateService from '../../services/DateService'
import CurrencyService from '../../services/CurrencyService'
import {
  ORDER_STATUSES,
  PENDING_PRESCRIPTION_STATUSES,
} from '../../constants/order'
import {
  ACCOUNT_CHILDREN,
  CART,
  PRESCRIPTION_VERIFICATION,
} from '../../constants/routes'
import {
  BagIcon,
  // HeartIconSlim,
  LookIcon,
} from '../../components/UI/Icons'
import {
  DashboardWrapper,
  DashboardTitleWrapper,
  DashboardTile,
  DashboardContainerWrapper,
  DashboardRow,
  DashboardTileHeader,
  DashboardTileContent,
  DashboardTileHeaderWrapper,
  DashboardTileSubheader,
  DashboardLastOrderContent,
  DashboardLastOrderTitle,
  DashboardLastOrderDetails,
  DashboardLastOrderDetailsRow,
  DashboardStyledAnchorCTA,
  DashboardLastOrderDetailsLabel,
  DashboardLastOrderDetailsItem,
  DashboardStyledLinkCTA,
  ShopNowAccountButton,
  StyledLinkAsButton,
  StyledButton,
  DashboardMyOrders,
  DashboardStyledMyStores,
  DashboardFindYourLook,
  DashboardMyFavourites,
} from './AccountDashboard.style'
import AccountDashboardLoader from './AccountDashboardLoader'

import { useTranslation } from 'react-i18next'
import cartService from '../../foundation/apis/transaction/cart.service'
import DashboardWishlist from './components/DashboardWishlist'
import { PLP_LINK_BRANDS } from '../../constants/ui'
import { useFrameGenius } from '../../components/FrameGenius/FrameGeniusContext'
import theme from './../../themes'
import { DashboardMyStores } from './components/DashboardMyStores'
import { formatPathWithBase } from '../../utils/url'
import useBreakpoints from '../../hooks/useBreakpoints'

const AccountDashboard: FC = () => {
  const { basePath, langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const { isViewportWidthUnder769 } = useBreakpoints()
  const userId = useSelector(userIdSelector)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [orders, setOrders] = useState<Order[]>([])
  const [lastOrder, setLastOrder] = useState<Order | null>(null)
  const navigate = useNavigate()
  const invoiceUrl =
    lastOrder?.orderExtendAttribute.find(
      (attribute) => attribute.attributeName === 'xinvoiceUrls'
    )?.attributeValue || ''

  const formatPriceValue = useCallback((value: number): string => {
    return value.toFixed(2)
  }, [])

  const fetchAndSetOrders = useCallback(
    async (paginationPageNumber: number) => {
      try {
        setLoading(true)

        const response = await orderService.getOrderHistory({
          pageNumber: paginationPageNumber,
          userId,
          pageSize: 1,
        })

        setOrders(response || [])
      } catch (error) {
        setOrders([])
      } finally {
        setLoading(false)
      }
    },
    [setLoading, setOrders]
  )

  useEffect(() => {
    fetchAndSetOrders(1)
  }, [])

  useEffect(() => {
    if (orders?.length) {
      setLastOrder(orders[0])
    }
  }, [orders])
  const isOrderRoxable = orderService.isRoxable(lastOrder?.orderExtendAttribute)
  const onOrderAgain = (orderId: string, isRX: string) => {
    if (orderId) {
      cartService
        .copyOrder({
          copyOrderItemId: '*',
          fromOrderId: orderId,
          fromOrderId_0: orderId,
          rxCopyEnabled: isRX,
          newUserId: userId,
        })
        .then(() => {
          navigate(generatePath(`/:country/${CART}`, { country: langCode }))
        })
    }
  }
  const shopNowLink = generatePath(`/:country/${PLP_LINK_BRANDS}`, {
    country: langCode,
  })
  const fgData = useFrameGenius()

  return (
    <DashboardWrapper>
      <DashboardContainerWrapper>
        <DashboardTitleWrapper>
          {t('AccountDashboard.Title')}{' '}
        </DashboardTitleWrapper>
        {!isViewportWidthUnder769 ? (
          <>
            <DashboardRow>
              <DashboardMyOrders>
                <DashboardTile>
                  {isLoading ? (
                    <AccountDashboardLoader />
                  ) : !orders.length ? (
                    <>
                      <DashboardTileHeaderWrapper>
                        <BagIcon
                          width={48}
                          height={48}
                          htmlColor={theme.palette.text.dark.primary}
                        />
                        <DashboardTileHeader>
                          {t('AccountDashboard.MyOrders').toUpperCase()}
                        </DashboardTileHeader>
                      </DashboardTileHeaderWrapper>

                      <DashboardTileContent>
                        {t('AccountDashboard.EmptyOrderHistory')}
                      </DashboardTileContent>

                      <ShopNowAccountButton
                        dataElementId={'myOrdersCTA'}
                        to={shopNowLink}
                      >
                        {t('AccountDashboard.ShopNow')}{' '}
                      </ShopNowAccountButton>
                    </>
                  ) : (
                    <>
                      <DashboardTileHeaderWrapper>
                        <DashboardTileHeader>
                          {t('AccountDashboard.MyOrders')}
                        </DashboardTileHeader>
                        <DashboardTileSubheader>
                          {t('Order.NumberOfOrders', {
                            count: orders.length,
                          })}
                        </DashboardTileSubheader>
                      </DashboardTileHeaderWrapper>

                      {lastOrder && (
                        <DashboardLastOrderContent>
                          <DashboardLastOrderTitle>{`${t('Order.OrderId')} ${
                            lastOrder.orderId
                          }`}</DashboardLastOrderTitle>
                          <DashboardLastOrderDetails>
                            <DashboardLastOrderDetailsRow>
                              <DashboardLastOrderDetailsLabel>
                                {t('Order.Date')}
                              </DashboardLastOrderDetailsLabel>
                              <DashboardLastOrderDetailsItem>
                                {DateService.format(
                                  lastOrder.placedDate || '',
                                  'dd/MM/yyyy'
                                )}
                              </DashboardLastOrderDetailsItem>
                            </DashboardLastOrderDetailsRow>
                            <DashboardLastOrderDetailsRow>
                              <DashboardLastOrderDetailsLabel>
                                {t('Order.Status')}
                              </DashboardLastOrderDetailsLabel>
                              <DashboardLastOrderDetailsItem>
                                {ORDER_STATUSES.includes(
                                  lastOrder.orderStatus
                                ) ? (
                                  <>
                                    {t(
                                      `Order.Status_.${lastOrder.orderStatus}`
                                    )}{' '}
                                    <DashboardStyledLinkCTA
                                      onClick={() =>
                                        onOrderAgain(
                                          lastOrder.orderId,
                                          isOrderRoxable
                                        )
                                      }
                                    >
                                      {t('OrderDetails.Actions.OrderAgain')}
                                    </DashboardStyledLinkCTA>
                                  </>
                                ) : (
                                  t(`Order.Status_.${lastOrder.orderStatus}`)
                                )}
                                {PENDING_PRESCRIPTION_STATUSES.includes(
                                  lastOrder.orderStatus
                                ) && (
                                  <DashboardStyledAnchorCTA
                                    to={formatPathWithBase(
                                      `${PRESCRIPTION_VERIFICATION}?orderId=${lastOrder.orderId}`,
                                      basePath
                                    )}
                                  >
                                    {t('Order.PendingPrescriptionCta')}
                                  </DashboardStyledAnchorCTA>
                                )}

                                {!!invoiceUrl && (
                                  <DashboardStyledAnchorCTA to={invoiceUrl}>
                                    {t('OrderDetails.Actions.DownloadInvoice')}
                                  </DashboardStyledAnchorCTA>
                                )}
                              </DashboardLastOrderDetailsItem>
                            </DashboardLastOrderDetailsRow>
                            <DashboardLastOrderDetailsRow>
                              <DashboardLastOrderDetailsLabel>
                                {t('OrderDetails.Total')}
                              </DashboardLastOrderDetailsLabel>
                              <DashboardLastOrderDetailsItem>{`${CurrencyService.getSymbolByName(
                                lastOrder.grandTotalCurrency
                              )} ${formatPriceValue(
                                +lastOrder.grandTotal
                              )}`}</DashboardLastOrderDetailsItem>
                            </DashboardLastOrderDetailsRow>
                          </DashboardLastOrderDetails>
                        </DashboardLastOrderContent>
                      )}

                      <StyledLinkAsButton
                        dataElementId={'myOrdersCTA'}
                        to={generateAccountPagePath(
                          langCode,
                          ACCOUNT_CHILDREN.ORDER_HISTORY
                        )}
                      >
                        {t('AccountDashboard.ViewAllOrders')}
                      </StyledLinkAsButton>
                    </>
                  )}
                </DashboardTile>
              </DashboardMyOrders>
              <DashboardStyledMyStores>
                <DashboardTile>
                  <DashboardMyStores />
                </DashboardTile>
              </DashboardStyledMyStores>
            </DashboardRow>
            <DashboardRow>
              <DashboardFindYourLook>
                <DashboardTile>
                  <DashboardTileHeaderWrapper>
                    <LookIcon
                      width={48}
                      height={48}
                      htmlColor={theme.palette.text.dark.primary}
                    />
                    <DashboardTileHeader>
                      {t('AccountDashboard.FindYourLook').toUpperCase()}
                    </DashboardTileHeader>
                  </DashboardTileHeaderWrapper>

                  <DashboardTileContent>
                    {t('AccountDashboard.FindTheBest')}
                  </DashboardTileContent>

                  <StyledButton onClick={fgData.openFrameAdvisor}>
                    {t('AccountDashboard.FrameAdvisor')}
                  </StyledButton>
                </DashboardTile>
              </DashboardFindYourLook>
              <DashboardMyFavourites>
                <DashboardWishlist />
              </DashboardMyFavourites>
            </DashboardRow>
          </>
        ) : (
          <>
            <DashboardMyOrders>
              <DashboardTile>
                {isLoading ? (
                  <AccountDashboardLoader />
                ) : !orders.length ? (
                  <>
                    <DashboardTileHeaderWrapper>
                      <BagIcon
                        width={48}
                        height={48}
                        htmlColor={theme.palette.text.dark.primary}
                      />
                      <DashboardTileHeader>
                        {t('AccountDashboard.MyOrders').toUpperCase()}
                      </DashboardTileHeader>
                    </DashboardTileHeaderWrapper>

                    <DashboardTileContent>
                      {t('AccountDashboard.EmptyOrderHistory')}
                    </DashboardTileContent>

                    <ShopNowAccountButton
                      dataElementId={'myOrdersCTA'}
                      to={shopNowLink}
                    >
                      {t('AccountDashboard.ShopNow')}{' '}
                    </ShopNowAccountButton>
                  </>
                ) : (
                  <>
                    <DashboardTileHeaderWrapper>
                      <DashboardTileHeader>
                        {t('AccountDashboard.MyOrders')}
                      </DashboardTileHeader>
                      <DashboardTileSubheader>
                        {t('Order.NumberOfOrders', {
                          count: orders.length,
                        })}
                      </DashboardTileSubheader>
                    </DashboardTileHeaderWrapper>

                    {lastOrder && (
                      <DashboardLastOrderContent>
                        <DashboardLastOrderTitle>{`${t('Order.OrderId')} ${
                          lastOrder.orderId
                        }`}</DashboardLastOrderTitle>
                        <DashboardLastOrderDetails>
                          <DashboardLastOrderDetailsRow>
                            <DashboardLastOrderDetailsLabel>
                              {t('Order.Date')}
                            </DashboardLastOrderDetailsLabel>
                            <DashboardLastOrderDetailsItem>
                              {DateService.format(
                                lastOrder.placedDate || '',
                                'dd/MM/yyyy'
                              )}
                            </DashboardLastOrderDetailsItem>
                          </DashboardLastOrderDetailsRow>
                          <DashboardLastOrderDetailsRow>
                            <DashboardLastOrderDetailsLabel>
                              {t('Order.Status')}
                            </DashboardLastOrderDetailsLabel>
                            <DashboardLastOrderDetailsItem>
                              {ORDER_STATUSES.includes(
                                lastOrder.orderStatus
                              ) ? (
                                <>
                                  {t(`Order.Status_.${lastOrder.orderStatus}`)}{' '}
                                  <DashboardStyledLinkCTA
                                    onClick={() =>
                                      onOrderAgain(
                                        lastOrder.orderId,
                                        isOrderRoxable
                                      )
                                    }
                                  >
                                    {t('OrderDetails.Actions.OrderAgain')}
                                  </DashboardStyledLinkCTA>
                                </>
                              ) : (
                                t(`Order.Status_.${lastOrder.orderStatus}`)
                              )}
                              {PENDING_PRESCRIPTION_STATUSES.includes(
                                lastOrder.orderStatus
                              ) && (
                                <DashboardStyledAnchorCTA
                                  to={formatPathWithBase(
                                    `${PRESCRIPTION_VERIFICATION}?orderId=${lastOrder.orderId}`,
                                    basePath
                                  )}
                                >
                                  {t('Order.PendingPrescriptionCta')}
                                </DashboardStyledAnchorCTA>
                              )}

                              {!!invoiceUrl && (
                                <DashboardStyledAnchorCTA to={invoiceUrl}>
                                  {t('OrderDetails.Actions.DownloadInvoice')}
                                </DashboardStyledAnchorCTA>
                              )}
                            </DashboardLastOrderDetailsItem>
                          </DashboardLastOrderDetailsRow>
                          <DashboardLastOrderDetailsRow>
                            <DashboardLastOrderDetailsLabel>
                              {t('OrderDetails.Total')}
                            </DashboardLastOrderDetailsLabel>
                            <DashboardLastOrderDetailsItem>{`${CurrencyService.getSymbolByName(
                              lastOrder.grandTotalCurrency
                            )} ${formatPriceValue(
                              +lastOrder.grandTotal
                            )}`}</DashboardLastOrderDetailsItem>
                          </DashboardLastOrderDetailsRow>
                        </DashboardLastOrderDetails>
                      </DashboardLastOrderContent>
                    )}

                    <StyledLinkAsButton
                      dataElementId={'myOrdersCTA'}
                      to={generateAccountPagePath(
                        langCode,
                        ACCOUNT_CHILDREN.ORDER_HISTORY
                      )}
                    >
                      {t('AccountDashboard.ViewAllOrders')}
                    </StyledLinkAsButton>
                  </>
                )}
              </DashboardTile>
            </DashboardMyOrders>
            <DashboardStyledMyStores>
              <DashboardTile>
                <DashboardMyStores />
              </DashboardTile>
            </DashboardStyledMyStores>
            <DashboardFindYourLook>
              <DashboardTile>
                <DashboardTileHeaderWrapper>
                  <LookIcon
                    width={48}
                    height={48}
                    htmlColor={theme.palette.text.dark.primary}
                  />
                  <DashboardTileHeader>
                    {t('AccountDashboard.FindYourLook').toUpperCase()}
                  </DashboardTileHeader>
                </DashboardTileHeaderWrapper>

                <DashboardTileContent>
                  {t('AccountDashboard.FindTheBest')}
                </DashboardTileContent>

                <StyledButton onClick={fgData.openFrameAdvisor}>
                  {t('AccountDashboard.FrameAdvisor')}
                </StyledButton>
              </DashboardTile>
            </DashboardFindYourLook>
            <DashboardMyFavourites>
              <DashboardWishlist />
            </DashboardMyFavourites>
          </>
        )}
      </DashboardContainerWrapper>
    </DashboardWrapper>
  )
}

export default AccountDashboard
