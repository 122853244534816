import {
  PRODUCT_PRICE_USAGE_NAMES,
  PriceModel,
  ProductType,
  ServerProduct,
  ServerProductPrice,
  ServerProductXPrice,
} from '../types/product'
import {
  getFrontColor,
  getLensesColorFacet,
  getProductType,
} from '../utils/productAttributes'

import CurrencyService from './CurrencyService'
import { IFacet } from '../features/plp/query'
import { PRODUCT_TYPES_MAP } from '../constants/product'

class ProductService {
  getCurrentPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getCurrentPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getCurrentPriceModel(
    prices: ServerProductPrice[]
  ): ServerProductPrice | null {
    return (
      prices?.find(
        (p) => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.CURRENT
      ) || null
    )
  }

  getInitialPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getInitialPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getInitialPriceModel(
    prices: ServerProductPrice[]
  ): ServerProductPrice | null {
    return (
      prices?.find(
        (p) => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.INITIAL
      ) || null
    )
  }

  genPriceWithoutValue(prices: ServerProductPrice[]) {
    return prices.map((price) => {
      if (!price.value) {
        const priceWithBaseValue = prices.find((price) => !!price.value)

        return {
          ...price,
          value: priceWithBaseValue?.value || '0',
        }
      }

      return price
    })
  }

  calculateTotalPriceModel(prices: ServerProductPrice[]): ServerProductPrice {
    return prices.reduce((total, price) => {
      return { ...total, ...price, value: String(+total.value + +price.value) }
    })
  }

  getProductType(product?: ServerProduct): ProductType {
    return product
      ? PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()]
      : PRODUCT_TYPES_MAP.frames
  }

  /** Used in ProductTile in MoCos slider */
  getProductColorLabel(
    product: ServerProduct,
    showPipeBetweenAttrs?: boolean
  ): string {
    const productType = this.getProductType(product)

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.optical:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = getFrontColor(product)
        const lensColorFacet = getLensesColorFacet(product)
        return showPipeBetweenAttrs
          ? frontColor && lensColorFacet
            ? `${frontColor} | ${lensColorFacet}`.trim()
            : frontColor
          : frontColor
      default:
        return ''
    }
  }

  findPriceFacetByName(name: string) {
    return name.startsWith('OfferPrice_')
  }

  formatPriceFacet(
    priceFacet: IFacet,
    rangeStartPrefix: string,
    rangeEndPrefix: string
  ): IFacet {
    const priceCurrencyName = priceFacet.value
      .replace('price_', '')
      .toUpperCase()
    const currencySymbol = CurrencyService.getSymbolByName(priceCurrencyName)

    return {
      ...priceFacet,
      entry: priceFacet.entry.map((v) => ({
        ...v,
        count: `${v.count}`,
        label: v.label.replace(
          /\({([\*\d.]*)\sTO\s([\*\d.\s]*)]\)/g,
          (_match, rangeStart, rangeEnd) => {
            if (rangeStart.trim() === '*') {
              return `${rangeStartPrefix} ${currencySymbol + rangeEnd}`
            } else if (rangeEnd.trim() === '*') {
              return `${rangeEndPrefix} ${currencySymbol + rangeStart}`
            } else {
              return `${currencySymbol + rangeStart} - ${
                currencySymbol + rangeEnd
              }`
            }
          }
        ),
      })),
    }
  }
  isValidXPriceDate(price: ServerProductXPrice) {
    // Final fix for Chrome, Firefox and Safari:
    const fixDate = (dateString) => {
      const replaced = dateString.replace(/-/g, ' ').replace('.', ':')

      const parts = replaced.split(' ')

      const monthNames = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ]
      const monthNumber = monthNames.indexOf(parts[1])

      const timeParts = parts[3].split(':')
      let hour = parseInt(timeParts[0], 10)
      if (parts[4] === 'PM' && hour < 12) hour += 12
      if (parts[4] === 'AM' && hour === 12) hour = 0

      const seconds = timeParts[2]
        ? parseInt(timeParts[2].split('.')[0], 10)
        : 0

      return new Date(
        Date.UTC(
          2000 + parseInt(parts[2], 10),
          monthNumber,
          parseInt(parts[0], 10),
          hour,
          parseInt(timeParts[1], 10),
          seconds
        )
      )
    }

    const startDate = price?.startDate && fixDate(price?.startDate)
    const endDate = price?.endDate && fixDate(price?.endDate)

    const isValidStartDate = startDate && new Date(startDate) < new Date()
    const isValidEndDate =
      endDate === undefined || (endDate && new Date(endDate) > new Date())

    return isValidStartDate && isValidEndDate
  }

  getXPriceModel(xprice: ServerProductXPrice): PriceModel | null {
    const checkDates = this.isValidXPriceDate(xprice)
    if (!checkDates) return null

    return { currency: xprice.currency, price: xprice.price }
  }
}
/**
 * @deprecated move those methods under /utils/product
 */
export default new ProductService()
