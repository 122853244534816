import React from 'react'
import {
  IconButton as IconButtonMaterial,
  IconButtonProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledButton = styled(IconButtonMaterial)({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

export const IconButton = (props: IconButtonProps) => {
  return <StyledButton {...props} />
}
