//Standard libraries
import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
import {
  PROFILE_NAME_PARAM,
  SEARCH_TERM_GROUPING_PROFILE_NAME,
  SEARCH_TERM_PROFILE_NAME,
  SORTING_NOT_CLUSTERED,
} from '../../../constants/common'
import RequestService, {
  PaginationResponse,
  RequestProps,
} from '../../../services/RequestService'

//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
import { ServerProduct } from '../../../types/product'
import { SingleSortOrderOption } from '../../../redux/reducers'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const productsService = {
  /**
   * Gets Products
   * `@method`
   * `@name Products#findProductsUsingGET`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} associationType ` The type of the merchandising association to be returned.
   ** `@property {string} attachementFilter ` The attachment filter.
   ** `@property {string} attributeKeyword ` The attribute associated keywords to be returned.
   ** `@property {integer} catalogId ` The catalog identifier. If none is specified, the store default catalog will be used.
   ** `@property {string} category ` The category identifier.
   ** `@property {boolean} checkEntitlement ` Option to force an entitlement check.
   ** `@property {integer} contractId ` The contractId
   ** `@property {string} currency ` The currency code to use. Example usage : currency=USD. If no currency code is specified, the store default currency will be used.
   ** `@property {array} id ` The product identifiers
   ** `@property {integer} langId ` Language identifier. If not specified, the "locale" parameter will be used. If "locale" is not specified, then the store default language will be used.
   ** `@property {integer} pageSize ` The page size.
   ** `@property {array} partNumber ` The product part numbers.
   ** `@property {integer} productId ` The product identifier.
   ** `@property {string} profileName ` Profile name. Profiles determine the subset of data to be returned by a search query.
   ** `@property {string} searchTerm ` The term to search for.
   */
  findProductsUsingGET(
    propParameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    let site = getSite()
    let parameters = { ...propParameters }
    let siteContext: string = ''
    if (site) {
      siteContext = site.searchContext || ''
    }
    let domain = url || siteContext
    let path = '/api/v2/products'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (!parameters) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters = {
        ...parameters,
        storeId: site.storeID,
      }
    }
    let headerValues: any = {}
    headerValues['Accept'] = ['application/json']
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['associationType'] !== undefined) {
      const name = 'associationType'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['attachementFilter'] !== undefined) {
      const name = 'attachementFilter'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['attributeKeyword'] !== undefined) {
      const name = 'attributeKeyword'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['catalogId'] !== undefined) {
      const name = 'catalogId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['categoryId'] !== undefined) {
      const name = 'categoryId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['checkEntitlement'] !== undefined) {
      const name = 'checkEntitlement'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['contractId'] !== undefined) {
      const name = 'contractId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['currency'] !== undefined) {
      const name = 'currency'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }
    if (parameters['profileName'] !== undefined) {
      const name = 'profileName'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['id'] !== undefined) {
      const name = 'id'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['langId'] !== undefined) {
      const name = 'langId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Param renamed; pending swagger spec change
    if (parameters['limit'] !== undefined) {
      const name = 'limit'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['offset'] !== undefined) {
      const name = 'offset'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['orderBy'] !== undefined) {
      const name = 'orderBy'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['facet'] !== undefined) {
      const name = 'facet'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['facetLimit'] !== undefined) {
      const name = 'facetLimit'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['minPrice'] !== undefined) {
      const name = 'minPrice'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    //Temp; pending swagger spec change
    if (parameters['maxPrice'] !== undefined) {
      const name = 'maxPrice'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['partNumber'] !== undefined) {
      const name = 'partNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['productId'] !== undefined) {
      const name = 'productId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['profileName'] !== undefined) {
      const name = 'profileName'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['searchTerm'] !== undefined) {
      const name = 'searchTerm'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request "/api/v2/products" missing required parameter storeId'
      )
    }
    if (parameters['storeId'] !== undefined) {
      const name = 'storeId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (
      header.get('content-type') === 'multipart/form-data' &&
      Object.keys(form).length > 0
    ) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION
        ? localStorageUtil.get(SHOW_API_FLOW) === 'true'
        : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from +
              ' -> Search: ' +
              method +
              ' ' +
              requestUrl +
              '?' +
              queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  },

  /**
   * Gets Products
   *
   * `@method`
   * `@name Products#get`
   *
   * `@param queryParams` can have following properties:
   ** `@property {string} associationType ` The type of the merchandising association to be returned.
   ** `@property {string} attachementFilter ` The attachment filter.
   ** `@property {string} attributeKeyword ` The attribute associated keywords to be returned.
   ** `@property {integer} catalogId ` The catalog identifier. If none is specified, the store default catalog will be used.
   ** `@property {string} category ` The category identifier.
   ** `@property {boolean} checkEntitlement ` Option to force an entitlement check.
   ** `@property {integer} contractId ` The contractId
   ** `@property {string} currency ` The currency code to use. Example usage : currency=USD. If no currency code is specified, the store default currency will be used.
   ** `@property {array} id ` The product identifiers
   ** `@property {integer} langId ` Language identifier. If not specified, the "locale" parameter will be used. If "locale" is not specified, then the store default language will be used.
   ** `@property {integer} pageSize ` The page size.
   ** `@property {array} partNumber ` The product part numbers.
   ** `@property {integer} productId ` The product identifier.
   ** `@property {string} profileName ` Profile name. Profiles determine the subset of data to be returned by a search query.
   ** `@property {string} searchTerm ` The term to search for.
   */
  get(queryParams: RequestProps['queryParams']) {
    return RequestService.request<
      PaginationResponse<ServerProduct & { type: 'item' }>
    >({
      extraParams: { siteContextKey: 'search' },
      method: 'GET',
      path: '/api/v2/products',
      queryParams,
    })
  },

  getUrlAndRequestParams(
    paramsBase,
    searchTerm: string,
    facets: any,
    facetLimits: string[],
    minPrice: number,
    maxPrice: number,
    sortOption: SingleSortOrderOption,
    offset: number = 0
  ): {
    requestParams: Record<string, string>
    urlQueryParams: Record<string, string>
  } {
    const urlQueryParams = {}
    const requestParams = Object.assign({}, paramsBase)

    const selectedFacetsArray = Object.keys(facets)
    if (selectedFacetsArray.length > 0) {
      urlQueryParams['facet'] = selectedFacetsArray
    }
    if (facetLimits.length > 0) {
      urlQueryParams['facetLimit'] = facetLimits
    }
    if (minPrice > -1 && maxPrice > -1) {
      urlQueryParams['minPrice'] = minPrice
      urlQueryParams['maxPrice'] = maxPrice
    }

    if (sortOption) {
      requestParams['orderBy'] = sortOption.value
      requestParams[PROFILE_NAME_PARAM] = sortOption.profileName

      if (sortOption.value !== '0') {
        urlQueryParams['orderBy'] = requestParams['orderBy']
        urlQueryParams[PROFILE_NAME_PARAM] = requestParams[PROFILE_NAME_PARAM]
      } else {
        delete urlQueryParams['orderBy']
        delete urlQueryParams[PROFILE_NAME_PARAM]
      }
    }

    if (searchTerm.length) {
      urlQueryParams[PROFILE_NAME_PARAM] = SORTING_NOT_CLUSTERED.includes(
        sortOption.value as string
      )
        ? SEARCH_TERM_PROFILE_NAME
        : SEARCH_TERM_GROUPING_PROFILE_NAME
    }

    if (offset > 0) {
      urlQueryParams['offset'] = offset
    }

    return {
      requestParams: { ...requestParams, ...urlQueryParams },
      urlQueryParams,
    }
  },
}

export default productsService
