import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ServerProduct,
  ProductType,
  ContactLensesData,
} from '../../types/product'
import { productApi } from './query'
import { PaginationResponse } from '../../services/RequestService'
import { getCurrentProductItem } from '../../utils/product'
import ProductService from '../../services/ProductService'

export const toggleAddToCartButton = (productType: ProductType): boolean => {
  switch (productType) {
    case 'contact-lenses':
      return false
    default:
      return true
  }
}

export type ProductState = {
  productType: ProductType | null
  contactLensesData?: ContactLensesData | null
  addContactLensesToCartError?: boolean
  addToCartEnabled?: boolean
  vmSupport?: string
  selectedPartNumber?: string
}

export type ProductApiState = Partial<ServerProduct>

const initialState: ProductState = {
  productType: null,
  contactLensesData: {
    left: {},
    right: {},
  },
  addToCartEnabled: false,
  addContactLensesToCartError: false,
  vmSupport: '',
  selectedPartNumber: '',
}

export const {
  actions: {
    updateContactLensData,
    toggleAddToCart,
    toggleAddContactLensesToCartError,
    toggleVmSupport,
    togglePartNumber,
  },
  reducer,
} = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateContactLensData: (
      state: ProductState,
      action: PayloadAction<ContactLensesData>
    ) => {
      state.contactLensesData = action.payload
    },
    toggleAddToCart: (state: ProductState, action: PayloadAction<boolean>) => {
      state.addToCartEnabled = action.payload
    },
    toggleAddContactLensesToCartError: (
      state: ProductState,
      action: PayloadAction<boolean>
    ) => {
      state.addContactLensesToCartError = action.payload
    },
    toggleVmSupport: (state: ProductState, action: PayloadAction<string>) => {
      state.vmSupport = action.payload
    },
    togglePartNumber: (state: ProductState, action: PayloadAction<string>) => {
      state.selectedPartNumber = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.getProductDetails.matchFulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<PaginationResponse<ServerProduct & { type: 'item' }>>
      ) => {
        const productData = payload?.contents?.find((content) => !!content)
        const currentProduct =
          productData || payload
            ? getCurrentProductItem(
                productData?.partNumber!,
                productData?.cluster
              )
            : null

        const productType = ProductService.getProductType(currentProduct!)

        state.productType = productType
        state.addToCartEnabled = toggleAddToCartButton(productType)
      }
    )
  },
})

export default reducer
