import { AppDispatch } from '../../redux/store'
import {
  closeDrawerMegaMenu,
  lastSelectedProduct,
  openDrawerCartSummary,
  openDrawerFilters,
  openDrawerMegaMenu,
  openDrawerNewsLetter,
  openDrawerProductNotificationAvailable,
  toggleHamburgerMenuOpen,
  openDrawerSearch,
  IUiState,
  productsMobileListingLayout,
  openModalSignIn,
  openModalRegistration,
  faqsSearch,
} from './slice'

export const setOpenDrawerMegaMenu =
  (id: string, name: string) => (dispatch: AppDispatch) => {
    dispatch(openDrawerMegaMenu({ id, name }))
  }

export const setCloseDrawerMegaMenu = () => (dispatch: AppDispatch) => {
  dispatch(closeDrawerMegaMenu())
}

export const setOpenDrawerFilters =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openDrawerFilters(value))
  }

export const setOpenDrawerSearch =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openDrawerSearch(value))
  }

export const setOpenDrawerNewsLetter =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openDrawerNewsLetter(value))
  }

export const setOpenDrawerCartSummary =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openDrawerCartSummary(value))
  }

export const setOpenDrawerProductNotificationAvailable =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openDrawerProductNotificationAvailable(value))
  }

export const setLastSelectedProduct =
  (value: string) => (dispatch: AppDispatch) => {
    dispatch(lastSelectedProduct(value))
  }

export const setHamburgerMenuOpenStatus =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(toggleHamburgerMenuOpen(value))
  }

export const setProductsMobileListingLayout =
  (value: IUiState['productsMobileListingLayout']) =>
  (dispatch: AppDispatch) => {
    dispatch(productsMobileListingLayout(value))
  }

export const setOpenModalSignIn =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openModalSignIn(value))
  }

export const setOpenModalRegistration =
  (value: boolean) => (dispatch: AppDispatch) => {
    dispatch(openModalRegistration(value))
  }

export const vMisOpen = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch(vMisOpen(value))
}

export const setFaqsSearch = (value: string) => (dispatch: AppDispatch) => {
  dispatch(faqsSearch(value))
}
