import { shallowEqual, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { CommonData } from '../analytics/tealium/interfaces'
import { RootReducerState, SiteInfo } from '../../redux/reducers'
import { useStoreIdentity } from './useStoreIdentity'
import {
  appliedPromotionCodesSelector,
  orderDiscountNamesSelector,
  totalOrderDiscountSelector,
} from '../../features/order/selector'

export const DEFAULT_LANG = 'en-US'

export const getCommonAnalyticsData = (
  site: SiteInfo | null,
  country: string,
  langCode: string
) => {
  const currency = site?.defaultCurrencyID
  const parsedLanguage = langCode.split('-')[0].toUpperCase()
  const parsedCountry = country.toUpperCase()
  let baseAnalyticsData: Partial<CommonData> = {
    Page_Brand: 'DC',
    Page_Design: '',
    Page_Country: `${parsedCountry}`,
    Page_Language: `${parsedLanguage}`,
    Page_DeviceType: 'X',
    Page_Platform: 'WCS',
    Page_Server: '23.218.225.8',
    Page_Section2: '',
    Order_Currency: `${currency}`,
  }
  return baseAnalyticsData
}

export const getBaseAnalyticsDataByPageType = (
  pageType: string,
  site: SiteInfo | null,
  totalOrderDiscount: string,
  orderDiscountNames: string,
  discountCodes: string,
  country: string,
  langCode: string
): Partial<CommonData> => {
  let baseAnalyticsData = getCommonAnalyticsData(site, country, langCode)
  switch (pageType) {
    case 'notFound':
      baseAnalyticsData = omit(baseAnalyticsData, ['Order_Currency'])
      break
    case 'home':
      baseAnalyticsData = {
        ...baseAnalyticsData,
      }
      break
    case 'cart':
      baseAnalyticsData = {
        ...baseAnalyticsData,
        Order_DiscountAmount: totalOrderDiscount,
        Order_DiscountName: orderDiscountNames,
      }
      break
    case 'promo':
      baseAnalyticsData = {
        Order_DiscountCode: discountCodes,
        Order_DiscountName: orderDiscountNames,
      }
      break
    case 'delivery':
    case 'payment':
      baseAnalyticsData = {
        ...baseAnalyticsData,
        Order_DiscountAmount: totalOrderDiscount,
        Order_DiscountName: orderDiscountNames,
        // Order_GiftCardCode NOT AVAILABLE FOR ARNETTE
        Order_DiscountCode: discountCodes,
      }
      break
    case 'order-confirmation':
      baseAnalyticsData = {
        ...baseAnalyticsData,
        Order_DiscountAmount: totalOrderDiscount,
        Order_DiscountName: orderDiscountNames,
        // Order_GiftCardCode NOT AVAILABLE FOR ARNETTE
        Order_DiscountCode: discountCodes,
      }
      break
    case 'page':
      baseAnalyticsData = {
        ...baseAnalyticsData,
      }
      break
    case 'plp':
    case 'pdp':
    case 'profile':
    default:
  }
  return baseAnalyticsData
}

export const useAnalyticsData = (pageType?: string): Partial<CommonData> => {
  const { country, langCode } = useStoreIdentity()
  const totalOrderDiscount = useSelector(totalOrderDiscountSelector)
  const orderDiscountNames = useSelector(orderDiscountNamesSelector)
  const discountCodes = useSelector(appliedPromotionCodesSelector)
  return useSelector((state: RootReducerState) => {
    const currency = state.site.currentSite?.defaultCurrencyID
    const parsedLanguage = langCode.split('-')[0].toUpperCase()
    const parsedCountry = country.toUpperCase()
    let baseAnalyticsData: Partial<CommonData> = {
      Page_Brand: 'DC',
      Page_Design: '',
      Page_Country: `${parsedCountry}`,
      Page_Language: `${parsedLanguage}`,
      Page_DeviceType: 'X',
      Page_Platform: 'WCS',
      Page_Server: '23.218.225.8',
      Page_Section2: '',
      Order_Currency: `${currency}`,
    }
    switch (pageType) {
      case 'notFound':
        baseAnalyticsData = omit(baseAnalyticsData, ['Order_Currency'])
        break
      case 'home':
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case 'cart':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
        }
        break
      case 'promo':
        baseAnalyticsData = {
          Order_DiscountCode: discountCodes,
          Order_DiscountName: orderDiscountNames,
        }
        break
      case 'delivery':
      case 'payment':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          // Order_GiftCardCode NOT AVAILABLE FOR ARNETTE
          Order_DiscountCode: discountCodes,
        }
        break
      case 'typ':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          // Order_GiftCardCode NOT AVAILABLE FOR ARNETTE
          Order_DiscountCode: discountCodes,
        }
        break
      case 'page':
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case 'plp':
      case 'pdp':
      case 'profile':
      default:
    }
    return baseAnalyticsData
  }, shallowEqual)
}
