import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import cartService from '../../../foundation/apis/transaction/cart.service'
import fetchCart from './fetchCart'
import { CartPayload } from '../../../types/cart'

export interface IRemoveItemArgs {
  widget: string
  orderItemId?: any
  orderItemId_1?: any
  orderItemId_2?: any
  orderItemId_3?: any
  orderItemId_4?: any
}

const removeItem = createAsyncThunk<any, IRemoveItemArgs>(
  'order/removeItem',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const { widget } = args
      const orderItemId = args.orderItemId
      const orderItemId_1 = args.orderItemId_1
      const orderItemId_2 = args.orderItemId_2
      const orderItemId_3 = args.orderItemId_3
      const orderItemId_4 = args.orderItemId_4

      const body: CartPayload = {
        body: {
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
          orderItemId,
          orderItemId_1,
          orderItemId_2,
          orderItemId_3,
          orderItemId_4,
        },
        widget,
      }

      const response = await cartService.deleteOrderItem(body)

      await dispatch(fetchCart({})).unwrap()

      return { response, args }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default removeItem
