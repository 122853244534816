//Standard libraries
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { sendAccountInformationEvent } from '../../foundation/analytics/tealium/lib'
//UI
import {
  AccountSummaryWrapper,
  AccountSummaryHeader,
  AccountSummaryTitle,
  AccountSummaryContainerWrapper,
} from './AccountSummary.style'
import PersonalInformationSection from './components/PersonalInformationSection'

const AccountSummary: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    sendAccountInformationEvent({
      Page_Type: 'Profile',
      Page_Section1: 'Account',
    })
  }, [])

  return (
    <AccountSummaryWrapper>
      <AccountSummaryHeader>
        <AccountSummaryTitle>{t('AccountSummary.Title')}</AccountSummaryTitle>
      </AccountSummaryHeader>
      <AccountSummaryContainerWrapper>
        <PersonalInformationSection />
      </AccountSummaryContainerWrapper>
    </AccountSummaryWrapper>
  )
}

export default AccountSummary
