import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import cartService from '../../../foundation/apis/transaction/cart.service'
import fetchCart from './fetchCart'
import { CartPayload } from '../../../types/cart'

export interface IUpdateItemArgs {
  widget?: string
  orderItem?: {
    quantity?: string
    orderItemId?: string
  }[]
  orderItemId?: any
  orderItemId_1?: any
  orderItemId_2?: any
  orderItemId_3?: any
  orderItemId_4?: any
}

const updateItem = createAsyncThunk<any, IUpdateItemArgs>(
  'order/updateItem',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const { widget, orderItem } = args
      const body: CartPayload = {
        body: {
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderItem: orderItem,
        },
        widget,
      }

      const responseData = (await cartService.updateOrderItem(body)).data

      await dispatch(fetchCart({})).unwrap()

      return responseData
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default updateItem
