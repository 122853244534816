import { createTheme } from '@mui/material/styles'
import { customPalette } from '../color-palette'
// import fontsArray from './fonts'

//TODO decomment this line once BE store name is set
//const appName = process.env.REACT_APP_STORENAME
const appName = 'DavidClulow'
//customPalette = palette[appName?.toLowerCase()] ? palette[appName?.toLowerCase()] : palette.emerald
const storePalette = customPalette[appName.toLowerCase()]

const {
  wildSand,
  white,
  cyprus,
  alto,
  boulder,
  halfBaked,
  sherpaBlue,
  jaggedIce,
  tulipTree,
  thunderBird,
  greenPea,
  black,
  grey,
} = storePalette

export const themeProps = {
  themeName: 'DavidClulow',
  name: 'DavidClulow',
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    htmlFontSize: 16,
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    type: 'light',
    background: {
      dark: {
        primary: cyprus,
        secondary: halfBaked,
        tertiary: sherpaBlue,
        quaternary: jaggedIce,
      },
      light: {
        primary: white,
        secondary: wildSand,
        tertiary: alto,
        quaternary: boulder,
      },
    },
    text: {
      dark: {
        primary: cyprus,
        secondary: halfBaked,
        tertiary: sherpaBlue,
        quaternary: jaggedIce,
      },
      light: {
        primary: white,
        secondary: boulder,
        tertiary: alto,
        quaternary: wildSand,
      },
    },
    color: {
      success: greenPea,
      warning: tulipTree,
      error: thunderBird,
      black: black,
      grey: grey,
    },
    action: {
      hover: cyprus,
    },
    custom: storePalette,
  },
  spacing: 4,
  button: {
    backgroundColor: cyprus,
    '&:hover': {
      backgroundColor: cyprus,
    },
  },
  child: {
    backgroundColor: cyprus,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  breakpoints: {
    values: {
      xs: 375,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  shadows: [
    'none',
    '0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16), 0 0 2.9px 0 rgba(40, 41, 61, 0.04)',
    '0px 1px 4px 2px rgba(0,0,0,0.15), 0px 2px 1px 0px rgba(0,0,0,0.13)',
    '0px 1px 2px 2px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'inset 0px 1px 2px 2px rgba(0,0,0,0.1), inset 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  components: {
    MuiCssBaseline: {
      html: {
        fontSize: '16px',
      },
      body: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: 'normal',
      },
    },
  },
}

const theme = createTheme(themeProps)

export default theme
