import { SiteInfoArgs, SiteInfoService } from './SiteInfoService'

//Standard libraries
import { Dispatch } from 'react'
//Redux
import { SiteInfo } from '../../../redux/reducers'
//Foundation libraries
import { useSite } from './useSite'

/**
 *
 */
function getSite(): SiteInfo | null {
  return SiteInfoService.getSiteInfo().getSiteValue()
}

/**
 *
 */
function initSite(s: SiteInfoArgs, country: string, dispatch: Dispatch<any>) {
  return SiteInfoService.getSiteInfo().setSite(s, country, dispatch)
}

export { useSite, getSite, initSite }
