import get from 'lodash/get'
import { RootReducerState } from '../reducers'

const loginStatusSelector = (state: RootReducerState) => {
  return state.user.userLoggedIn || false
}
const loginErrorMsgSelector = (state: RootReducerState) => {
  return state.user.userLoginErrorMsg
}
const guestStatusSelector = (state: RootReducerState) => {
  return state.user.isGuest || false
}
const wcTokenSelector = (state: RootReducerState) => {
  return state.user.WCToken
}
const wcTrustedTokenSelector = (state: RootReducerState) => {
  return state.user.WCTrustedToken
}

const logonIdSelector = (state: RootReducerState) => {
  return state.user.details?.logonId || ''
}

const userIdSelector = (state: RootReducerState) => {
  return state.user.userId
}

const forUserIdSelector = (state: RootReducerState) => {
  return state.user.forUserId
}

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || ''
  const lastName = state.user.details?.lastName || ''
  return { firstName, lastName }
}

const userInitStatusSelector = (state: RootReducerState) => {
  return state.user.initiatedFromStorage
}

const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated
}

const registrationStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistration || false
}

const pendingRegistrationStatusSelector = (state: RootReducerState) => {
  return state.user.pendingRegistration || false
}

const firstAvailableEmailContactSelector = (state: RootReducerState) => {
  const contacts = state.user?.details?.contact || []
  const firstContactWithValidEmail = contacts.find((c) => c.email1)
  return firstContactWithValidEmail?.email1
}

const userEmailSelector = (state: RootReducerState) => {
  return state.user.details?.email1
}

const userDetailsSelector = (state: RootReducerState) => state.user.details

const areUserDetailsLoadingSelector = (state: RootReducerState) =>
  state.user.areDetailsLoading

const firstAvailableContactInformationSelector = (state: RootReducerState) =>
  get(state.user, 'details.contact[0]')

export {
  loginStatusSelector,
  loginErrorMsgSelector,
  guestStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  logonIdSelector,
  registrationStatusSelector,
  pendingRegistrationStatusSelector,
  userNameSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  forUserIdSelector,
  firstAvailableEmailContactSelector,
  userEmailSelector,
  userDetailsSelector,
  areUserDetailsLoadingSelector,
  firstAvailableContactInformationSelector,
}
