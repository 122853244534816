import * as yup from 'yup'
import { TFunction } from 'i18next'

import { IFormField } from '../types/form'
import { PersonalInfoFormField } from '../types/user'
import sub from 'date-fns/sub'
import { PRESCRIPTION_FORM_I18N_INVALID_MSG_BASE } from './prescription'

export const FORM_I18N_INVALID_MSG_BASE = 'AddressForm.Msgs.'
export const FORM_I18N_FORM_FIELDS_INVALID_MSGS: Partial<
  Record<PersonalInfoFormField['fieldName'], string>
> = {
  firstName: 'InvalidFirstName',
  lastName: 'InvalidLastName',
  phone1: 'InvalidPhone',
  email1: 'InvalidEmail',
  dateOfBirth: 'InvalidEmail',
}

export const buildYupValidationSchema = <FormFieldType extends IFormField>({
  formFields,
  i18nInvalidMsgBase,
  i18nFormFieldsInvalidMsgs,
  t,
}: {
  formFields: FormFieldType[]
  i18nInvalidMsgBase: string
  i18nFormFieldsInvalidMsgs: Record<string, string>
  t: TFunction
}) => {
  let dobObjectSchema = {}
  const yupObjectFields = formFields.reduce((prev, curr) => {
    let currentYupSchema = yup.string()
    const { fieldName } = curr
    const invalidMessage = t(
      i18nInvalidMsgBase +
        (i18nFormFieldsInvalidMsgs[fieldName] || 'InvalidFormat')
    )
    const emptyFieldMessage = t(i18nInvalidMsgBase + ('Empty.' + [fieldName]))
    const lettersOnlyMessage = t(i18nInvalidMsgBase + 'LettersOnly')
    const emailMessage = t(
      i18nInvalidMsgBase + FORM_I18N_FORM_FIELDS_INVALID_MSGS.email1
    )
    const privacyMessage = t(i18nInvalidMsgBase + 'InvalidPrivacy1')

    if (
      fieldName === 'email' ||
      fieldName === 'email1' ||
      fieldName === 'email2'
    )
      currentYupSchema = currentYupSchema.email(emailMessage)

    if (fieldName === 'lastName' || fieldName === 'firstName')
      currentYupSchema = currentYupSchema.matches(
        /^[A-Za-z ]*$/,
        lettersOnlyMessage
      )

    if (fieldName === 'email2')
      currentYupSchema = currentYupSchema.oneOf(
        [yup.ref('email'), null],
        'RegistrationLayout.Msgs.InvalidEmail2'
      )

    if (fieldName === 'verifyPassword')
      currentYupSchema = currentYupSchema.oneOf(
        [yup.ref('newPassword'), null],
        'RegistrationLayout.Msgs.InvalidPassword2'
      )

    if (fieldName === 'password2')
      currentYupSchema = currentYupSchema.oneOf(
        [yup.ref('password1'), null],
        'RegistrationLayout.Msgs.InvalidPassword2'
      )

    if (fieldName === 'verifyPassword')
      currentYupSchema = currentYupSchema.notOneOf(
        [yup.ref('currentPassword'), null],
        'RegistrationLayout.Msgs.InvalidPasswordSameOfOld'
      )

    if (fieldName === 'privacy1')
      currentYupSchema = currentYupSchema.matches(/true/, privacyMessage)

    /*currentYupSchema = yup
        .mixed()
        .nullable()
        .required('A file is required')
        .test(
          'fileSize',
          'File is too large',
          (value) => !value || (value && value.size <= 10485760)
        )
        .test(
          'format',
          'upload file',
          (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        )*/

    if (
      fieldName === 'dateOfBirth' &&
      i18nInvalidMsgBase !== PRESCRIPTION_FORM_I18N_INVALID_MSG_BASE
    )
      dobObjectSchema = {
        dateOfBirth: yup
          .date()
          .max(
            dateOfBirthMinimumDate,
            'RegistrationLayout.Msgs.InvalidDateValue'
          ),
      }

    if (curr.mandatory === 'true') {
      currentYupSchema = currentYupSchema.required(emptyFieldMessage)
    } else {
      currentYupSchema = currentYupSchema.notRequired()
    }

    if (curr.validation) {
      currentYupSchema = currentYupSchema.matches(curr.validation, {
        excludeEmptyString: true,
        message: invalidMessage,
      })
    }

    return { ...prev, [fieldName]: currentYupSchema }
  }, {} as Record<keyof FormFieldType['fieldName'], yup.StringSchema>)

  return yup.object().shape({
    ...yupObjectFields,
    ...dobObjectSchema,
  })
}

const passwordSchemaFieldNames = {
  MINIMUM_LENGTH: 'minimumLength',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  NUMBER: 'number',
  SPECIAL_CHARACTER: 'specialCharacter',
} as const

export const passwordValidationFields = {
  [passwordSchemaFieldNames.MINIMUM_LENGTH]: {
    regexp: /.{8,}/,
    message: 'RegistrationLayout.Msgs.Requirements.MinimumLength',
  },
  [passwordSchemaFieldNames.UPPERCASE]: {
    regexp: /[A-Z]/,
    message: 'RegistrationLayout.Msgs.Requirements.Uppercase',
  },
  [passwordSchemaFieldNames.LOWERCASE]: {
    regexp: /[a-z]/,
    message: 'RegistrationLayout.Msgs.Requirements.Lowercase',
  },
  [passwordSchemaFieldNames.NUMBER]: {
    regexp: /\d/,
    message: 'RegistrationLayout.Msgs.Requirements.Number',
  },
  [passwordSchemaFieldNames.SPECIAL_CHARACTER]: {
    regexp: /[!@#$%^&*)(+=._\-£§€:;<>?\[\]`{|}~"'/]/,
    message: 'RegistrationLayout.Msgs.Requirements.SpecialCharacter',
  },
}

export const passwordValidationSchema = yup
  .string()
  .required()
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.MINIMUM_LENGTH].regexp,
    passwordSchemaFieldNames.MINIMUM_LENGTH
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.NUMBER].regexp,
    passwordSchemaFieldNames.NUMBER
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.LOWERCASE].regexp,
    passwordSchemaFieldNames.LOWERCASE
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.UPPERCASE].regexp,
    passwordSchemaFieldNames.UPPERCASE
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.SPECIAL_CHARACTER].regexp,
    passwordSchemaFieldNames.SPECIAL_CHARACTER
  )

export const dateOfBirthMinimumDate = sub(new Date(), { years: 16 })
