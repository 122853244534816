import { useAppSelector } from '../redux'

/**
 * @returns details of all orders as Map with orderId as key
 */
export const useAllOrdersDetails = () => {
  return useAppSelector(({ orderDetails }) => orderDetails.data)
}

/**
 * @param orderId string
 * @returns details: `IOrderDetails` of the particular order
 */
export const useOrderDetails = (orderId: string) => {
  return useAppSelector(({ orderDetails }) => orderDetails.data[orderId])
}

/**
 * @param orderId string
 * @returns loading state of the order's details with provided orderId
 */
export const useOrderDetailsLoadings = (orderId: string) => {
  const areOrderDetailsLoading = useAppSelector((state) =>
    state.orderDetails.loadings.includes(orderId)
  )

  return areOrderDetailsLoading
}
