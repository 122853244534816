import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { SeoResponse } from 'src/types/seo'
import { seoApi } from './query'

export type SeoStateInterface = {
  loading?: boolean
  data?: SeoResponse
}

const initialState: SeoStateInterface = {
  loading: false,
}

export const { reducer } = createSlice({
  name: 'seo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(seoApi.endpoints.getSeo.matchPending, (state) => {
      state.loading = true
    })
    builder
      .addMatcher(
        seoApi.endpoints.getSeo.matchFulfilled,
        (state, { payload }: PayloadAction<SeoResponse>) => {
          Object.assign(state, { data: { ...payload } })
        }
      )
      .addMatcher(
        isAnyOf(
          seoApi.endpoints.getSeo.matchFulfilled,
          seoApi.endpoints.getSeo.matchRejected
        ),
        (state) => {
          state.loading = false
        }
      )
  },
})

export default reducer
