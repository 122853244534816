import * as ROUTES from '../../constants/routes'

import { useLocation, useMatch } from 'react-router-dom'

import { TFunction } from 'i18next'
import { parsedOrderItemsSelector } from '../../features/order/selector'
import { generatePath } from 'react-router'
import { useCheckoutPaths } from '../../foundation/hooks/useCheckoutPaths'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'

const filterStepsInfo = (
  t: TFunction,
  country: string,
  shouldNavigateToUploadPrescription?: boolean
): {
  stepId: string
  stepNumber: number
  stepTitle: string
  url: string
}[] => {
  let steps: {
    stepId: string
    stepNumber: number
    stepTitle: string
    url: string
  }[] = []
  switch (true) {
    case shouldNavigateToUploadPrescription:
      steps = [
        {
          stepId: 'upload-prescription',
          stepNumber: 0,
          stepTitle: t('UploadPrescription.Title'),
          url: generatePath(
            `/:country/${ROUTES.CHECKOUT_UPLOAD_PRESCRIPTION}`,
            { country }
          ),
        },
        {
          stepId: 'shipping',
          stepNumber: 0,
          stepTitle: t('Shipping.BarTitle'),
          url: generatePath(`/:country/${ROUTES.CHECKOUT_SHIPPING}`, {
            country,
          }),
        },
        {
          stepId: 'payment',
          stepNumber: 1,
          stepTitle: t('Payment.BarTitle'),
          url: generatePath(`/:country/${ROUTES.CHECKOUT_PAYMENT}`, {
            country,
          }),
        },
        {
          stepId: 'order-confirmation',
          stepNumber: 2,
          stepTitle: t('OrderConfirmation.Title'),
          url: generatePath(`/:country/${ROUTES.ORDER_CONFIRMATION}`, {
            country,
          }),
        },
      ]
      break
    default:
      steps = [
        {
          stepId: 'shipping',
          stepNumber: 0,
          stepTitle: t('Shipping.BarTitle'),
          url: generatePath(`/:country/${ROUTES.CHECKOUT_SHIPPING}`, {
            country,
          }),
        },
        {
          stepId: 'payment',
          stepNumber: 1,
          stepTitle: t('Payment.BarTitle'),
          url: generatePath(`/:country/${ROUTES.CHECKOUT_PAYMENT}`, {
            country,
          }),
        },
        {
          stepId: 'order-confirmation',
          stepNumber: 2,
          stepTitle: t('OrderConfirmation.Title'),
          url: generatePath(`/:country/${ROUTES.ORDER_CONFIRMATION}`, {
            country,
          }),
        },
      ]
  }
  return steps
}

export const useCheckoutSteps = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const match = useMatch(location.pathname)
  const { langCode } = useStoreIdentity()
  const checkoutPaths = useCheckoutPaths(langCode)
  const parsedOrderItems = useSelector(parsedOrderItemsSelector)
  const shouldNavigateToUploadPrescription = useMemo(() => {
    return (
      parsedOrderItems?.cl?.filter(
        (clOrderItem) => !!clOrderItem.prescriptionDetails
      ).length !== parsedOrderItems?.cl?.length
    )
  }, [parsedOrderItems])

  const checkoutSteps = filterStepsInfo(
    t,
    langCode,
    shouldNavigateToUploadPrescription
  )

  const activeStep = useMemo(() => {
    if (match?.pathname === checkoutPaths.checkout) {
      return 'checkout'
    } else {
      return location.pathname.split('/').slice(-1).pop()
    }
  }, [location, match])

  const previousSteps = useMemo(() => {
    return checkoutSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i <
          checkoutSteps.findIndex((step) => {
            return step.stepId === activeStep
          })
      )
    })
  }, [checkoutSteps])

  const nextSteps = useMemo(() => {
    return checkoutSteps.filter((step, i) => {
      return (
        step.stepId !== activeStep &&
        i >
          checkoutSteps.findIndex((step) => {
            return step.stepId === activeStep
          })
      )
    })
  }, [checkoutSteps])

  return {
    activeStep,
    previousSteps,
    nextSteps,
    checkoutSteps,
  }
}
