import { styled } from '@mui/material/styles'
import Snackbar from '@mui/material/Snackbar'

export const StyledSnackbar = styled(Snackbar, {
  name: 'StyledSnackbar',
  slot: 'Snackbar',
})(() => ({
  '&&': {
    backgroundColor: '#9b0800',
    transform: 'translateX(0)',
    left: 15,
    bottom: 16,
    height: 64,
    opacity: 0.9,
  },
}))
