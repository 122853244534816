import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { IToggleButtonsProps } from './Toggle'

export const variantMap = (
  theme,
  variant: IToggleButtonsProps['variant'] = 'primary'
) => {
  const primary = {
    background: theme.palette.background.dark.secondary,
    color: theme.palette.text.dark.primary,
    selected: theme.palette.text.dark.primary,
  }
  const secondary = {
    background: theme.palette.background.dark.primary,
    color: theme.palette.text.light.primary,
    selected: theme.palette.text.light.primary,
  }

  return {
    primary,
    secondary,
  }[variant]
}

export const StyledStack = styled(Stack, {
  name: 'Toggle',
  slot: 'Stack',
})(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'scroll',
  overflowY: 'hidden',
  justifyContent: 'center',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    padding: '0',
    maxWidth: 'unset',
    overflowX: 'hidden',
    overflowY: 'hidden',
    marginBottom: 0,
  },
}))

export const StyledToggleButton = styled(ToggleButton, {
  name: 'Toggle',
  slot: 'ToggleButton',
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: IToggleButtonsProps['variant'] }>(({ theme, variant }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
  border: 'none',
  background: 'transparent',
  borderRadius: '40px !important',
  transition: 'all 0.2s ease-in-out',
  height: '40px',
  svg: {
    path: {
      fill: theme.palette.text.dark.primary,
    },
  },
  '&.Mui-selected': {
    background: theme.palette.background.dark.secondary,
    //background: 'none',
    svg: {
      pointerEvents: 'none',
      path: {
        fill: variantMap(theme, variant)?.selected,
      },
    },
  },
  '&.Mui-selected:hover': {
    background: theme.palette.background.dark.secondary,
    borderLeft: 'none',
    //background: 'none',
  },
  '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: '0',
    borderLeft: 'none',
  },
}))

export const StyledToggleItems = styled('div', {
  name: 'Toggle',
  slot: 'ToggleItems',
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: IToggleButtonsProps['variant'] }>(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  zIndex: 2,
  pointerEvents: 'none',
  span: {
    fontSize: '14px',
    fontWeight: '600',
    pointerEvents: 'none',
    color: variantMap(theme, variant)?.color,
    '&:hover': {
      color: theme.palette.text.dark.tertiary,
    },
  },
}))

export const StyledToggleGroup = styled(ToggleButtonGroup, {
  name: 'Toggle',
  slot: 'ToggleGroup',
})(({ theme }) => ({
  borderRadius: '40px',
  transition: 'all 0.2s ease-in-out',
  position: 'relative',
  background: theme.palette.background.light.secondary,
}))

export const StyledToggleSlider = styled('div', {
  name: 'Toggle',
  slot: 'ToggleSlider',
  shouldForwardProp: (prop) =>
    prop !== 'shift' && prop !== 'width' && prop !== 'variant',
})<{ shift: number; width: string; variant: IToggleButtonsProps['variant'] }>(
  ({ theme, shift, width, variant }) => ({
    position: 'absolute',
    height: '40px',
    width: `${width}px`,
    borderRadius: '40px',
    backgroundColor: variantMap(theme, variant)?.background,
    zIndex: 1,
    top: 0,
    left: 0,
    transform: `translateX(${shift}px)`,
    transition: `all ${theme.transitions.duration.standard}ms ease-in-out`,
  })
)
