import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { CheckmarkIcon, IndeterminateCheckBoxIcon } from '../Icons'
import { createStyles, makeStyles } from '@mui/styles'

import { CustomTheme } from '../../../types/theme'
// @ts-nocheck
// TODO fix later
import React from 'react'

interface CustomCheckboxProps extends CheckboxProps {
  error?: boolean
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      marginRight: 10,
      padding: 4,
      '&:hover': {
        // backgroundColor: 'transparent',
      },
    },
    errorIcon: {
      borderRadius: 1,
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme.palette.custom.thunderbird,
      borderStyle: 'solid',
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: theme.palette.custom.thunderbird,
        background: 'transparent',
      },
    },
    icon: {
      borderRadius: 1,
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme.palette.custom.cyprus,
      borderStyle: 'solid',
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: theme.palette.custom.alto,
        background: 'transparent',
      },
    },
    checkedIcon: {
      borderRadius: 1,
      color: theme.palette.custom.white,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.custom.cyprus,
      display: 'inherit',
      borderWidth: 1,
      borderColor: theme.palette.custom.cyprus,
      borderStyle: 'solid',
      position: 'relative',
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: theme.palette.custom.alto,
        color: theme.palette.custom.alto,
        background: 'transparent',
      },
      '& > svg': {
        position: 'absolute',
        height: '24px',
        width: '24px',
        right: '-5px',
        top: '-5px',
      },
    },
  })
)

export const StyledCheckbox = ({ error, ...other }: CustomCheckboxProps) => {
  const classes = useStyles()
  return (
    <Checkbox
      className={classes.root}
      icon={<span className={error ? classes.errorIcon : classes.icon} />}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classes.checkedIcon}>
          <CheckmarkIcon />
        </span>
      }
      indeterminateIcon={
        <span className={classes.checkedIcon}>
          <IndeterminateCheckBoxIcon />
        </span>
      }
      {...other}
    />
  )
}
