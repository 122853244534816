/* eslint-disable */

import Log from '../../../services/Log'
//Foundation libraries
import eventService from '../../../foundation/apis/transaction/event.service'
//Standard libraries
import { select } from 'redux-saga/effects'
//custom library
import { wcTokenSelector } from '../../selectors/user'

/**
 *
 */
function* performClickEvent(action: any) {
  const WCToken = yield select(wcTokenSelector)
  if (!!WCToken) {
    //only tract click with guest  of register user
    const { eSpotDesc, eSpotRoot } = action.payload
    const payload = action.payload
    let body = {
      evtype: 'CpgnClick',
      productId: eSpotDesc.productId || '',
      categoryId: eSpotDesc.filteredResultId || '',
      DM_ReqCmd: '',
      intv_id: eSpotDesc.activityIdentifierID,
      expDataType: eSpotDesc.baseMarketingSpotDataType,
      mpe_id: eSpotRoot.marketingSpotIdentifier,
      expDataUniqueID: eSpotDesc.baseMarketingSpotActivityID,
    }
    if (eSpotDesc.experimentResult) {
      const expResult = eSpotDesc.experimentResult[0]
      const bodyExt = {
        experimentId: expResult.experimentResultId,
        testElementId: expResult.experimentResultTestElementId,
        controlElement: expResult.controlElement,
      }
      Object.assign(body, bodyExt)
    }
    const params = {
      body,
    }
    if (payload.widget) {
      params['widget'] = payload.widget
    }
    try {
      yield eventService.handleClickInfo(params)
    } catch (e) {
      Log.error('error: ' + e)
    }
  }
}

/**
 *
 */
function* performTriggerMarketing(action: any) {
  const WCToken = yield select(wcTokenSelector)
  if (!!WCToken) {
    //only tract click with guest  of register user
    try {
      const params = {
        body: action.payload,
      }
      if (action?.payload?.widget) {
        params['widget'] = action.payload.widget
      }
      yield eventService.triggerMarketing(params)
    } catch (e) {
      Log.error('error: ' + e)
    }
  }
}

export { performClickEvent, performTriggerMarketing }
