import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {
  DashboardTileHeaderWrapper,
  DashboardTileHeader,
  DashboardLastOrderContent,
  DashboardLastOrderDetails,
  DashboardLastOrderDetailsRow,
  DashboardLastOrderDetailsLabel,
} from './AccountDashboard.style'

interface IProps {}

const AccountDashboardLoader: React.FC<IProps> = () => {
  const { t } = useTranslation()
  const ShopNowButtonSkeleton = () => (
    <Box display="grid" width="100%" marginTop="auto">
      <Skeleton width="100%" height="100%" />
    </Box>
  )

  return (
    <SkeletonTheme
      baseColor="transparent"
      highlightColor={'rgba(0, 0, 0, 0.1)'}
    >
      <>
        <DashboardTileHeaderWrapper>
          <DashboardTileHeader>
            {t('AccountDashboard.MyOrders')}
          </DashboardTileHeader>
          <Box display="grid" width="100%">
            <Skeleton width="100%" />
          </Box>
        </DashboardTileHeaderWrapper>
        <DashboardLastOrderContent>
          <Box display="grid" width="100%">
            <Skeleton width="100%" />
          </Box>
          <DashboardLastOrderDetails>
            <DashboardLastOrderDetailsRow>
              <DashboardLastOrderDetailsLabel>
                {t('Order.Date')}
              </DashboardLastOrderDetailsLabel>
              <Box display="grid" width="100%">
                <Skeleton width="100%" />
              </Box>
            </DashboardLastOrderDetailsRow>
            <DashboardLastOrderDetailsRow>
              <DashboardLastOrderDetailsLabel>
                {t('Order.Status')}
              </DashboardLastOrderDetailsLabel>
              <Box display="grid" width="100%">
                <Skeleton width="100%" />
              </Box>
            </DashboardLastOrderDetailsRow>
            <DashboardLastOrderDetailsRow>
              <DashboardLastOrderDetailsLabel>
                {t('OrderDetails.Total')}
              </DashboardLastOrderDetailsLabel>
              <Box display="grid" width="100%">
                <Skeleton width="100%" />
              </Box>
            </DashboardLastOrderDetailsRow>
          </DashboardLastOrderDetails>
        </DashboardLastOrderContent>

        <ShopNowButtonSkeleton />
      </>
    </SkeletonTheme>
  )
}

export default AccountDashboardLoader
