export const countryUtil = (country = '', langCode = '') => {
  const currentCountry = country.substr(3).toLowerCase()
  const currentLangCode = `${langCode.slice(0, 2)}-${langCode
    .slice(3, 5)
    .toLowerCase()}`

  return {
    currentCountry,
    currentLangCode,
  }
}
