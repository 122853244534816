// @ts-nocheck
// TODO fix later
import React, { FC, useEffect, useMemo } from 'react'
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormGroup from '@mui/material/FormGroup'
import { useTheme } from '@mui/material/styles'

import { dateOfBirthMinimumDate } from '../../../constants/form'

import {
  PersonalInfoAddressFormData,
  PersonalInfoFormData,
} from '../../../types/user'
import {
  DatePicker,
  StyledCheckbox,
  StyledFormControlLabel,
} from '../../../components/UI'
import {
  AccountSummaryFormContainer,
  AccountSummaryContainer,
  AccountSummaryFieldContainer,
  AccountSummaryFieldLabel,
  AccountSummaryLastNameContainer,
  AccountSummarySaveButton,
  AccountSummaryTextField,
  AccountSummarySubmitSuccessMessage,
  AccountSummarySubmitSuccessMessageWrapper,
  AccountSummarySaveChangesWrapper,
} from './../AccountSummary.style'
import { CheckmarkRoundedValidIcon } from '../../../components/UI/Icons'
import DateService from './../../../services/DateService'
import personService from './../../../foundation/apis/transaction/person.service'
import { PERSONAL_INFO_FORM_FIELDS } from '../../../constants/user'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
interface PersonalInformationFormProps {
  isSuccessSubmit: boolean
  fieldsData: PersonalInfoFormData
  onDataChange(data: PersonalInfoFormData): void
  onSubmit(data: PersonalInfoFormData): void
}

const TextFormFieldController: FC<{
  control: Control<PersonalInfoFormData>
  errors: FieldErrors<PersonalInfoAddressFormData>
  fieldName: keyof PersonalInfoAddressFormData
  required?: boolean
}> = ({ control, errors, fieldName, required = false }) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
        <AccountSummaryTextField
          {...rest}
          inputRef={ref}
          value={value}
          required={required}
          label={t(`AddressForm.Labels.${fieldName}`)}
          error={!!error}
          helperText={errors && errors[fieldName]?.message}
          fullWidth
          showvalidationstatus
          isvalid={!error && value?.length}
          ispristine={!value?.length}
        />
      )}
    />
  )
}

const PersonalInformationForm: FC<PersonalInformationFormProps> = ({
  isSuccessSubmit,
  fieldsData,
  onSubmit: onSubmitProp,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const formFields = personService.getPersonFormFields(
    PERSONAL_INFO_FORM_FIELDS
  )

  const formFieldsMap = useMemo(
    () =>
      formFields.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.fieldName]: cur.mandatory === 'true',
        }),
        {}
      ),
    [formFields]
  )

  const dateObject = useMemo(() => {
    const parsedBirthDate = fieldsData.dateOfBirth
      ? new Date(fieldsData.dateOfBirth)
      : new Date()

    if (DateService.isValid(parsedBirthDate)) return parsedBirthDate

    return new Date()
  }, [fieldsData.dateOfBirth])

  const nameRegExp = /^[a-zA-Z ]*$/
  const phoneRegExp = /^\d+$/

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegExp, t('AddressForm.Msgs.InvalidFirstName'))
      .required(t('AddressForm.Msgs.Empty.firstName')),
    lastName: yup
      .string()
      .matches(nameRegExp, t('AddressForm.Msgs.InvalidLastName'))
      .required(t('AddressForm.Msgs.Empty.LastName')),
    email1: yup
      .string()
      .email(t('AddressForm.Msgs.InvalidEmail'))
      .required(t('AddressForm.Msgs.Empty.email1')),
    phone1: yup
      .string()
      .matches(phoneRegExp, t('AddressForm.Labels.ErrorPhone'))
      .nullable()
      .transform((value) => (!!value ? value : null)),
    dateOfBirth: yup
      .date()
      .required(t('AddressForm.Msgs.InvalidDateOfBirth'))
      .max(dateOfBirthMinimumDate, t('AddressForm.Msgs.Empty.DateOfBirth')),
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    register,
    setValue,
  } = useForm<PersonalInfoFormData>({
    defaultValues: fieldsData,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (fieldsData.gender === t('PersonalInformationSection.GenderOne'))
      setValue('gender', t('PersonalInformationSection.GenderOne'))
    else if (fieldsData.gender === t('PersonalInformationSection.GenderTwo'))
      setValue('gender', t('PersonalInformationSection.GenderTwo'))
    else setValue('gender', t('PersonalInformationSection.GenderUnspecified'))
  }, [fieldsData.gender])

  const onSubmit = (data: PersonalInfoFormData) => {
    const dateOfBirth = data.dateOfBirth
      ? DateService.format(data.dateOfBirth, 'yyyy-MM-dd')
      : ''

    const phone1 = data.phone1 === null ? '' : data.phone1

    onSubmitProp({ ...data, dateOfBirth, phone1 })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AccountSummaryFormContainer>
        <AccountSummaryContainer nrOfColumns={2}>
          <AccountSummaryFieldContainer>
            <AccountSummaryFieldLabel>
              {t('PersonalInformationSection.FullNameLabel')}
            </AccountSummaryFieldLabel>
            <TextFormFieldController
              control={control}
              errors={errors}
              fieldName="firstName"
              showvalidationstatus
              required={formFieldsMap['firstName']}
            />
          </AccountSummaryFieldContainer>

          <AccountSummaryLastNameContainer>
            <TextFormFieldController
              control={control}
              errors={errors}
              fieldName="lastName"
              showvalidationstatus
              required={formFieldsMap['lastName']}
            />
          </AccountSummaryLastNameContainer>

          <AccountSummaryFieldContainer>
            <AccountSummaryFieldLabel>
              {t('PersonalInformationSection.EmailLabel')}
            </AccountSummaryFieldLabel>
            <TextFormFieldController
              control={control}
              errors={errors}
              fieldName="email1"
              showvalidationstatus
              required={formFieldsMap['email1']}
            />
          </AccountSummaryFieldContainer>

          <AccountSummaryFieldContainer>
            <AccountSummaryFieldLabel>
              {t('PersonalInformationSection.PhoneNumber')}
            </AccountSummaryFieldLabel>
            <TextFormFieldController
              control={control}
              fieldName="phone1"
              errors={errors}
              showvalidationstatus
              required={false}
            />
          </AccountSummaryFieldContainer>
        </AccountSummaryContainer>

        <AccountSummaryContainer nrOfColumns={1}>
          <AccountSummaryFieldContainer>
            <AccountSummaryFieldLabel>
              {t('PersonalInformationSection.DateOfBirth')}
            </AccountSummaryFieldLabel>
            <Controller
              name={'dateOfBirth'}
              control={control}
              defaultValue={dateObject}
              render={({
                field: { onChange, value, ref, onBlur, ...rest },
                fieldState: { error },
              }) => {
                const date = new Date(value)
                return (
                  <DatePicker
                    {...rest}
                    inputRef={ref}
                    error={Boolean(error)}
                    onAccept={() => {
                      onBlur()
                    }}
                    onChange={(e) => {
                      // this is because new Date(null) returns 1970
                      // instead of empty string
                      onChange(e === null ? undefined : e)
                    }}
                    disableFuture
                    views={['year', 'day']}
                    value={date}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <AccountSummaryTextField
                        onFocus={() => clearErrors('dateOfBirth')}
                        onBlur={onBlur}
                        {...params}
                        error={!!error}
                        label={t('RegistrationLayout.DateOfBirth')}
                        helperText={t(
                          error?.type === 'typeError'
                            ? 'AddressForm.Msgs.InvalidDateOfBirth'
                            : error?.message
                            ? error.message
                            : ''
                        )}
                        showvalidationstatus={true}
                        required={formFieldsMap['dateOfBirth']}
                        ispristine={!value?.length}
                      />
                    )}
                  />
                )
              }}
            />
          </AccountSummaryFieldContainer>
          <AccountSummaryFieldContainer>
            <AccountSummaryFieldLabel>
              {t('PersonalInformationSection.Gender')}
            </AccountSummaryFieldLabel>
            <Controller
              name="gender"
              control={control}
              render={({ field: { value } }) => (
                <FormGroup {...register('gender')}>
                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        color="primary"
                        checked={
                          value === t('PersonalInformationSection.GenderOne')
                        }
                        onChange={() =>
                          value !== t('PersonalInformationSection.GenderOne')
                            ? setValue(
                                'gender',
                                t('PersonalInformationSection.GenderOne')
                              )
                            : setValue(
                                'gender',
                                t(
                                  'PersonalInformationSection.GenderUnspecified'
                                )
                              )
                        }
                      />
                    }
                    label={t('PersonalInformationSection.GenderOne')}
                  />
                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        color="primary"
                        checked={
                          value === t('PersonalInformationSection.GenderTwo')
                        }
                        onChange={() =>
                          value !== t('PersonalInformationSection.GenderTwo')
                            ? setValue(
                                'gender',
                                t('PersonalInformationSection.GenderTwo')
                              )
                            : setValue(
                                'gender',
                                t(
                                  'PersonalInformationSection.GenderUnspecified'
                                )
                              )
                        }
                      />
                    }
                    label={t('PersonalInformationSection.GenderTwo')}
                  />
                </FormGroup>
              )}
            />
          </AccountSummaryFieldContainer>

          <AccountSummarySaveChangesWrapper>
            <AccountSummarySaveButton type="submit" variant="primary">
              {t('PersonalInformationSection.SaveChanges')}
            </AccountSummarySaveButton>
            {isSuccessSubmit && (
              <AccountSummarySubmitSuccessMessageWrapper>
                <CheckmarkRoundedValidIcon
                  width={16}
                  height={16}
                  htmlColor={theme.palette.color?.success}
                ></CheckmarkRoundedValidIcon>
                <AccountSummarySubmitSuccessMessage>
                  {t('PersonalInformationSection.Msgs.SuccessLabel')}
                </AccountSummarySubmitSuccessMessage>
              </AccountSummarySubmitSuccessMessageWrapper>
            )}
          </AccountSummarySaveChangesWrapper>
        </AccountSummaryContainer>
      </AccountSummaryFormContainer>
    </form>
  )
}

export default PersonalInformationForm
