import { ErrorForAnalytics } from '../interfaces'
import get from 'lodash/get'

export const getFormattedError = (error): ErrorForAnalytics => {
  const statusCode = get(error, 'response.status', '500')
  const errorKey = get(error, 'response.data.errors[0].errorKey', '')
  const errorMessage = get(error, 'response.data.errors[0].errorMessage', '')
  return {
    id: 'Error',
    Error_Source: 'Server',
    Error_Code: String(statusCode),
    Error_Details: errorKey,
    Error_Message: errorMessage,
  }
}
