import Log from '../../../services/Log'
import config from '../../../configs'

const maintenanceService = {
  checkMaintenanceStatus: async () => {
    try {
      const res = await fetch(config.maintenanceServiceUrl, {
        credentials: 'include',
      })
      return res.status
    } catch (e) {
      Log.error(
        `Error on retrieving maintenance status - ${config.maintenanceServiceUrl}`
      )
      return 500
    }
  },
}

export default maintenanceService
