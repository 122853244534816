import React from 'react'

import {
  StyledCheckoutProgressContainer,
  StyledCheckoutProgressBar,
  StyledCheckoutProgressBarElement,
  StyledCheckoutProgressBarElementLine,
  StyledCheckoutProgressBarElementStepname,
  StyledCheckoutProgressBarElementDot,
  StyledCheckoutProgressBarElementWrapper,
} from './CheckoutProgress.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useCheckoutSteps } from '../../../hooks/useCheckoutSteps'
import { CheckmarkRoundedIcon } from '../../../components/UI/Icons'
import { useTheme } from '@mui/material/styles'
import Log from '../../../services/Log'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import { useNavigate } from 'react-router-dom'

export const CheckoutProgress: React.FC<{
  activeStep?: string
}> = () => {
  const { activeStep, previousSteps, checkoutSteps } = useCheckoutSteps()
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  const navigate = useNavigate()

  const checkout = async (index: number) => {
    try {
      await paymentInstructionService.deleteAllPaymentInstructions()
      navigate(`${checkoutSteps[index].url}`)
    } catch (e) {
      if (e instanceof Error) {
        Log.error(window.location.href, 'unable finalize order', e.message)
      } else {
        Log.error(window.location.href, 'unable finalize order, unknown error')
      }
    }
  }

  return (
    <StyledCheckoutProgressContainer>
      <StyledCheckoutProgressBar>
        {checkoutSteps.map((step, index) => {
          const isActive = step.stepId === activeStep ? 1 : 0
          const isCompleted = previousSteps.includes(step) ? 1 : 0
          const isLastStep = index + 1 === checkoutSteps.length

          return (
            <StyledCheckoutProgressBarElement
              key={step.stepId}
              active={isActive}
              completed={isCompleted}
              last={isLastStep ? 1 : 0}
            >
              <StyledCheckoutProgressBarElementWrapper
                completed={isCompleted}
                onClick={() => isCompleted && checkout(index)}
              >
                <StyledCheckoutProgressBarElementDot
                  completed={isCompleted}
                  active={isActive}
                >
                  {isCompleted === 1 && (
                    <CheckmarkRoundedIcon
                      htmlColor={theme.palette.custom.cyprus}
                    />
                  )}
                  {isMobile && (
                    <StyledCheckoutProgressBarElementStepname>
                      {step.stepTitle.replace('verification', '').trim()}
                    </StyledCheckoutProgressBarElementStepname>
                  )}
                </StyledCheckoutProgressBarElementDot>
                {!isMobile && (
                  <StyledCheckoutProgressBarElementStepname>
                    {step.stepTitle}
                  </StyledCheckoutProgressBarElementStepname>
                )}
              </StyledCheckoutProgressBarElementWrapper>
              {!isLastStep && (
                <StyledCheckoutProgressBarElementLine completed={isCompleted} />
              )}
            </StyledCheckoutProgressBarElement>
          )
        })}
      </StyledCheckoutProgressBar>
    </StyledCheckoutProgressContainer>
  )
}
