import queryString from 'query-string'
import config from '../configs/config.base'

import { SiteInfo } from '../redux/reducers'
import { Attachment } from '../types/product'

export const sortImageByConf = (
  images: Attachment[],
  sortArray: number[] | undefined,
  filterImagesByConf?: boolean
) => {
  if (!Array.isArray(sortArray)) return images
  return filterImagesByConf
    ? images
        .filter((image) => sortArray.includes(parseInt(image.sequence)))
        .sort((a, b) => {
          return (
            sortArray.indexOf(parseInt(a.sequence)) -
            sortArray.indexOf(parseInt(b.sequence))
          )
        })
    : images.sort((a, b) => {
        return (
          sortArray.indexOf(parseInt(a.sequence)) -
          sortArray.indexOf(parseInt(b.sequence))
        )
      })
}

export const parseAttachmentUrl = (
  site: SiteInfo,
  attachments: Attachment[]
) => {
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain

  const attachment = attachments[0]

  const url404 = process.env.PUBLIC_URL + '/images/common/404.svg'
  const imagePath = attachment
    ? damDomain + attachment.attachmentAssetPathRaw
    : url404

  const imageUrl = queryString.stringifyUrl({
    url: imagePath.toString(),
  })

  return imageUrl
}
