import { getCaseImageUrl } from '../../utils/productAttributes'
import { useEffect, useState } from 'react'
import { Attachment, ServerProduct } from '../../types/product'

const createAttachmentFromUrl = (url: string) => ({
  // using high value on sequence so will be set as last
  sequence: '99.0',
  name: 'Case',
  usage: 'PDP',
  attachmentAssetPathRaw: url,
})

/**
 * @param { ServerProduct } product current selected product object
 */
export const useProductImages = (product?: ServerProduct) => {
  const caseImageUrl = product && getCaseImageUrl(product)
  const [images, setImages] = useState<Attachment[]>([])

  useEffect(() => {
    const attachments = product?.attachments || []
    // filter images array to get array with PDP images
    const PDPImagesArray = attachments.filter((d) => d.usage === 'PDP')
    if (!PDPImagesArray.length) {
      return
    }
    if (caseImageUrl) {
      PDPImagesArray.push(createAttachmentFromUrl(caseImageUrl) as Attachment)
    }
    // order images by "sequence" attribute
    PDPImagesArray.sort(
      (a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
    )
    // remove first image from array with PDP images
    setImages(PDPImagesArray)
  }, [product?.attachments, caseImageUrl])

  return {
    images,
  }
}

// plain function instead to perform sync retrieving and to fix issues connected with hook's async flow
export const retrieveProductImages = (
  product?: ServerProduct
): Attachment[] => {
  if (!(product && product.attachments)) return []

  const caseImageUrl = product && getCaseImageUrl(product)

  // filter images array to get array with PDP images
  const PDPImagesArray = product.attachments.filter((d) => d.usage === 'PDP')
  if (!PDPImagesArray.length) {
    return []
  }

  if (caseImageUrl) {
    PDPImagesArray.push(createAttachmentFromUrl(caseImageUrl) as Attachment)
  }

  // order images by "sequence" attribute
  PDPImagesArray.sort(
    (a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10)
  )

  return PDPImagesArray
}
