import { useCallback, useEffect, useMemo } from 'react'

import cartService from '../../foundation/apis/transaction/cart.service'
import qs from 'qs'

export const useAbandonedOrder = () => {
  let qsAbandonedOrderId: string
  qsAbandonedOrderId = useMemo<string>(
    () =>
      qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        decoder: (c) => c,
      }).abandonedOrderId as string,
    [window.location]
  )

  const sendAbandonedOrderId = useCallback(
    async (qsAbandonedOrderId: string) => {
      await cartService
        .createCart({ abandonedOrderId: decodeURI(qsAbandonedOrderId) })
        .catch((ex) => {
          throw ex
        })
    },
    []
  )

  useEffect(() => {
    if (qsAbandonedOrderId) {
      sendAbandonedOrderId(qsAbandonedOrderId)
    }
  }, [qsAbandonedOrderId])
}
