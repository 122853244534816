import React from 'react'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { TooltipWithStyle } from './Tooltip.style'

import type { ContentVariant, CustomTooltipProps } from './Tooltip.type'

const ContentVariantHandler = (props: ContentVariant) => {
  switch (props.type) {
    case 'textonly':
      return (
        <div className="popover-body-container">
          <p className="popover-body-container__body">{props.body}</p>
        </div>
      )

    case 'title+text':
      return (
        <div className="popover-body-container">
          <h3 className="popover-body-container__title">{props.title}</h3>
          <p className="popover-body-container__body">{props.body}</p>
        </div>
      )

    case 'storelocator':
      return (
        <div className="popover-body-container">
          <h3 className="popover-body-container__title">{props.title}</h3>
          <p className="popover-body-container__body popover-body-container__body--spacing-up">
            {props.body}
          </p>
          <div className="popover-body-container--footer-container">
            <span className="popover-body-container--footer-container__phone">
              {props.phone}
            </span>
            <span className="popover-body-container--footer-container__cta">
              {props.cta}
            </span>
          </div>
        </div>
      )
  }
}

export const StyledTooltip = ({
  contentVariant,
  children,
  ...props
}: Omit<CustomTooltipProps, 'title'>) => {
  const { isMobile, isTablet, isTabletLandscape } = useBreakpoints()
  const touchableDevices = isMobile || isTablet || isTabletLandscape

  return (
    <TooltipWithStyle
      enterTouchDelay={0}
      leaveTouchDelay={2500}
      disableFocusListener={touchableDevices}
      disableHoverListener={touchableDevices}
      arrow
      title={<ContentVariantHandler {...contentVariant} />}
      {...props}
    >
      {children}
    </TooltipWithStyle>
  )
}
