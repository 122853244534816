import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { IWishlist } from '../../types/wishlist'
import addWishlistItem from './thunks/addWishlistItem'
import removeWishlistItem from './thunks/removeWishlistItem'

export type WishListState = Partial<Pick<IWishlist, 'GiftList' | 'x_data'>> & {
  isLoading: boolean
}

const initialState = {} as WishListState

export const wishListSlice = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    setWishList: (state, action: PayloadAction<WishListState>) => {
      return { ...state, ...action.payload }
    },
    removeWishList: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(addWishlistItem.pending, removeWishlistItem.pending),
      (state) => {
        state.isLoading = true
      }
    )
    builder.addMatcher(
      isAnyOf(addWishlistItem.fulfilled, removeWishlistItem.fulfilled),
      (state) => {
        state.isLoading = false
      }
    )
  },
})

export const { removeWishList, setWishList } = wishListSlice.actions
export default wishListSlice.reducer
