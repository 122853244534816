import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { NOT_FOUND } from 'http-status-codes'
import { HYPHEN, ORDER_ID } from '../../constants/common'
import {
  ESTIMATED_TAXES,
  getOrderItemsAttachments,
  INVENTORY_STATUS,
  ORDER_STATUS,
  RX_PRODUCTS_IN_CART_LIMIT,
  TAXES_ZIP_CODE,
} from '../../constants/order'
import { ACCOUNT } from '../../foundation/constants/common'
import {
  localStorageUtil,
  sessionStorageUtil,
} from '../../foundation/utils/storageUtil'

import { PaymentInfoType } from '../../types/checkout'
import {
  Cart,
  IOrderDetails,
  OrderItem,
  ShippingChargesWithoutPromotions,
  OrderItemWithRoxProps,
} from '../../types/order'
import { isRxProduct } from '../../utils/rx'
import { logout } from '../../features/common/slice'
import { IOrderSliceState } from './IOrderSliceState'
import {
  addItem,
  fetchCart,
  fetchPayMethods,
  fetchPaypalExpress,
} from './thunks'
import { addFrame, addLens } from './thunks/addLensItem'
import { orderApi } from './query'
import {
  orderHasPrescriptionUploaded,
  orderHasPrescriptionPeriodExcedeed,
  getOrderItemsMap,
  isOrderComplete,
} from '../../utils/order'
import { ShippingsMethodsEnum } from '../../types/cart'

const initialState: IOrderSliceState = {
  isFetching: false,
  isCartFetched: false,
  isFetchingOrderDetails: false,
  //isFetchingShippingInfo: false,
  isAddingItem: false,
  billingFormStatus: {
    isValid: false,
  },
  isShippingUsedAsBilling: false,
  isCheckoutDisabled: false,
  isFinalizationFailed: false,
  isFinalizing: false,
  isRXProductsLimitExceeded: false,
  isRecurringOrderDisabled: false,
  numItems: 0,
  orderComplete: false,
  orderItems: [],
  parsedOrderItems: {
    rx: null,
    cl: null,
    'cl-acc': null,
    sun: null,
    default: null,
  },
  payMethods: [],
  paypalExpress: {
    isSelected: false,
    error: false,
    loading: false,
  },
  selectedPayMethodInfoList: [],
  shippingFormStatus: {
    isValid: false,
  },
  orderDetails: null,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionFormData: {},
    prescriptionMacroGroups: [],
  },
  creditCardNumber: undefined,
  cartShippingCharges: {
    shippingCharges: undefined,
    error: undefined,
  },
}

export type OrderApiState = Partial<IOrderSliceState>

// Find a way to execute those requests
//yield takeLatest([LOGIN_SUCCESS_ACTION, GUEST_LOGIN_SUCCESS_ACTION], WORKERS.fetchCart)
//yield takeLatest(INIT_USER_FROM_STORAGE_SUCCESS_ACTION, WORKERS.initFromStorageFetchCart)

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<any>) => {
      state.cart = action.payload
    },
    resetCart: () => {
      //! SessionStorageUtil shouldn't be used inside a reducer
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
      return initialState
    },

    toggleCartOrderComplete: (state, action: PayloadAction<boolean>) => {
      state.orderComplete = action.payload
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
    },

    toggleShippingAsBilling: (state, action: PayloadAction<boolean>) => {
      state.isShippingUsedAsBilling = action.payload
    },

    togglePaypalExpress: (state, action: PayloadAction<boolean>) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: action.payload,
      }
    },

    setBillingFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.billingFormStatus = {
        ...state.billingFormStatus,
        isValid: payload,
      }
    },

    setPaymentMethod: (
      state,
      { payload }: PayloadAction<PaymentInfoType[]>
    ) => {
      state.selectedPayMethodInfoList = payload
    },

    setShippingFormValidStatus: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.shippingFormStatus = {
        ...state.shippingFormStatus,
        isValid: payload,
      }
    },
    setCreditCardNumber: (state, { payload }: PayloadAction<string>) => {
      state.creditCardNumber = payload
    },
    resetCreditCardNumber: (state) => {
      state.creditCardNumber = initialState.creditCardNumber
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)

    builder.addCase(addItem.rejected, (state) => {
      state.isAddingItem = false
    })

    builder.addCase(addItem.pending, (state) => {
      state.isAddingItem = true
    })

    builder.addCase(addItem.fulfilled, (state) => {
      state.isAddingItem = false
    })

    /* builder.addCase(fetchShipInfo.fulfilled, (state, action) => {
      state.isFetchingShippingInfo = false
      state.shipInfos = action.payload
    })

    builder.addCase(fetchShipInfo.rejected, (state) => {
      state.isFetchingShippingInfo = false
      state.shipInfos = undefined
    })

    builder.addCase(fetchShipInfo.pending, (state) => {
      state.isFetchingShippingInfo = true
    }) */

    builder.addCase(fetchPayMethods.fulfilled, (state, { payload }) => {
      if (payload.usablePaymentInformation) {
        const cardsList: any[] = []
        for (const payment of payload.usablePaymentInformation) {
          cardsList.push(payment)
        }

        if (cardsList.length > 0) {
          state.payMethods = cardsList
        }
      }
    })

    builder.addCase(fetchPaypalExpress.pending, (state) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: true,
        loading: true,
        error: false,
      }
    })

    builder.addCase(fetchPaypalExpress.rejected, (state) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: false,
        loading: false,
        error: true,
      }
    })

    builder.addCase(fetchPaypalExpress.fulfilled, (state, { payload }) => {
      if (payload) {
        state.paypalExpress = {
          ...state.paypalExpress,
          loading: false,
          error: false,
          redirecturl: payload.redirecturl,
        }
      }
    })

    builder.addCase(fetchCart.rejected, (state, action) => {
      //! SORRY TEAM THIS CAST TO ANY WAS NECESSARY BECAUSE TYPES ARE JUST BROKEN
      //! HOPE TO FIX THEM ALL ASAP ☮️
      const error = action.error as any
      if (error?.response?.status && error.response.status === NOT_FOUND) {
        state.cart = undefined
        state.numItems = 0
        state.orderItems = []
      }
      state.isCheckoutDisabled = true
      state.isFetching = false
    })

    builder.addCase(fetchCart.pending, (state) => {
      state.isFetching = true
    })

    builder
      .addMatcher(
        isAnyOf(
          addItem.fulfilled,
          addFrame.fulfilled,
          addLens.fulfilled,
          addItem.rejected,
          addFrame.rejected,
          addLens.rejected
        ),
        (state) => {
          state.isAddingItem = false
        }
      )
      .addMatcher(
        isAnyOf(addItem.pending, addFrame.pending, addLens.pending),
        (state) => {
          state.isAddingItem = true
        }
      )
      /*  .addMatcher(
        orderApi.endpoints.getShippingInfo.matchFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.isFetchingShippingInfo = false
          state.shipInfos = payload
        }
      ) */
      .addMatcher(
        isAnyOf(orderApi.endpoints.finalizeOrderWithCybersource.matchPending),
        (state) => {
          state.isFinalizing = true
        }
      )
      .addMatcher(
        isAnyOf(orderApi.endpoints.finalizeOrderWithCybersource.matchFulfilled),
        (state) => {
          state.isFinalizing = false
        }
      )
      .addMatcher(
        isAnyOf(orderApi.endpoints.finalizeOrderWithCybersource.matchRejected),
        (state) => {
          state.isFinalizing = false
          state.isFinalizationFailed = true
        }
      )
      .addMatcher(orderApi.endpoints.getCart.matchPending, (state, payload) => {
        const { meta } = payload
        const originalArgs = meta?.arg?.originalArgs
        //state.isFetchingShippingInfo = true
        state.isFetching = originalArgs?.refetch ? !originalArgs?.refetch : true
        state.isCartFetched = false
      })
      .addMatcher(orderApi.endpoints.getCart.matchRejected, (state) => {
        //state.isFetchingShippingInfo = false
        state.isFetching = false
        state.isCartFetched = true
      })
      .addMatcher(
        orderApi.endpoints.getCart.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            cart: Cart
            catentries?: IOrderSliceState['catentries']
            //shippingInfo: IOrderSliceState['shipInfos']
            checkInventory: boolean
            //fetchShippingInfo: boolean
            filterPrescriptionItemType?: 'cl' | 'rx'
            filterPrescriptionNeededItems?: boolean
          }>
        ) => {
          state.isFetching = true
          if (payload) {
            const {
              catentries,
              checkInventory,
              cart,
              //shippingInfo,
              //fetchShippingInfo,
            } = payload
            const orderItems = cart?.orderItem

            const orderItemsAttachments = getOrderItemsAttachments(
              orderItems,
              catentries
            )
            state.catentries = catentries
            state.cart = cart || null
            //state.shipInfos = fetchShippingInfo ? shippingInfo : state.shipInfos
            let count = 0
            if (orderItems) {
              count = orderItems.reduce(
                (c: number, item: any) => +item.quantity + c,
                0
              )
            }
            state.numItems = count
            let newOrderItems: any[] = []
            let disableRecurringOrder = false
            let disableCheckout = false
            if (orderItems && orderItems.length > 0) {
              newOrderItems = []
              orderItems.forEach((item: OrderItem) => {
                if (checkInventory) {
                  if (
                    item.orderItemInventoryStatus !==
                      INVENTORY_STATUS.available &&
                    item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
                  ) {
                    disableCheckout = true
                  }
                }
                const obj: OrderItem | OrderItemWithRoxProps = {
                  ...item,
                }

                const catentryId = item.productId
                if (catentries != null) {
                  const catentry = catentries[catentryId]
                  if (catentry !== undefined) {
                    if (catentry.name !== undefined) {
                      obj['name'] = catentry.name
                    }
                    if (catentry.thumbnail !== undefined) {
                      obj['thumbnail'] = catentry.thumbnail
                    }
                    if (catentry.attributes !== undefined) {
                      obj['attributes'] = catentry.attributes
                    }
                    if (catentry.seo !== undefined) {
                      obj['seo'] = catentry.seo
                    }
                    if (catentry.disallowRecurringOrder !== undefined) {
                      obj['disallowRecurringOrder'] =
                        catentry.disallowRecurringOrder
                      if (catentry.disallowRecurringOrder === '1') {
                        disableRecurringOrder = true
                      }
                    }
                    if (catentry.parentCatalogGroupID !== undefined) {
                      obj['parentCatalogGroupID'] =
                        catentry.parentCatalogGroupID
                    }

                    if (catentry.x_offerpriceRx !== undefined) {
                      obj['x_offerpriceRx'] = catentry.x_offerpriceRx
                    }
                    if (catentry.x_offerDiscountpriceRx !== undefined) {
                      obj['x_offerDiscountpriceRx'] =
                        catentry.x_offerDiscountpriceRx
                    }
                    if (catentry.x_priceBadge !== undefined) {
                      obj['x_priceBadge'] = catentry.x_priceBadge
                    }
                    obj.attachments = orderItemsAttachments[obj.productId]
                  }
                }
                obj.prescriptionUploaded =
                  orderHasPrescriptionUploaded(item) || false
                newOrderItems.push(obj)
              })

              state.isCheckoutDisabled = disableCheckout
              state.isRecurringOrderDisabled = disableRecurringOrder
            }
            state.orderItems = newOrderItems
            state.parsedOrderItems = getOrderItemsMap(state.orderItems)
            state.orderComplete = !!cart
              ? cart.orderStatus === ORDER_STATUS.Created
              : true
            state.isRXProductsLimitExceeded =
              newOrderItems.filter((item) =>
                isRxProduct(item?.orderItemExtendAttribute)
              ).length > RX_PRODUCTS_IN_CART_LIMIT
          }
          if (
            state.isRecurringOrderDisabled &&
            state.cart &&
            state.cart.orderId
          ) {
            if (
              localStorageUtil.get(
                ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId
              )
            ) {
              const recurringOrderInfo: any[] = [false, '0', null]
              localStorageUtil.set(
                ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId,
                recurringOrderInfo
              )
            }
          }
          state.isFetching = false
          //state.isFetchingShippingInfo = false
          state.isCartFetched = true
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById.matchPending, (state) => {
        state.isFetchingOrderDetails = true
      })
      .addMatcher(
        orderApi.endpoints.findOrderdById.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            orderData: IOrderDetails
            catentries?: IOrderSliceState['catentries']
          }>
        ) => {
          if (payload) {
            const { orderData, catentries } = payload
            const { orderStatus } = orderData

            const updatedOrderItems = orderData.orderItem?.map((oi) => {
              const p = orderData?.x_data?.productDetails?.find(
                ({ id }) => oi?.productId === id
              )
              const rxServices = oi?.roxableServices
              const rxServicesDetails =
                orderData?.x_data?.productDetails.filter(({ id }) =>
                  rxServices?.some((s) => s.productId === id)
                )
              const catentryId = oi.productId
              if (catentries != null) {
                const catentry = catentries[catentryId]
                if (catentry !== undefined) {
                  if (catentry.attributes !== undefined) {
                    oi['attributes'] = catentry.attributes
                  }
                }
              }

              const newOrderItem: OrderItem = {
                ...oi,
                attachments: p?.attachments || [],
                attributes: p?.attributes || [],
                roxableServices: rxServicesDetails,
                orderItemPrice: oi?.orderItemPrice || '',
                currency: oi?.currency || '',
                productId: oi?.productId || '',
                x_offerpriceRx: p?.x_offerpriceRx,
                prescriptionUploaded: orderHasPrescriptionUploaded(oi) || false,
              }
              return newOrderItem
            })
            state.orderItems = updatedOrderItems

            const orderPaymentMethodId = orderData?.paymentInstruction?.find(
              (pi) => !!pi
            )?.payMethodId

            state.orderDetails = {
              ...orderData,
              orderItem: updatedOrderItems,
              orderComplete: isOrderComplete(orderStatus, orderPaymentMethodId),
              parsedOrderItems: getOrderItemsMap(updatedOrderItems),
              prescriptionPeriodExpired: orderHasPrescriptionPeriodExcedeed(
                orderData,
                10
              ),
            }
            state.orderComplete = isOrderComplete(
              orderStatus,
              orderPaymentMethodId
            )
            state.isFetchingOrderDetails = false

            if (catentries) {
              state.catentries = catentries
            }
          }
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById.matchRejected, (state) => {
        state.isFetchingOrderDetails = false
      })
      .addMatcher(
        orderApi.endpoints.getShippingPrice.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            shipList?: ShippingChargesWithoutPromotions[]
          }>
        ) => {
          if (payload?.shipList && payload.shipList.length) {
            const data = {
              [ShippingsMethodsEnum.Green]: payload.shipList.find(
                (ship) => ship.shipModeName === ShippingsMethodsEnum.Green
              ),
              [ShippingsMethodsEnum.Standard]: payload.shipList.find(
                (ship) => ship.shipModeName === ShippingsMethodsEnum.Standard
              ),
            }
            state.cartShippingCharges.shippingCharges = data
          }
        }
      )
      .addMatcher(
        orderApi.endpoints.getShippingPrice.matchRejected,
        (state, { payload }) => {
          state.cartShippingCharges.error = payload
        }
      )
  },
})

export const {
  updateCart,
  resetCart,
  toggleCartOrderComplete,
  toggleShippingAsBilling,
  togglePaypalExpress,
  setBillingFormValidStatus,
  setPaymentMethod,
  setShippingFormValidStatus,
  setCreditCardNumber,
  resetCreditCardNumber,
} = orderSlice.actions

export default orderSlice.reducer
