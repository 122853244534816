import { RadioGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledRadioGroup = styled(RadioGroup)(({ row, theme }) => ({
  '.MuiFormControlLabel-root': {
    alignItems: 'flex-start',
    marginBottom: !row ? 24 : 0,
    padding: 0,
  },
  '.MuiRadio-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(1, 3, 0, 0),
    margin: 0,
  },
}))
