import { productApi } from '../../features/product/query'
import { cmsApi } from '../../features/cms/query'
import { SeoApiState, seoApi } from '../../features/seo/query'
import { orderApi } from '../../features/order/query'
import { siteApi } from 'src/features/site/query'
import {
  ApiReducerState,
  ConfirmationReducerState,
  ContextReducerState,
  ContractReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  OrganizationReducerState,
  SearchReducerState,
  ThemeReducerState,
  UserReducerState,
} from './reducerStateInterface'
import account, { IAccountState } from '../../features/account'
import preview, { PreviewState } from '../../features/preview/slice'
import success, { ISuccessSliceState } from '../../features/success/slice'
import wishList, { WishListState } from '../../features/wishList/slice'
import plp, { PlpStateInterface } from '../../features/plp/slice'
import stores, { StoresState } from '../../features/stores/slice'
import product, {
  ProductApiState,
  ProductState,
} from '../../features/product/slice'
import seo, { SeoStateInterface } from '../../features/seo/slice'
import ui, { IUiState } from '../../features/ui/slice'
import { IOrderSliceState } from '../../features/order/IOrderSliceState'

//Standard libraries
import { combineReducers } from 'redux'
import confirmation from './confirmation'
import api from './api'
import context from './context'
import contract from './contract'
import error from './error'
import order, { OrderApiState } from '../../features/order/slice'
import { PrescriptionSliceState } from '../../features/prescription/PrescriptionSliceState'
import prescription from '../../features/prescription/slice'
import orderDetails from './orderDetails'
import organization from './organization'
import search from './search'
import theme from './theme'
import user from './user'
import { plpApi } from '../../features/plp/query'
import { categoryApi } from '../../features/category/query'
import cms, { ICmsState } from '../../features/cms/slice'
import site, { SiteApiState, SiteReducerState } from '../../features/site/slice'

export * from './reducerStateInterface'

export interface RootReducerState {
  account: IAccountState
  api: ApiReducerState
  user: UserReducerState
  order: IOrderSliceState
  orderApi: OrderApiState
  error: ErrorReducerState
  seo: SeoStateInterface
  seoApi: SeoApiState
  contract: ContractReducerState
  search: SearchReducerState
  organization: OrganizationReducerState
  context: ContextReducerState
  success: ISuccessSliceState
  confirmation: ConfirmationReducerState
  orderDetails: OrderDetailsReducerState
  orderDetailsLoadings: { [orderId: string]: boolean }
  theme: ThemeReducerState
  site: SiteReducerState
  siteApi: SiteApiState
  wishList: WishListState
  preview: PreviewState
  product: ProductState
  cms: ICmsState
  productApi: ProductApiState
  plp: PlpStateInterface
  ui: IUiState
  prescription: PrescriptionSliceState
  stores: StoresState
}

const reducers = {
  account,
  api,
  user,
  order,
  error,
  contract,
  search,
  context,
  organization,
  success,
  confirmation,
  orderDetails,
  theme,
  wishList,
  preview,
  site,
  [productApi.reducerPath]: productApi.reducer,
  product,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [seoApi.reducerPath]: seoApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [plpApi.reducerPath]: plpApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  plp,
  ui,
  cms,
  prescription,
  stores,
  seo,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
