import { RootReducerState } from '../../redux/reducers'
import { createSelector } from '@reduxjs/toolkit'
import { seoApi } from './query'
//import {ISeo, SeoResponse} from '../../types/seo';

const seoSelector = (state: RootReducerState) => {
  return state.seo || ''
}

const currentPageSeoDataSelector = (identifier: string) =>
  createSelector(
    seoApi.endpoints.getSeo.select({ identifier: decodeURI(identifier) }),
    (result) => result.data?.contents?.[0]!
  )

export { seoSelector, currentPageSeoDataSelector }
