import Axios, { Canceler } from 'axios'
import { Button, ModalDialog } from '../UI'
//Styles
import {
  ButtonContainer,
  StyledHeadingContainer,
} from './RemoveAddressDialog.style'
//Standard libraries
import React, { useEffect } from 'react'

import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../redux/actions/user'
//UI
//Custom libraries
import getDisplayName from 'react-display-name'
import personContactService from '../../foundation/apis/transaction/personContact.service'
import { sendSuccessMessage } from '../../features/success/slice'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
//Foundation libraries
import { useTranslation } from 'react-i18next'
import { Contact } from '../../types/user'

const RemoveAddressDialogLayout: React.FC<{
  handleClose: () => void
  userAddress: Contact
}> = ({ handleClose, userAddress }) => {
  const { isDesktop } = useBreakpoints()

  const widgetName = getDisplayName(RemoveAddressDialogLayout)
  const [removeAddressDialog, setRemoveAddressDialogState] =
    React.useState<boolean>(true)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payload: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const deleteAddress = (nickName: string | undefined) => {
    const parameters: any = {
      nickName: nickName,
      ...payload,
    }
    personContactService.deletePersonContact(parameters).then(() => {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payload))
      handleClose()

      dispatch(
        sendSuccessMessage({
          key: 'success-message.DELETE_ADDRESS_SUCCESS',
          messageParameters: {
            '0': nickName,
          },
        })
      )
    })
  }

  const handleRemoveAddressDialogState = () =>
    setRemoveAddressDialogState(false)

  useEffect(() => {
    if (removeAddressDialog) handleRemoveAddressDialogState()
  }, [])

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  return (
    <ModalDialog
      open={true}
      onClose={handleClose}
      onCloseButton={isDesktop ? handleClose : undefined}
      widthSize={'sm'}
      roundBorders
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <StyledHeadingContainer>
          {t('RemoveAddressDialog.Title')}
        </StyledHeadingContainer>
        <ButtonContainer>
          <Button onClick={() => deleteAddress(userAddress.nickName)}>
            {t('RemoveAddressDialog.YesRemove')}
          </Button>

          <Button fillType={'outline'} onClick={handleClose}>
            {t('RemoveAddressDialog.NoClose')}
          </Button>
        </ButtonContainer>
      </>
    </ModalDialog>
  )
}

export { RemoveAddressDialogLayout }
