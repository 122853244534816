import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { IconNameProp, WrappedSVGIconProps } from './icon.type'
import { styled } from '@mui/material/styles'

const HiddenTitleForWCAG = ({ name }: Required<IconNameProp>) => (
  <span style={{ visibility: 'hidden', position: 'absolute' }}>{name}</span>
)

const StyledSvgIcon = styled(SvgIcon)({
  width: 'inherit',
  height: 'inherit',
})

const IconWrapper = ({ children, name, ...props }: WrappedSVGIconProps) => {
  return (
    <StyledSvgIcon {...props}>
      {name ? <HiddenTitleForWCAG name={name} /> : null}
      {children}
    </StyledSvgIcon>
  )
}
export default IconWrapper
