import React, { useEffect, useMemo, useRef, useState } from 'react'
import loadable from '@loadable/component'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import Fade from '@mui/material/Fade/Fade'
import { TransitionGroup } from 'react-transition-group'
//types
import { IPlacement } from '../../types/teaser'
//components
//redux
import {
  hamburgerMenuOpenSelector,
  openDrawerSearchSelector,
} from '../../features/ui/selector'
import {
  menuLoadedSelector,
  headerLoadedSelector,
} from 'src/features/cms/selector'
//queries
import { useGetHeaderQuery } from '../../features/cms/query'
import {
  BenefitContainer,
  HamburgerMenuGreyBackground,
  WrapperHeader,
} from './Header.style'
//skeletons
import BenefitBarSkeleton from './components/HeaderSkeleton/BenefitBarSkeleton'
import NavigationBarSkeleton from './components/HeaderSkeleton/NavigationBarSkeleton'
import PromoBarSkeleton from './components/HeaderSkeleton/PromoBarSkeleton'
import useScrollingUp from '../../hooks/useScrollingUp/useScrollingUp'
import { fetchWishlistAction } from '../../features/wishList/action'
import { wishlistEnabledSelector } from '../../features/wishList/selector'
import { useSite } from '../../foundation/hooks/useSite'
import { wcTokenSelector } from '../../redux/selectors/user'
import { useAbandonedOrder } from '../../pages/Cart/useAbandonedOrder'
import { Helmet } from 'react-helmet'
import config from '../../configs'
import { useSearchParams } from 'react-router-dom'
import { useDynamicCmsContent } from 'src/foundation/hooks/useDynamicCmsContent'

const contentsHeader = (headerPlacements: IPlacement[]) => {
  const benefitBar = find(headerPlacements, { name: 'header_benefit_bar' })
  const trendingNow = find(headerPlacements, {
    name: 'header_search_trending_now',
  })
  const searchBanner = find(headerPlacements, { name: 'header_search_banner' })
  const promoBar = find(headerPlacements, { name: 'header_promo_bar' })
  const findStore = find(headerPlacements, { name: 'header_find_a_store' })

  return {
    benefitBar,
    trendingNow,
    searchBanner,
    promoBar,
    findStore,
  }
}

const Header: React.FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const previewDate = searchParams.get('previewDate') || undefined
  const filterRulesLocaleOverride =
    searchParams.get('filterRulesLocaleOverride') || undefined

  const params =
    previewDate || filterRulesLocaleOverride
      ? { previewDate, filterRulesLocaleOverride }
      : undefined

  const { data } = useGetHeaderQuery(params)
  const { benefitBar, findStore } = contentsHeader(data?.headerPlacements!)

  const temp = useDynamicCmsContent(data?.headerPlacements!)
  const { promoBar } = contentsHeader(temp.dynamicContentBanners!)

  const extraCSS = data?.extraCSS || []
  const extraJS = data?.extraJS || []
  const isHamburgerDrawerOpen = useSelector(hamburgerMenuOpenSelector)
  const isSearchDrawerOpen = useSelector(openDrawerSearchSelector)
  const scrolled = useScrollingUp()

  const wcToken = useSelector(wcTokenSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const menuLoaded = useSelector(menuLoadedSelector)
  //const menuLoading = useSelector(menuLoadingSelector)
  const headerLoaded = useSelector(headerLoadedSelector)
  //const headerLoading = useSelector(headerLoadingSelector)
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const NavigationBar = useMemo(() => {
    return loadable(() => import('./components/NavigationBar'), {
      fallback: <NavigationBarSkeleton />,
    })
  }, [menuLoaded])
  const HamburgerMenu = useMemo(() => {
    return loadable(() => import('./components/HamburgerMenu'))
  }, [menuLoaded])
  const BenefitBar = useMemo(() => {
    return loadable(() => import('./components/BenefitBar'), {
      fallback: <BenefitBarSkeleton />,
    })
  }, [headerLoaded])
  const PromotionBar = useMemo(() => {
    return loadable(() => import('./components/PromotionBar'), {
      fallback: <PromoBarSkeleton />,
    })
  }, [headerLoaded])

  const SearchLayer = useMemo(() => {
    return loadable(() => import('./components/SearchLayer'))
  }, [headerLoaded])

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
  })

  useAbandonedOrder()

  useEffect(() => {
    // `wcToken` (loggedIn or guest user) is required for wishlist requests
    if (mySite && isWishlistEnabled && !!wcToken) {
      dispatch(fetchWishlistAction())
    }
  }, [isWishlistEnabled, mySite, wcToken])

  const [isHeaderVisible, setHeaderVisibility] = useState<boolean | null>(null)

  useEffect(() => {
    if (scrolled == null) {
      setHeaderVisibility(null)
    } else {
      setHeaderVisibility(scrolled || isSearchDrawerOpen)
    }
  }, [scrolled, isSearchDrawerOpen])

  const cssLink = (link: string) => (
    <link
      key={link}
      rel="stylesheet"
      type="text/css"
      href={[config.cmsImageServerUrl, link].join('')}
    />
  )

  const jsTag = (src: string) => (
    <script
      key={src}
      src={[config.cmsImageServerUrl, src].join('')}
      type="text/javascript"
    />
  )

  return (
    <>
      <Helmet>
        {extraJS && extraJS.map((el) => jsTag(el))}
        {extraCSS && extraCSS.map((el) => cssLink(el))}
      </Helmet>
      {isHamburgerDrawerOpen && <HamburgerMenuGreyBackground />}
      <WrapperHeader isSticky={isHeaderVisible}>
        <TransitionGroup component={null}>
          {headerLoaded ? (
            <Fade timeout={{ enter: 1000 }} in={headerLoaded}>
              <BenefitContainer ref={ref}>
                <BenefitBar
                  hasScrolled={scrolled!}
                  data={benefitBar!}
                  findStore={findStore!}
                />
              </BenefitContainer>
            </Fade>
          ) : (
            <BenefitBarSkeleton />
          )}
          {menuLoaded ? (
            <Fade timeout={{ enter: 500 }} in={menuLoaded}>
              <div className="navbar-animation-wrapper">
                <NavigationBar />
              </div>
            </Fade>
          ) : (
            <NavigationBarSkeleton />
          )}
        </TransitionGroup>
        <HamburgerMenu
          open={isHamburgerDrawerOpen}
          plusMargin={isHamburgerDrawerOpen ? height : 0}
          findStore={findStore!}
        />
        <TransitionGroup component={null}>
          {headerLoaded ? (
            <Fade timeout={{ enter: 500 }} in={headerLoaded}>
              <div className="promobar-animation-wrapper">
                {!isSearchDrawerOpen && <PromotionBar data={promoBar!} />}
              </div>
            </Fade>
          ) : (
            <PromoBarSkeleton />
          )}
        </TransitionGroup>

        <SearchLayer
          hasScrolled={isHeaderVisible!}
          drawerprops={{
            variant: 'temporary',
            ModalProps: {
              keepMounted: true,
            },
          }}
          open={isSearchDrawerOpen}
        />
      </WrapperHeader>
    </>
  )
}

export default Header
