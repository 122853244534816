//Redux
import { SiteInfo } from '../../../redux/reducers'
import { siteSelector } from '../../../redux/selectors/site'
//Standard libraries
import { useSelector } from 'react-redux'

interface useSiteResponse {
  mySite: SiteInfo
  storeDisplayName: string
}

const useSite = (): useSiteResponse => {
  const mySite = useSelector(siteSelector)!

  const storeDisplayName = process?.env?.REACT_APP_STORENAME || 'site'

  return { mySite, storeDisplayName }
}

export { useSite }
