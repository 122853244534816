import { Tabs, TabsProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

export const StyledTabsWithStyle = styled((props: TabsProps) => (
  <Tabs {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.custom.alto}`,

  [theme.breakpoints.up('xs')]: {
    minHeight: theme.spacing(5),
    height: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    minHeight: theme.spacing(6),
    height: theme.spacing(6),
  },

  '& .MuiTabs-scroller': {
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },

  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.custom.halfBaked,
  },
}))
