import type { CustomSVGIconProps } from './icon.type'
import IconWrapper from './IconWrapper'
import React from 'react'
import { styled } from '@mui/material/styles'

const PaymentIconWrapper = styled(IconWrapper)`
  width: initial;
  height: initial;
`

// A

export const AddIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.5H11V11.5L7 11.5V13.5H11V17.5H13V13.5H17V11.5L13 11.5V7.5Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AddressIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 0C14.079 0 6.036 8.043 6.036 17.964c0 10.414 12.116 24.619 16.447 29.365a2.049 2.049 0 0 0 3.034 0c4.33-4.746 16.447-18.951 16.447-29.365C41.964 8.043 33.92 0 24 0zm.039 45.981h-.08c-3.735-4.093-15.924-18.21-15.924-28.017C8.035 9.161 15.197 2 24 2s15.964 7.161 15.964 15.964c0 9.807-12.19 23.924-15.925 28.017z M24 10c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AmExIcon: React.FC<CustomSVGIconProps> = () => {
  return (
    <IconWrapper viewBox={'0 0 30 20'} width={30} height={20} fill="none">
      <rect
        x=".376"
        y=".208"
        width="28.75"
        height="19.583"
        rx="2.292"
        fill="#1F72CD"
        stroke="#D8D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m5.725 7.083-2.729 6.04h3.267l.405-.964h.926l.405.963h3.596v-.735l.32.735h1.86l.32-.75v.75h7.479l.91-.938.85.938 3.842.008-2.738-3.01 2.738-3.037h-3.782l-.885.92-.824-.92h-8.136l-.699 1.559-.715-1.559h-3.26v.71l-.362-.71H5.725zm.632.858h1.592l1.81 4.095V7.94h1.745l1.398 2.936L14.19 7.94h1.736v4.333H14.87L14.86 8.88l-1.54 3.395h-.944L10.828 8.88v3.395H8.656l-.412-.971H6.018l-.41.97H4.442l1.914-4.332zm14.83 0h-4.294v4.33h4.228l1.362-1.435 1.314 1.436h1.373l-1.996-2.153L25.17 7.94h-1.314L22.501 9.36l-1.314-1.42zm-14.056.733-.733 1.73h1.465l-.732-1.73zm10.823.955v-.791h2.68l1.169 1.264-1.221 1.272h-2.628v-.863h2.343v-.882h-2.343z"
        fill="#fff"
      />
    </IconWrapper>
  )
}

export const AffirmIcon: React.FC<CustomSVGIconProps> = () => {
  return (
    <PaymentIconWrapper viewBox="0 0 41 25" width={41} height={25} fill="none">
      <path
        d="M.166 1a1 1 0 0 1 1-1h38a1 1 0 0 1 1 1v23a1 1 0 0 1-1 1h-38a1 1 0 0 1-1-1V1z"
        fill="#fff"
      />
      <path fill="#fff" d="M3.074 5.768h34.184V19.23H3.074z" />
      <mask
        id="affirm_icon_a"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="13"
        width="6"
        height="7"
      >
        <path d="M3.074 13.352h5.224v5.88H3.074v-5.88z" fill="#fff" />
      </mask>
      <g mask="url(#affirm_icon_a)">
        <path
          d="M5.316 18.072c-.423 0-.634-.205-.634-.544 0-.627.711-.841 2.008-.977 0 .839-.574 1.521-1.374 1.521zm.559-4.72c-.927 0-1.994.431-2.573.887l.53 1.098c.464-.42 1.215-.777 1.892-.777.644 0 .999.212.999.64 0 .288-.236.433-.681.49-1.663.214-2.968.666-2.968 1.932 0 1.003.724 1.61 1.854 1.61.807 0 1.525-.443 1.867-1.027v.863h1.503v-3.616c0-1.494-1.051-2.1-2.423-2.1z"
          fill="#060809"
        />
      </g>
      <path
        d="M20.973 13.515v5.553h1.61v-2.675c0-1.273.779-1.646 1.323-1.646.212 0 .499.06.688.2l.293-1.467a1.858 1.858 0 0 0-.721-.128c-.828 0-1.347.362-1.69 1.096v-.933h-1.503zM32.35 13.352c-.852 0-1.488.496-1.819.975-.307-.619-.959-.975-1.739-.975-.85 0-1.44.466-1.712 1.003v-.84h-1.55v5.553h1.61V16.21c0-1.027.544-1.519 1.052-1.519.46 0 .883.294.883 1.052v3.325h1.608V16.21c0-1.039.532-1.519 1.064-1.519.425 0 .873.305.873 1.04v3.337h1.608V15.23c0-1.249-.85-1.878-1.879-1.878zM17.056 13.515h-1.458v-.565c0-.734.425-.945.792-.945.405 0 .72.178.72.178l.496-1.12s-.503-.325-1.418-.325c-1.028 0-2.198.572-2.198 2.368v.409h-2.44v-.565c0-.734.425-.945.79-.945.209 0 .49.048.721.178l.496-1.12c-.296-.172-.772-.325-1.417-.325-1.029 0-2.199.572-2.199 2.368v.409h-.933v1.225h.933v4.328h1.608V14.74h2.441v4.328h1.608V14.74h1.458v-1.225z"
        fill="#060809"
      />
      <mask
        id="affirm_icon_b"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="35"
        height="15"
      >
        <path d="M3.074 19.231h34.184V5.768H3.074V19.23z" fill="#fff" />
      </mask>
      <g mask="url(#affirm_icon_b)">
        <path d="M18.016 19.067h1.606v-5.553h-1.606v5.553z" fill="#060809" />
      </g>
      <mask
        id="affirm_icon_c"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="35"
        height="15"
      >
        <path d="M3.074 19.231h34.184V5.768H3.074V19.23z" fill="#fff" />
      </mask>
      <g mask="url(#affirm_icon_c)">
        <path
          d="M27.388 5.768c-4.344 0-8.215 2.976-9.314 6.802h1.575c.918-2.85 4.033-5.35 7.739-5.35 4.505 0 8.398 3.385 8.398 8.655 0 1.183-.155 2.25-.449 3.192h1.528l.015-.051c.25-.973.377-2.029.377-3.141 0-5.878-4.339-10.107-9.869-10.107z"
          fill="#0FA0EA"
        />
      </g>
    </PaymentIconWrapper>
  )
}

export const ApplePayIcon: React.FC<CustomSVGIconProps> = ({
  width = 30,
  height = 20,
  ...rest
}) => {
  return (
    <PaymentIconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.04"
          y=".208"
          width="28.75"
          height="19.583"
          rx="2.292"
          fill="#fff"
          stroke="#D8D8D8"
        />
        <path
          d="M9.498 7.494c.229-.267.384-.627.343-.994a1.511 1.511 0 0 0-.978.475c-.213.229-.4.603-.351.955.375.03.75-.176.986-.436zM9.836 7.999c-.545-.03-1.008.29-1.269.29-.26 0-.658-.275-1.09-.267a1.625 1.625 0 0 0-1.366.777c-.585.946-.154 2.349.415 3.12.277.38.61.8 1.05.785.414-.015.577-.252 1.08-.252.505 0 .651.252 1.09.244.456-.007.74-.381 1.017-.762.318-.435.447-.854.456-.877-.009-.008-.879-.32-.887-1.259-.008-.785.683-1.159.716-1.182-.39-.54-1-.602-1.212-.617zM14.582 6.936c1.184 0 2.009.765 2.009 1.879 0 1.118-.842 1.887-2.04 1.887h-1.31v1.954h-.948v-5.72h2.289zm-1.341 3.02h1.087c.825 0 1.294-.415 1.294-1.137 0-.721-.47-1.134-1.29-1.134H13.24v2.272zM16.84 11.471c0-.73.596-1.177 1.654-1.233l1.218-.067V9.85c0-.464-.334-.742-.892-.742-.53 0-.86.238-.94.61h-.862c.05-.752.736-1.307 1.835-1.307 1.08 0 1.769.535 1.769 1.371v2.874h-.876v-.686h-.021c-.258.464-.82.758-1.405.758-.871 0-1.48-.508-1.48-1.257zm2.872-.377v-.329l-1.096.064c-.545.035-.854.261-.854.618 0 .365.322.603.812.603.639 0 1.138-.413 1.138-.956zM21.448 14.19v-.693c.067.016.22.016.296.016.423 0 .651-.167.79-.595 0-.008.081-.254.081-.258l-1.607-4.174h.99l1.125 3.393h.017l1.125-3.393h.965l-1.667 4.388c-.38 1.011-.82 1.336-1.743 1.336-.076 0-.305-.008-.372-.02z"
          fill="#000"
        />
      </svg>
    </PaymentIconWrapper>
  )
}

export const AfterCareIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.84 24.866a3.952 3.952 0 0 0-2.642-2.687 4.023 4.023 0 0 0-3.515.566l-3.033 2.159-1.563-15.11A1.995 1.995 0 0 0 35.098 8H31V7c0-3.86-3.14-7-7-7s-7 3.14-7 7v1h-4.098a1.995 1.995 0 0 0-1.989 1.794L9.236 26H8a.988.988 0 0 0-.481.124l-7 3.847a.998.998 0 0 0-.519.876V47a1.001 1.001 0 0 0 1.496.868L8.266 44H29a.997.997 0 0 0 .656-.246l16.832-14.639c1.284-1.027 1.803-2.655 1.352-4.249zM19 7c0-2.757 2.243-5 5-5s5 2.243 5 5v1H19V7zm-6.098 3H17v4h2v-4h10v4h2v-4h4.098l1.68 16.238L28.681 32h-3.234a3.846 3.846 0 0 0 .547-1.787 3.969 3.969 0 0 0-1.092-2.966A4.02 4.02 0 0 0 22 26H11.247l1.655-16zm32.305 17.58L28.626 42H8a.993.993 0 0 0-.496.132L2 45.277V31.438L8.257 28H22c.554 0 1.07.222 1.451.624s.575.929.546 1.485C23.942 31.152 22.97 32 21.83 32H16v2h13c.208 0 .411-.065.58-.185l13.265-9.44a1.997 1.997 0 0 1 1.778-.28c.612.184 1.12.7 1.293 1.314v.001c.231.819-.022 1.62-.709 2.17z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ApplePayTransparentIcon: React.FC<CustomSVGIconProps> = ({
  fill = '#ffffff',
  width = 37,
  height = 25,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 512 210.2"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fill={fill}
      id="XMLID_34_"
      d="M93.6,27.1C87.6,34.2,78,39.8,68.4,39c-1.2-9.6,3.5-19.8,9-26.1c6-7.3,16.5-12.5,25-12.9
      C103.4,10,99.5,19.8,93.6,27.1 M102.3,40.9c-13.9-0.8-25.8,7.9-32.4,7.9c-6.7,0-16.8-7.5-27.8-7.3c-14.3,0.2-27.6,8.3-34.9,21.2
      c-15,25.8-3.9,64,10.6,85c7.1,10.4,15.6,21.8,26.8,21.4c10.6-0.4,14.8-6.9,27.6-6.9c12.9,0,16.6,6.9,27.8,6.7
      c11.6-0.2,18.9-10.4,26-20.8c8.1-11.8,11.4-23.3,11.6-23.9c-0.2-0.2-22.4-8.7-22.6-34.3c-0.2-21.4,17.5-31.6,18.3-32.2
      C123.3,42.9,107.7,41.3,102.3,40.9 M182.6,11.9v155.9h24.2v-53.3h33.5c30.6,0,52.1-21,52.1-51.4c0-30.4-21.1-51.2-51.3-51.2H182.6z
      M206.8,32.3h27.9c21,0,33,11.2,33,30.9c0,19.7-12,31-33.1,31h-27.8V32.3z M336.6,169c15.2,0,29.3-7.7,35.7-19.9h0.5v18.7h22.4V90.2
      c0-22.5-18-37-45.7-37c-25.7,0-44.7,14.7-45.4,34.9h21.8c1.8-9.6,10.7-15.9,22.9-15.9c14.8,0,23.1,6.9,23.1,19.6v8.6l-30.2,1.8
      c-28.1,1.7-43.3,13.2-43.3,33.2C298.4,155.6,314.1,169,336.6,169z M343.1,150.5c-12.9,0-21.1-6.2-21.1-15.7c0-9.8,7.9-15.5,23-16.4
      l26.9-1.7v8.8C371.9,140.1,359.5,150.5,343.1,150.5z M425.1,210.2c23.6,0,34.7-9,44.4-36.3L512,54.7h-24.6l-28.5,92.1h-0.5
      l-28.5-92.1h-25.3l41,113.5l-2.2,6.9c-3.7,11.7-9.7,16.2-20.4,16.2c-1.9,0-5.6-0.2-7.1-0.4v18.7C417.3,210,423.3,210.2,425.1,210.2z"
    />
  </IconWrapper>
)

export const ArrowSlimDownIcon: React.FC<CustomSVGIconProps> = ({
  width = 64,
  height = 64,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g>
        <polygon points="31,0 31,60.586 23.707,53.293 22.293,54.854 31.293,64 32.707,64 41.707,54.854 40.293,53.366 33,60.586 33,0 	" />
      </g>
    </IconWrapper>
  )
}

export const ArrowDownIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 12.943.195 5.138l.943-.943L8 11.057l6.862-6.862.943.943L8 12.943z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ArrowLeftIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M10.862 15.805 3.057 8 10.862.195l.942.943L4.943 8l6.861 6.862-.942.943z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ArrowRightIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m5.138 15.805-.943-.943L11.057 8 4.195 1.138l.943-.943L12.943 8l-7.805 7.805z"
        fill={htmlColor}
      />{' '}
    </IconWrapper>
  )
}

export const ArrowUpIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M14.862 11.8043L7.99998 4.94264L1.13798 11.8043L0.195312 10.8616L7.99998 3.05664L15.8046 10.8616L14.862 11.8043Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const AddCircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 30.667c-8.087 0-14.667-6.58-14.667-14.667S7.913 1.333 16 1.333 30.667 7.913 30.667 16 24.087 30.667 16 30.667z"
        fill={htmlColor}
      />
      <path
        d="M16.665 9.333h-1.333v6h-6v1.334h6v6h1.333v-6h6v-1.334h-6v-6z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// B
export const BabySmileIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M20 21c0-2.757-2.243-5-5-5s-5 2.243-5 5h2c0-1.654 1.346-3 3-3s3 1.346 3 3h2zM33 16c-2.757 0-5 2.243-5 5h2c0-1.654 1.346-3 3-3s3 1.346 3 3h2c0-2.757-2.243-5-5-5zM18 30a6 6 0 1 0 12 0v-2H18v2zm10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4h8z"
        fill={htmlColor}
      />
      <path
        d="M24 0C10.767 0 0 10.767 0 24s10.767 24 24 24 24-10.767 24-24S37.233 0 24 0zm0 46C11.869 46 2 36.131 2 24S11.869 2 24 2c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4h-2c0 3.309 2.691 6 6 6s6-2.691 6-6a5.952 5.952 0 0 0-1.093-3.441C38.683 4.795 46 13.556 46 24c0 12.131-9.869 22-22 22z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const BagIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M41.0764 12.829C40.9864 11.786 40.1294 11 39.0834 11H33.0014V9C33.0014 4.038 28.9634 0 24.0014 0C19.0394 0 15.0014 4.038 15.0014 9V11H8.91944C7.87344 11 7.01644 11.786 6.92644 12.829L4.19044 44.744C4.11944 45.579 4.40244 46.412 4.96944 47.029C5.53644 47.646 6.34244 48 7.18044 48H40.8234C41.6614 48 42.4674 47.646 43.0334 47.029C43.6004 46.412 43.8844 45.579 43.8124 44.744L41.0764 12.829ZM17.0014 9C17.0014 5.14 20.1414 2 24.0014 2C27.8614 2 31.0014 5.14 31.0014 9V11H17.0014V9ZM8.91944 13H15.0014V18H17.0014V13H31.0014V18H33.0014V13H39.0834L41.3114 39H6.69144L8.91944 13ZM41.5594 45.676C41.3674 45.885 41.1054 46 40.8224 46H7.18044C6.89744 46 6.63544 45.885 6.44344 45.676C6.25244 45.467 6.15944 45.197 6.18444 44.914L6.51944 41H41.4834L41.8194 44.915C41.8434 45.197 41.7514 45.467 41.5594 45.676Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const BestSellingIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.58 20.523c-.916-3.487-3.239-5.759-6.394-6.235A33.544 33.544 0 0 0 37 14.007V14H11v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-27.646-.156c-1.353 5.066-5.446 9.789-9.733 11.228-1.44.483-3.52.775-5.253-.574-2.426-1.895-3.565-6.282-2.593-9.99.413-1.574 1.577-4.285 4.72-4.76A31.574 31.574 0 0 1 11 15.998V16h.416c3.732.034 6.777.81 8.002 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.12a5.583 5.583 0 0 0-.96.823 4.161 4.161 0 0 0-1.093 2.531A5.97 5.97 0 0 0 24 19c-.69 0-1.365.127-2.004.354zm21.056 11.667c-1.733 1.35-3.813 1.058-5.253.574-4.287-1.439-8.38-6.161-9.727-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165H37v-.001c1.247.007 2.561.091 3.907.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.167 8.095-2.593 9.99z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ButterflyGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.003 4.667h-.013c-.97 0-4.973.47-6.053 2.243A1.98 1.98 0 0 0 8 6.667a2 2 0 0 0-.943.237C5.973 5.137 1.98 4.667 1.01 4.667H.997C.253 4.67 0 5.294 0 5.894v3.073c0 1.517.66 2.097 2.663 2.097 2.007 0 4.67-.707 4.67-3.214a1.92 1.92 0 0 0-.03-.313 1.308 1.308 0 0 1 1.394-.003c-.017.103-.03.206-.03.316 0 2.507 2.663 3.214 4.67 3.214 2.003 0 2.663-.58 2.663-2.097V5.894c0-.6-.253-1.224-.997-1.227zM5.437 9.81c-.957.51-2.147.587-2.774.587-1.903 0-1.996-.517-1.996-1.43V5.894c0-.55.227-.56.329-.56.516.001 2.023.148 3.387.6 1.044.346 2.284.956 2.284 1.916 0 .877-.404 1.517-1.23 1.96zm9.896-.843c0 .913-.093 1.43-1.996 1.43-.627 0-1.817-.077-2.774-.587-.826-.443-1.23-1.083-1.23-1.96 0-.96 1.24-1.57 2.284-1.916 1.365-.452 2.871-.6 3.387-.6.101 0 .33.009.33.56v3.073z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// C
export const CalendarIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M46 4H37V0H35V4H25V0H23V4H13V0H11V4H2C0.897 4 0 4.897 0 6V46C0 47.103 0.897 48 2 48H46C47.103 48 48 47.103 48 46V6C48 4.897 47.103 4 46 4ZM46.001 46H2V16H46L46.001 46ZM2 14V6H11V10H13V6H23V10H25V6H35V10H37V6H46V14H2Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CalendarIconSmall: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M11.833 1.333v.5H15c.275 0 .5.225.5.5V15c0 .275-.225.5-.5.5H1a.501.501 0 0 1-.5-.5V2.333c0-.275.225-.5.5-.5h3.167V.5H4.5v1.333h3.333V.5h.334v1.333H11.5V.5h.333v.833zM4.167 2.667v-.5H.833v3.666h14.334V2.167h-3.334V3.5H11.5V2.167H8.167V3.5h-.334V2.167H4.5V3.5h-.333v-.833zm-3.334 12v.5h14.334v-9H.833v8.5z"
        fill={htmlColor}
        stroke={htmlColor}
      />
    </IconWrapper>
  )
}

export const CameraIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={'14 6 20 20'}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M31 11.9999H28.138L26.569 10.4309C26.5067 10.3683 26.4217 10.3333 26.3333 10.3333H21.6667C21.5783 10.3333 21.4933 10.3683 21.431 10.4309L19.862 11.9999H17C16.4487 11.9999 16 12.4486 16 12.9999V20.3333C16 20.8846 16.4487 21.3333 17 21.3333H31C31.5513 21.3333 32 20.8846 32 20.3333V12.9999C32 12.4486 31.5513 11.9999 31 11.9999ZM31.3333 20.3333C31.3333 20.5173 31.1837 20.6666 31 20.6666H17C16.8163 20.6666 16.6667 20.5173 16.6667 20.3333V12.9999C16.6667 12.8159 16.8163 12.6666 17 12.6666H20C20.0853 12.6666 20.1707 12.6339 20.2357 12.5689L21.8047 10.9999H26.1953L27.7643 12.5689C27.8293 12.6339 27.9147 12.6666 28 12.6666H31C31.1837 12.6666 31.3333 12.8159 31.3333 12.9999V20.3333Z"
        fill={htmlColor}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M24 13.3333C22.346 13.3333 21 14.6789 21 16.3333C21 17.9876 22.346 19.3333 24 19.3333C25.654 19.3333 27 17.9876 27 16.3333C27 14.6789 25.654 13.3333 24 13.3333ZM24 18.6666C22.7133 18.6666 21.6667 17.6196 21.6667 16.3333C21.6667 15.0469 22.7133 13.9999 24 13.9999C25.2867 13.9999 26.3333 15.0469 26.3333 16.3333C26.3333 17.6196 25.2867 18.6666 24 18.6666Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CartIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M14.054 5.209A1.327 1.327 0 0 0 12.726 4h-1.393v-.667A3.337 3.337 0 0 0 8 0a3.337 3.337 0 0 0-3.333 3.333V4H3.274c-.693 0-1.263.52-1.328 1.209l-.84 8.969A1.67 1.67 0 0 0 2.764 16h10.47a1.67 1.67 0 0 0 1.66-1.822l-.841-8.969zM6 3.333c0-1.103.897-2 2-2s2 .897 2 2V4H6v-.667zm-1.333 2v1.334H6V5.333h4v1.334h1.333V5.333h1.393l.563 6H2.71l.563-6h1.393zm8.815 9.225a.33.33 0 0 1-.247.109H2.765a.33.33 0 0 1-.247-.11.33.33 0 0 1-.085-.255l.153-1.635h10.828l.153 1.635a.33.33 0 0 1-.085.256z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CatEyeGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.869 5.334c-.384 0-.775.03-1.156.106-1.061.21-2.18.768-2.71 1.502a1.983 1.983 0 0 0-2.007-.004C6.463 6.206 5.346 5.65 4.287 5.44a5.984 5.984 0 0 0-1.156-.106c-1.63 0-3.122.552-3.122.552 0 2.845 1.572 4.78 4.163 4.78s3.352-1.72 3.122-3.058c-.003-.02-.01-.04-.015-.06a1.33 1.33 0 0 1 1.441-.003c-.004.021-.012.042-.015.063-.23 1.338.53 3.057 3.122 3.057 2.591 0 4.163-1.935 4.163-4.779 0 0-1.49-.552-3.122-.552zM6.255 9.241c-.418.496-1.138.758-2.082.758-2.017 0-3.32-1.378-3.48-3.63.53-.152 1.46-.368 2.44-.368.367 0 .713.031 1.026.093 1.256.248 2.368.977 2.48 1.626.095.564-.044 1.118-.384 1.521zm5.573.758c-.944 0-1.664-.262-2.082-.758-.34-.403-.48-.957-.383-1.52.11-.65 1.223-1.38 2.48-1.627A5.342 5.342 0 0 1 12.868 6c.973 0 1.907.217 2.439.368-.161 2.252-1.464 3.63-3.48 3.63z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const ChatIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M46.271 4H1.729C.774 4 0 4.774 0 5.729v30.546c0 .955.774 1.729 1.729 1.729h28.305v4.174c0 1.042.853 1.732 1.741 1.732.425 0 .858-.158 1.21-.51l5.396-5.396h7.889c.955 0 1.729-.774 1.729-1.729V5.729A1.727 1.727 0 0 0 46.271 4zM46 36.004h-8.446l-.586.586-2.087 2.087-2.847 2.847v-5.519H2V6h44v30.004z"
        fill={htmlColor}
      />

      <path
        d="M26 19h-4v4h4v-4zM16 19h-4v4h4v-4zM36 19h-4v4h4v-4z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CheckedRadioIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      {/* <rect x="4" y="4" width="16" height="16" rx="8" stroke={htmlColor} /> */}
      <circle cx="12" cy="12" r="5" fill={htmlColor} />
    </IconWrapper>
  )
}

export const CheckmarkFilledIcon: React.FC<CustomSVGIconProps> = ({
  primaryFill = 'none',
  secondaryFill = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <circle cx={8.069} cy={8} r={8} fill={primaryFill} />
      <path
        d="M6.535 12.009 2.37 8.12l.737-.689 3.428 3.2 6.45-6.04.747.679-7.197 6.738z"
        fill={secondaryFill}
      />
    </IconWrapper>
  )
}

export const CheckmarkIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m16.505 9.28-5 6c-.184.222-.768.86-.768.86s-.542-.611-.737-.824l-3-3.273 1.474-1.351 2.227 2.43L14.97 8l1.536 1.28z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CheckmarkRoundedIcon: React.FC<CustomSVGIconProps> = ({
  primaryFill = 'none',
  htmlColor = 'none',
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      stroke={primaryFill}
      strokeWidth="9"
      d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z"
      fill="none"
    />
    <path
      d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z"
      fill={htmlColor}
    />
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const CheckmarkRoundedValidIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.333 9.057 5.471 7.195l-.942.943 2.804 2.805 4.472-4.472-.943-.942-3.529 3.528z"
      fill={htmlColor}
    />
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const CheckmarkRoundedErrorIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 8,
  height = 8,
  ...rest
}) => (
  <IconWrapper
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill={htmlColor}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
      fill={htmlColor}
    />
    <path
      d="M10.195 4.861 8 7.057 5.805 4.86l-.943.943 2.195 2.195-2.195 2.196.943.942L8 8.942l2.195 2.195.943-.942-2.195-2.196 2.195-2.195-.943-.943z"
      fill={htmlColor}
    />
  </IconWrapper>
)

export const ChevronLeftIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M10.862 15.805 3.057 8 10.862.195l.943.943L4.943 8l6.862 6.862-.943.943z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ChevronRightIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m5.138 15.805-.943-.943L11.057 8 4.195 1.138l.943-.943L12.943 8l-7.805 7.805z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ChooseIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m6.766 17.722 1.922.553c.786-2.732 2.942-7.444 8.548-8.302l-.303-1.977c-4.9.75-8.604 4.294-10.167 9.726z"
        fill={htmlColor}
      />
      <path
        d="M16.55 43.834c-2.786 0-5.25-.828-7.312-2.455C3.228 36.593.43 25.682 2.867 16.54c1.03-3.867 3.919-10.53 11.65-11.715a74.334 74.334 0 0 1 9.806-.66c9.939 0 17.565 2.131 20.399 5.701 1.208 1.522 1.555 3.31 1.06 5.464a39.84 39.84 0 0 1-.821 2.693 7.428 7.428 0 0 1 1.975.391c.289-.859.556-1.726.787-2.603 2.28-9.799-9.914-13.646-23.401-13.646-3.345 0-6.766.236-10.07.677-17.474 2.675-18.043 30.717-6.26 40.103 2.52 1.988 5.45 2.891 8.558 2.891 1.137 0 2.298-.12 3.473-.354l-1.2-1.827c-.772.116-1.532.18-2.273.18z"
        fill={htmlColor}
      />

      <path
        d="M44.501 22c-.539 0-1.044.133-1.5.351V21.5c0-1.93-1.57-3.5-3.5-3.5-.98 0-1.864.407-2.5 1.058a3.485 3.485 0 0 0-2.5-1.058c-.539 0-1.044.133-1.5.351V11.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5v20.247l-1.42-1.286-2.878-2.604c-1.38-1.249-3.334-1.277-4.75-.064-1.415 1.211-1.689 3.145-.667 4.702L26.461 48H43.62l4.382-8.764V25.5c0-1.93-1.57-3.5-3.5-3.5zm1.5 16.764L42.383 46H27.541l-9.583-14.603c-.568-.866-.196-1.665.296-2.085.493-.422 1.34-.665 2.107.029l2.877 2.604 3.092 2.798a1.008 1.008 0 0 0 1.077.171 1 1 0 0 0 .594-.914V11.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-6.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-6.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5V28h2v-2.5c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v13.264z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ClearFieldIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#98hekkf45a)">
          <path
            d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm3.805 10.862-.943.943L8 8.943l-2.862 2.862-.943-.943L7.057 8 4.195 5.138l.943-.943L8 7.057l2.862-2.862.943.943L8.943 8l2.862 2.862z"
            fill={htmlColor}
          />
        </g>
        <defs>
          <clipPath id="98hekkf45a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}

export const ClockIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.334 0c-13.233 0-24 10.767-24 24s10.767 24 24 24c13.232 0 24-10.767 24-24s-10.768-24-24-24zm0 46c-12.131 0-22-9.869-22-22s9.869-22 22-22c12.13 0 22 9.869 22 22s-9.87 22-22 22z"
        fill={htmlColor}
      />
      <path
        d="M25.334 7h-2v17.414l12.293 12.293 1.413-1.414-11.706-11.707V7z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseCircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path
        d="M10.195 4.862 8 7.057 5.805 4.862l-.943.942L7.057 8l-2.195 2.195.943.943L8 8.942l2.195 2.196.943-.943L8.943 8l2.195-2.196-.943-.942z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m15.8 1.133-.933-.934L8 7.066 1.134.199.2 1.133l6.867 6.866L.2 14.866l.934.933L8 8.933l6.867 6.866.933-.933-6.866-6.867L15.8 1.133z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseFilledIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm3.805 10.862-.943.943L8 8.943l-2.862 2.862-.943-.943L7.057 8 4.195 5.138l.943-.943L8 7.057l2.862-2.862.943.943L8.943 8l2.862 2.862z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CloseIconSearch: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm3.805 10.862-.943.943L8 8.943l-2.862 2.862-.943-.943L7.057 8 4.195 5.138l.943-.943L8 7.057l2.862-2.862.943.943L8.943 8l2.862 2.862z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ContactLensIcon: React.FC<CustomSVGIconProps> = ({
  width = 25,
  height = 25,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      {...rest}
    >
      <g clipPath="url(#7zzjt8v2sa)">
        <path
          d="M24.587 13.722c0-5.787-3.103-10.277-5.771-10.277-.115 0-.229.009-.342.022-2.233.091-4.232 1.06-5.75 2.626a15.424 15.424 0 0 0-.939-.669c-3.457-2.248-7.261-2.747-8.85-1.158l-.002.003c-3.454 3.5-2.988 9.623 1.038 13.65 2.112 2.111 4.866 3.285 7.588 3.285.33 0 .66-.024.989-.059 1.601 1.74 3.781 2.81 6.224 2.85.015 0 .029.005.043.005l.013-.002c.03 0 .058.005.087.005v-.013c3.032-.103 5.672-4.852 5.672-10.268zM11.239 6.263c.28.183.555.381.825.586-1.419 1.81-2.279 4.205-2.279 6.877 0 1.353.229 2.632.633 3.806a14.374 14.374 0 0 1-3.59-2.625c-3.63-3.631-4.712-8.398-3.189-9.93l.004-.004c1.065-1.064 4.271-.873 7.596 1.29zm-6.56 10.948c-2.743-2.742-3.68-6.535-2.663-9.57.25 2.59 1.909 5.777 4.105 7.973 1.427 1.427 3.11 2.557 4.875 3.284.233.458.493.895.784 1.303-2.53.068-5.12-1.008-7.102-2.99zm6.106-3.485c0-3.983 2.071-7.297 5.06-8.636-1.693 1.995-2.801 5.482-2.801 8.632 0 3.676 1.252 6.822 2.849 8.633-3.019-1.343-5.108-4.653-5.108-8.63zm8.043 9.272-.045-.002c-1.921-.042-4.739-3.921-4.739-9.274 0-5.097 2.567-9.204 4.718-9.27.018 0 .035-.003.053-.003v-.003c1.922 0 4.771 3.895 4.771 9.277 0 5.214-2.554 9.26-4.758 9.275z"
          fill="#00303C"
        />
      </g>
      <defs>
        <clipPath id="7zzjt8v2sa">
          <path fill="#fff" transform="translate(.625)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const ContactLensFingerIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M10.892 16.866c1.854 0 3.624.576 5.117 1.665.54.394 1.036.852 1.523 1.414l27.573 27.589 1.415-1.414-23.567-23.577c1.605.514 3.226.773 4.804.773 4.627 0 8.872-2.158 11.184-6.163v-.001c1.007-1.745.487-4.15-1.464-6.77-1.777-2.386-4.58-4.754-7.892-6.666C26.273 1.804 22.822.56 19.866.214c-3.246-.381-5.586.373-6.594 2.118-.015.026-.022.054-.036.08l-.007-.004c-2.235 4.047-1.915 8.826.426 12.824a10.712 10.712 0 0 0-2.763-.367c-5.895 0-10.69 4.796-10.69 10.69v.415l21.856 21.856 1.414-1.414-11.153-11.15c1.991-2.436 2.756-5.292 1.932-7.503-.61-1.64-2.114-3.028-4.124-3.81-2.388-.93-5.145-.894-7.776.04.743-4.044 4.285-7.123 8.541-7.123zm9.686 2.453c-4.628-2.672-7.158-7.323-6.832-11.77.276.508.596 1.025.99 1.553 1.776 2.387 4.58 4.755 7.891 6.667 4.13 2.384 8.161 3.61 11.248 3.61.128 0 .247-.01.371-.014-3.657 2.536-8.969 2.667-13.668-.046zM18.273 2.117c.422 0 .877.027 1.361.084 2.691.315 5.87 1.469 8.951 3.248 3.082 1.779 5.67 3.955 7.288 6.128 1.423 1.91 1.91 3.579 1.334 4.575-1.173 2.03-7.039 1.66-13.58-2.115-3.083-1.78-5.67-3.956-7.289-6.13-1.423-1.91-1.91-3.578-1.334-4.574.458-.793 1.62-1.216 3.27-1.216zm-8.87 23.696c1.48.574 2.562 1.538 2.974 2.643.552 1.484-.04 3.556-1.476 5.387l-7.949-7.948c2.202-.798 4.491-.843 6.451-.082z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CopyIcon: React.FC<CustomSVGIconProps> = ({
  width = 24,
  height = 24,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M 22.207 7 L 15.5 0.293 C 15.311 0.104 15.06 0 14.793 0 H 7.5 c -0.5515 0 -1 0.4485 -1 1 v 18 c 0 0.5515 0.4485 1 1 1 h 14 c 0.5515 0 1 -0.4485 1 -1 V 7.707 C 22.5 7.44 22.396 7.189 22.207 7 z M 15.5 1.707 L 20.793 7 L 15.5 7 V 1.707 z M 7.5 19 V 1 h 7 v 6.5 c 0 0.1325 0.0525 0.26 0.1465 0.3535 S 14.8675 8 15 8 l 6.5 0 l 0.0005 11 H 7.5 z M 16.5 23 H 2.5 V 5 h 2.5 V 4 H 2.5 c -0.5515 0 -1 0.4485 -1 1 v 18 c 0 0.5515 0.4485 1 1 1 h 14 c 0.5515 0 1 -0.4485 1 -1 v -1.5 h -1 V 23 z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CreditCardIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44 6H4a4 4 0 0 0-4 4v28a4 4 0 0 0 4 4h40a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4zm2 32c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V20h44v18zm0-20H2v-4h44v4zm0-6H2v-2c0-1.103.897-2 2-2h40c1.103 0 2 .897 2 2v2z"
        fill={htmlColor}
      />
      <path
        d="M11 37a4.963 4.963 0 0 0 3-1.018A4.963 4.963 0 0 0 17 37c2.757 0 5-2.243 5-5s-2.243-5-5-5a4.963 4.963 0 0 0-3 1.018A4.963 4.963 0 0 0 11 27c-2.757 0-5 2.243-5 5s2.243 5 5 5zm0-8c.864 0 1.662.369 2.248 1.038h1.505A2.955 2.955 0 0 1 17 29c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.959 2.959 0 0 1-2.248-1.038h-1.505A2.955 2.955 0 0 1 11 35c-1.654 0-3-1.346-3-3s1.346-3 3-3z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// D

export const DiscoverIcon: React.FC<CustomSVGIconProps> = ({
  width = 38,
  height = 25,
  ...rest
}) => {
  return (
    <PaymentIconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".54"
          y=".208"
          width="28.75"
          height="19.583"
          rx="2.292"
          fill="#fff"
          stroke="#D8D8D8"
        />
        <path
          d="m12.621 19.583 16.458-4.791V17.5c0 1.15-.932 2.083-2.083 2.083H12.621z"
          fill="#FD6020"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.955 8.01c.906 0 1.404.402 1.404 1.163a1.089 1.089 0 0 1-.906 1.165l1.223 1.656h-.951l-1.041-1.612h-.09v1.612h-.77V8.01h1.131zm-.362 1.835h.226c.498 0 .725-.224.725-.627 0-.358-.227-.582-.725-.582h-.226v1.21zm-3.442 2.15h2.173v-.672h-1.403v-1.075h1.358v-.672H22.92v-.895h1.403v-.672h-2.173v3.985zm-2.263-1.3L18.846 8.01h-.814l1.675 4.075h.407l1.675-4.075h-.815l-1.086 2.687zm-9.19-.67c0 1.118.905 2.059 2.037 2.059.362 0 .679-.09.996-.224v-.896c-.226.269-.543.448-.906.448-.724 0-1.313-.537-1.313-1.254v-.09c-.045-.715.544-1.342 1.268-1.387.362 0 .725.179.95.448v-.896c-.27-.179-.633-.224-.95-.224-1.177-.09-2.082.851-2.082 2.015zm-1.404-.493c-.453-.18-.588-.269-.588-.493.045-.268.271-.492.543-.448.226 0 .453.135.634.314l.407-.537c-.317-.27-.724-.448-1.132-.448a1.156 1.156 0 0 0-1.222 1.074v.045c0 .537.226.85.95 1.075.182.044.363.134.544.223a.47.47 0 0 1 .226.404c0 .313-.271.582-.543.582h-.045a.894.894 0 0 1-.815-.538l-.498.493c.272.492.815.76 1.358.76a1.272 1.272 0 0 0 1.358-1.208v-.134c-.045-.538-.271-.806-1.177-1.164zm-2.626 2.462h.77V8.01h-.77v3.985zM3.09 8.01H4.448c1.087.044 1.947.94 1.902 2.014 0 .582-.272 1.12-.725 1.523-.407.313-.905.492-1.403.447H3.09V8.01zm.996 3.313c.362.045.77-.09 1.041-.314.272-.268.408-.626.408-1.03 0-.358-.136-.716-.408-.984a1.444 1.444 0 0 0-1.04-.314H3.86v2.642h.226z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.044 7.917c-1.132 0-2.083.895-2.083 2.06 0 1.119.905 2.059 2.083 2.104 1.177.044 2.082-.896 2.127-2.06-.045-1.164-.95-2.104-2.127-2.104z"
          fill="#FD6020"
        />
      </svg>
    </PaymentIconWrapper>
  )
}

export const DisplayIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M45,18H33c-1.654,0-3,1.346-3,3v7.444V30H2V2h38v14h2V2c0-1.105-0.895-2-2-2H2C0.895,0,0,0.895,0,2v28c0,1.105,0.895,2,2,2
        h14v6h-2v2h10h2h2v-2h-2v-6h4v13c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V28.444V21C48,19.346,46.654,18,45,18z M24,38h-6v-6h6
        V38z M46,45c0,0.551-0.449,1-1,1H33c-0.551,0-1-0.449-1-1V21c0-0.551,0.449-1,1-1h12c0.551,0,1,0.449,1,1V45z"
        fill={htmlColor}
      />
      <path
        d="M40,41h-2c-0.552,0-1,0.448-1,1s0.448,1,1,1h2c0.552,0,1-0.448,1-1S40.552,41,40,41z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const DotIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path d="M11.334 7.333H4.667v1.334h6.667V7.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const DropIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M25.83 1.054A2.134 2.134 0 0 0 24 .027c-.751 0-1.436.383-1.83 1.026C17.91 8.006 8 24.938 8 32c0 8.822 7.178 16 16 16s16-7.178 16-16c0-7.062-9.91-23.994-14.17-30.946zM24 46V2.027c.081 0 .114.054.125.071C30.833 13.044 38 26.648 38 32c0 7.72-6.28 14-14 14z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// E
export const EyeCareIcon: React.FC<CustomSVGIconProps> = ({
  width = 25,
  height = 25,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M13.13 6.23V3a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v3.23A5.001 5.001 0 0 0 4.63 11v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V11a5.002 5.002 0 0 0-3.5-4.77zm-4-.23V3h3v3h-3zm6.5 17h-10V11c0-2.206 1.794-4 4-4h2c2.205 0 4 1.794 4 4v12z"
        fill="#000"
      />
    </IconWrapper>
  )
}

export const EyeIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 14c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
        fill={htmlColor}
      />
      <path d="M24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill={htmlColor} />
      <path
        d="M47.781 23.083C43.202 14.101 34.272 8 24 8 13.728 8 4.798 14.101.219 23.083a2.031 2.031 0 0 0 0 1.835C4.798 33.899 13.728 40 24 40c10.272 0 19.202-6.101 23.781-15.083a2.03 2.03 0 0 0 0-1.834zM24 38c-9.17 0-17.6-5.361-21.999-14.009C6.4 15.361 14.83 10 24 10c9.17 0 17.6 5.361 21.999 14.009C41.6 32.639 33.17 38 24 38z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const EyewearRangeIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M2 8h16v2h2V8h8v2h2V4h-2v2h-8V4h-2v2H2V4H0v6h2V8zM47.58 20.523c-.916-3.487-3.239-5.759-6.394-6.235A33.544 33.544 0 0 0 37 14.007V14H11v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-27.646-.156c-1.353 5.066-5.446 9.789-9.733 11.228-1.44.483-3.52.775-5.253-.574-2.426-1.895-3.565-6.282-2.593-9.99.413-1.574 1.577-4.285 4.72-4.76A31.574 31.574 0 0 1 11 15.998V16h.416c3.732.034 6.777.81 8.002 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.12a5.583 5.583 0 0 0-.96.823 4.161 4.161 0 0 0-1.093 2.531A5.97 5.97 0 0 0 24 19c-.69 0-1.365.127-2.004.354zm21.056 11.667c-1.733 1.35-3.813 1.058-5.253.574-4.287-1.439-8.38-6.161-9.727-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165H37v-.001c1.247.007 2.561.091 3.907.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.167 8.095-2.593 9.99z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// F

export const FacebookIcon: React.FC<CustomSVGIconProps> = ({
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={'0 0 24 24'}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        className="st0"
        fill="#FFFFFF"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7,12.1c0-6.5-5.3-11.7-11.7-11.7S0.3,5.6,0.3,12.1c0,5.9,4.3,10.7,9.9,11.6v-8.2h-3v-3.4h3V9.5
	c0-2.9,1.8-4.6,4.4-4.6c1.3,0,2.6,0.2,2.6,0.2V8h-1.5c-1.5,0-1.9,0.9-1.9,1.8v2.2h3.3l-0.5,3.4h-2.7v8.2
	C19.4,22.8,23.7,17.9,23.7,12.1L23.7,12.1z"
      />
    </IconWrapper>
  )
}

export const FaceGlassesSmileIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.581 12.523c-.915-3.487-3.238-5.759-6.393-6.235a33.531 33.531 0 0 0-4.187-.281V6h-26v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.232-1.179 4.495.24 9.686 3.297 12.073 1.19.927 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.651 1.256-.952 3.052-1.056 4.418-.293v15.446h-6.204v2h8.204V17.334c2.183 3.814 5.608 6.962 9.166 8.156 1.006.338 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.477-7.578 3.3-12.074zm-27.645-.156c-1.353 5.066-5.446 9.788-9.733 11.227-1.44.483-3.52.777-5.254-.574-2.426-1.895-3.564-6.282-2.592-9.989.413-1.574 1.577-4.285 4.72-4.76a31.545 31.545 0 0 1 3.924-.273V8h.411c3.734.033 6.781.81 8.007 2.165.56.619.722 1.328.517 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.544 5.544 0 0 0-.96-.824h8.12a5.583 5.583 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A6.005 6.005 0 0 0 24.001 11c-.69 0-1.365.127-2.004.354zM43.054 23.02c-1.734 1.351-3.814 1.058-5.254.574-4.287-1.439-8.38-6.161-9.726-11.198a3.202 3.202 0 0 1-.076-.552v-.176h-.005a2.163 2.163 0 0 1 .591-1.503C29.81 8.809 32.861 8.033 36.591 8h.41v-.001c1.247.007 2.561.091 3.907.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.166 8.094-2.592 9.989zM24.003 40a14.862 14.862 0 0 1-10.89-4.687l-1.453 1.375C14.904 40.113 19.288 42 24.003 42c4.716 0 9.101-1.887 12.345-5.312l-1.452-1.374a14.867 14.867 0 0 1-10.893 4.686z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceOvalIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435 22.989c-.515-.792-1.58-1.716-3.763-1.939l.06-.359c.129-.771.195-1.555.23-2.339l.007-.001-.005-.024c.013-.292.036-.584.036-.875 0-4.369-1.566-8.355-4.145-11.42A6.975 6.975 0 0 0 39 1V0H21a5.006 5.006 0 0 0-4.904 4.046C11.005 4.505 7 8.791 7 14v3.452c0 1.083.09 2.173.268 3.24l.057.341c-2.413.157-3.575 1.125-4.115 1.957-.704 1.083-.75 2.51-.123 3.917l.234.534C4.255 29.577 5.313 32 8 32a4.53 4.53 0 0 0 1.128-.147l.362 2.174a22.137 22.137 0 0 0 2.037 6.265A13.87 13.87 0 0 0 24 48a13.868 13.868 0 0 0 12.472-7.708 22.127 22.127 0 0 0 2.037-6.265l.35-2.102c.257.045.518.075.786.075 2.687 0 3.745-2.423 4.679-4.56l.235-.534c.625-1.407.58-2.834-.124-3.917zM24 46c-4.554 0-8.647-2.53-10.683-6.603a20.153 20.153 0 0 1-1.854-5.699L9.24 20.362a17.77 17.77 0 0 1-.218-2.363c6.802-.008 9.022-3.597 9.7-6.518C20.522 13.08 24.116 15 31 15c6.638 0 7.76 1.911 7.96 3.418a17.757 17.757 0 0 1-.201 1.944l-2.223 13.336a20.122 20.122 0 0 1-1.854 5.699A11.875 11.875 0 0 1 24 46zM36.753 2.494c-.16.509-.397.979-.701 1.403C35.144 5.163 33.672 6 32 6v2c1.165 0 2.262-.29 3.229-.796a15.59 15.59 0 0 1 3.518 7.446C36.313 13.007 32.574 13 31 13c-10.052 0-12.058-4.341-12.071-4.372L16.999 9c0 3.193-1.387 7-8 7v-2c0-4.411 3.59-8 8-8h1V5c0-1.654 1.347-3 3-3h15.9c-.034.17-.094.33-.146.494zM8 30c-1.311 0-1.936-1.277-2.847-3.361l-.24-.545c-.202-.456-.475-1.325-.027-2.015.418-.642 1.397-1.014 2.768-1.068l1.14 6.848A2.55 2.55 0 0 1 8 30zm34.73-3.906-.24.545C41.58 28.723 40.957 30 39.646 30c-.156 0-.306-.027-.455-.054l1.152-6.915c1.186.1 2.035.461 2.416 1.047.449.691.176 1.56-.027 2.016z"
        fill={htmlColor}
      />
      <path
        d="M26.013 36h-4.027v2h4.027v-2zM24.434 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceOvalIconThick: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435,22.989c-0.515-0.792-1.579-1.716-3.763-1.939l0.06-0.359c0.129-0.771,0.195-1.555,0.231-2.339l0.006-0.001
		c-0.001-0.009-0.004-0.016-0.005-0.024C40.977,18.035,41,17.743,41,17.452c0-4.369-1.566-8.355-4.145-11.42
		C38.175,4.758,39,2.975,39,1V0H21c-2.43,0-4.456,1.744-4.904,4.046C11.005,4.505,7,8.791,7,14v3.452c0,1.083,0.09,2.173,0.268,3.24
		l0.057,0.341c-2.413,0.157-3.575,1.125-4.115,1.957c-0.704,1.083-0.749,2.51-0.123,3.917l0.234,0.534C4.255,29.577,5.313,32,8,32
		c0.389,0,0.765-0.054,1.128-0.147l0.362,2.174c0.363,2.18,1.049,4.288,2.037,6.265C13.905,45.046,18.684,48,24,48
		s10.095-2.954,12.472-7.708c0.988-1.977,1.674-4.084,2.037-6.265l0.35-2.102C39.116,31.97,39.377,32,39.645,32
		c2.687,0,3.745-2.423,4.679-4.56l0.235-0.534C45.184,25.499,45.139,24.072,44.435,22.989z M24,46c-4.554,0-8.647-2.53-10.683-6.603
		c-0.899-1.798-1.523-3.716-1.854-5.699L9.241,20.362c-0.13-0.779-0.193-1.571-0.218-2.363c6.802-0.008,9.022-3.597,9.7-6.518
		C20.522,13.08,24.116,15,31,15c6.638,0,7.761,1.911,7.96,3.418c-0.036,0.652-0.094,1.303-0.201,1.944l-2.223,13.336
		c-0.331,1.983-0.954,3.901-1.854,5.699C32.647,43.47,28.554,46,24,46z M36.753,2.494c-0.16,0.509-0.397,0.979-0.701,1.403
		C35.144,5.163,33.673,6,32,6v2c1.165,0,2.262-0.29,3.229-0.796c1.773,2.066,3.018,4.619,3.518,7.446C36.313,13.007,32.574,13,31,13
		c-10.052,0-12.058-4.341-12.071-4.372L17,9c0,3.193-1.388,7-8,7v-2c0-4.411,3.589-8,8-8h1V5c0-1.654,1.346-3,3-3h15.899
		C36.865,2.17,36.805,2.33,36.753,2.494z M8,30c-1.311,0-1.936-1.277-2.847-3.361l-0.239-0.545
		c-0.203-0.456-0.476-1.325-0.028-2.015c0.418-0.642,1.397-1.014,2.768-1.068l1.141,6.848C8.541,29.942,8.277,30,8,30z
		 M42.731,26.094l-0.24,0.545C41.581,28.723,40.956,30,39.645,30c-0.156,0-0.306-0.027-0.455-0.054l1.152-6.915
		c1.186,0.1,2.035,0.461,2.416,1.047C43.207,24.769,42.934,25.638,42.731,26.094z M21.99,36h4.03v2h-4.03Z M24.434,31 21.986,31 21.986,33 26.434,33 26.434,23.057 24.434,23.057z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceRectangleIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M43.605 22.165c-.618-.721-1.624-1.089-3.132-1.152l.233-1.744c.475-3.562-.045-6.98-1.505-9.886a17.212 17.212 0 0 0-2.542-3.734A7.975 7.975 0 0 0 39 0H21a5.01 5.01 0 0 0-4.908 4.041C11.002 4.501 7 8.792 7 14l.484 4 .363 3.004c-1.688.022-2.79.39-3.453 1.16-.907 1.057-.685 2.509-.469 3.914l.084.562C4.313 28.775 4.772 32 8 32c.4 0 .786-.057 1.158-.155l1.21 9.998A7.007 7.007 0 0 0 17.315 48h13.431c3.494 0 6.477-2.612 6.938-6.075l1.338-10.032c.316.069.643.107.977.107 3.228 0 3.687-3.225 3.99-5.359l.084-.562c.216-1.405.44-2.857-.469-3.914zM30.747 46h-13.43a5.004 5.004 0 0 1-4.964-4.398l-2.89-23.85c4.981-.305 8.046-2.183 9.11-5.639C20.095 13.575 23.095 15 29 15c5.148 0 8.784 1.068 9.837 2.864-.025.377-.062.757-.113 1.14l-3.02 22.657A5.015 5.015 0 0 1 30.746 46zM36.65 2c-.826 2.327-3.043 4-5.65 4v2a7.952 7.952 0 0 0 3.06-.611c.352-.147.69-.323 1.015-.517a15.155 15.155 0 0 1 2.34 3.408c.731 1.457 1.182 3.071 1.364 4.775C36.092 13.316 31.765 13 29 13c-9.697 0-9.999-3.982-10-4h-2c0 2.769-.846 6.396-7.778 6.777L9 13.941C9.032 9.557 12.608 6 17 6h1V5c0-1.654 1.346-3 3-3h15.65zM8 30c-1.157 0-1.608-.816-2.01-3.641l-.088-.585c-.154-1.001-.299-1.948.01-2.307.15-.175.62-.467 2.088-.467h.09l.824 6.817A2.594 2.594 0 0 1 8 30zm34.098-4.226-.088.585C41.608 29.184 41.157 30 40 30a2.55 2.55 0 0 1-.71-.11l.918-6.885c1.296.028 1.737.296 1.88.462.308.36.163 1.306.01 2.307z"
        fill={htmlColor}
      />
      <path
        d="M26.013 36h-4.027v2h4.027v-2zM24.434 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceRectangleIconSlim: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M43.605,22.165c-0.618-0.721-1.624-1.089-3.132-1.152l0.233-1.744c0.475-3.562-0.045-6.98-1.505-9.886
		c-0.691-1.376-1.552-2.624-2.542-3.734C38.105,4.2,39,2.203,39,0H22h-1c-2.429,0-4.459,1.741-4.908,4.041
		C11.002,4.501,7,8.792,7,14l0.484,4l0.363,3.004c-1.688,0.022-2.791,0.39-3.453,1.16c-0.907,1.057-0.685,2.509-0.469,3.914
		l0.084,0.562C4.313,28.775,4.772,32,8,32c0.399,0,0.786-0.057,1.158-0.155l1.209,9.998C10.793,45.353,13.781,48,17.316,48h13.431
		c3.494,0,6.477-2.612,6.938-6.075l1.338-10.032C39.339,31.962,39.666,32,40,32c3.228,0,3.687-3.225,3.99-5.359l0.084-0.562
		C44.29,24.674,44.513,23.222,43.605,22.165z M30.747,46H17.316c-2.525,0-4.659-1.891-4.963-4.398l-2.891-23.85
		c4.982-0.305,8.047-2.183,9.11-5.639C20.096,13.575,23.096,15,29,15c5.148,0,8.784,1.068,9.837,2.864
		c-0.025,0.377-0.062,0.757-0.113,1.14l-3.021,22.657C35.373,44.135,33.243,46,30.747,46z M36.65,2C35.824,4.327,33.607,6,31,6v2
		c1.083,0,2.116-0.219,3.059-0.611c0.353-0.147,0.69-0.323,1.016-0.517c0.916,1.006,1.707,2.147,2.34,3.408
		c0.731,1.457,1.182,3.071,1.364,4.775C36.092,13.316,31.765,13,29,13c-9.697,0-9.999-3.982-10-4h-2
		c0,2.769-0.846,6.396-7.778,6.777L9,13.941C9.032,9.557,12.608,6,17,6h1V5c0-1.654,1.346-3,3-3H36.65z M8,30
		c-1.157,0-1.608-0.816-2.01-3.641l-0.088-0.585c-0.154-1.001-0.299-1.948,0.01-2.307C6.062,23.292,6.532,23,8,23h0.089l0.825,6.817
		C8.623,29.926,8.32,30,8,30z M42.098,25.774l-0.088,0.585C41.608,29.184,41.157,30,40,30c-0.246,0-0.481-0.044-0.71-0.11
		l0.918-6.885c1.296,0.028,1.737,0.296,1.88,0.462C42.396,23.827,42.251,24.773,42.098,25.774z M21.99,36h4.03v2h-4.03Z M24.434,31 21.986,31 21.986,33 26.434,33 26.434,23.057 24.434,23.057z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceRectangleIconThick: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.605,22.165C43.924,21.37,42.778,21,41,21v-4c0-4.355-1.66-8.32-4.362-11.331C38.096,4.219,39,2.214,39,0H21
		c-2.43,0-4.456,1.744-4.904,4.046C11.005,4.505,7,8.791,7,14v4v3c-1.778,0-2.924,0.37-3.605,1.165
		c-0.907,1.057-0.685,2.509-0.469,3.913l0.084,0.562C3.313,28.775,3.772,32,7,32v5c0,6.065,4.935,11,11,11h12
		c6.065,0,11-4.935,11-11v-5c3.228,0,3.687-3.225,3.99-5.359l0.084-0.562C45.29,24.674,45.513,23.222,44.605,22.165z M30,46H18
		c-4.962,0-9-4.037-9-9V17.772c3.683-0.151,6.361-1.127,8.007-2.927c0.702-0.768,1.238-1.668,1.565-2.732
		c1.435,1.377,4.181,2.721,9.428,2.872C28.324,14.994,28.656,15,29,15c4.047,0,7.125,0.65,8.786,1.792
		c0.593,0.408,1.005,0.879,1.214,1.406V37C39,41.963,34.962,46,30,46z M36.65,2C35.824,4.327,33.607,6,31,6v2
		c1.209,0,2.351-0.277,3.38-0.759c0.229-0.107,0.451-0.224,0.668-0.352c2.044,2.232,3.423,5.074,3.821,8.226
		C36.206,13.333,31.845,13,29,13c-0.354,0-0.67-0.016-1-0.026c-8.693-0.27-8.999-3.957-9-3.974h-2c0,1.896-0.405,4.19-2.875,5.574
		c-1.176,0.659-2.826,1.107-5.125,1.209v-1.69V14c0-4.411,3.589-8,8-8h1V5c0-1.654,1.346-3,3-3H36.65z M4.99,26.359l-0.088-0.585
		c-0.154-1.002-0.299-1.947,0.01-2.308C5.062,23.293,5.532,23,7,23v7C5.843,30,5.392,29.184,4.99,26.359z M43.098,25.774
		l-0.088,0.585C42.608,29.184,42.157,30,41,30v-7c1.468,0,1.938,0.293,2.088,0.467C43.396,23.827,43.251,24.772,43.098,25.774z M21.99,36h4.03v2h-4.03Z M24.434,31 21.986,31 21.986,33 26.434,33 26.434,23.057 24.434,23.057z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceRoundIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.605 22.165C43.925 21.37 42.778 21 41 21v-4c0-4.355-1.66-8.32-4.362-11.331A7.973 7.973 0 0 0 39 0H21a5.006 5.006 0 0 0-4.904 4.046C11.006 4.505 7 8.791 7 14v7c-1.778 0-2.924.37-3.605 1.165-.907 1.057-.685 2.509-.469 3.913l.084.562C3.313 28.775 3.772 32 7 32l.051-.003C7.571 40.906 14.963 48 24.001 48c9.036 0 16.428-7.094 16.948-16.003L41 32c3.228 0 3.687-3.225 3.99-5.359l.084-.562c.216-1.405.44-2.857-.469-3.914zM24 46c-8.27 0-15-6.729-15-15V17.772c5.253-.215 8.477-2.099 9.572-5.659 1.435 1.377 4.181 2.721 9.428 2.872.324.009.656.015 1 .015 4.047 0 7.125.65 8.786 1.792.593.408 1.005.879 1.214 1.406V31c0 8.271-6.729 15-15 15zM36.65 2c-.826 2.327-3.043 4-5.65 4v2a7.941 7.941 0 0 0 3.38-.759c.23-.107.451-.224.668-.352a14.934 14.934 0 0 1 3.821 8.226C36.206 13.333 31.845 13 29 13c-.354 0-.67-.016-1-.026-8.693-.27-8.999-3.957-9-3.974h-2c0 2.798-.87 6.467-8 6.783V14c0-4.411 3.59-8 8-8h1V5c0-1.654 1.346-3 3-3h15.65zM4.99 26.359l-.088-.585c-.154-1.002-.299-1.947.01-2.308.15-.173.62-.466 2.088-.466v7c-1.157 0-1.608-.816-2.01-3.641zm38.108-.585-.088.585C42.608 29.184 42.157 30 41 30v-7c1.468 0 1.938.293 2.088.467.308.36.163 1.305.01 2.307z"
        fill={htmlColor}
      />
      <path
        d="M26.013 36h-4.027v2h4.027v-2zM24.434 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceRoundIconThick: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.605,22.165C43.924,21.37,42.778,21,41,21v-4c0-4.355-1.66-8.32-4.362-11.331C38.096,4.219,39,2.214,39,0H21
		c-2.43,0-4.456,1.744-4.904,4.046C11.005,4.505,7,8.791,7,14v4v3c-1.778,0-2.924,0.37-3.605,1.165
		c-0.907,1.057-0.685,2.509-0.469,3.913l0.084,0.562C3.313,28.775,3.772,32,7,32c0.017,0,0.034-0.003,0.051-0.003
		C7.571,40.906,14.963,48,24,48s16.429-7.094,16.949-16.003C40.966,31.997,40.983,32,41,32c3.228,0,3.687-3.225,3.99-5.359
		l0.084-0.562C45.29,24.674,45.513,23.222,44.605,22.165z M24,46c-8.271,0-15-6.729-15-15V17.772
		c5.253-0.215,8.477-2.099,9.572-5.659c1.435,1.377,4.181,2.721,9.428,2.872C28.324,14.994,28.656,15,29,15
		c4.047,0,7.125,0.65,8.786,1.792c0.593,0.408,1.005,0.879,1.214,1.406V31C39,39.271,32.271,46,24,46z M36.65,2
		C35.824,4.327,33.607,6,31,6v2c1.209,0,2.351-0.277,3.38-0.759c0.229-0.107,0.451-0.224,0.668-0.352
		c2.044,2.232,3.423,5.074,3.821,8.226C36.206,13.333,31.845,13,29,13c-0.354,0-0.67-0.016-1-0.026c-8.693-0.27-8.999-3.957-9-3.974
		h-2c0,2.798-0.87,6.467-8,6.783V14c0-4.411,3.589-8,8-8h1V5c0-1.654,1.346-3,3-3H36.65z M4.99,26.359l-0.088-0.585
		c-0.154-1.002-0.299-1.947,0.01-2.308C5.062,23.293,5.532,23,7,23v7C5.843,30,5.392,29.184,4.99,26.359z M43.098,25.774
		l-0.088,0.585C42.608,29.184,42.157,30,41,30v-7c1.468,0,1.938,0.293,2.088,0.467C43.396,23.827,43.251,24.772,43.098,25.774z M24.434,31 21.986,31 21.986,33 26.434,33 26.434,23.057 24.434,23.057z M21.99,36h4.03v2h-4.03Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceSlimIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435,22.989c-0.508-0.782-1.557-1.689-3.685-1.927l0.242-1.939L41,17c0-4.206-1.544-8.053-4.084-11.024
		C38.201,4.706,39,2.945,39,1V0H21c-2.43,0-4.456,1.744-4.904,4.046C11.005,4.505,7,8.791,7,14v3v1v1l0.253,2.036
		c-2.366,0.172-3.508,1.13-4.044,1.954c-0.704,1.083-0.749,2.51-0.123,3.917l0.234,0.534C4.255,29.577,5.313,32,8,32
		c0.22,0,0.435-0.021,0.648-0.052c0.491,2.535,1.659,4.865,3.417,6.784l6.17,6.731C19.713,47.076,21.814,48,24,48
		s4.287-0.924,5.765-2.536l6.17-6.731c1.751-1.91,2.917-4.228,3.411-6.75C39.446,31.99,39.544,32,39.645,32
		c2.687,0,3.745-2.423,4.679-4.56l0.235-0.534C45.184,25.499,45.139,24.072,44.435,22.989z M34.46,37.381l-6.17,6.731
		C27.19,45.312,25.627,46,24,46s-3.19-0.688-4.29-1.887l-6.17-6.731c-1.692-1.846-2.751-4.136-3.062-6.62L9,18.938V18
		c6.821,0,9.043-3.594,9.722-6.519C20.522,13.08,24.116,15,31,15c7.029,0,7.982,2.1,7.998,3.951l-1.476,11.81
		C37.211,33.246,36.153,35.535,34.46,37.381z M36.899,2c-0.035,0.17-0.095,0.33-0.146,0.494c-0.16,0.509-0.397,0.979-0.701,1.403
		C35.144,5.163,33.673,6,32,6v2c1.194,0,2.318-0.302,3.302-0.831c1.814,2.083,3.056,4.667,3.5,7.516C36.381,13.011,32.616,13,31,13
		c-10.052,0-12.058-4.341-12.071-4.372L17,9c0,3.193-1.388,7-8,7v-2c0-4.411,3.589-8,8-8h1V5c0-1.654,1.346-3,3-3H36.899z
		 M5.153,26.639l-0.239-0.545c-0.203-0.456-0.476-1.325-0.028-2.015c0.402-0.618,1.322-0.988,2.613-1.063l0.863,6.941
		C8.242,29.974,8.124,30,8,30C6.689,30,6.064,28.723,5.153,26.639z M42.731,26.094l-0.24,0.545C41.581,28.723,40.956,30,39.645,30
		c-0.004,0-0.008-0.001-0.012-0.001l0.869-6.951c1.102,0.119,1.893,0.472,2.257,1.031C43.207,24.769,42.934,25.638,42.731,26.094z M21.99,36h4.03v2h-4.03Z M24.434,31 21.986,31 21.986,33 26.434,33 26.434,23.057 24.434,23.057z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceSquareIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.605 22.165C43.925 21.37 42.778 21 41 21v-4c0-4.355-1.66-8.32-4.362-11.331A7.973 7.973 0 0 0 39 0H21a5.006 5.006 0 0 0-4.904 4.046C11.006 4.505 7 8.791 7 14v7c-1.778 0-2.924.37-3.605 1.165-.907 1.057-.685 2.509-.469 3.913l.084.562C3.313 28.775 3.772 32 7 32v5c0 6.065 4.935 11 11 11h12c6.065 0 11-4.935 11-11v-5c3.228 0 3.687-3.225 3.99-5.359l.084-.562c.216-1.405.44-2.857-.469-3.914zM30 46H18c-4.962 0-9-4.037-9-9V17.772c3.683-.151 6.361-1.127 8.007-2.927a7.08 7.08 0 0 0 1.565-2.732c1.435 1.377 4.181 2.721 9.428 2.872.324.009.656.015 1 .015 4.047 0 7.125.65 8.786 1.792.593.408 1.005.879 1.214 1.406V37c0 4.963-4.038 9-9 9zm6.65-44c-.826 2.327-3.043 4-5.65 4v2a7.941 7.941 0 0 0 3.38-.759c.23-.107.451-.224.668-.352a14.934 14.934 0 0 1 3.821 8.226C36.206 13.333 31.845 13 29 13c-.354 0-.67-.016-1-.026-8.693-.27-8.999-3.957-9-3.974h-2c0 1.896-.405 4.19-2.875 5.574-1.176.659-2.826 1.107-5.125 1.209V14c0-4.411 3.59-8 8-8h1V5c0-1.654 1.346-3 3-3h15.65zM4.99 26.359l-.088-.585c-.154-1.002-.299-1.947.01-2.308.15-.173.62-.466 2.088-.466v7c-1.157 0-1.608-.816-2.01-3.641zm38.108-.585-.088.585C42.608 29.184 42.157 30 41 30v-7c1.468 0 1.938.293 2.088.467.308.36.163 1.305.01 2.307z"
        fill={htmlColor}
      />
      <path
        d="M26.013 36h-4.027v2h4.027v-2zM24.434 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FaceTriangleIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435 22.989c-.508-.782-1.557-1.689-3.685-1.927l.242-1.939L41 17c0-4.206-1.544-8.053-4.084-11.024A6.978 6.978 0 0 0 39 1V0H21a5.006 5.006 0 0 0-4.904 4.046C11.005 4.505 7 8.791 7 14v5l.253 2.036c-2.366.172-3.508 1.13-4.044 1.954-.704 1.083-.749 2.51-.123 3.917l.234.534C4.255 29.577 5.313 32 8 32c.22 0 .435-.021.648-.052a13.917 13.917 0 0 0 3.417 6.784l6.17 6.731A7.837 7.837 0 0 0 24 48a7.84 7.84 0 0 0 5.765-2.536l6.17-6.731a13.9 13.9 0 0 0 3.411-6.75c.1.007.198.017.299.017 2.687 0 3.745-2.423 4.679-4.56l.235-.534c.625-1.407.58-2.834-.124-3.917zM34.46 37.381l-6.17 6.731A5.833 5.833 0 0 1 24 46a5.836 5.836 0 0 1-4.29-1.887l-6.17-6.731a11.942 11.942 0 0 1-3.062-6.62L9 18.938V18c6.821 0 9.043-3.594 9.722-6.519C20.522 13.08 24.116 15 31 15c7.029 0 7.982 2.1 7.998 3.951l-1.476 11.81a11.932 11.932 0 0 1-3.062 6.62zM36.899 2c-.035.17-.095.33-.146.494-.16.509-.397.979-.701 1.403C35.144 5.163 33.673 6 32 6v2a6.953 6.953 0 0 0 3.302-.831 14.93 14.93 0 0 1 3.5 7.516C36.381 13.011 32.616 13 31 13c-10.052 0-12.058-4.341-12.071-4.372L17 9c0 3.193-1.388 7-8 7v-2c0-4.411 3.589-8 8-8h1V5c0-1.654 1.346-3 3-3h15.899zM5.153 26.639l-.239-.545c-.203-.456-.476-1.325-.028-2.015.402-.618 1.322-.988 2.613-1.063l.863 6.941c-.12.017-.238.043-.362.043-1.311 0-1.936-1.277-2.847-3.361zm37.578-.545-.24.545C41.581 28.723 40.956 30 39.645 30l-.012-.001.869-6.951c1.102.119 1.893.472 2.257 1.031.448.69.175 1.559-.028 2.015z"
        fill={htmlColor}
      />
      <path
        d="M26.013 36h-4.027v2h4.027v-2zM24.434 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FiltersIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor = '#ffffff',
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M6 1.667v-1A.666.666 0 0 0 5.333 0H2.667A.666.666 0 0 0 2 .667v1H0v.666h2v1c0 .369.298.667.667.667h2.666A.666.666 0 0 0 6 3.333v-1h10v-.666H6zm-.667 1.666H2.667V.667h2.666v2.666zM10 6.667A.666.666 0 0 0 9.333 6H6.667A.666.666 0 0 0 6 6.667v1H0v.666h6v1c0 .369.298.667.667.667h2.666A.666.666 0 0 0 10 9.333v-1h6v-.666h-6v-1zm-.667 2.666H6.667V6.667h2.666v2.666zM14 12.667a.666.666 0 0 0-.667-.667h-2.666a.666.666 0 0 0-.667.667v1H0v.666h10v1c0 .369.298.667.667.667h2.666a.666.666 0 0 0 .667-.667v-1h2v-.666h-2v-1zm-.667 2.666h-2.666v-2.666h2.666v2.666z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FrameAdvisorIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m16.4 11.6c0-1.8 0.5-3.5 1.4-5q0.4-0.5 0.8-1 0.4-0.5 0.9-0.9 0.5-0.5 1-0.8 0.6-0.3 1.2-0.6 0.6-0.2 1.2-0.4 0.6-0.2 1.2-0.2 0.7-0.1 1.3-0.1 0.6 0.1 1.2 0.2 0.7 0.1 1.3 0.4 0.6 0.2 1.1 0.5 0.6 0.3 1.1 0.7 0.5 0.4 1 0.8 0.4 0.5 0.8 1.1 0.4 0.5 0.7 1.1 0.3 0.6 0.5 1.2 0.2 0.6 0.3 1.2 0.1 0.7 0.2 1.3 0 0.7-0.1 1.4 0 0.6-0.2 1.3-0.1 0.6-0.4 1.2-0.2 0.6-0.5 1.2-0.4 0.6-0.8 1.1-0.4 0.5-0.8 0.9-0.5 0.5-1.1 0.8-0.5 0.4-1 0.7-0.6 0.3-1.2 0.5-0.6 0.2-1.3 0.3-0.6 0-1.2 0.1c-2.3 0-4.5-1-6.1-2.7-1.6-1.7-2.5-3.9-2.5-6.3zm-14.6 39.8h14.5v-0.4h-14.6v0.2q0 0.1 0.1 0.2zm15.2-3.7l-13.7-5.2q-0.2 0.4-0.3 0.8l13.7 5.2q0.1-0.4 0.3-0.8zm-0.5 1.6l-14.4-2.7q0 0.3-0.1 0.6l14.4 2.7q0.1-0.3 0.1-0.6c0 0 0 0 0 0zm1.3-3.1l-12.6-7.6q-0.3 0.5-0.6 1l12.7 7.6q0.2-0.5 0.5-1zm1-1.3q0 0 0 0zm-11.2-9.8q-0.1 0.1-0.2 0.3-0.1 0.1-0.2 0.2-0.1 0.2-0.3 0.3-0.1 0.1-0.2 0.3l11.2 9.8q0.4-0.6 0.9-1.1zm26 16.3h14.6v-0.2c0-6.4-2.4-12.4-6.6-17l-0.2-0.1q-0.3-0.4-0.7-0.7-0.3-0.3-0.7-0.7-0.3-0.3-0.7-0.6-0.4-0.3-0.8-0.6l-2 2.5 1.5-2.8q-0.3-0.3-0.7-0.5-0.3-0.2-0.7-0.4-0.3-0.3-0.7-0.5-0.4-0.2-0.8-0.4l-2.3 4.2 1.6-4.5q-0.4-0.2-0.7-0.3-0.4-0.2-0.7-0.3-0.4-0.2-0.8-0.3-0.4-0.1-0.7-0.2l-2.1 5.9 1-6.2q-0.3-0.1-0.7-0.2-0.3-0.1-0.7-0.1-0.3-0.1-0.7-0.1-0.3-0.1-0.7-0.1l-1.3 7.7v-7.9q-0.1 0-0.3 0-0.2 0-0.3 0-0.2 0-0.4 0-0.1 0-0.3 0-0.6 0-1.3 0v9.4l-1.6-9.2q-1.1 0.1-2.3 0.4l1.8 10.7-3.5-10.2q-1 0.3-2 0.8l4.1 11.6-6-10.7q-0.8 0.4-1.6 0.9l6.7 12.1-8.6-10.7q-0.6 0.6-1.2 1.1l9.4 11.7q0.3-0.3 0.6-0.6 0.3-0.3 0.7-0.6 0.3-0.2 0.7-0.4 0.4-0.2 0.8-0.4 0.4-0.2 0.8-0.3 0.4-0.1 0.8-0.2 0.4-0.1 0.8-0.1 0.5-0.1 0.9-0.1c1.1 0 2.3 0.3 3.3 0.7q0.4 0.2 0.8 0.4 0.4 0.2 0.7 0.5 0.4 0.2 0.7 0.5 0.3 0.3 0.6 0.6h0.1l0.1 0.2 0.2 0.2 0.1 0.1 0.1 0.2q0.5 0.6 0.9 1.3 0.3 0.7 0.6 1.5 0.2 0.7 0.3 1.4 0.1 0.8 0.1 1.6 0 0 0 0z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
export const FrameAdvisorFrameSizeIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M5.26 3.043c0-.565.16-1.118.46-1.588.302-.47.73-.836 1.23-1.053A2.63 2.63 0 0 1 8.53.24c.531.11 1.019.383 1.402.783s.643.909.749 1.463c.105.555.051 1.13-.156 1.652A2.836 2.836 0 0 1 9.518 5.42c-.45.314-.98.481-1.521.481a2.68 2.68 0 0 1-1.936-.837 2.924 2.924 0 0 1-.801-2.021zM.616 15.731h4.642v-.13H.616v.064c0 .022-.002.044 0 .066zm4.855-1.175-4.366-1.658c-.031.088-.062.17-.091.263l4.364 1.66c.021-.092.059-.18.093-.265zm-.163.509-4.574-.848a6.74 6.74 0 0 0-.032.192l4.574.848c.016-.056.027-.12.04-.185l-.008-.007zm.416-.98L1.7 11.66a7.344 7.344 0 0 0-.176.315L5.55 14.4c.05-.11.108-.215.174-.315zm.325-.419-3.572-3.124c-.095.11-.187.225-.275.339L5.762 14c.086-.122.182-.235.287-.34v.005zm4.692 2.07h4.643v-.066c.002-2.023-.76-3.966-2.12-5.406l-.04-.044c-.289-.3-.6-.576-.93-.823l-.65.807.505-.912a7.218 7.218 0 0 0-.927-.561l-.738 1.333.505-1.445a7.126 7.126 0 0 0-.932-.354l-.662 1.899.336-1.989a7.098 7.098 0 0 0-.896-.17l-.416 2.464v-2.5a6.761 6.761 0 0 0-.416-.013c-.14 0-.277 0-.415.014v2.982l-.495-2.936a7.104 7.104 0 0 0-.74.134l.577 3.414-1.136-3.257c-.215.071-.424.151-.63.24l1.299 3.71-1.89-3.417a7.492 7.492 0 0 0-.51.308l2.12 3.835-2.732-3.39c-.133.11-.263.223-.39.338l2.988 3.724c.255-.269.559-.482.894-.627a2.64 2.64 0 0 1 2.11.005c.335.146.638.36.892.63l.012.012.04.046.01.01.044.05.044.057.047.06c.396.52.606 1.169.592 1.833l.007.005z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FreeReturnIcon: React.FC<CustomSVGIconProps> = ({
  width = 32,
  height = 32,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m30.729 5.714-7.633-2.987-.006-.015-.016.006L16.728.235a2.003 2.003 0 0 0-1.457 0l-14 5.478A1.99 1.99 0 0 0 0 7.576v16.847c0 .83.499 1.56 1.271 1.863l14 5.478a2.006 2.006 0 0 0 1.458 0l2.147-.841a8.706 8.706 0 0 1-.675-1.167l-1.534.6V13.121l14-5.478v11.065c.478.308.925.66 1.333 1.052V7.576c0-.828-.499-1.56-1.271-1.862zM15.757 1.478a.663.663 0 0 1 .486 0l5.002 1.957L7.757 8.713l.006.015-5.267-2.062 13.26-5.188zm-.424 28.878L1.757 25.044a.663.663 0 0 1-.424-.621V7.643l14 5.478v17.235zM16 11.95 9.57 9.434 23.074 4.15l6.43 2.516L16 11.95z"
        fill={htmlColor}
      />
      <path
        d="M28 23.35v-.017h-5.24l2.195-2.195-.943-.943L20.207 24l3.805 3.805.943-.943-2.195-2.195h4.907c1.654 0 3 1.346 3 3s-1.346 3-3 3h-2.334V32h2.334A4.338 4.338 0 0 0 32 27.667a4.336 4.336 0 0 0-4-4.317z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// G
export const GbFlag: React.FC<CustomSVGIconProps> = ({
  width = 30,
  height = 20,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#82igts38ba)">
        <mask
          id="0grl9v8h7b"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
        >
          <path
            d="M27.222 0H3.612C2.076 0 .832 1.194.832 2.667v14.666C.833 18.806 2.077 20 3.611 20h23.611C28.756 20 30 18.806 30 17.333V2.667C30 1.194 28.756 0 27.222 0z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#0grl9v8h7b)">
          <path
            d="M27.222 0H3.612C2.076 0 .832 1.194.832 2.667v14.666C.833 18.806 2.077 20 3.611 20h23.611C28.756 20 30 18.806 30 17.333V2.667C30 1.194 28.756 0 27.222 0z"
            fill="#22438B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m5 1.333-2.814.035L2.222 4l23.586 14.704 2.83-.05-.053-2.616L5 1.333z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.611 1.333 2.222 2.666l25 16 1.39-1.333-25-16z"
            fill="#C7152A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.833 1.333h2.778V4S12.291 13.86 5.025 18.704c-.088.058-2.775.004-2.775.004l-.215-2.535 23.798-14.84z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m27.282 1.296 1.33 1.37-25 16-1.39-1.333 25.06-16.037z"
            fill="#C7152A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.944 1.333h6.945v5.333h9.722v6.667H18.89v5.333h-6.945v-5.333H2.222V6.666h9.722V1.333z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.333 1.333H17.5V8H28.61v4H17.5v6.666h-4.167V12H2.223V8h11.11V1.333z"
            fill="#C7152A"
          />
          <path
            d="M27.222.667H3.612c-1.151 0-2.084.895-2.084 2v14.667c0 1.104.933 2 2.083 2h23.611c1.15 0 2.084-.896 2.084-2V2.667c0-1.105-.933-2-2.084-2z"
            stroke="#000"
            strokeOpacity=".1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="82igts38ba">
          <path fill="#fff" transform="translate(.833)" d="M0 0h29.167v20H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GeometricalGlassesIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.91 7.667 14.37 5a.666.666 0 0 0-.576-.333h-3.08a.666.666 0 0 0-.577.333l-.681 1.18a2.325 2.325 0 0 0-2.912 0L5.863 5a.667.667 0 0 0-.578-.333H2.206A.666.666 0 0 0 1.63 5L.089 7.667a.667.667 0 0 0 0 .667L1.63 11c.119.207.34.334.577.334h3.08A.666.666 0 0 0 5.863 11l1.54-2.666a.667.667 0 0 0 0-.667l-.52-.9a1.66 1.66 0 0 1 2.235 0l-.52.9a.667.667 0 0 0 0 .667L10.138 11c.118.207.339.334.577.334h3.079A.666.666 0 0 0 14.37 11l1.54-2.666a.668.668 0 0 0 0-.667zm-10.625 3H2.206L.666 8l1.54-2.666h3.08L6.826 8l-1.54 2.667zm8.509 0h-3.08L9.174 8l1.54-2.666h3.08L15.334 8l-1.54 2.667z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const GlassesCase: React.FC<CustomSVGIconProps> = ({
  width = 25,
  height = 25,
  ...rest
}) => {
  return (
    <IconWrapper
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
    >
      <g clipPath="url(#bjv0v7boma)">
        <path d="M12.375 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill="#00303C" />
        <path
          d="M23.375 6h-22a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-11 11c-2.726 0-3.245-1.447-4.031-3.637-.617-1.721-1.317-3.673-3.33-5.223-.56-.43-1.262-.654-1.94-.869-.279-.088-.552-.175-.795-.27H22.47c-.242.095-.514.182-.793.27-.679.216-1.381.439-1.94.869-2.013 1.55-2.713 3.502-3.331 5.223-.785 2.19-1.304 3.637-4.031 3.637zm-11 0V7.673c.415.237.909.397 1.396.552.596.189 1.212.384 1.632.707 1.78 1.371 2.428 3.176 3 4.768.452 1.263.893 2.483 1.824 3.3H1.375zm14.148 0c.931-.817 1.372-2.037 1.825-3.3.571-1.592 1.219-3.397 2.999-4.768.42-.324 1.036-.519 1.633-.708.486-.155.981-.314 1.395-.55V17h-7.852z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="bjv0v7boma">
          <path fill="#fff" transform="translate(.375)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GlassesIcon: React.FC<CustomSVGIconProps> = ({
  width = 32,
  height = 32,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M31.698 13.682c-.61-2.325-2.157-3.84-4.26-4.157a22.33 22.33 0 0 0-2.788-.187v-.004H7.328v.004a22.42 22.42 0 0 0-2.802.19C2.437 9.844.89 11.357.28 13.683c-.786 2.997.159 6.457 2.196 8.049.792.618 1.75.931 2.796.931.623 0 1.277-.11 1.948-.336 3.06-1.028 5.979-4.226 7.154-7.768.93-.704 2.298-.705 3.228 0 1.167 3.533 4.09 6.739 7.155 7.768.67.224 1.325.336 1.948.336 1.046 0 2.004-.314 2.796-.931 2.038-1.592 2.982-5.052 2.197-8.05zm-18.417-.104c-.902 3.378-3.628 6.526-6.484 7.486-.96.322-2.345.516-3.5-.383-1.616-1.264-2.375-4.188-1.727-6.66.275-1.05 1.05-2.857 3.144-3.174a21.02 21.02 0 0 1 2.615-.181h.277c2.486.024 4.515.54 5.331 1.444.372.413.48.886.344 1.468zm1.373-.675a2.775 2.775 0 0 0-.73-1.687 3.72 3.72 0 0 0-.64-.548h5.409a3.718 3.718 0 0 0-.64.548 2.775 2.775 0 0 0-.728 1.688 3.975 3.975 0 0 0-1.336-.237c-.46 0-.91.085-1.335.236zM28.68 20.68c-1.154.9-2.54.705-3.5.383-2.855-.96-5.583-4.108-6.48-7.466-.14-.603-.033-1.075.34-1.488.818-.904 2.85-1.421 5.335-1.443h.273c.831.004 1.707.06 2.603.178 2.106.32 2.882 2.127 3.157 3.176.647 2.471-.111 5.396-1.728 6.66z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const GlobalCareIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.84 24.866A3.95 3.95 0 0 0 45.2 22.18a4.09 4.09 0 0 0-1.311-.15c.068-.674.112-1.351.112-2.03C44 8.972 35.028 0 24 0S4 8.972 4 20c0 2.525.487 4.968 1.399 7.288L.519 29.97a1 1 0 0 0-.519.877V47a1 1 0 0 0 1.496.868L8.266 44H29a1 1 0 0 0 .656-.246l16.832-14.639c1.285-1.027 1.803-2.655 1.352-4.249zm-6.156-2.12-7.63 5.43c.547-2.252.86-4.68.926-7.176h6.977a18.01 18.01 0 0 1-.17 1.69c-.033.021-.07.033-.103.056zM25 27.374V21h7.961c-.091 3.24-.608 6.345-1.54 9.05L28.681 32h-3.234c.318-.53.513-1.136.547-1.787a3.958 3.958 0 0 0-.994-2.84zM15.536 26a33.25 33.25 0 0 1-.511-5H23v5.144A3.956 3.956 0 0 0 22 26h-6.464zm-9.485-7A17.898 17.898 0 0 1 9.994 8.72c1.365.303 2.919.559 4.617.759-.93 2.777-1.493 6.015-1.584 9.521H6.05zm28.922 0c-.091-3.506-.654-6.744-1.584-9.521 1.698-.2 3.252-.456 4.617-.76A17.899 17.899 0 0 1 41.949 19h-6.976V19zM25 2.125c2.144.5 4.082 2.582 5.516 5.625-1.755.137-3.61.217-5.516.239V2.125zm-2 5.864a83.385 83.385 0 0 1-5.516-.239C18.918 4.707 20.856 2.625 23 2.125v5.864zm2 2c2.19-.025 4.32-.126 6.315-.3.96 2.684 1.559 5.885 1.659 9.31H25V9.99zm7.658-2.44c-.78-1.805-1.731-3.342-2.812-4.555a18.006 18.006 0 0 1 6.576 4.011 50.72 50.72 0 0 1-3.764.544zm-17.316 0a50.623 50.623 0 0 1-3.764-.544 18.005 18.005 0 0 1 6.576-4.011c-1.08 1.213-2.031 2.75-2.812 4.555zm1.343 2.14c1.996.174 4.125.275 6.315.3V19h-7.974c.1-3.426.7-6.627 1.659-9.31zM6.052 21h6.973c.045 1.709.204 3.382.48 5H8a1 1 0 0 0-.481.123l-.354.195A17.796 17.796 0 0 1 6.052 21zm39.155 6.58L28.626 42H8a1 1 0 0 0-.496.132L2 45.277V31.438L8.257 28H22c.554 0 1.07.222 1.451.623.382.402.575.93.546 1.486C23.942 31.152 22.97 32 21.83 32H16v2h13a1 1 0 0 0 .58-.185l13.265-9.44a1.998 1.998 0 0 1 1.778-.28c.612.184 1.12.7 1.293 1.314v.001c.231.819-.022 1.62-.709 2.17z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const GoogleIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <defs>
        <path
          id="a"
          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
        />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
      <path
        clipPath="url(#b)"
        fill="#EA4335"
        d="M0 11l17 13 7-6.1L48 14V0H0z"
      />
      <path
        clipPath="url(#b)"
        fill="#34A853"
        d="M0 37l30-23 7.9 1L48 0v48H0z"
      />
      <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
    </IconWrapper>
  )
}

export const GreenShippingIcon: React.FC<CustomSVGIconProps> = ({
  width = 32,
  height = 32,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g>
        <path
          d="m32.269 17.566-3.13-4.87A1.537 1.537 0 0 0 27.846 12h-4.68V6.063c0-.737-.596-1.334-1.333-1.334h-8v1.334h8V24H8.405a2.664 2.664 0 0 0-2.572-2 2.664 2.664 0 0 0-2.572 2H1.833v-6.666a6.1 6.1 0 0 1 .506-2.575 4.653 4.653 0 0 0 2.828.958c1.194 0 2.39-.454 3.3-1.364 2.989-2.99 3.024-8.715 3.024-8.957a.665.665 0 0 0-.667-.667c-.242 0-5.967.035-8.957 3.024a4.67 4.67 0 0 0-.523 5.97C.502 15.384.5 16.644.5 17.332V24c0 .737.597 1.334 1.333 1.334h1.428a2.664 2.664 0 0 0 2.572 2 2.664 2.664 0 0 0 2.572-2h16.19a2.664 2.664 0 0 0 2.572 2 2.664 2.664 0 0 0 2.572-2h1.237c.842 0 1.524-.664 1.524-1.482v-5.501c0-.277-.08-.55-.231-.785zm-29.46-8.87c2.036-2.036 5.772-2.498 7.318-2.603-.104 1.545-.564 5.277-2.604 7.317-1.245 1.245-3.232 1.284-4.54.142.456-.712 1.086-1.496 1.975-2.346l-.921-.964c-.806.77-1.42 1.497-1.9 2.177a3.334 3.334 0 0 1 .672-3.724zm4.358 15.97a1.335 1.335 0 0 1-2.667 0c0-.735.598-1.332 1.333-1.332.736 0 1.334.598 1.334 1.333zm20 1.334a1.335 1.335 0 0 1-1.334-1.333 1.335 1.335 0 0 1 2.667 0c0 .735-.598 1.333-1.333 1.333zm4-2.148c0 .072-.077.148-.191.148h-1.237a2.664 2.664 0 0 0-2.572-2 2.664 2.664 0 0 0-2.572 2h-1.428V13.334h4.679c.07 0 .138.032.17.084l3.13 4.869c.014.02.02.042.02.064v5.501z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GreenShippingIconDescription: React.FC<CustomSVGIconProps> = ({
  width,
  height,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m9.65 10.821.272-.272a2.992 2.992 0 0 0 1.869.654 2.99 2.99 0 0 0 2.121-.878c1.97-1.97 1.757-5.528 1.747-5.679a.333.333 0 0 0-.31-.31c-.15-.01-3.71-.223-5.68 1.747a3.004 3.004 0 0 0-.22 3.995l-.271.272c-.34.34-.623.721-.852 1.129a9.369 9.369 0 0 0-1.273-4.457 3.31 3.31 0 0 0 .961-2.341c0-.89-.346-1.727-.976-2.357C4.836.123.825.331.655.342a.333.333 0 0 0-.313.313c-.01.17-.22 4.181 1.982 6.383.63.63 1.467.976 2.357.976a3.31 3.31 0 0 0 1.851-.56 8.725 8.725 0 0 1 1.134 4.317v1.902c-3.864.069-6.989 1.297-7.124 1.35l.248.62c.033-.013 3.319-1.31 7.21-1.31 3.884 0 7.176 1.297 7.21 1.31l.247-.619c-.135-.054-3.25-1.278-7.108-1.35a4.46 4.46 0 0 1 1.3-2.853zm.491-4.267c1.424-1.424 3.915-1.561 4.821-1.561h.04c.004.872-.116 3.416-1.561 4.86-.83.83-2.13.9-3.043.22l1.17-1.171-.47-.471L9.925 9.6a2.335 2.335 0 0 1 .215-3.047zm-7.346.013C1.136 4.908 1 1.955 1 1.001h.022C2 1 4.917 1.147 6.566 2.796a2.668 2.668 0 0 1 .116 3.643 9.536 9.536 0 0 0-1.114-1.341l-.471.471c.407.408.769.848 1.084 1.316a2.67 2.67 0 0 1-3.386-.318z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// H
export const HeartIconSlim: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.565 6.85a13.395 13.395 0 0 0-9.5-3.936 13.394 13.394 0 0 0-9.5 3.935L24.5 7.886 23.435 6.85a13.394 13.394 0 0 0-9.5-3.935 13.394 13.394 0 0 0-9.5 3.935c-5.247 5.247-5.247 13.753 0 19L5.5 26.914 23.086 44.5c.391.391.902.586 1.414.586a1.99 1.99 0 0 0 1.414-.586L43.5 26.914l1.065-1.065c5.247-5.246 5.247-13.753 0-19zm-1.414 17.585L42.086 25.5 24.5 43.086 6.914 25.5 5.85 24.435A11.359 11.359 0 0 1 2.5 16.35a11.36 11.36 0 0 1 3.35-8.086 11.359 11.359 0 0 1 8.085-3.349c3.054 0 5.926 1.19 8.105 3.368l1.065 1.037 1.395 1.358 1.395-1.358 1.084-1.056a11.36 11.36 0 0 1 8.086-3.349 11.36 11.36 0 0 1 8.086 3.35 11.36 11.36 0 0 1 3.35 8.085c0 3.055-1.19 5.926-3.35 8.086z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const HeartIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      {/* <path
          d="M44.565 6.85a13.395 13.395 0 0 0-9.5-3.936 13.394 13.394 0 0 0-9.5 3.935L24.5 7.886 23.435 6.85a13.394 13.394 0 0 0-9.5-3.935 13.394 13.394 0 0 0-9.5 3.935c-5.247 5.247-5.247 13.753 0 19L5.5 26.914 23.086 44.5c.391.391.902.586 1.414.586a1.99 1.99 0 0 0 1.414-.586L43.5 26.914l1.065-1.065c5.247-5.246 5.247-13.753 0-19zm-1.414 17.585L42.086 25.5 24.5 43.086 6.914 25.5 5.85 24.435A11.359 11.359 0 0 1 2.5 16.35a11.36 11.36 0 0 1 3.35-8.086 11.359 11.359 0 0 1 8.085-3.349c3.054 0 5.926 1.19 8.105 3.368l1.065 1.037 1.395 1.358 1.395-1.358 1.084-1.056a11.36 11.36 0 0 1 8.086-3.349 11.36 11.36 0 0 1 8.086 3.35 11.36 11.36 0 0 1 3.35 8.085c0 3.055-1.19 5.926-3.35 8.086z"
          fill={htmlColor} />
        <path d="M25.055 7.342a.79.79 0 0 1-1.113-.003l-.182.505.666.24.416-.213.213-.53z"/> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.983 3.227 8 4.225l-.983-.998-.004-.005a3.091 3.091 0 0 0-2.24-.972c-.806 0-1.616.32-2.242.972-1.263 1.318-1.263 3.47 0 4.789l5.47 5.706 5.469-5.706c1.263-1.318 1.263-3.47 0-4.789a3.091 3.091 0 0 0-2.242-.972c-.804 0-1.615.32-2.24.972l-.005.005zm-.657-.639L8 2.918l-.325-.33a4.223 4.223 0 0 0-.291-.277 3.99 3.99 0 0 0-2.612-.978c-1.05 0-2.101.419-2.903 1.255-1.603 1.673-1.603 4.384 0 6.057l5.7 5.946a.596.596 0 0 0 .863 0l5.7-5.946c1.603-1.672 1.603-4.384 0-6.057a4.007 4.007 0 0 0-2.903-1.255 3.99 3.99 0 0 0-2.612.978c-.1.087-.197.18-.291.277z"
        fill={htmlColor}
      />{' '}
    </IconWrapper>
  )
}

export const HeartFilledIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  fill,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      {...rest}
    >
      <path
        d="M 11.3563 2.185 z m 0 -1.2663 c -1.0763 0 -2.185 0.4113 -3.0077 1.235 L 8 2.47 l -0.3486 -0.3164 a 4.2683 4.2683 90 0 0 -3.0077 -1.235 a 4.3234 4.3234 90 0 0 -3.0086 1.235 C -0.0113 3.8 -0.0113 6.5236 1.635 8.17 l 0.3486 0.3486 l 5.5727 5.5727 c 0.1273 0.1263 0.285 0.19 0.4436 0.19 a 0.6251 0.6251 90 0 0 0.4436 -0.19 l 5.5727 -5.5727 l 0.3486 -0.3486 c 1.6463 -1.6463 1.6463 -4.37 0 -6.0164 a 4.3234 4.3234 90 0 0 -3.0086 -1.235 z"
        fill={fill}
        stroke={htmlColor}
        fillOpacity={'0.8'}
      />
    </IconWrapper>
  )
}

export const HexagonalGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill={htmlColor}
      {...rest}
    >
      <path
        d="M15.91 7.667 14.372 5a.666.666 0 0 0-.577-.333h-3.08a.666.666 0 0 0-.577.333l-.681 1.18a2.325 2.325 0 0 0-2.912 0L5.863 5a.667.667 0 0 0-.578-.333H2.206A.666.666 0 0 0 1.63 5L.089 7.667a.667.667 0 0 0 0 .667L1.63 11c.119.207.34.334.577.334h3.08A.666.666 0 0 0 5.863 11l1.54-2.666a.667.667 0 0 0 0-.667l-.52-.9a1.66 1.66 0 0 1 2.235 0l-.52.9a.667.667 0 0 0 0 .667L10.138 11c.118.207.339.334.577.334h3.079a.666.666 0 0 0 .577-.334l1.54-2.666a.668.668 0 0 0 0-.667zm-10.625 3H2.206L.666 8l1.54-2.666h3.08L6.826 8l-1.54 2.667zm8.509 0h-3.08L9.174 8l1.54-2.666h3.08L15.334 8l-1.54 2.667z"
        fill="#00303C"
      />
    </IconWrapper>
  )
}

export const HomeIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.725 5.61 8.391.284a.667.667 0 0 0-.783 0L.275 5.601a.666.666 0 0 0-.275.54v9.192c0 .368.298.666.667.666h14.666a.666.666 0 0 0 .667-.666V6.149a.667.667 0 0 0-.275-.54zM6 15.333v-4.667h4v4.667H6zm9.333 0h-4.666v-4.667A.667.667 0 0 0 10 10H6a.667.667 0 0 0-.667.667v4.667H.667V6.142L8 .824l7.333 5.325v9.185z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const HamburgerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#00303C',
  width = 16,
  height = 17,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M.667 4.348h14.666a.667.667 0 0 0 0-1.333H.667a.667.667 0 0 0 0 1.333z"
        fill={htmlColor}
      />
      <path
        d="M15.333 7.348H.667a.667.667 0 0 0 0 1.334h14.666a.667.667 0 0 0 0-1.334zM15.333 11.682H.667a.667.667 0 0 0 0 1.333h14.666a.667.667 0 0 0 0-1.333z"
        fill="#000000"
      />
    </IconWrapper>
  )
}

// I
export const IndeterminateCheckBoxIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path fill={htmlColor} d="M8 11h8v2H8z" />
    </IconWrapper>
  )
}

export const InfoIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} 17`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 16.593c4.411 0 8-3.589 8-8 0-4.412-3.589-8-8-8s-8 3.588-8 8c0 4.411 3.589 8 8 8zM8 1.926a6.674 6.674 0 0 1 6.667 6.667A6.674 6.674 0 0 1 8 15.259a6.674 6.674 0 0 1-6.667-6.666A6.674 6.674 0 0 1 8 1.926z"
        fill="#00303C"
      />
      <path
        d="M7.333 9.926h1.334V4.592H7.333v5.334zM7.333 12.593h1.334v-1.334H7.333v1.334z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const InfoOutlinedIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.667A6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333 6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667z"
        fill={htmlColor}
      />
      <path
        d="M8.667 6.667H7.334V12h1.333V6.667zM8.667 4H7.334v1.333h1.333V4z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const IrregularGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.333 4.5h-3a3.332 3.332 0 0 0-3.225 2.504 2 2 0 0 0-2.217-.002A3.331 3.331 0 0 0 3.667 4.5h-3A.666.666 0 0 0 0 5.167v3A3.333 3.333 0 0 0 3.333 11.5h3A.666.666 0 0 0 7 10.833v-3l-.002-.035c.254-.293.61-.465 1.002-.465.386 0 .75.169 1.002.456L9 7.833v3c0 .369.298.667.667.667h3A3.333 3.333 0 0 0 16 8.167v-3a.666.666 0 0 0-.667-.667zm-9 6.333h-3A2.67 2.67 0 0 1 .667 8.167v-3h3a2.67 2.67 0 0 1 2.666 2.666v3zm9-2.666a2.67 2.67 0 0 1-2.666 2.666h-3v-3a2.67 2.67 0 0 1 2.666-2.666h3v3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

//K
export const KlarnaIcon: React.FC<CustomSVGIconProps> = ({
  width = 42,
  height = 25,
  ...rest
}) => {
  return (
    <PaymentIconWrapper
      viewBox="0 0 30 20"
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".376"
          y=".208"
          width="28.747"
          height="19.58"
          rx="2.29"
          fill="#FFB3C7"
          stroke="#D8D8D8"
        />
        <path
          d="M25.374 11.258c-.346 0-.627.251-.627.561 0 .31.28.561.627.561.346 0 .626-.25.626-.56 0-.31-.28-.562-.626-.562zm-2.06-.434c0-.424-.406-.768-.905-.768-.5 0-.904.344-.904.768 0 .425.405.769.904.769.5 0 .904-.344.904-.769zm.002-1.493h.998v2.986h-.998v-.19a1.895 1.895 0 0 1-.989.273c-.971 0-1.758-.705-1.758-1.576 0-.87.787-1.575 1.758-1.575.367 0 .707.101.99.273v-.19zm-7.985.39v-.39h-1.022v2.986h1.024v-1.394c0-.47.57-.723.964-.723h.012v-.868c-.405 0-.778.155-.978.388zm-2.545 1.103c0-.424-.405-.768-.904-.768-.5 0-.904.344-.904.768 0 .425.405.769.904.769.5 0 .904-.344.904-.769zm.003-1.493h.998v2.986h-.998v-.19a1.895 1.895 0 0 1-.989.273c-.971 0-1.759-.705-1.759-1.576 0-.87.788-1.575 1.76-1.575.366 0 .706.101.988.273v-.19zm6.007-.08c-.4 0-.776.111-1.029.417v-.336h-.993v2.985h1.006v-1.569c0-.454.34-.676.749-.676.438 0 .69.235.69.67v1.575h.997V10.42c0-.695-.617-1.168-1.42-1.168zM8.588 12.317h1.044V8.001H8.588v4.316zM4 12.32h1.106V8H4v4.319zM7.868 8c0 .935-.406 1.805-1.131 2.451l1.529 1.868H6.9l-1.662-2.03.429-.288C6.378 9.524 6.786 8.795 6.786 8h1.082z"
          fill="#0A0B09"
        />
      </svg>
    </PaymentIconWrapper>
  )
}

// L
export const LensIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.805 14.877 11.828 10.9a6.666 6.666 0 1 0-.943.943l3.977 3.977.943-.943zm-9.138-2.862a5.34 5.34 0 0 1-5.334-5.333 5.34 5.34 0 0 1 5.334-5.334A5.34 5.34 0 0 1 12 6.682a5.34 5.34 0 0 1-5.333 5.333z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensCleanerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14,21.717C14,18.564,16.564,16,19.717,16H22h8h2.283C35.436,16,38,18.564,38,21.717v4.392C38.326,26.044,38.658,26,39,26
		s0.674,0.044,1,0.108v-4.392C40,17.455,36.545,14,32.283,14H32V4c0-1.105-0.895-2-2-2h-8c-1.105,0-2,0.895-2,2v10h-0.283
		C15.455,14,12,17.455,12,21.717V46c0,1.105,0.895,2,2,2h16v-2H14V21.717z M22,4h8v10h-8V4z"
        fill={htmlColor}
      />
      <path
        fill={htmlColor}
        d="M6,7h8v2h-8Z M7.11,13.13l6.93,-4l1,1.73l-6.93,4Z M7.11,2.87l1,-1.73l6.93,4l-1,1.73Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.359,37.963l-4.882-2.441l-2.441-4.882C39.823,30.214,39.412,30,39,30s-0.823,0.214-1.037,0.641l-2.441,4.882
		l-4.882,2.441c-0.854,0.427-0.854,1.646,0,2.074l4.882,2.441l2.441,4.882C38.177,47.786,38.588,48,39,48s0.823-0.214,1.037-0.641
		l2.441-4.882l4.882-2.441C48.214,39.61,48.214,38.39,47.359,37.963z M41.583,40.689l-0.596,0.298l-0.298,0.596L39,44.961
		l-1.689-3.378l-0.298-0.596l-0.596-0.298L33.039,39l3.378-1.689l0.596-0.298l0.298-0.596L39,33.039l1.689,3.378l0.298,0.596
		l0.596,0.298L44.961,39L41.583,40.689z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M6.764,17.723l1.922,0.553c0.786-2.732,2.942-7.444,8.548-8.302l-0.303-1.977C12.032,8.746,8.327,12.291,6.764,17.723z M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
		c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M45.781,15.272c-1.986,7.559-6.295,14.795-12.135,20.376
		C28.093,40.956,21.861,44,16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124
		C3.924,12.529,6.82,5.852,14.52,4.664C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72
		C45.927,11.264,46.281,13.081,45.781,15.272z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M38.608,13.217c0.2-0.613,0.389-1.23,0.554-1.854c1.871-8.041-8.136-11.198-19.203-11.198
		c-2.745,0-5.553,0.194-8.263,0.556C-2.644,2.915-3.111,25.926,6.558,33.628c0.837,0.66,1.732,1.168,2.667,1.546
		c0.943,4.235,2.958,8.075,5.945,10.454C17.238,47.259,19.641,48,22.192,48c9.891,0,22.016-11.13,25.581-24.637
		C49.039,17.923,44.866,14.721,38.608,13.217z M7.804,32.064c-4.828-3.845-7.07-12.635-5.105-20.01
		c0.823-3.088,3.125-8.411,9.262-9.35c2.675-0.357,5.366-0.538,7.999-0.538c8.047,0,14.2,1.698,16.458,4.543
		c0.918,1.156,1.181,2.522,0.803,4.174c-0.171,0.646-0.37,1.288-0.585,1.926c-2.501-0.437-5.248-0.643-8.064-0.643
		c-2.745,0-5.553,0.194-8.263,0.556C11.231,14.11,7.719,23.837,8.81,32.744l0,0C8.464,32.539,8.126,32.317,7.804,32.064z
		 M8.988,33.963c0.002,0.009,0.004,0.019,0.005,0.028C8.991,33.982,8.989,33.972,8.988,33.963z M45.832,22.883
		C42.624,34.986,31.364,46,22.192,46c-2.204,0-4.15-0.654-5.777-1.936c-4.828-3.845-7.07-12.635-5.105-20.01
		c0.823-3.088,3.125-8.411,9.261-9.35c2.675-0.357,5.366-0.538,7.999-0.538c8.047,0,14.2,1.698,16.458,4.543
		C45.947,19.865,46.21,21.23,45.832,22.883z M13.989,24.882l1.922,0.553c0.634-2.201,2.369-5.997,6.87-6.685l-0.303-1.977C18.387,17.399,15.292,20.354,13.989,24.882z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassesLaserIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <rect x="38" y="44" width="2" height="4" />
      <rect
        x="44.062"
        y="40.5"
        transform="matrix(0.5 -0.866 0.866 0.5 -14.2744 60.2769)"
        width="2"
        height="4"
      />
      <rect
        x="43.062"
        y="34.5"
        transform="matrix(0.8661 -0.4999 0.4999 0.8661 -11.712 27.282)"
        width="4"
        height="2"
      />
      <rect
        x="31.938"
        y="33.5"
        transform="matrix(0.5 -0.866 0.866 0.5 -14.2747 46.2764)"
        width="2"
        height="4"
      />
      <rect
        x="30.938"
        y="41.5"
        transform="matrix(0.8661 -0.4999 0.4999 0.8661 -16.8353 22.1582)"
        width="4"
        height="2"
      />
      <path
        d="M6.764,17.723l1.922,0.553c0.786-2.732,2.942-7.444,8.548-8.302l-0.303-1.977C12.032,8.746,8.327,12.291,6.764,17.723z"
        fill={htmlColor}
      />
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
        c3.271,0,6.743-1.013,10.153-2.812c-0.219-0.644-0.387-1.31-0.503-1.994C22.881,43.015,19.567,44,16.55,44
        c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124C3.924,12.529,6.82,5.852,14.52,4.664
        C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72c1.228,1.544,1.583,3.361,1.083,5.552
        c-0.992,3.777-2.565,7.473-4.618,10.923c0.688,0.115,1.358,0.281,2.005,0.501c1.97-3.403,3.547-7.093,4.556-10.945
        C50.004,5.876,37.809,2,24.323,2z"
        fill={htmlColor}
      />
      <path
        d="M40,37.277V30h-2v7.277c-0.595,0.346-1,0.984-1,1.723c0,1.105,0.895,2,2,2s2-0.895,2-2C41,38.262,40.595,37.624,40,37.277z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassesPhotochromicIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M6.764,17.723l1.922,0.553c0.786-2.732,2.942-7.444,8.548-8.302l-0.303-1.977C12.032,8.746,8.327,12.291,6.764,17.723z"
        fill={htmlColor}
      />
      <path
        d="M16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124C3.924,12.529,6.82,5.852,14.52,4.664
        C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72c1.228,1.544,1.583,3.361,1.083,5.552
        c-0.992,3.776-2.564,7.471-4.617,10.919c0.689,0.114,1.359,0.279,2.007,0.497c1.968-3.401,3.543-7.089,4.552-10.938
        C50.004,5.876,37.809,2,24.323,2c-3.345,0-6.767,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087
        C10.512,45.09,13.441,46,16.55,46c3.278,0,6.757-1.017,10.173-2.822c-0.219-0.644-0.385-1.311-0.501-1.997
        C22.896,43.01,19.574,44,16.55,44z"
        fill={htmlColor}
      />
      <path
        d="M46.914,34.71c-0.139-0.255-0.289-0.503-0.451-0.742c-0.323-0.479-0.692-0.925-1.099-1.332C43.735,31.007,41.485,30,39,30
        c-4.971,0-9,4.029-9,9c0,4.971,4.029,9,9,9c2.485,0,4.735-1.007,6.364-2.636c0.407-0.407,0.776-0.853,1.099-1.332
        c0.162-0.239,0.312-0.487,0.451-0.742C47.607,42.015,48,40.553,48,39S47.607,35.985,46.914,34.71z M32,39c0-3.86,3.14-7,7-7v14
        C35.14,46,32,42.86,32,39z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensSunIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
        c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M21.293,13.293l1.414,1.414l-8,8l-1.414-1.414L21.293,13.293
        z M15.707,29.707l-1.414-1.414l2-2l1.414,1.414L15.707,29.707z M19.774,25.641l-1.414-1.415l10.933-10.933l1.414,1.414
        L19.774,25.641z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensSunOakleyIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M18.625,9.347c-0.158-0.083-0.329-0.147-0.501-0.195c-0.562-0.158-1.137-0.153-1.713-0.105
        c-0.69,0.058-1.357,0.222-2.011,0.442c-0.096,0.033-0.191,0.066-0.287,0.099c-0.904,0.32-1.726,0.784-2.455,1.408
        c-0.345,0.295-0.638,0.633-0.82,1.055c-0.243,0.563-0.066,1.087,0.466,1.39c0.26,0.148,0.543,0.229,0.835,0.278
        c0.636,0.107,1.27,0.068,1.902-0.033c0.896-0.144,1.755-0.411,2.575-0.799c0.697-0.33,1.344-0.734,1.893-1.283
        c0.258-0.258,0.486-0.538,0.622-0.882C19.357,10.153,19.168,9.632,18.625,9.347z"
        fill={htmlColor}
      />
      <path
        d="M14.253,2.682C-3.222,5.376-3.791,33.631,7.992,43.087c12.295,9.769,34.268-6.476,39.732-27.338
        C50.57,3.427,30.875,0.447,14.253,2.682z M21.865,9.647c-0.102,0.388-0.328,0.705-0.592,0.997c-0.52,0.575-1.131,1.039-1.789,1.442
        c-1.239,0.76-2.558,1.346-3.937,1.8c-0.654,0.215-1.316,0.406-1.988,0.555c-1.016,0.225-2.041,0.386-3.086,0.358
        c-0.507-0.014-1.01-0.06-1.491-0.233c-0.15-0.054-0.299-0.12-0.432-0.206c-0.488-0.311-0.638-0.803-0.421-1.34
        c0.159-0.393,0.424-0.713,0.716-1.011c0.454-0.464,0.98-0.838,1.525-1.185c0.55-0.35,1.121-0.663,1.713-0.936
        c0.421-0.194,0.849-0.37,1.274-0.555c0.02-0.009,0.039-0.022,0.058-0.033c0.43-0.149,0.86-0.298,1.291-0.447
        c0.205-0.059,0.41-0.118,0.614-0.178c0.68-0.198,1.37-0.351,2.071-0.459c0.602-0.093,1.206-0.167,1.817-0.172
        c0.583-0.004,1.161,0.041,1.721,0.221c0.218,0.07,0.424,0.167,0.601,0.316C21.866,8.865,21.976,9.223,21.865,9.647z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensSunRayBanIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M14.253,2.682C-3.222,5.376-3.791,33.631,7.992,43.087c12.295,9.769,34.268-6.476,39.732-27.338
       C50.57,3.427,30.875,0.447,14.253,2.682z M13.419,15.094c0.207,0.097,0.194,0.193,0.083,0.387
       c-0.111,0.193-0.194,0.221-0.346,0.152l-1.686-0.816c-0.069-0.014-0.117,0.014-0.131,0.097v0.352
       c-0.014,0.166-0.048,0.415-0.567,0.518l0.021-1.161c0-0.138-0.041-0.18-0.18-0.235l-0.581-0.276c-0.124-0.041-0.166-0.166,0-0.249
       l0.721-0.406c0.028-0.012,0.053-0.054,0.054-0.106l0.034-2.398c0.014-0.111,0.007-0.145,0.159-0.235l0.221-0.111
       c0.138-0.048,0.2,0.014,0.2,0.152l-0.048,2.08c0,0.097,0.043,0.157,0.159,0.097c0.304-0.159,0.974-0.802,1.21-1.244
       c0.215-0.404,0.386-1.361-0.318-1.521c-0.733-0.166-1.355,0.207-1.617,0.318c-0.263,0.111-1.673,0.76-2.599,1.617
       C8.083,12.26,8.028,12.274,8,12.122c0.014-0.359-0.055-0.76,0.442-1.092c0.558-0.372,1.846-1.057,3.11-1.327
       c1.424-0.304,2.335,0.813,1.424,2.143c-0.684,0.998-1.346,1.365-1.56,1.501c-0.05,0.036-0.057,0.075-0.058,0.113l-0.003,0.279
       l4.062-1.456l0.338,0.941l-3.556,1.275L13.419,15.094z M16.721,12.764c-0.234,0.135-0.513,0.109-0.606-0.051
       c-0.092-0.16,0.016-0.413,0.251-0.548s0.506-0.102,0.599,0.058C17.057,12.382,16.956,12.628,16.721,12.764z M19.52,12.612
       c0,0-1.071,1.12-1.32,1.583c-0.062,0.104-0.159,0.145-0.235,0.021c-0.076-0.124-0.297-0.498-0.083-0.726
       c0,0,1.009-1.009,1.106-1.382c0.028-0.124,0.048-0.29-0.138-0.477s-0.369-0.387-0.442-0.491c-0.104-0.145-0.145-0.325,0.035-0.532
       c0.18-0.207,0.885-0.875,0.981-1.486c0.062-0.394-0.055-0.588-0.138-0.664c-0.083-0.076-0.394-0.263-0.698,0.2
       c-0.304,0.463-0.348,0.997-0.387,1.286c-0.062,0.463-0.069,2.537-0.069,2.654c0,0.093,0.014,0.311-0.166,0.456
       c-0.18,0.145-0.574,0.366-0.442-0.007c0.035-0.159,0.069-1.002,0.069-1.313c0-0.311,0.007-0.595,0.014-0.843
       c0.035-1.313,0.155-1.472,0.415-1.956c0.401-0.746,1.204-0.994,1.583-0.871c0.276,0.09,0.661,0.646,0.415,1.286
       c-0.256,0.664-0.905,1.092-1.32,1.368c-0.069,0.055-0.146,0.153-0.035,0.283c0.069,0.08,0.212,0.201,0.354,0.315l4.27-1.531
       l0.338,0.941l-3.939,1.412C19.689,12.287,19.65,12.447,19.52,12.612z M23.968,9.278c-0.17,0-0.308-0.137-0.308-0.308
       c0-0.169,0.137-0.306,0.308-0.306c0.169,0,0.306,0.137,0.306,0.306C24.274,9.14,24.137,9.278,23.968,9.278z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensSunRX: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <polygon
        points="47.329,32 45.071,32 42,37.849 38.929,32 36.671,32 40.871,40 36.671,48 38.929,48 42,42.151 45.071,48 47.329,48
        43.129,40 	"
      />
      <path
        d="M24,29.951h17.107c2.941-4.246,5.276-9.084,6.617-14.201C50.004,5.876,37.809,2,24.323,2c-3.345,0-6.766,0.238-10.07,0.682
        C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46c2.41,0,4.928-0.554,7.45-1.561V29.951z M17.234,9.973
        c-5.606,0.858-7.762,5.57-8.548,8.302l-1.922-0.553c1.562-5.432,5.268-8.977,10.167-9.726L17.234,9.973z"
        fill={htmlColor}
      />
      <path
        d="M28,42.003h1.922L33.071,48h2.259l-3.219-6.131C34.336,41.363,36,39.381,36,37.003c0-2.761-2.239-5-5-5h-3v0h-2v0v10V48h2
        V42.003z M31,34.003c1.654,0,3,1.346,3,3c0,1.654-1.346,3-3,3h-3v-6H31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LocationIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 11h-2.05c-.471-4.717-4.233-8.479-8.95-8.95V0h-2v2.05C6.283 2.522 2.521 6.284 2.05 11H0v2h2.05c.472 4.717 4.233 8.479 8.95 8.95V24h2v-2.05c4.717-.471 8.479-4.233 8.95-8.95H24v-2zm-11 8.93V17h-2v2.93A8.007 8.007 0 0 1 4.07 13H7v-2H4.07A8.007 8.007 0 0 1 11 4.07V7h2V4.07A8.007 8.007 0 0 1 19.93 11H17v2h2.93A8.007 8.007 0 0 1 13 19.93z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LookIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.105 22.165c-.618-.721-1.624-1.089-3.132-1.152l.233-1.744c.475-3.562-.045-6.98-1.505-9.886a17.212 17.212 0 0 0-2.542-3.734A7.975 7.975 0 0 0 39.5 0h-18a5.01 5.01 0 0 0-4.908 4.041C11.502 4.501 7.5 8.792 7.5 14l.484 4 .363 3.004c-1.688.022-2.79.39-3.453 1.16-.907 1.057-.685 2.509-.469 3.914l.084.562C4.813 28.775 5.272 32 8.5 32c.4 0 .786-.057 1.158-.155l1.21 9.998A7.007 7.007 0 0 0 17.815 48h13.431c3.494 0 6.477-2.612 6.938-6.075l1.338-10.032c.316.069.643.107.977.107 3.228 0 3.687-3.225 3.99-5.359l.084-.562c.216-1.405.44-2.857-.469-3.914zM31.247 46h-13.43a5.004 5.004 0 0 1-4.964-4.398l-2.89-23.85c4.981-.305 8.046-2.183 9.11-5.639C20.595 13.575 23.595 15 29.5 15c5.148 0 8.784 1.068 9.837 2.864-.025.377-.062.757-.113 1.14l-3.02 22.657A5.015 5.015 0 0 1 31.246 46zM37.15 2c-.826 2.327-3.043 4-5.65 4v2a7.952 7.952 0 0 0 3.06-.611c.352-.147.69-.323 1.015-.517a15.155 15.155 0 0 1 2.34 3.408c.731 1.457 1.182 3.071 1.364 4.775C36.592 13.316 32.265 13 29.5 13c-9.697 0-9.999-3.982-10-4h-2c0 2.769-.846 6.396-7.778 6.777L9.5 13.941C9.532 9.557 13.108 6 17.5 6h1V5c0-1.654 1.346-3 3-3h15.65zM8.5 30c-1.157 0-1.608-.816-2.01-3.641l-.088-.585c-.154-1.001-.299-1.948.01-2.307.15-.175.62-.467 2.088-.467h.09l.824 6.817A2.594 2.594 0 0 1 8.5 30zm34.098-4.226-.088.585C42.108 29.184 41.657 30 40.5 30a2.55 2.55 0 0 1-.71-.11l.918-6.885c1.296.028 1.737.296 1.88.462.308.36.163 1.306.01 2.307z"
        fill={htmlColor}
      />
      <path
        d="M26.513 36h-4.027v2h4.027v-2zM24.934 31h-2.448v2h4.448v-9.943h-2V31z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LogoutIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width,
  height,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M1.333 13V3A1.65 1.65 0 0 1 3 1.333h1.667V0H3C1.333 0 0 1.333 0 3v10c0 1.667 1.333 3 3 3h1.667v-1.333H3A1.65 1.65 0 0 1 1.333 13z"
        fill={htmlColor}
      />
      <path
        d="m10.466 2.533-.933.934L13.4 7.333H3.333v1.334H13.4l-3.867 3.866.933.934L15.933 8l-5.467-5.467z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LuxuryGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M28 24c0-2.793 1.023-6 9-6s9 3.207 9 6c0 1.984-.484 5.888-3.937 7.835.089.724.278 1.385.541 1.954C46.025 32.08 48 28.643 48 24c0-5.309-3.701-8-11-8-5.554 0-9.019 1.563-10.361 4.648a6.252 6.252 0 0 0-5.278 0C20.019 17.563 16.554 16 11 16c-7.299 0-11 2.691-11 8 0 6.785 4.215 11 11 11 6.065 0 11-4.935 11-11 0-.486-.042-.943-.104-1.385 1.265-.767 2.943-.767 4.208 0A9.882 9.882 0 0 0 26 24c0 5.313 3.786 9.757 8.801 10.779a5.854 5.854 0 0 0 .924-1.88C31.366 32.277 28 28.529 28 24zm-17 9c-8.123 0-9-6.294-9-9 0-2.793 1.023-6 9-6s9 3.207 9 6c0 4.962-4.038 9-9 9z"
        fill={htmlColor}
      />
      <path
        d="M45 38c-2.36 0-5-2.994-5-7a1 1 0 0 0-2 0c0 4.006-2.64 7-5 7a1 1 0 0 0 0 2c2.36 0 5 2.994 5 7a1 1 0 0 0 2 0c0-4.006 2.64-7 5-7a1 1 0 0 0 0-2zm-6 4.506C38.305 41.03 37.291 39.8 36.101 39c1.19-.8 2.204-2.03 2.899-3.506.695 1.476 1.709 2.706 2.899 3.506-1.19.8-2.204 2.03-2.899 3.506z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

//M

export const MaintenanceIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 64,
  height = 64,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M46.91 38.424a1.332 1.332 0 0 0-1.886 0l-2.359 2.359-5.01-5.01 4.777-4.777A15.851 15.851 0 0 0 48 32c8.823 0 16-7.177 16-16 0-2.22-.455-4.381-1.35-6.423l-.785-1.786-11.037 11.037A3.968 3.968 0 0 1 48 20c-2.205 0-4-1.795-4-4 0-1.068.416-2.073 1.17-2.828L56.209 2.136l-1.787-.785A15.877 15.877 0 0 0 48 0c-8.823 0-16 7.177-16 16 0 1.913.337 3.783 1.004 5.57l-4.777 4.777-11.678-11.678 4.394-4.393a1.335 1.335 0 0 0-.256-2.085L7.8.459a1.993 1.993 0 0 0-2.444.3L.759 5.356A2.002 2.002 0 0 0 .457 7.8L8.19 20.687a1.335 1.335 0 0 0 2.087.256l4.388-4.388 11.677 11.677L1.952 52.62A6.621 6.621 0 0 0 0 57.333 6.674 6.674 0 0 0 6.667 64a6.625 6.625 0 0 0 4.714-1.953L35.77 37.659l5.011 5.009-2.355 2.355a1.332 1.332 0 0 0 0 1.885L53.76 62.241A5.954 5.954 0 0 0 58 64c3.308 0 6-2.692 6-6 0-1.604-.624-3.11-1.759-4.244L46.91 38.424zM9.598 17.851 3.012 6.875l3.861-3.862L17.85 9.597 9.6 17.851zm-.104 42.31a3.968 3.968 0 0 1-2.828 1.172c-2.206 0-4-1.794-4-4 0-1.068.416-2.073 1.17-2.828l32.322-32.32-.367-.834A13.24 13.24 0 0 1 34.667 16c0-7.352 5.981-13.333 13.333-13.333 1.173 0 2.328.152 3.45.456l-8.165 8.164A6.621 6.621 0 0 0 41.333 16 6.674 6.674 0 0 0 48 22.667a6.625 6.625 0 0 0 4.715-1.954l8.164-8.164c.301 1.123.454 2.276.454 3.451 0 7.352-5.981 13.333-13.333 13.333-1.85 0-3.65-.378-5.35-1.126l-.834-.366-32.321 32.32zM58 61.333c-.89 0-1.727-.346-2.357-.976l-14.391-14.39 4.713-4.714 14.391 14.391v.001c.63.627.977 1.464.977 2.355A3.337 3.337 0 0 1 58 61.333z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MastercardIcon: React.FC<CustomSVGIconProps> = () => {
  return (
    <PaymentIconWrapper
      viewBox={'0 0 30 20'}
      width={30}
      height={20}
      fill="none"
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".208"
          y=".208"
          width="28.75"
          height="19.583"
          rx="2.292"
          fill="#fff"
          stroke="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 14.485a5.626 5.626 0 0 1-3.688 1.373c-3.141 0-5.687-2.575-5.687-5.752 0-3.177 2.546-5.752 5.687-5.752 1.407 0 2.695.517 3.688 1.373a5.626 5.626 0 0 1 3.688-1.373c3.14 0 5.686 2.575 5.686 5.752 0 3.177-2.546 5.752-5.686 5.752a5.626 5.626 0 0 1-3.688-1.373z"
          fill="#ED0006"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 14.485a5.767 5.767 0 0 0 1.998-4.379 5.767 5.767 0 0 0-1.998-4.379 5.626 5.626 0 0 1 3.688-1.373c3.14 0 5.687 2.575 5.687 5.752 0 3.177-2.546 5.752-5.687 5.752a5.626 5.626 0 0 1-3.688-1.373z"
          fill="#F9A000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.748 14.485a5.767 5.767 0 0 0 1.999-4.38 5.767 5.767 0 0 0-1.999-4.378 5.767 5.767 0 0 0-1.998 4.379c0 1.753.775 3.323 1.998 4.379z"
          fill="#FF5E00"
        />
      </svg>
    </PaymentIconWrapper>
  )
}

export const MagnifyingGlassIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M48.374 46.293 35.482 33.401c3.212-3.548 5.185-8.238 5.185-13.401 0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20c5.163 0 9.853-1.973 13.401-5.185L46.96 47.707l1.414-1.414zM2.718 21h1.949v-2H2.718A17.896 17.896 0 0 1 7.272 8.019l1.375 1.375 1.414-1.414-1.375-1.375a17.896 17.896 0 0 1 10.981-4.554V4h2V2.051a17.896 17.896 0 0 1 10.981 4.554L31.273 7.98l1.414 1.414 1.375-1.375A17.9 17.9 0 0 1 38.616 19h-1.949v2h1.949a17.896 17.896 0 0 1-4.554 10.981l-1.375-1.375-1.414 1.414 1.375 1.375a17.9 17.9 0 0 1-10.981 4.554V36h-2v1.949a17.896 17.896 0 0 1-10.981-4.554l1.375-1.375-1.414-1.414-1.375 1.375A17.896 17.896 0 0 1 2.718 21z"
        fill={htmlColor}
      />
      <path
        d="M20.667 24a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
        fill={htmlColor}
      />
      <path
        d="M20.667 29.245c5.753 0 10.777-3.311 13.49-8.24a2.076 2.076 0 0 0 0-2.01c-2.713-4.93-7.737-8.24-13.49-8.24s-10.777 3.31-13.49 8.24a2.076 2.076 0 0 0 0 2.01c2.713 4.929 7.737 8.24 13.49 8.24zM8.93 19.959c2.446-4.444 6.943-7.205 11.737-7.205 4.794 0 9.292 2.76 11.737 7.205a.079.079 0 0 1 0 .08c-2.446 4.445-6.943 7.205-11.737 7.205-4.794 0-9.292-2.761-11.737-7.204a.074.074 0 0 1 0-.081z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MapMarkIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0a5.988 5.988 0 0 0-5.988 5.988c0 3.471 4.038 8.206 5.482 9.788a.683.683 0 0 0 1.011 0c1.444-1.582 5.483-6.317 5.483-9.788A5.988 5.988 0 0 0 8 0zm0 14.342C5.668 11.68 3.345 8.24 3.345 5.988A4.66 4.66 0 0 1 8 1.333a4.66 4.66 0 0 1 4.654 4.655c0 2.252-2.322 5.693-4.654 8.354z"
        fill={htmlColor}
      />
      <path
        d="M8 3.333a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.333zm0 4A1.335 1.335 0 0 1 6.666 6a1.335 1.335 0 0 1 2.667 0c0 .736-.598 1.333-1.333 1.333z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MapSpotIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 7.333h-1.367a6.678 6.678 0 0 0-5.966-5.966V0H7.333v1.367a6.678 6.678 0 0 0-5.966 5.966H0v1.334h1.367a6.679 6.679 0 0 0 5.966 5.966V16h1.334v-1.367a6.679 6.679 0 0 0 5.966-5.966H16V7.333zm-7.333 5.954v-1.954H7.333v1.954a5.338 5.338 0 0 1-4.62-4.62h1.954V7.333H2.713a5.338 5.338 0 0 1 4.62-4.62v1.954h1.334V2.713a5.338 5.338 0 0 1 4.62 4.62h-1.954v1.334h1.954a5.338 5.338 0 0 1-4.62 4.62z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MaskIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44,15h-6.135C33.849,11.289,25.437,9,24,9c-1.513,0-9.886,2.289-13.879,6.001c0,0,0,0,0.001-0.001H4c-2.2,0-4,1.8-4,4v4
		c0,2.2,1.8,4,4,4h5.055c0-0.001,0-0.001,0-0.002C11.19,34.419,16.482,39,24,39s12.81-4.581,14.946-12.002c0,0.001,0,0.001,0,0.002
		H44c2.2,0,4-1.8,4-4v-4C48,16.8,46.2,15,44,15z M38.356,15.486c-0.003-0.003-0.006-0.007-0.009-0.01
		C38.35,15.479,38.353,15.482,38.356,15.486z M9.643,15.475c-0.004,0.004-0.007,0.008-0.011,0.012
		C9.636,15.483,9.639,15.479,9.643,15.475z M8.573,25H4c-1.103,0-2-0.897-2-2v-4c0-1.103,0.897-2,2-2h4.53
		C8.195,17.638,8,18.305,8,19C8,21.137,8.201,23.14,8.573,25C8.573,25,8.573,25,8.573,25z M24,37c-8.635,0-14-6.897-14-18
		c0-1.609,2.246-3.626,6.008-5.397c3.672-1.728,7.345-2.576,7.99-2.603C26.072,11.095,38,15.028,38,19C38,30.103,32.635,37,24,37z
		 M46,23c0,1.103-0.897,2-2,2h-4.573c0,0,0,0,0,0C39.799,23.14,40,21.137,40,19c0-0.695-0.196-1.362-0.533-2H44c1.103,0,2,0.897,2,2
		V23z M15.884,19.745l0.231,1.986c2.397-0.279,5.097-0.303,7.884-0.303s5.487,0.023,7.884,0.303l0.231-1.986
		c-2.503-0.292-5.267-0.316-8.116-0.316S18.387,19.453,15.884,19.745z"
        fill={htmlColor}
      />
      <path
        d="M24,25.583c-2.787,0-5.487-0.023-7.884-0.303l-0.231,1.986c2.503,0.292,5.267,0.316,8.116,0.316s5.613-0.024,8.116-0.316
		l-0.231-1.986C29.487,25.56,26.787,25.583,24,25.583z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const NewsletterIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={htmlColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.667 2H1.333C.597 2 0 2.597 0 3.333v9.334C0 13.403.597 14 1.333 14h13.334c.736 0 1.333-.597 1.333-1.333V3.333C16 2.597 15.403 2 14.667 2zm-.92 1.333L8.443 8.048a.667.667 0 0 1-.886 0L2.253 3.333h11.494zM1.333 12.667V4.299l5.338 4.745a1.995 1.995 0 0 0 2.658 0L14.667 4.3v8.368H1.333z"
          fill="#fff"
        />
      </svg>
    </IconWrapper>
  )
}

// O
export const OakleyGlassIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
		c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M45.781,15.272c-1.986,7.559-6.295,14.795-12.135,20.376
		C28.093,40.956,21.861,44,16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124
		C3.924,12.529,6.82,5.852,14.52,4.664C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72
		C45.927,11.264,46.281,13.081,45.781,15.272z M20.927,8.266c-0.56-0.18-1.138-0.225-1.721-0.221c-0.61,0.005-1.214,0.079-1.817,0.172
		c-0.701,0.108-1.391,0.261-2.071,0.459c-0.205,0.06-0.41,0.118-0.614,0.178c-0.43,0.149-0.86,0.298-1.291,0.447
		c-0.019,0.011-0.037,0.024-0.058,0.033c-0.425,0.185-0.854,0.361-1.274,0.555c-0.592,0.273-1.163,0.586-1.713,0.936
		c-0.545,0.347-1.071,0.721-1.525,1.185c-0.292,0.299-0.556,0.618-0.716,1.011c-0.218,0.537-0.067,1.029,0.421,1.34
		c0.134,0.085,0.283,0.152,0.432,0.206c0.481,0.173,0.984,0.219,1.491,0.233c1.045,0.028,2.07-0.132,3.086-0.358
		c0.672-0.149,1.334-0.34,1.988-0.555c1.38-0.454,2.698-1.04,3.937-1.8c0.658-0.403,1.269-0.867,1.789-1.442
		c0.265-0.292,0.49-0.609,0.592-0.997c0.112-0.424,0.001-0.782-0.337-1.065C21.351,8.433,21.146,8.336,20.927,8.266z M19.132,10.723
		c-0.135,0.344-0.363,0.624-0.622,0.882c-0.549,0.548-1.196,0.953-1.893,1.283c-0.82,0.388-1.679,0.655-2.575,0.799
		c-0.632,0.101-1.266,0.14-1.902,0.033c-0.292-0.049-0.576-0.13-0.835-0.278c-0.532-0.303-0.709-0.827-0.466-1.39
		c0.182-0.422,0.475-0.76,0.82-1.055c0.729-0.624,1.551-1.088,2.455-1.408c0.095-0.034,0.191-0.066,0.287-0.099
		c0.654-0.22,1.321-0.385,2.011-0.442c0.576-0.048,1.151-0.053,1.713,0.105c0.172,0.048,0.343,0.112,0.501,0.195
		C19.168,9.632,19.357,10.153,19.132,10.723z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OfferIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.429,23.009l-5.243-3.045c-0.545-0.317-0.732-1.015-0.418-1.562l3.018-5.258c0.438-0.763-0.111-1.715-0.991-1.717
		l-6.063-0.016c-0.631-0.002-1.142-0.513-1.143-1.143l-0.016-6.063c-0.002-0.668-0.55-1.145-1.148-1.145
		c-0.19,0-0.385,0.048-0.569,0.154l-5.258,3.018c-0.18,0.103-0.376,0.152-0.57,0.152c-0.395,0-0.78-0.205-0.992-0.571l-3.045-5.243
		C24.77,0.19,24.385,0,24,0s-0.77,0.19-0.991,0.571l-3.045,5.243c-0.213,0.366-0.597,0.571-0.992,0.571
		c-0.194,0-0.39-0.049-0.57-0.152l-5.258-3.018C12.96,3.108,12.765,3.06,12.575,3.06c-0.598,0-1.146,0.477-1.148,1.145l-0.016,6.063
		c-0.002,0.631-0.513,1.142-1.143,1.143l-6.063,0.016c-0.88,0.002-1.429,0.954-0.991,1.717l3.018,5.258
		c0.314,0.547,0.127,1.245-0.418,1.562l-5.243,3.045c-0.761,0.442-0.761,1.541,0,1.982l5.243,3.045
		c0.545,0.317,0.732,1.015,0.418,1.562l-3.018,5.258c-0.438,0.763,0.111,1.715,0.991,1.717l6.063,0.016
		c0.631,0.002,1.142,0.513,1.143,1.143l0.016,6.063c0.002,0.668,0.55,1.145,1.148,1.145c0.19,0,0.385-0.048,0.569-0.154l5.258-3.018
		c0.18-0.103,0.376-0.152,0.57-0.152c0.395,0,0.78,0.205,0.992,0.571l3.045,5.243C23.23,47.81,23.615,48,24,48
		s0.77-0.19,0.991-0.571l3.045-5.243c0.213-0.366,0.597-0.571,0.992-0.571c0.194,0,0.39,0.049,0.57,0.152l5.258,3.018
		c0.184,0.106,0.379,0.154,0.569,0.154c0.598,0,1.146-0.477,1.148-1.145l0.016-6.063c0.002-0.631,0.513-1.142,1.143-1.143
		l6.063-0.016c0.88-0.002,1.429-0.954,0.991-1.717l-3.018-5.258c-0.314-0.547-0.127-1.245,0.418-1.562l5.243-3.045
		C48.19,24.549,48.19,23.451,47.429,23.009z M41.182,26.307c-1.493,0.867-2.008,2.79-1.149,4.287l2.286,3.984l-4.593,0.012
		c-1.726,0.004-3.134,1.412-3.138,3.138l-0.012,4.593l-3.984-2.286c-0.476-0.273-1.017-0.418-1.565-0.418
		c-1.118,0-2.161,0.6-2.722,1.566L24,45.154l-2.307-3.972c-0.561-0.966-1.604-1.566-2.722-1.566c-0.548,0-1.089,0.144-1.565,0.418
		l-3.983,2.286l-0.012-4.593c-0.004-1.726-1.412-3.134-3.138-3.138L5.68,34.577l2.286-3.983c0.859-1.497,0.344-3.42-1.149-4.287
		L2.846,24l3.972-2.307c1.493-0.867,2.008-2.79,1.149-4.287L5.68,13.423l4.593-0.012c1.726-0.004,3.134-1.412,3.138-3.138
		l0.012-4.593l3.984,2.286c0.476,0.273,1.017,0.418,1.565,0.418c1.118,0,2.161-0.6,2.722-1.566L24,2.846l2.307,3.972
		c0.561,0.966,1.604,1.566,2.722,1.566c0.548,0,1.089-0.144,1.565-0.418l3.983-2.286l0.012,4.593
		c0.004,1.726,1.412,3.134,3.138,3.138l4.593,0.012l-2.286,3.983c-0.859,1.497-0.344,3.42,1.149,4.287L45.154,24L41.182,26.307z M22,18c0-2.209-1.791-4-4-4s-4,1.791-4,4c0,2.209,1.791,4,4,4S22,20.209,22,18z M16,18c0-1.103,0.897-2,2-2s2,0.897,2,2
		s-0.897,2-2,2S16,19.103,16,18z M30,26c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4s4-1.791,4-4C34,27.791,32.209,26,30,26z M30,32c-1.103,0-2-0.897-2-2
		c0-1.103,0.897-2,2-2s2,0.897,2,2C32,31.103,31.103,32,30,32z M15.29,31.29l16,-16l1.41,1.41l-16,16Z"
        fill={htmlColor}
      />

      <path
        d="m31.293 15.293-16 16 1.414 1.414 16-16-1.415-1.414zM30 26a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM22 18a4 4 0 1 0-8 0 4 4 0 0 0 8 0zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OpticianIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 5.8c-1.414 0-2.64.481-3.25 1.185a1.993 1.993 0 0 0-2.165 0C6.307 6.281 5.082 5.8 3.667 5.8 1.642 5.8 0 6.785 0 8c0 1.215 1.642 2.2 3.667 2.2S7.333 9.215 7.333 8c0-.151-.025-.299-.074-.441a1.321 1.321 0 0 1 1.481.001 1.367 1.367 0 0 0-.073.44c0 1.215 1.641 2.2 3.666 2.2S16 9.215 16 8c0-1.215-1.642-2.2-3.667-2.2zM3.667 9.533c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533zm8.666 0c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const OptometristIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.334 24c6.617 0 12-5.383 12-12s-5.384-12-12-12c-6.617 0-12 5.383-12 12s5.383 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.487 10-10 10c-5.514 0-10-4.486-10-10s4.486-10 10-10zM34.334 26.067H32.05l-7.718 7.718-7.717-7.718h-2.282c-8.014 0-14 9.433-14 17.866V48h2v-4.066c0-6.205 3.754-13.242 9-15.272v5.44a4.999 4.999 0 0 0 1 9.898 5 5 0 0 0 1-9.9v-5.954a7.9 7.9 0 0 1 1-.078h1.454l8.546 8.545 8.546-8.546h1.453c.339 0 .67.037 1 .078v5.96a4.864 4.864 0 0 0-3.856 4.752V44h2v-5.143A2.86 2.86 0 0 1 36.334 36a2.86 2.86 0 0 1 2.856 2.857V44h2v-5.143a4.864 4.864 0 0 0-3.856-4.752V28.66c5.244 2.03 9 9.067 9 15.272V48h2v-4.066c0-8.434-5.988-17.867-14-17.867zM15.334 39c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const OvalGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 5.8c-1.414 0-2.64.481-3.25 1.185a1.993 1.993 0 0 0-2.165 0C6.307 6.281 5.082 5.8 3.667 5.8 1.642 5.8 0 6.785 0 8c0 1.215 1.642 2.2 3.667 2.2S7.333 9.215 7.333 8c0-.151-.025-.299-.074-.441a1.321 1.321 0 0 1 1.481.001 1.367 1.367 0 0 0-.073.44c0 1.215 1.641 2.2 3.666 2.2S16 9.215 16 8c0-1.215-1.642-2.2-3.667-2.2zM3.667 9.533c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533zm8.666 0c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

// P
export const PadlockIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M42 16h-8v-6c0-5.794-4.206-10-10-10S14 4.206 14 10v6H6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V18a2 2 0 0 0-2-2zm-26-6c0-4.71 3.29-8 8-8s8 3.29 8 8v6H16v-6zm26 36H6V18h36v28z"
        fill={htmlColor}
      />

      <path
        d="M24 36c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PilotDarkGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.581 20.523c-.915-3.487-3.238-5.759-6.393-6.235a33.355 33.355 0 0 0-4.187-.281V14h-26v.007c-1.453.006-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-25.584-1.169a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.121a5.628 5.628 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A5.97 5.97 0 0 0 24.001 19c-.69 0-1.365.127-2.004.354z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PilotGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.86 6.841c-.305-1.162-1.08-1.92-2.131-2.078a11.184 11.184 0 0 0-1.396-.094v-.002H3.666v.002c-.484.003-.964.037-1.402.095C1.22 4.922.444 5.679.14 6.842c-.393 1.498.08 3.228 1.099 4.024.396.31.875.466 1.398.466.312 0 .64-.056.975-.168 1.532-.514 2.992-2.113 3.58-3.884a1.367 1.367 0 0 1 1.615 0c.584 1.766 2.046 3.369 3.58 3.884.335.112.663.168.975.168.523 0 1.002-.157 1.398-.466 1.02-.796 1.492-2.526 1.1-4.025zM6.645 6.79c-.451 1.689-1.816 3.263-3.245 3.743-.48.161-1.173.258-1.75-.191C.84 9.709.46 8.247.784 7.01c.138-.525.526-1.429 1.573-1.587.451-.06.892-.089 1.309-.091h.138c1.244.012 2.26.27 2.668.722.186.207.24.443.172.734zm.687-.337a1.387 1.387 0 0 0-.365-.844 1.863 1.863 0 0 0-.32-.274h2.706a1.86 1.86 0 0 0-.32.274c-.22.243-.341.529-.365.844A1.99 1.99 0 0 0 8 6.334c-.23 0-.455.042-.668.118zm7.018 3.889c-.577.45-1.27.352-1.75.191-1.43-.48-2.794-2.054-3.243-3.733-.07-.301-.017-.537.17-.744.41-.452 1.426-.71 2.67-.721h.136c.416.002.854.03 1.302.089 1.054.16 1.442 1.063 1.58 1.588.324 1.235-.056 2.698-.865 3.33z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PilotGlassesMeterIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 8h16v2h2V4h-2v2H16V4h-2v6h2V8zM47.581 20.523c-.915-3.487-3.238-5.759-6.393-6.235a33.544 33.544 0 0 0-4.187-.281V14h-26v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073v.001c1.19.927 2.626 1.397 4.196 1.397.936 0 1.918-.167 2.924-.505 4.595-1.543 8.975-6.338 10.74-11.651 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.299 6.138 10.108 10.741 11.653 1.006.338 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.397v-.001c3.06-2.389 4.476-7.579 3.298-12.075zm-27.645-.156c-1.353 5.066-5.446 9.788-9.733 11.228-1.44.483-3.52.777-5.254-.575-2.426-1.894-3.564-6.281-2.592-9.989.413-1.574 1.577-4.285 4.72-4.76a31.574 31.574 0 0 1 3.925-.273V16h.44c3.721.037 6.755.813 7.978 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.121a5.628 5.628 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A5.97 5.97 0 0 0 24.001 19c-.69 0-1.365.127-2.004.354zM43.054 31.02c-1.734 1.353-3.814 1.059-5.254.575-4.287-1.439-8.38-6.161-9.726-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165h.41v-.001c1.246.007 2.56.091 3.906.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.166 8.095-2.592 9.989z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PayPalIcon: React.FC<CustomSVGIconProps> = ({
  width = 30,
  height = 20,
  ...rest
}) => {
  return (
    <PaymentIconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".876"
          y=".208"
          width="28.75"
          height="19.583"
          rx="2.292"
          fill="#fff"
          stroke="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m13.204 15.374.189-1.208-.422-.01h-2.014l1.4-8.911a.114.114 0 0 1 .114-.097h3.396c1.127 0 1.905.235 2.311.7a1.6 1.6 0 0 1 .371.697c.061.263.063.578.003.961l-.005.028v.246l.19.108c.161.086.288.183.386.295.163.187.268.424.313.705.046.289.03.633-.044 1.022a3.61 3.61 0 0 1-.418 1.157 2.373 2.373 0 0 1-.66.727c-.252.18-.552.316-.89.404a4.426 4.426 0 0 1-1.113.129h-.264a.8.8 0 0 0-.786.673l-.02.109-.335 2.129-.015.078c-.004.024-.01.037-.02.045a.056.056 0 0 1-.035.013h-1.632z"
          fill="#28356A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.92 7.563a5.96 5.96 0 0 1-.034.2c-.448 2.308-1.98 3.106-3.937 3.106h-.996a.484.484 0 0 0-.479.411l-.655 4.17a.256.256 0 0 0 .252.296h1.768c.209 0 .386-.153.42-.36l.017-.09.332-2.12.022-.117a.426.426 0 0 1 .42-.36h.264c1.712 0 3.053-.698 3.445-2.718.163-.844.079-1.549-.355-2.044a1.688 1.688 0 0 0-.483-.375z"
          fill="#298FC2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.45 7.375a3.513 3.513 0 0 0-.435-.097 5.519 5.519 0 0 0-.878-.065h-2.662a.421.421 0 0 0-.42.36l-.566 3.602-.016.105a.484.484 0 0 1 .478-.411h.996c1.957 0 3.49-.798 3.938-3.107.013-.068.024-.135.034-.2a2.378 2.378 0 0 0-.468-.187z"
          fill="#22284F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.055 7.574a.424.424 0 0 1 .42-.36h2.662c.315 0 .61.02.878.064a4.016 4.016 0 0 1 .436.097l.1.031c.133.044.255.096.369.156.133-.853-.002-1.434-.461-1.96-.507-.579-1.42-.827-2.59-.827h-3.397a.486.486 0 0 0-.48.412l-1.414 9.003a.293.293 0 0 0 .288.339h2.096l1.093-6.955z"
          fill="#28356A"
        />
      </svg>
    </PaymentIconWrapper>
  )
}

export const PaypalRegularIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width,
  ...rest
}) => {
  return (
    <IconWrapper
      {...rest}
      htmlColor={htmlColor}
      viewBox="0 0 566.93 137.35"
      width={width}
      fill="none"
    >
      <path
        fill="#019cdf"
        d="M192.95,386.87h38.74c20.8,0,28.63,10.53,27.42,26-2,25.54-17.44,39.67-37.92,39.67H210.85c-2.81,0-4.7,1.86-5.46,6.9L201,488.74c-0.29,1.9-1.29,3-2.79,3.15H173.87c-2.29,0-3.1-1.75-2.5-5.54l14.84-93.93C186.79,388.66,188.85,386.87,192.95,386.87Z"
        transform="translate(-143.48 -354.54)"
      />
      <path
        fill="#013088"
        d="M361.14,385.13c13.07,0,25.13,7.09,23.48,24.76-2,21-13.25,32.62-31,32.67H338.11c-2.23,0-3.31,1.82-3.89,5.55l-3,19.07c-0.45,2.88-1.93,4.3-4.11,4.3H312.68c-2.3,0-3.1-1.47-2.59-4.76L322,390.29c0.59-3.76,2-5.16,4.57-5.16h34.54Zm-23.5,40.92h11.75c7.35-.28,12.23-5.37,12.72-14.55,0.3-5.67-3.53-9.73-9.62-9.7l-11.06.05-3.79,24.2h0Zm86.21,39.58c1.32-1.2,2.66-1.82,2.47-.34l-0.47,3.54c-0.24,1.85.49,2.83,2.21,2.83h12.82c2.16,0,3.21-.87,3.74-4.21l7.9-49.58c0.4-2.49-.21-3.71-2.1-3.71H436.32c-1.27,0-1.89.71-2.22,2.65l-0.52,3.05c-0.27,1.59-1,1.87-1.68.27-2.39-5.66-8.49-8.2-17-8-19.77.41-33.1,15.42-34.53,34.66-1.1,14.88,9.56,26.57,23.62,26.57,10.2,0,14.76-3,19.9-7.7h0ZM413.11,458c-8.51,0-14.44-6.79-13.21-15.11s9.19-15.11,17.7-15.11,14.44,6.79,13.21,15.11S421.63,458,413.11,458h0Zm64.5-44h-13c-2.68,0-3.77,2-2.92,4.46l16.14,47.26L462,488.21c-1.33,1.88-.3,3.59,1.57,3.59h14.61a4.47,4.47,0,0,0,4.34-2.13l49.64-71.2c1.53-2.19.81-4.49-1.7-4.49H516.63c-2.37,0-3.32.94-4.68,2.91l-20.7,30L482,416.82C481.46,415,480.11,414,477.62,414Z"
        transform="translate(-143.48 -354.54)"
      />
      <path
        fill="#019cdf"
        d="M583.8,385.13c13.07,0,25.13,7.09,23.48,24.76-2,21-13.25,32.62-31,32.67H560.78c-2.23,0-3.31,1.82-3.89,5.55l-3,19.07c-0.45,2.88-1.93,4.3-4.11,4.3H535.35c-2.3,0-3.1-1.47-2.59-4.76l11.93-76.45c0.59-3.76,2-5.16,4.57-5.16H583.8Zm-23.5,40.92h11.75c7.35-.28,12.23-5.37,12.72-14.55,0.3-5.67-3.53-9.73-9.62-9.7l-11.06.05-3.79,24.2h0Zm86.21,39.58c1.32-1.2,2.66-1.82,2.47-.34l-0.47,3.54c-0.24,1.85.49,2.83,2.21,2.83h12.82c2.16,0,3.21-.87,3.74-4.21l7.9-49.58c0.4-2.49-.21-3.71-2.1-3.71H659c-1.27,0-1.89.71-2.22,2.65l-0.52,3.05c-0.27,1.59-1,1.87-1.68.27-2.39-5.66-8.49-8.2-17-8-19.77.41-33.1,15.42-34.53,34.66-1.1,14.88,9.56,26.57,23.62,26.57,10.2,0,14.76-3,19.9-7.7h0ZM635.78,458c-8.51,0-14.44-6.79-13.21-15.11s9.19-15.11,17.7-15.11,14.44,6.79,13.21,15.11S644.29,458,635.78,458h0Zm59.13,13.74h-14.8a1.75,1.75,0,0,1-1.81-2l13-82.36a2.55,2.55,0,0,1,2.46-2h14.8a1.75,1.75,0,0,1,1.81,2l-13,82.36A2.55,2.55,0,0,1,694.91,471.76Z"
        transform="translate(-143.48 -354.54)"
      />
      <path
        fill="#013088"
        d="M168.72,354.54h38.78c10.92,0,23.88.35,32.54,8,5.79,5.11,8.83,13.24,8.13,22-2.38,29.61-20.09,46.2-43.85,46.2H185.2c-3.26,0-5.41,2.16-6.33,8l-5.34,34c-0.35,2.2-1.3,3.5-3,3.66H146.6c-2.65,0-3.59-2-2.9-6.42L160.9,361C161.59,356.62,164,354.54,168.72,354.54Z"
        transform="translate(-143.48 -354.54)"
      />
      <path
        fill="#012269"
        d="M179.43,435.29l6.77-42.87c0.59-3.76,2.65-5.56,6.75-5.56h38.74c6.41,0,11.6,1,15.66,2.85-3.89,26.36-20.94,41-43.26,41H185C182.44,430.72,180.56,432,179.43,435.29Z"
        transform="translate(-143.48 -354.54)"
      />
    </IconWrapper>
  )
}

export const PrescriptionIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M11.992 16h1.332v-2.666h2.665V12h-2.665V9.334h-1.332V12H9.327v1.334h2.665V16zM9.327 20h13.324v1.333H9.327zM9.327 25.334h9.327v1.333H9.327z"
        fill={htmlColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.59 9.333 18.654.391A1.323 1.323 0 0 0 17.712 0H5.33c-.735 0-1.333.598-1.333 1.333v29.334c0 .735.598 1.333 1.333 1.333h21.318c.735 0 1.333-.598 1.333-1.333V10.276c0-.356-.139-.69-.39-.943zm-8.936-7.057 7.052 7.057h-7.052V2.276zM5.33 1.333v29.334h21.319v-20h-8.661A.668.668 0 0 1 17.32 10V1.333H5.33z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ProfileIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 8.667A4.333 4.333 0 1 0 8 0a4.333 4.333 0 0 0 0 8.667zm0-7.334c1.655 0 3 1.346 3 3 0 1.655-1.345 3-3 3-1.654 0-3-1.345-3-3 0-1.654 1.346-3 3-3zM11.478 10H4.522C2.024 10 0 12.328 0 15.2v.133c0 .369.298.667.667.667h14.666a.666.666 0 0 0 .667-.667V15.2c0-2.872-2.024-5.2-4.522-5.2zM1.364 14.667c.215-1.881 1.549-3.334 3.158-3.334h6.957c1.609 0 2.943 1.453 3.158 3.334H1.364z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PlpFullWidthListingIcon = ({
  htmlColor = '#D8D8D8',
  width = 24,
  height = 24,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M23.938 0v15H0V0h23.938zM0 19.2h24V24H0v-4.8z"
        fill={`${htmlColor}`}
      />
    </IconWrapper>
  )
}

export const PlpCompactWidthListingIcon = ({
  htmlColor = '#00303C',
  width = 24,
  height = 24,
  ...rest
}: CustomSVGIconProps) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M9.6 0v9.6H0V0h9.6zM24 0v9.545h-9.6V0H24zM0 14.4h9.545V24H0v-9.6zM14.455 14.438h9.504v9.5h-9.504v-9.5z"
        fill={`${htmlColor}`}
      />
    </IconWrapper>
  )
}
export const PasswordShowVisibleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M 8.208 4.788 c -1.8858 0 -3.42 1.5342 -3.42 3.42 s 1.5342 3.42 3.42 3.42 s 3.42 -1.5342 3.42 -3.42 s -1.5342 -3.42 -3.42 -3.42 z m 0 6.156 c -1.5086 0 -2.736 -1.2274 -2.736 -2.736 s 1.2274 -2.736 2.736 -2.736 s 2.736 1.2274 2.736 2.736 s -1.2274 2.736 -2.736 2.736 z M 8.208 8.892 a 0.684 0.684 90 1 0 0 -1.368 a 0.684 0.684 90 0 0 0 1.368 z M 16.3411 7.8944 C 14.7751 4.8226 11.721 2.736 8.208 2.736 C 4.695 2.736 1.6409 4.8226 0.0749 7.8944 a 0.6946 0.6946 90 0 0 0 0.6276 C 1.6409 11.5934 4.695 13.68 8.208 13.68 c 3.513 0 6.5671 -2.0866 8.1331 -5.1584 a 0.6943 0.6943 90 0 0 0 -0.6272 z M 8.208 12.996 c -3.1361 0 -6.0192 -1.8335 -7.5236 -4.791 C 2.1888 5.2535 5.0719 3.42 8.208 3.42 c 3.1361 0 6.0192 1.8335 7.5236 4.791 C 14.2272 11.1625 11.3441 12.996 8.208 12.996 z"
        fill={htmlColor}
        stroke={htmlColor}
      />
    </IconWrapper>
  )
}
export const PasswordHideIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 4c2.696 0 5.19 1.525 6.579 4a8.114 8.114 0 0 1-1.763 2.166l.945.945a9.536 9.536 0 0 0 2.153-2.778.73.73 0 0 0 0-.665c-1.53-2.98-4.5-5.001-7.914-5.001-.813 0-1.6.118-2.35.333l1.112 1.113A7.14 7.14 0 0 1 8 4zM1.138.195.342.992l-.147.146 3.006 3.006A9.426 9.426 0 0 0 .077 7.687c-.1.195-.1.43 0 .626 1.527 2.99 4.502 5.02 7.923 5.02a8.517 8.517 0 0 0 3.596-.794l3.266 3.266.943-.943-.167-.167-14.5-14.5zM8 12c-2.697 0-5.19-1.524-6.579-4a8 8 0 0 1 2.744-2.892l2.032 2.031A2 2 0 0 0 8.86 9.803l1.714 1.714A7.175 7.175 0 0 1 8 12z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PlayIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <mask id="play-icon" fill={htmlColor}>
        <path d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 15.333C3.956 15.333.667 12.043.667 8 .667 3.956 3.957.667 8 .667c4.044 0 7.333 3.29 7.333 7.333 0 4.044-3.29 7.333-7.333 7.333z" />
      </mask>
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 15.333C3.956 15.333.667 12.043.667 8 .667 3.956 3.957.667 8 .667c4.044 0 7.333 3.29 7.333 7.333 0 4.044-3.29 7.333-7.333 7.333z"
        fill={htmlColor}
      />
      <path
        d="M8-1C3.037-1-1 3.037-1 8h2c0-3.859 3.141-7 7-7v-2zm-9 9c0 4.963 4.037 9 9 9v-2c-3.859 0-7-3.141-7-7h-2zm9 9c4.963 0 9-4.037 9-9h-2c0 3.859-3.141 7-7 7v2zm9-9c0-4.963-4.037-9-9-9v2c3.859 0 7 3.141 7 7h2zm-9 6.333A6.342 6.342 0 0 1 1.667 8h-2c0 4.596 3.737 8.333 8.333 8.333v-2zM1.667 8A6.342 6.342 0 0 1 8 1.667v-2C3.404-.333-.333 3.404-.333 8h2zM8 1.667A6.342 6.342 0 0 1 14.333 8h2c0-4.596-3.737-8.333-8.333-8.333v2zM14.333 8A6.341 6.341 0 0 1 8 14.333v2c4.596 0 8.333-3.737 8.333-8.333h-2z"
        fill={htmlColor}
        mask="url(#play-icon)"
      />
      <path
        d="M11.096 7.435 6.354 4.471a.667.667 0 0 0-1.02.565v5.928a.668.668 0 0 0 1.02.566l4.742-2.964a.666.666 0 0 0 0-1.13zm-5.095 3.53.002-5.927 4.74 2.963L6 10.964z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PanthosGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 5.333c-1.851 0-3.006.521-3.453 1.55a2.084 2.084 0 0 0-1.76 0c-.447-1.029-1.602-1.55-3.453-1.55C1.234 5.333 0 6.23 0 8c0 2.261 1.405 3.666 3.667 3.666A3.671 3.671 0 0 0 7.333 8c0-.162-.014-.315-.034-.462a1.378 1.378 0 0 1 1.402 0c-.02.147-.034.3-.034.462a3.671 3.671 0 0 0 3.666 3.666C14.595 11.666 16 10.261 16 8c0-1.77-1.234-2.667-3.667-2.667zM3.667 11c-2.708 0-3-2.098-3-3 0-.931.34-2 3-2 2.659 0 3 1.069 3 2 0 1.654-1.346 3-3 3zm8.666 0c-1.654 0-3-1.346-3-3 0-.931.341-2 3-2 2.66 0 3 1.069 3 2 0 .902-.292 3-3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PauseIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <mask id="pause-icon" fill={htmlColor}>
        <path d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 15.333C3.956 15.333.667 12.043.667 8 .667 3.956 3.957.667 8 .667c4.044 0 7.333 3.29 7.333 7.333 0 4.044-3.29 7.333-7.333 7.333z" />
      </mask>
      <path
        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 15.333C3.956 15.333.667 12.043.667 8 .667 3.956 3.957.667 8 .667c4.044 0 7.333 3.29 7.333 7.333 0 4.044-3.29 7.333-7.333 7.333z"
        fill={htmlColor}
      />
      <path
        d="M8-1C3.037-1-1 3.037-1 8h2c0-3.859 3.141-7 7-7v-2zm-9 9c0 4.963 4.037 9 9 9v-2c-3.859 0-7-3.141-7-7h-2zm9 9c4.963 0 9-4.037 9-9h-2c0 3.859-3.141 7-7 7v2zm9-9c0-4.963-4.037-9-9-9v2c3.859 0 7 3.141 7 7h2zm-9 6.333A6.342 6.342 0 0 1 1.667 8h-2c0 4.596 3.737 8.333 8.333 8.333v-2zM1.667 8A6.342 6.342 0 0 1 8 1.667v-2C3.404-.333-.333 3.404-.333 8h2zM8 1.667A6.342 6.342 0 0 1 14.333 8h2c0-4.596-3.737-8.333-8.333-8.333v2zM14.333 8A6.341 6.341 0 0 1 8 14.333v2c4.596 0 8.333-3.737 8.333-8.333h-2z"
        fill={htmlColor}
        mask="url(#pause-icon)"
      />
      <path
        d="M6.333 4.666h-.667v6.667h.667V4.666zM10.333 4.666h-.667v6.667h.667V4.666z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PillowGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 5.333c-2.077 0-3.034.002-3.428.887a2.05 2.05 0 0 0-1.81 0c-.394-.885-1.351-.887-3.428-.887C.667 5.333 0 5.333 0 8c0 2.666.667 2.666 3.667 2.666s3.666 0 3.666-2.666c0-.441-.02-.803-.064-1.11a1.351 1.351 0 0 1 1.462 0A7.914 7.914 0 0 0 8.667 8c0 2.666.666 2.666 3.666 2.666S16 10.666 16 8c0-2.667-.667-2.667-3.667-2.667zM6.276 9.765C5.895 10 4.96 10 3.666 10c-1.293 0-2.227 0-2.608-.235C.855 9.64.667 9.34.667 8c0-1.34.188-1.641.39-1.766C1.44 6 2.374 6 3.668 6c1.293 0 2.228 0 2.609.234.203.125.39.425.39 1.766 0 1.34-.187 1.64-.39 1.765zm8.666 0c-.38.235-1.315.235-2.609.235-1.293 0-2.228 0-2.609-.235-.203-.125-.39-.425-.39-1.765 0-1.34.187-1.641.39-1.766C10.105 6 11.04 6 12.334 6c1.293 0 2.227 0 2.608.234.203.125.391.425.391 1.766 0 1.34-.188 1.64-.39 1.765z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

//Q
export const QuestionMarkIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 0a7.98 7.98 0 0 0-8 8c0 4.433 3.567 8 8 8s8-3.567 8-8-3.567-8-8-8zm0 14.667c-3.667 0-6.667-3-6.667-6.667s3-6.667 6.667-6.667 6.667 3 6.667 6.667-3 6.667-6.667 6.667z"
        fill={htmlColor}
      />
      <path
        d="M8.666 11.666H7.333V13h1.333v-1.334zM8.667 3.067c-1-.2-2 .066-2.767.7a3.237 3.237 0 0 0-1.233 2.566H6c0-.6.267-1.166.734-1.533A1.884 1.884 0 0 1 8.4 4.4a2.04 2.04 0 0 1 1.567 1.567c.2.933-.3 1.866-1.167 2.233-.9.367-1.466 1.2-1.466 2.133h1.333c0-.4.233-.733.667-.933A3.345 3.345 0 0 0 11.3 5.667c-.3-1.3-1.333-2.334-2.633-2.6z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

// R
export const RayBanGlassIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
		c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M45.781,15.272c-1.986,7.559-6.295,14.795-12.135,20.376
		C28.093,40.956,21.861,44,16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124
		C3.924,12.529,6.82,5.852,14.52,4.664C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72
		C45.927,11.264,46.281,13.081,45.781,15.272z M23.968,8.664c-0.17,0-0.308,0.137-0.308,0.306c0,0.17,0.137,0.308,0.308,0.308c0.169,0,0.306-0.137,0.306-0.308
		C24.274,8.801,24.137,8.664,23.968,8.664z M16.366,12.164c-0.234,0.135-0.343,0.389-0.251,0.548c0.092,0.16,0.371,0.187,0.606,0.051
		c0.234-0.135,0.336-0.382,0.244-0.542C16.872,12.062,16.6,12.029,16.366,12.164z M15.417,12.282l-4.062,1.456l0.003-0.279c0.001-0.038,0.008-0.077,0.058-0.113c0.214-0.136,0.876-0.503,1.56-1.501
		c0.911-1.33,0-2.447-1.424-2.143c-1.264,0.27-2.552,0.955-3.11,1.327C7.945,11.361,8.014,11.762,8,12.122
		c0.028,0.152,0.083,0.138,0.207-0.014c0.926-0.857,2.336-1.507,2.599-1.617c0.263-0.111,0.885-0.484,1.617-0.318
		c0.704,0.159,0.533,1.116,0.318,1.521c-0.235,0.442-0.905,1.085-1.21,1.244c-0.116,0.06-0.159,0-0.159-0.097l0.048-2.08
		c0-0.138-0.062-0.2-0.2-0.152L11,10.719c-0.152,0.09-0.145,0.124-0.159,0.235l-0.034,2.398c-0.001,0.052-0.025,0.093-0.054,0.106
		l-0.721,0.406c-0.166,0.083-0.124,0.207,0,0.249l0.581,0.276c0.138,0.055,0.18,0.097,0.18,0.235l-0.021,1.161
		c0.518-0.104,0.553-0.352,0.567-0.518v-0.352c0.014-0.083,0.062-0.111,0.131-0.097l1.686,0.816
		c0.152,0.069,0.235,0.041,0.346-0.152c0.111-0.194,0.124-0.29-0.083-0.387l-1.219-0.595l3.556-1.275L15.417,12.282z M23.287,9.785l-4.27,1.531c-0.142-0.114-0.285-0.234-0.354-0.315c-0.111-0.13-0.035-0.228,0.035-0.283
		c0.415-0.276,1.064-0.705,1.32-1.368c0.247-0.64-0.138-1.196-0.415-1.286c-0.379-0.123-1.182,0.124-1.583,0.871
		c-0.26,0.484-0.38,0.643-0.415,1.956c-0.007,0.249-0.014,0.532-0.014,0.843c0,0.311-0.035,1.154-0.069,1.313
		c-0.131,0.373,0.263,0.152,0.442,0.007c0.18-0.145,0.166-0.363,0.166-0.456c0-0.118,0.007-2.191,0.069-2.654
		c0.039-0.288,0.083-0.822,0.387-1.286c0.304-0.463,0.615-0.276,0.698-0.2c0.083,0.076,0.2,0.27,0.138,0.664
		c-0.097,0.611-0.802,1.279-0.981,1.486c-0.18,0.207-0.138,0.387-0.035,0.532c0.074,0.103,0.256,0.304,0.442,0.491
		s0.166,0.352,0.138,0.477c-0.097,0.373-1.106,1.382-1.106,1.382c-0.214,0.228,0.007,0.601,0.083,0.726
		c0.076,0.124,0.173,0.083,0.235-0.021c0.249-0.463,1.32-1.583,1.32-1.583c0.13-0.165,0.169-0.324,0.166-0.473l3.939-1.412
		L23.287,9.785z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RectangleGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.333 5.8h-6a.666.666 0 0 0-.666.666v.322a2.058 2.058 0 0 0-1.334 0v-.322a.666.666 0 0 0-.666-.666h-6A.666.666 0 0 0 0 6.466v3.067c0 .368.298.667.667.667h6a.666.666 0 0 0 .666-.667V7.516a1.358 1.358 0 0 1 1.334 0v2.017c0 .368.298.667.666.667h6A.666.666 0 0 0 16 9.533V6.466a.666.666 0 0 0-.667-.666zM6.667 9.533h-6V6.466h6v3.067zm8.666 0h-6V6.466h6v3.067z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RetinalScreeningIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44.435 22.989c-.508-.782-1.557-1.689-3.685-1.927l.242-1.939v-.124H41c0-.031-.007-.057-.007-.087L41 17c0-4.206-1.544-8.053-4.084-11.024A6.978 6.978 0 0 0 39 1V0H21a5.006 5.006 0 0 0-4.904 4.046C11.005 4.505 7 8.791 7 14v3H4v-3H2v3H0v19h2v12h2V36h6.108a14.023 14.023 0 0 0 1.956 2.732l6.17 6.731A7.845 7.845 0 0 0 24 48a7.844 7.844 0 0 0 5.765-2.536l6.17-6.731a13.906 13.906 0 0 0 3.411-6.749c.1.006.198.016.299.016 2.687 0 3.745-2.423 4.679-4.561l.235-.533c.625-1.407.58-2.834-.124-3.917zM34.46 37.381l-6.17 6.731A5.835 5.835 0 0 1 24 46a5.835 5.835 0 0 1-4.29-1.888l-6.17-6.731c-.4-.436-.761-.899-1.089-1.381H20c3.309 0 6-2.691 6-6v-7c0-3.309-2.691-6-6-6h-5.702c2.799-1.274 3.966-3.547 4.424-5.519C20.522 13.08 24.116 15 31 15c7.029 0 7.982 2.1 7.998 3.951l-1.476 11.81a11.932 11.932 0 0 1-3.062 6.62zM2 19h18c2.206 0 4 1.794 4 4v7c0 2.206-1.794 4-4 4H2V19zM36.899 2c-.035.17-.095.33-.146.494-.16.509-.397.979-.701 1.403C35.144 5.163 33.673 6 32 6v2a6.953 6.953 0 0 0 3.302-.831 14.93 14.93 0 0 1 3.5 7.517C36.381 13.011 32.616 13 31 13c-10.052 0-12.058-4.341-12.071-4.371L17 9c0 3.193-1.388 7-8 7v-2c0-4.411 3.589-8 8-8h1V5c0-1.654 1.346-3 3-3h15.899zm5.832 24.094-.24.545C41.581 28.723 40.956 30 39.645 30l-.012-.001.869-6.951c1.102.119 1.893.472 2.257 1.031.448.691.175 1.559-.028 2.015z"
        fill={htmlColor}
      />

      <path
        d="M19 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM16 31a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM13 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ReplaceIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M32 37h9.741C37.602 42.664 31.1 46 24 46 11.869 46 2 36.131 2 24H0c0 13.233 10.767 24 24 24 7.547 0 14.473-3.456 19-9.348V48h2V35H32v2zM24 0C16.453 0 9.528 3.456 5 9.348V0H3v13h13v-2H6.258C10.398 5.336 16.9 2 24 2c12.131 0 22 9.869 22 22h2C48 10.767 37.233 0 24 0z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const RoundGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 4.333a3.672 3.672 0 0 0-3.486 2.535 2.083 2.083 0 0 0-1.694 0 3.672 3.672 0 0 0-3.486-2.535A3.671 3.671 0 0 0 0 8a3.671 3.671 0 0 0 3.667 3.666A3.671 3.671 0 0 0 7.333 8c0-.157-.013-.311-.032-.463a1.378 1.378 0 0 1 1.398 0A3.724 3.724 0 0 0 8.667 8a3.671 3.671 0 0 0 3.666 3.666A3.671 3.671 0 0 0 16 8a3.671 3.671 0 0 0-3.667-3.667zM3.667 11c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3s3 1.345 3 3c0 1.654-1.346 3-3 3zm8.666 0c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3s3 1.345 3 3c0 1.654-1.346 3-3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const RoundWarningIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#xrqi5uq57a)" fill={htmlColor}>
        <path d="M12 24c6.617 0 12-5.383 12-12S18.617 0 12 0 0 5.383 0 12s5.383 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2z" />
        <path d="M11 14h2V6h-2v8zM11 18h2v-2h-2v2z" />
      </g>
      <defs>
        <clipPath id="xrqi5uq57a">
          <path
            fill={htmlColor}
            transform="rotate(-180 12 12)"
            d="M0 0h24v24H0z"
          />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const RoundWarningSmallIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#6bz14j603a)" fill={htmlColor}>
        <path d="M8 16c4.411 0 8-3.589 8-8s-3.589-8-8-8-8 3.589-8 8 3.589 8 8 8zM8 1.333A6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667 6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333z" />
        <path d="M7.333 9.334h1.333V4H7.333v5.334zM7.333 12h1.333v-1.333H7.333V12z" />
      </g>
      <defs>
        <clipPath id="6bz14j603a">
          <path fill="#fff" transform="rotate(-180 8 8)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const RemoveCircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.822 0 16 0zm0 30.667c-8.087 0-14.667-6.58-14.667-14.667S7.913 1.333 16 1.333 30.667 7.913 30.667 16 24.087 30.667 16 30.667z"
        fill={htmlColor}
      />
      <path d="M22.665 15.333H9.332v1.334h13.333v-1.334z" fill={htmlColor} />
    </IconWrapper>
  )
}

// S
export const SearchIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m15.805 14.862-3.977-3.977a6.666 6.666 0 1 0-.943.943l3.977 3.977.943-.943zM6.667 12a5.34 5.34 0 0 1-5.334-5.333 5.34 5.34 0 0 1 5.334-5.334A5.34 5.34 0 0 1 12 6.667 5.34 5.34 0 0 1 6.667 12z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ShoppingIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M28.03 8.553a1.326 1.326 0 0 0-1.328-1.22H22.65V6c0-3.308-2.69-6-5.995-6a6.005 6.005 0 0 0-5.996 6v1.333H6.607c-.697 0-1.268.524-1.328 1.22L3.456 29.829a2.006 2.006 0 0 0 .52 1.524c.377.411.914.647 1.472.647h22.413a2.003 2.003 0 0 0 1.992-2.17L28.03 8.552zM11.991 6a4.67 4.67 0 0 1 4.663-4.667A4.67 4.67 0 0 1 21.319 6v1.333h-9.327V6zM6.607 8.667h4.052V12h1.332V8.667h9.327V12h1.332V8.667h4.052L28.186 26H5.123L6.607 8.667zM28.352 30.45a.66.66 0 0 1-.491.216H5.448a.66.66 0 0 1-.49-.216.66.66 0 0 1-.173-.508l.223-2.61h23.293l.224 2.61a.659.659 0 0 1-.173.508z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
export const SlashSlimIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#000000',
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
    >
      <path
        fill={htmlColor}
        fillRule="evenodd"
        d="m 14 10 L 10 16 L 9 16 l 4 -6 Z"
        clipRule="evenodd"
      />
    </IconWrapper>
  )
}

export const SmoothRectangleGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M37 16c-6.232 0-9.104.005-10.284 2.66a6.152 6.152 0 0 0-5.433-.001C20.104 16.005 17.231 16 11 16c-9 0-11 0-11 8s2 8 11 8 11 0 11-8c0-1.322-.062-2.408-.194-3.329a4.054 4.054 0 0 1 4.388 0C26.062 21.592 26 22.678 26 24c0 8 2 8 11 8s11 0 11-8-2-8-11-8zM18.827 29.297C17.684 30 14.88 30 11 30s-6.684 0-7.827-.703C2.564 28.922 2 28.021 2 24s.564-4.922 1.173-5.297C4.316 18 7.12 18 11 18s6.684 0 7.827.703C19.436 19.078 20 19.979 20 24s-.564 4.922-1.173 5.297zm26 0C43.684 30 40.88 30 37 30s-6.684 0-7.827-.703C28.564 28.922 28 28.021 28 24s.564-4.922 1.173-5.297C30.316 18 33.12 18 37 18s6.684 0 7.827.703C45.436 19.078 46 19.979 46 24s-.564 4.922-1.173 5.297z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const SoundOnIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <mask id="sound-on-icon" fill={htmlColor}>
        <path d="M10.846 6.82c.314.316.488.735.488 1.18 0 .445-.174.863-.488 1.178l.471.472C11.757 9.209 12 8.623 12 8a2.32 2.32 0 0 0-.683-1.65l-.471.47z" />
      </mask>
      <path
        d="M10.846 6.82c.314.316.488.735.488 1.18 0 .445-.174.863-.488 1.178l.471.472C11.757 9.209 12 8.623 12 8a2.32 2.32 0 0 0-.683-1.65l-.471.47z"
        fill={htmlColor}
      />
      <path
        d="m10.846 6.82-.707-.706-.707.707.706.707.708-.707zm0 2.358-.708-.706-.706.707.707.706.707-.707zm.471.472-.707.707.707.707.707-.708-.707-.706zm0-3.3.707-.707-.707-.708-.707.708.707.707zm-1.179 1.178a.657.657 0 0 1 .196.472h2c0-.71-.279-1.383-.78-1.886l-1.416 1.414zm.196.472c0 .18-.068.344-.196.472l1.415 1.413c.502-.502.78-1.175.78-1.885h-2zm-.195 1.885.47.472 1.415-1.414-.471-.472-1.414 1.414zm1.885.471A3.318 3.318 0 0 0 13 8h-2c0 .358-.137.69-.39.943l1.414 1.413zM13 8a3.32 3.32 0 0 0-.976-2.357L10.61 7.056c.253.254.39.586.39.944h2zm-2.39-2.357-.471.47 1.414 1.415.471-.471-1.414-1.415z"
        fill={htmlColor}
        mask="url(#sound-on-icon)"
      />
      <path
        d="m12.731 4.936-.471.47A3.643 3.643 0 0 1 13.334 8c0 .979-.382 1.9-1.074 2.592l.471.471A4.304 4.304 0 0 0 14.001 8c0-1.158-.451-2.246-1.27-3.064z"
        fill={htmlColor}
      />
      <path
        d="m14.145 3.521-.471.472A5.63 5.63 0 0 1 15.334 8a5.63 5.63 0 0 1-1.66 4.007l.471.471A6.291 6.291 0 0 0 16 8a6.291 6.291 0 0 0-1.855-4.479zM9.605.14a.658.658 0 0 0-.718.113L2.967 5.58a.333.333 0 0 1-.223.086H.667A.667.667 0 0 0 0 6.333v3.334c0 .367.299.666.667.666h2.077c.083 0 .162.03.223.086l5.92 5.328a.66.66 0 0 0 .717.114.66.66 0 0 0 .396-.61V.748A.66.66 0 0 0 9.605.14zm-.272 15.112-5.92-5.329a.998.998 0 0 0-.669-.256H.667V6.333h2.077a.997.997 0 0 0 .669-.256L9.333.748v14.504z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const SoundOffIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <mask id="sound-off-icon" fill={htmlColor}>
        <path d="m9.333 15.252-5.92-5.329a.998.998 0 0 0-.669-.257H.667V6.333h2.077a.997.997 0 0 0 .669-.257L9.333.748v3.918H10V.748A.66.66 0 0 0 9.605.14a.659.659 0 0 0-.718.113l-5.92 5.329a.333.333 0 0 1-.223.085H.667A.667.667 0 0 0 0 6.333v3.333c0 .368.299.667.667.667h2.077c.083 0 .162.03.223.085l5.92 5.329a.66.66 0 0 0 .717.114.66.66 0 0 0 .396-.61v-3.918h-.667v3.918z" />
      </mask>
      <path
        d="m9.333 15.252-5.92-5.329a.998.998 0 0 0-.669-.257H.667V6.333h2.077a.997.997 0 0 0 .669-.257L9.333.748v3.918H10V.748A.66.66 0 0 0 9.605.14a.659.659 0 0 0-.718.113l-5.92 5.329a.333.333 0 0 1-.223.085H.667A.667.667 0 0 0 0 6.333v3.333c0 .368.299.667.667.667h2.077c.083 0 .162.03.223.085l5.92 5.329a.66.66 0 0 0 .717.114.66.66 0 0 0 .396-.61v-3.918h-.667v3.918z"
        fill={htmlColor}
      />
      <path
        d="m9.333 15.252-.669.743 1.67 1.502v-2.245h-1zm-5.92-5.329-.67.743h.001l.669-.743zM.667 9.666h-1v1h1v-1zm0-3.333v-1h-1v1h1zm2.746-.257-.67-.743.67.743zM9.333.748h1v-2.245L8.664.005l.67.743zm0 3.918h-1v1h1v-1zm.667 0v1h1v-1h-1zM8.887.252 8.22-.49l.668.743zm-5.92 5.329.668.744h.001l-.669-.744zm0 4.837.669-.743-.001-.001-.668.745zm5.92 5.329.67-.743-.67.743zm.717.114-.406-.914h-.002l.408.914zM10 11.332h1v-1h-1v1zm-.667 0v-1h-1v1h1zm.67 3.175L4.081 9.18l-1.338 1.486 5.92 5.329 1.338-1.487zM4.082 9.18a1.998 1.998 0 0 0-1.339-.514v2L4.082 9.18zm-1.339-.514H.667v2h2.077v-2zm-1.077 1V6.333h-2v3.333h2zm-1-2.333h2.077v-2H.667v2zm2.077 0c.494 0 .97-.182 1.338-.514l-1.34-1.485h.002c0-.001 0 0 0 0v2zm1.338-.513 5.92-5.329L8.664.005l-5.92 5.328L4.082 6.82zM8.333.748v3.918h2V.748h-2zm1 4.918H10v-2h-.667v2zm1.667-1V.748H9v3.918h2zm0-3.918a1.66 1.66 0 0 0-.988-1.522l-.815 1.826A.335.335 0 0 1 9.055.93.335.335 0 0 1 9 .749h2zm-.988-1.522a1.659 1.659 0 0 0-1.793.283L9.556.996a.335.335 0 0 1-.17.08.336.336 0 0 1-.189-.024l.815-1.826zm-1.794.283-5.92 5.329 1.338 1.486L9.556.996 8.218-.491zM2.3 4.836a.667.667 0 0 1 .445-.17v2c.33 0 .647-.122.891-.34L2.3 4.835zm.445-.17H.667v2h2.077v-2zm-2.077 0C-.253 4.666-1 5.413-1 6.333h2a.333.333 0 0 1-.333.333v-2zM-1 6.333v3.333h2V6.333h-2zm0 3.333c0 .92.747 1.667 1.667 1.667v-2c.184 0 .333.149.333.333h-2zm1.667 1.667h2.077v-2H.667v2zm2.077 0a.667.667 0 0 1-.445-.17l1.336-1.489a1.333 1.333 0 0 0-.891-.34v2zm-.446-.171 5.92 5.328 1.338-1.486-5.92-5.329-1.338 1.487zm5.92 5.327c.308.278.703.43 1.113.43v-2a.34.34 0 0 1 .226.085l-1.34 1.485zm1.113.43c.233 0 .465-.049.682-.146l-.817-1.825a.332.332 0 0 1 .135-.029v2zm.68-.145c.605-.27.989-.86.989-1.523H9c0-.058.017-.122.055-.18.038-.059.09-.1.143-.124l.813 1.827zM11 15.251v-3.918H9v3.918h2zm-1-4.918h-.667v2H10v-2zm-1.667 1v3.918h2v-3.918h-2z"
        fill={htmlColor}
        mask="url(#sound-off-icon)"
      />
      <path
        d="m11.903 6.235-.471-.471-1.765 1.764-1.764-1.764-.471.471 1.764 1.764-1.764 1.765.471.471 1.764-1.764 1.765 1.764.471-.471-1.764-1.765 1.764-1.764z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const SocialDistanceIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm-1 0C6.5 9.121 5.378 8 4 8a2.503 2.503 0 0 0-2.5 2.5C1.5 11.879 2.622 13 4 13s2.5-1.121 2.5-2.5zM0 18.6C0 16.612 1.791 15 4 15s4 1.612 4 3.6v4.5c0 .497-.447.9-1 .9H1c-.552 0-1-.403-1-.9v-4.5zM6 23h1v-4.4C7 17.166 5.654 16 4 16s-3 1.166-3 2.6V23h5zM20 14a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zm0-1c1.378 0 2.5-1.121 2.5-2.5S21.378 8 20 8a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5zM16 18.6c0-1.988 1.791-3.6 4-3.6s4 1.612 4 3.6v4.5c0 .497-.448.9-1 .9h-6c-.552 0-1-.403-1-.9v-4.5zm6 4.4h1v-4.4c0-1.434-1.346-2.6-3-2.6s-3 1.166-3 2.6V23h5z"
        fill={htmlColor}
      />
      <path
        d="M6.414 4.268 5.146 3h13.707l-1.267 1.268.707.707L20.767 2.5 18.293.025l-.707.707L18.853 2H5.146L6.414.732 5.707.025 3.232 2.5l2.475 2.475.707-.707z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const SquareGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.333 4.333h-6A.666.666 0 0 0 8.667 5v1.12a2.058 2.058 0 0 0-1.334 0V5a.666.666 0 0 0-.666-.667h-6A.666.666 0 0 0 0 5v6c0 .368.298.666.667.666h6A.666.666 0 0 0 7.333 11V6.85a1.358 1.358 0 0 1 1.334 0V11c0 .368.298.666.666.666h6A.666.666 0 0 0 16 11V5a.666.666 0 0 0-.667-.667zM6.667 11h-6V5h6v6zm8.666 0h-6V5h6v6z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const StoreManagerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 10H32a6 6 0 0 0-6-6h-4a6 6 0 0 0-6 6H2a2 2 0 0 0-2 2v30a2 2 0 0 0 2 2h44a2 2 0 0 0 2-2V12a2 2 0 0 0-2-2zM22 6h4c2.206 0 4 1.794 4 4H18c0-2.206 1.794-4 4-4zm-4 6h28c0 7.72-6.28 14-14 14h-4v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h-4C8.28 26 2 19.72 2 12h16zm8 12v6h-4v-6h4zM2 42V19.74C4.728 24.664 9.972 28 16 28h4v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h4c6.028 0 11.272-3.336 14-8.26V42H2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const StoresIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5 16c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2h-2.8l-2.697-6.743A2 2 0 0 0 39.146 0H9.854a2 2 0 0 0-1.857 1.257L5.3 8H2.5c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2v30h-4v2h48v-2h-4V16h2zM9.854 2h29.292l2.4 6H7.454l2.4-6zM2.5 14v-4h44l.001 4H2.5zm10 32V32h8v14h-8zm16 0V32h8v14h-8zm14 0h-4V32c0-1.103-.897-2-2-2h-8c-1.103 0-2 .897-2 2v14h-4V32c0-1.103-.897-2-2-2h-8c-1.103 0-2 .897-2 2v14h-4V16h36v30z"
        fill={htmlColor}
      />
      <path d="M38.5 24h-28v2h28v-2z" fill={htmlColor} />
    </IconWrapper>
  )
}

// T
export const TempleSizeIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M2 27v-4h4v-2H0v8h6v-2H2zM47.085 32.556 33.181 22.515A8.001 8.001 0 0 0 28.497 21H18v2h10.497c1.269 0 2.484.393 3.513 1.136l13.904 10.042c.012.009.037.027.041.073a.09.09 0 0 1-.029.079l-2.042 2.042a2.976 2.976 0 0 1-2.117.879 2.967 2.967 0 0 1-2.462-1.28l-.019-.027-.019-.026-4.401-5.873a10.047 10.047 0 0 0-8.002-4.003 10 10 0 0 0-2.41.295l-2.67.663H18v2h4.028l2.907-.722a8.002 8.002 0 0 1 8.33 2.967l4.401 5.873a4.983 4.983 0 0 0 4.1 2.133c1.27 0 2.546-.479 3.531-1.465l2.042-2.042a2.1 2.1 0 0 0-.254-3.188zM11 21v8H9v2h6v-2h-2v-8h2v-2H9v2h2z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const TestIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M44 0H4a2 2 0 0 0-2 2v44a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 46H4V2h40v44z"
        fill={htmlColor}
      />
      <path
        d="M20 40h2v-6h4v6h2v-8h-8v8zM18 38h-6v-4h6v-2h-8v8h8v-2zM30 34h6v4h-6v2h8v-8h-8v2zM21 26h10v-5h-2v3h-8v-6h5v-2h-5v-6h8v3h2V8H16v2h3v14h-3v2h5z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const TriangleDangerIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox || `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M25 16h-2v18h2V16zM25 38h-2v2h2v-2z M46.778 43.941 25.89 2.168A2.088 2.088 0 0 0 24 1c-.75 0-1.501.389-1.89 1.168L1.224 43.941C.522 45.346 1.543 47 3.114 47h41.774c1.57 0 2.593-1.654 1.89-3.059zm-1.794 1.005c-.033.054-.07.054-.097.054H3.115c-.027 0-.063 0-.097-.054-.033-.054-.017-.087-.005-.11L23.899 3.063C23.916 3.031 23.93 3 24 3c.07 0 .085.031.102.063l20.886 41.773c.012.023.028.056-.005.11z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

//U
export const UnicodeArrowLeftIcon: React.FC<CustomSVGIconProps> = ({
  width = 24,
  height = 24,
  ...rest
}) => (
  <IconWrapper
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 12.5316V11.4711L15.5 9V10.0605L10.002 12.0013L15.5 13.9422V15.0026L8.5 12.5316Z"
      fill="black"
    />
  </IconWrapper>
)

export const UnicodeArrowRightIcon: React.FC<CustomSVGIconProps> = ({
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11.9709L15.5 13.0314L8.5 15.5024L8.5 14.442L13.998 12.5011L8.5 10.5603L8.5 9.4998L15.5 11.9709Z"
        fill="black"
      />
    </IconWrapper>
  )
}
export const UserIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width,
  height,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M8 8.667A4.333 4.333 0 1 0 8 0a4.333 4.333 0 0 0 0 8.667zm0-7.334c1.655 0 3 1.346 3 3 0 1.655-1.345 3-3 3-1.654 0-3-1.345-3-3 0-1.654 1.346-3 3-3zM11.478 10H4.522C2.024 10 0 12.328 0 15.2v.133c0 .369.298.667.667.667h14.666a.666.666 0 0 0 .667-.667V15.2c0-2.872-2.024-5.2-4.522-5.2zM1.364 14.667c.215-1.881 1.549-3.334 3.158-3.334h6.957c1.609 0 2.943 1.453 3.158 3.334H1.364z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

//V

export const VisaIcon: React.FC<CustomSVGIconProps> = () => {
  return (
    <IconWrapper viewBox={'0 0 30 20'} width={30} height={20} fill="none">
      <rect
        x=".376"
        y=".208"
        width="28.75"
        height="19.583"
        rx="2.292"
        fill="#fff"
        stroke="#D8D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.026 13.548H7.26L5.934 8.493c-.063-.232-.196-.438-.393-.534a5.751 5.751 0 0 0-1.62-.535v-.195h2.847c.393 0 .688.292.737.632l.687 3.646 1.766-4.278h1.718l-2.65 6.32zm3.632 0h-1.67l1.375-6.32h1.669l-1.374 6.32zm3.534-4.568c.049-.34.343-.535.687-.535a3.11 3.11 0 0 1 1.62.291l.294-1.36a4.224 4.224 0 0 0-1.521-.293c-1.62 0-2.798.876-2.798 2.09 0 .925.835 1.41 1.424 1.702.637.292.883.486.834.778 0 .437-.491.632-.982.632-.589 0-1.178-.146-1.717-.39l-.295 1.362c.59.243 1.227.34 1.816.34 1.816.048 2.945-.826 2.945-2.139 0-1.653-2.307-1.75-2.307-2.478zm8.147 4.568-1.325-6.32h-1.423a.738.738 0 0 0-.688.487l-2.453 5.833h1.718l.343-.923h2.11l.197.923h1.521zm-2.504-4.617.49 2.382h-1.373l.883-2.382z"
        fill="#172B85"
      />
    </IconWrapper>
  )
}

// W
export const WarningIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clipPath="url(#41pydu9ura)" fill={htmlColor}>
        <path d="M8.667 5.334H7.334v5.333h1.333V5.334zM8.667 12H7.334v1.333h1.333V12z" />
        <path d="M15.763 12.916 9.783 1.168A1.978 1.978 0 0 0 8 .075c-.706 0-1.411.365-1.782 1.093L.237 12.916C-.482 14.328.543 16 2.127 16h11.746c1.584 0 2.609-1.672 1.89-3.084zm-1.218 1.374a.776.776 0 0 1-.672.377H2.127a.776.776 0 0 1-.672-.377.777.777 0 0 1-.03-.769L7.406 1.773A.65.65 0 0 1 8 1.409a.65.65 0 0 1 .594.364l5.981 11.748a.776.776 0 0 1-.03.77z" />
      </g>
      <defs>
        <clipPath id="41pydu9ura">
          <path fill="none" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

// Z
export const ZoomIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#00303C',
  width = 32,
  height = 32,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g filter="url(#8afjeh34ua)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418z"
          fill="#fff"
        />
      </g>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.436 19.73a6.5 6.5 0 1 0-.707.707l4.418 4.417.707-.707-4.418-4.418zM15.5 21a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zM12 15.002v1h3V19h1v-2.998h3v-1h-3V12h-1v3.002h-3z"
        fill={htmlColor}
      />
      <defs>
        <filter
          id="8afjeh34ua"
          x="6"
          y="7"
          width="21.855"
          height="21.854"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1587_230429"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1587_230429"
            result="shape"
          />
        </filter>
      </defs>
    </IconWrapper>
  )
}

export const GeopositionIcon: React.FC<CustomSVGIconProps> = ({
  fill = '#000',
  width = 16,
  height = 16,
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M16 7.333h-1.367a6.678 6.678 0 0 0-5.966-5.966V0H7.333v1.367a6.678 6.678 0 0 0-5.966 5.966H0v1.334h1.367a6.679 6.679 0 0 0 5.966 5.966V16h1.334v-1.367a6.679 6.679 0 0 0 5.966-5.966H16V7.333zm-7.333 5.954v-1.954H7.333v1.954a5.338 5.338 0 0 1-4.62-4.62h1.954V7.333H2.713a5.338 5.338 0 0 1 4.62-4.62v1.954h1.334V2.713a5.338 5.338 0 0 1 4.62 4.62h-1.954v1.334h1.954a5.338 5.338 0 0 1-4.62 4.62z"
        fill={fill}
      />
    </IconWrapper>
  )
}

export const CheckedRadioWithBorderIcon: React.FC<CustomSVGIconProps> = ({
  fill = '#000',
  width = 16,
  height = 16,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none">
      <path d="M4 12a8 8 0 1 1 16 0 8 8 0 1 1-16 0z" stroke={fill} />
      <circle cx="12" cy="12" r="5" fill={fill} />
    </svg>
  )
}

export const FittingSizeAdvisorIcon: React.FC<CustomSVGIconProps> = ({
  fill = '#000',
  width = 24,
  height = 24,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M23 2v1H1V2H0v3h1V4h22v1h1V2h-1zM20.594 7.144a16.772 16.772 0 0 0-2.094-.14V7h-13v.004c-.726.003-1.446.055-2.103.142C1.83 7.383.667 8.519.21 10.262-.38 12.51.33 15.105 1.86 16.3c.594.464 1.313.699 2.098.699.468 0 .959-.084 1.462-.253 2.297-.77 4.487-3.169 5.37-5.826a2.05 2.05 0 0 1 2.422 0c.876 2.65 3.07 5.054 5.37 5.826.503.169.994.253 1.462.253.785 0 1.504-.236 2.098-.699 1.53-1.194 2.238-3.789 1.649-6.037-.457-1.743-1.619-2.879-3.196-3.118zm-10.626 3.04c-.677 2.533-2.723 4.895-4.867 5.614-.72.242-1.76.388-2.626-.287-1.213-.947-1.783-3.141-1.297-4.995.207-.787.789-2.143 2.36-2.38a15.787 15.787 0 0 1 1.963-.137V8h.225c1.858.02 3.373.408 3.984 1.083.28.31.36.664.258 1.101zm1.03-.507c-.035-.47-.217-.9-.547-1.265a2.796 2.796 0 0 0-.481-.411h4.06a2.814 2.814 0 0 0-.481.411 2.08 2.08 0 0 0-.546 1.266A2.98 2.98 0 0 0 12 9.5c-.345 0-.682.064-1.002.177zm10.528 5.834c-.866.675-1.906.529-2.626.287-2.144-.72-4.19-3.08-4.864-5.6-.106-.451-.025-.806.255-1.115.614-.678 2.14-1.066 4.004-1.083h.205c.624.003 1.28.045 1.954.134 1.58.24 2.162 1.595 2.369 2.382.486 1.854-.084 4.047-1.297 4.995z"
        fill={fill}
      />
    </svg>
  )
}

export const MobileIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M34 0H14C11.243 0 9 2.243 9 5V43C9 45.757 11.243 48 14 48H34C36.757 48 39 45.757 39 43V5C39 2.243 36.757 0 34 0ZM37 43C37 44.654 35.654 46 34 46H14C12.346 46 11 44.654 11 43V5C11 3.346 12.346 2 14 2H34C35.654 2 37 3.346 37 5V43Z"
        fill={htmlColor}
      />
      <path d="M28 6H20V8H28V6Z" fill={htmlColor} />
      <path
        d="M24 42C25.1046 42 26 41.1046 26 40C26 38.8954 25.1046 38 24 38C22.8954 38 22 38.8954 22 40C22 41.1046 22.8954 42 24 42Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CheckmarkOutlinedIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clip-path="url(#clip0_4653_201)">
        <path
          d="M24 0C10.767 0 0 10.767 0 24C0 37.233 10.767 48 24 48C37.233 48 48 37.233 48 24C48 10.767 37.233 0 24 0ZM24 46C11.869 46 2 36.131 2 24C2 11.869 11.869 2 24 2C36.131 2 46 11.869 46 24C46 36.131 36.131 46 24 46Z"
          fill={htmlColor}
        />
        <path
          d="M20.44 30.586L11.2 21.346L9.786 22.76L20.438 33.414L38.183 15.708L36.77 14.292L20.44 30.586Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_201">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const AntiStaticCoatingIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clip-path="url(#clip0_4653_366)">
        <path
          d="M6.764 17.723L8.686 18.276C9.472 15.544 11.628 10.832 17.234 9.974L16.931 7.997C12.032 8.746 8.327 12.291 6.764 17.723Z"
          fill={htmlColor}
        />
        <path
          d="M16.55 44C13.766 44 11.306 43.166 9.243 41.528C3.204 36.681 0.411001 25.645 2.886 16.404C3.924 12.529 6.82 5.852 14.52 4.664C17.798 4.224 21.096 4 24.323 4C34.233 4 41.85 6.138 44.698 9.72C45.926 11.264 46.281 13.081 45.781 15.272C44.807 18.979 43.268 22.605 41.27 26H43.568C45.353 22.792 46.784 19.34 47.724 15.749C50.004 5.876 37.809 2 24.323 2C20.978 2 17.556 2.238 14.253 2.682C-3.222 5.376 -3.791 33.631 7.992 43.087C10.512 45.09 13.441 46 16.55 46C19.597 46 22.818 45.124 26 43.551V41.302C22.749 43.054 19.507 44 16.55 44Z"
          fill={htmlColor}
        />
        <path
          d="M32 34C33.1046 34 34 33.1046 34 32C34 30.8954 33.1046 30 32 30C30.8954 30 30 30.8954 30 32C30 33.1046 30.8954 34 32 34Z"
          fill={htmlColor}
        />
        <path
          d="M46 48C47.1046 48 48 47.1046 48 46C48 44.8954 47.1046 44 46 44C44.8954 44 44 44.8954 44 46C44 47.1046 44.8954 48 46 48Z"
          fill={htmlColor}
        />
        <path
          d="M38 48C39.1046 48 40 47.1046 40 46C40 44.8954 39.1046 44 38 44C36.8954 44 36 44.8954 36 46C36 47.1046 36.8954 48 38 48Z"
          fill={htmlColor}
        />
        <path
          d="M32 42C33.1046 42 34 41.1046 34 40C34 38.8954 33.1046 38 32 38C30.8954 38 30 38.8954 30 40C30 41.1046 30.8954 42 32 42Z"
          fill={htmlColor}
        />
        <path
          d="M46 40C47.1046 40 48 39.1046 48 38C48 36.8954 47.1046 36 46 36C44.8954 36 44 36.8954 44 38C44 39.1046 44.8954 40 46 40Z"
          fill={htmlColor}
        />
        <path
          d="M40 34C41.1046 34 42 33.1046 42 32C42 30.8954 41.1046 30 40 30C38.8954 30 38 30.8954 38 32C38 33.1046 38.8954 34 40 34Z"
          fill={htmlColor}
        />
        <path
          d="M39 41C40.1046 41 41 40.1046 41 39C41 37.8954 40.1046 37 39 37C37.8954 37 37 37.8954 37 39C37 40.1046 37.8954 41 39 41Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_366">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const UVProtectionIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clip-path="url(#clip0_4653_162)">
        <path
          d="M6.76401 17.723L8.68601 18.276C9.47201 15.544 11.628 10.832 17.234 9.974L16.931 7.997C12.032 8.746 8.32701 12.291 6.76401 17.723Z"
          fill={htmlColor}
        />
        <path
          d="M16.55 44C13.766 44 11.306 43.166 9.24301 41.528C3.20401 36.681 0.411009 25.645 2.88601 16.404C3.92401 12.529 6.82001 5.852 14.52 4.664C17.798 4.224 21.096 4 24.323 4C34.233 4 41.85 6.138 44.698 9.72C45.926 11.264 46.281 13.081 45.781 15.272C44.789 19.049 43.216 22.745 41.163 26.195C41.851 26.31 42.521 26.476 43.168 26.696C45.138 23.293 46.715 19.603 47.724 15.751C50.004 5.876 37.809 2 24.323 2C20.978 2 17.557 2.238 14.253 2.682C-3.22199 5.376 -3.79099 33.631 7.99201 43.087C10.512 45.09 13.441 46 16.55 46C19.821 46 23.293 44.987 26.703 43.188C26.484 42.544 26.316 41.878 26.2 41.194C22.881 43.015 19.567 44 16.55 44Z"
          fill={htmlColor}
        />
        <path d="M40 30H38V32H40V30Z" fill={htmlColor} />
        <path
          d="M33.3428 31.9289L31.9286 33.3431L33.3428 34.7573L34.757 33.3431L33.3428 31.9289Z"
          fill={htmlColor}
        />
        <path d="M32 38H30V40H32V38Z" fill={htmlColor} />
        <path
          d="M33.3424 43.2429L31.9282 44.6571L33.3424 46.0713L34.7566 44.6571L33.3424 43.2429Z"
          fill={htmlColor}
        />
        <path d="M40 46H38V48H40V46Z" fill={htmlColor} />
        <path
          d="M44.6561 43.2427L43.2419 44.6569L44.6561 46.0711L46.0703 44.6569L44.6561 43.2427Z"
          fill={htmlColor}
        />
        <path d="M48 38H46V40H48V38Z" fill={htmlColor} />
        <path
          d="M44.6566 31.9287L43.2424 33.3429L44.6566 34.7571L46.0708 33.3429L44.6566 31.9287Z"
          fill={htmlColor}
        />
        <path
          d="M39 34C36.239 34 34 36.239 34 39C34 41.761 36.239 44 39 44C41.761 44 44 41.761 44 39C44 36.239 41.761 34 39 34ZM39 42C37.346 42 36 40.654 36 39C36 37.346 37.346 36 39 36C40.654 36 42 37.346 42 39C42 40.654 40.654 42 39 42Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_162">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const Default: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <div color={htmlColor}>
      <IconWrapper
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        fill="none"
        {...rest}
      />
    </div>
  )
}
export const BetterClarityIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g clip-path="url(#clip0_4653_187)">
        <path
          d="M47.781 23.083C43.352 14.395 34.848 8.42 25 8.037V0H23V8.037C13.152 8.42 4.648 14.395 0.219 23.083C-0.073 23.655 -0.073 24.346 0.219 24.918C4.648 33.605 13.152 39.58 23 39.963V48H25V39.963C34.848 39.58 43.352 33.605 47.781 24.917C48.073 24.345 48.073 23.655 47.781 23.083ZM23 31.931C19.06 31.436 16 28.072 16 24C16 19.928 19.06 16.564 23 16.069V31.931ZM25 16.069C28.94 16.564 32 19.928 32 24C32 28.072 28.94 31.436 25 31.931V16.069ZM2.001 23.991C6.239 15.676 14.221 10.411 23 10.036V14.05C17.954 14.553 14 18.823 14 23.999C14 29.175 17.954 33.445 23 33.948V37.962C14.221 37.589 6.239 32.323 2.001 23.991ZM25 37.964V33.95C30.046 33.447 34 29.177 34 24.001C34 18.825 30.046 14.555 25 14.052V10.038C33.779 10.413 41.761 15.679 45.999 24.011C41.761 32.324 33.779 37.589 25 37.964Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4653_187">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}
