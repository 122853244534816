import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import { sendAddToCartEvent } from '../../../foundation/analytics/tealium/lib'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { ContactLensPayload, ServerProduct } from '../../../types/product'
import { sendSuccessMessage } from '../../success/slice'
import { toggleAddContactLensesToCartError } from '../../product/slice'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'

export type ContactLensesAddItemArgs = {
  contractId?: string
  callback?: any
  product?: ServerProduct
  updateCart?: boolean
  langId: string
  items:
    | {
        x_contactLens: ContactLensPayload
        quantity: string
      }
    | {}[]
    | null
}

const addContactLenses = createAsyncThunk<any, ContactLensesAddItemArgs>(
  'order/addContactLensItem',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const payload = args
      const addToCartSuccessCallback = args.callback

      const _orderExtendAttributes = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }

      let body = {
        body: {
          orderId: '.',
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: args.items,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderExtendAttribute: [_orderExtendAttributes],
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        },
        skipErrorSnackbar: true,
      }

      const response = await cartService.addOrderItem(body)

      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )

      addToCartSuccessCallback?.()

      sendAddToCartEvent(payload.product ? [payload.product] : [])

      return response.data
    } catch (error) {
      dispatch(toggleAddContactLensesToCartError(true))
      return rejectWithValue(error)
    }
  }
)

export default addContactLenses
