import { styled } from '@mui/material/styles'

export const OpeningHoursContainer = styled('table', {
  name: 'StoreOpeningHours',
  slot: 'OpeningHoursTable',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  wordBreak: 'break-word',
  maxHeight: 80,
  columnGap: theme.spacing(6),
  paddingBottom: theme.spacing(2),
  justifyContent: 'space-between',
  alignContent: 'stretch',

  [theme.breakpoints.up('sm')]: {
    alignContent: 'flex-start',
    columnGap: theme.spacing(5.5),
    paddingBottom: theme.spacing(6.75),
  },
}))

export const WeekDayHours = styled('tr', {
  name: 'StoreOpeningHours',
  slot: 'WeekDayHoursTableRow',
})(() => ({
  display: 'flex',
  minWidth: 80,
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const WeekDay = styled('th', {
  name: 'StoreOpeningHours',
  slot: 'WeekDayTableHeader',
  shouldForwardProp: (prop) => prop !== 'isBold',
})<{ isBold?: boolean }>(({ theme, isBold }) => ({
  fontSize: 9,
  fontWeight: isBold ? 600 : 400,
  lineHeight: 1.2,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  marginRight: theme.spacing(1),
}))

export const Hours = styled('td', {
  name: 'StoreOpeningHours',
  slot: 'HoursTableData',
})(({ theme }) => ({
  fontSize: 9,
  lineHeight: 1.2,
  color: theme.palette.text.dark.primary,
}))
