/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
/**
 *
 */
export function executeOnce<Args extends unknown[], Return>(
  cb: (...args: Args) => Return,
  id: string
) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const requiredValuesMissingInObject = (
  obj,
  values: string[]
): boolean => {
  return (
    Object.keys(obj).filter((key) => values.includes(key) && !!obj[key])
      .length <= 0
  )
}

export const browserInUse = (): string => {
  const sUsrAg = window.navigator.userAgent
  let browser: string | null = null
  switch (true) {
    case sUsrAg.indexOf('Firefox') > -1:
      browser = 'Firefox'
      break
    case sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1:
      browser = 'Opera'
      break
    case sUsrAg.indexOf('Trident') > -1:
      browser = 'Explorer'
      break
    case sUsrAg.indexOf('Edge') > -1:
      browser = 'Edge'
      break
    case sUsrAg.indexOf('Chrome') > -1:
      browser = 'Chrome'
      break
    case sUsrAg.indexOf('Safari') > -1:
      browser = 'Safari'
      break
    default:
      browser = 'unknown'
      break
  }
  return browser
}

export const generateRandomId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36).slice(2)
}
