import { IPlacement } from '../../types/teaser'
import React, { useMemo } from 'react'
import find from 'lodash/find'
import loadable from '@loadable/component'
import { paymentMethodsSelector } from '../../redux/selectors/site'
import { styled } from '@mui/material/styles'
import { useGetFooterQuery } from '../../features/cms/query'
import { useSelector } from 'react-redux'

import { useIsAccountPage } from '../../hooks/useIsAccountPage'
import { usePageType } from '../../foundation/hooks/usePageType'
import { orderDetailsSelector } from '../../features/order/selector'
import {
  footerLoadedSelector,
  //footerLoadingSelector,
} from '../../features/cms/selector'
import PromoBarSkeleton from '../Header/components/HeaderSkeleton/PromoBarSkeleton'
import WithSkeleton from '@components/WithSkeleton/WithSkeleton'
import { Skeleton } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useDynamicCmsContent } from 'src/foundation/hooks/useDynamicCmsContent'

const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.dark.primary,
}))

const CopyrightWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.light.secondary,
  padding: `0 ${theme.spacing(4)}`,

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(8)}`,
  },

  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(16)}`,
  },
}))

const contentsFooter = (footerPlacements: IPlacement[]) => {
  const navigation = find(footerPlacements, { name: 'footer_navigation' })
  const newsLetter = find(footerPlacements, { name: 'footer_newsletter' })
  const newsLetterSideBanner = find(footerPlacements, {
    name: 'footer_newsletter_side_banner',
  })
  const newsLetterPopup = find(footerPlacements, {
    name: 'footer_newsletter_overlay',
  })
  const crossSiteLinks = find(footerPlacements, {
    name: 'footer_cross_site_links',
  })
  const copyRight = find(footerPlacements, { name: 'footer_copyright' })
  const checkoutFooter = find(footerPlacements, { name: 'footer_checkout' })
  return {
    navigation,
    crossSiteLinks,
    copyRight,
    newsLetter,
    newsLetterPopup,
    newsLetterSideBanner,
    checkoutFooter,
  }
}

const Footer: React.FC = () => {
  const { pageType } = usePageType()
  const [searchParams, _setSearchParams] = useSearchParams()
  const footerPaymentMethods = useSelector(paymentMethodsSelector)
  const previewDate = searchParams.get('previewDate') || undefined
  const filterRulesLocaleOverride =
    searchParams.get('filterRulesLocaleOverride') || undefined

  const params =
    previewDate || filterRulesLocaleOverride
      ? { previewDate, filterRulesLocaleOverride }
      : undefined
  const { data } = useGetFooterQuery(params)
  const isAccountPage = useIsAccountPage()
  const isCartPage = pageType === 'cart'
  const isCheckoutPage = pageType === 'checkout'
  const isSignInPage = pageType === 'sign-in'
  const orderDetails = useSelector(orderDetailsSelector)
  //const footerLoading = useSelector(footerLoadingSelector)
  const footerLoaded = useSelector(footerLoadedSelector)

  const FooterNavigation = useMemo(() => {
    return loadable(() => import('./components/FooterNavigation'), {
      fallback: <PromoBarSkeleton />,
    })
  }, [footerLoaded])
  const NewsLetter = useMemo(() => {
    return loadable(() => import('./components/NewsLetter'))
  }, [footerLoaded])
  const OtherLinks = useMemo(() => {
    return loadable(() => import('./components/OtherLinks'))
  }, [footerLoaded])
  const PreFooter = useMemo(() => {
    return loadable(() => import('./components/PreFooter'))
  }, [footerLoaded])
  const PaymentMethod = useMemo(() => {
    return loadable(() => import('./components/PaymentMethod'))
  }, [footerLoaded])

  const dynamicContentBanners = useDynamicCmsContent(data?.footerPlacements)

  if (!data) return null
  const {
    copyRight,
    crossSiteLinks,
    navigation,
    newsLetter,
    newsLetterPopup,
    checkoutFooter,
    newsLetterSideBanner,
  } = contentsFooter(
    dynamicContentBanners?.dynamicContentBanners || data?.footerPlacements
  )

  return (
    <>
      {!isAccountPage &&
        !isCartPage &&
        !isCheckoutPage &&
        !isSignInPage &&
        !orderDetails && (
          <WithSkeleton
            isLoaded={footerLoaded}
            renderSkeletonElement={() => {
              return <Skeleton variant={'rectangular'} height={280} />
            }}
          >
            <PreFooter />
          </WithSkeleton>
        )}
      {!isCheckoutPage ? (
        <>
          <FooterWrapper data-element-id="X_X_Footer">
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={footerLoaded}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={90} />
              }}
            >
              <NewsLetter
                newsLetter={newsLetter!}
                newsLetterPopup={newsLetterPopup!}
                newsLetterSideBanner={newsLetterSideBanner!}
              />
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={footerLoaded}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={400} />
              }}
            >
              <FooterNavigation navigation={navigation!} />
            </WithSkeleton>
            <WithSkeleton
              animationDuration={500}
              animationType="fade"
              isLoaded={footerLoaded}
              renderSkeletonElement={() => {
                return <Skeleton variant={'rectangular'} height={60} />
              }}
            >
              <PaymentMethod availablePaymentMethods={footerPaymentMethods!} />
            </WithSkeleton>
          </FooterWrapper>
          <CopyrightWrapper data-element-id="X_X_Copyright">
            <OtherLinks
              crossSiteLinks={crossSiteLinks!}
              copyright={copyRight!}
            />
          </CopyrightWrapper>
        </>
      ) : (
        <FooterWrapper data-element-id="X_X_Footer">
          <WithSkeleton
            animationDuration={500}
            animationType="fade"
            isLoaded={footerLoaded}
            renderSkeletonElement={() => {
              return <Skeleton variant={'rectangular'} height={60} />
            }}
          >
            <PaymentMethod
              availablePaymentMethods={footerPaymentMethods!}
              checkoutFooter={checkoutFooter}
              copyright={copyRight}
            />
          </WithSkeleton>
        </FooterWrapper>
      )}
    </>
  )
}

export default Footer
