import { CART } from '../../../constants/routes'
import { ORDER_CONFIGS } from '../../../configs/order'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import type { SiteInfo } from '../../../redux/reducers'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import last from 'lodash/last'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import { sendSuccessMessage } from '../../success/slice'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'
import { OrderItem } from 'src/types/order'
import rxService from 'src/foundation/apis/rx-config/rx.service'
import { PrescriptionDetailsBody, RXFileLink } from 'src/types/rxConfigurator'
import RequestService from 'src/services/RequestService'
import { AxiosResponse } from 'axios'
import { sendAddToCartEvent } from 'src/foundation/analytics/tealium/lib'

export interface IAddLensArgs {
  params: {
    partnumber: string | string[]
    quantity: string | string[]
    catentryId?: string | string[]
    contractId?: string
    widget?: string
    langId: string
    product?: any
    images?: ImageryType
  }
  prescriptionData?: OrderItem['prescriptionDetails'] | null
  orderdId?: string
  orderItemId?: string
  callback?: any
  siteInfo: SiteInfo
}

export interface ICommonArgs {
  partnumbers: string[]
  quantities: any[]
  langId: string
  widget?: string
  contractId?: string
}

export interface IAddSingleLensArgs extends ICommonArgs {
  catentryIds: string[]
  images?: ImageryType
}

export interface IAddFrameArgs extends ICommonArgs {
  orderItemId: string
}

function dataURLtoFile(fileData) {
  const new_blob = new Blob([fileData.content], {
    type: fileData.content.type,
  })
  return new_blob
}

const addFrame = createAsyncThunk<any, IAddFrameArgs>(
  'order/addFrame',
  async (args) => {
    const { orderItemId, partnumbers, quantities, langId, widget, contractId } =
      args
    const _orderFrameExtendAttribute: any[] = []
    const _orderFrameExtendAttributes: any[] = []
    const _orderFrameItems: any[] = []

    for (const i in partnumbers) {
      if (orderItemId) {
        _orderFrameExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'RxLensId',
              attributeType: 'String',
              attributeValue: orderItemId,
            },
          ]
        )
      }
      _orderFrameItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId,
        orderItemExtendAttribute: orderItemId
          ? _orderFrameExtendAttribute
          : undefined,
      }
      _orderFrameExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: langId,
      }
    }

    let bodyFrame = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderFrameItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderFrameExtendAttributes,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      },
      widget,
    }

    return (await cartService.addOrderItem(bodyFrame)).data
  }
)

const addLens = createAsyncThunk<any, IAddSingleLensArgs>(
  'order/addSingleLens',
  async (args, { rejectWithValue }) => {
    try {
      const { quantities, catentryIds, langId, widget, contractId, images } =
        args

      const _orderLensExtendAttribute: any[] = []
      const _orderLensExtendAttributes: any[] = []
      const _orderLensItems: any[] = []

      const imagesArray = [
        {
          attributeName: 'rxProductImage',
          attributeType: 'String',
          attributeValue: images?.productImage || '',
        },
        {
          attributeName: 'rxFallbackImage',
          attributeType: 'String',
          attributeValue: images?.fallbackImage || '',
        },
      ]

      for (const i in catentryIds) {
        _orderLensExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'IsRoxLens',
              attributeType: 'String',
              attributeValue: 'true',
            },
            ...imagesArray,
          ]
        )
        _orderLensItems[i] = {
          quantity: quantities[i].toString(),
          productId: catentryIds[i],
          contractId: contractId,
          orderItemExtendAttribute: _orderLensExtendAttribute,
        }
        _orderLensExtendAttributes[i] = {
          attributeName: 'LanguageId',
          attributeType: 'string',
          attributeValue: langId,
        }
      }

      let bodyLens = {
        body: {
          orderId: '.',
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: _orderLensItems,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderExtendAttribute: _orderLensExtendAttributes,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        },
        widget,
      }

      return (await cartService.addOrderItem(bodyLens)).data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const addLensItem = createAsyncThunk<any, IAddLensArgs>(
  'order/addLens',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const {
        partnumber,
        catentryId,
        quantity,
        langId,
        contractId,
        widget,
        product,
        images,
      } = args.params
      const { prescriptionData } = args
      const addToCartSuccessCallback = args.callback
      const mySite = args.siteInfo

      const savedPrescription = localStorageUtil.get('prescriptionObject')

      let catentryIds: string[] = []
      let partnumbers: string[] = []
      let quantities: any[] = []

      if (partnumber) {
        partnumbers = partnumber instanceof Array ? partnumber : [partnumber]
      }

      if (catentryId) {
        catentryIds = catentryId instanceof Array ? catentryId : [catentryId]
      }

      quantities = quantity instanceof Array ? quantity : [quantity]

      const common: ICommonArgs = {
        langId,
        partnumbers,
        quantities,
        contractId,
        widget,
      }

      const responseLens = await dispatch(
        addLens({ ...common, catentryIds, images })
      ).unwrap()

      const orderItemId = (last(responseLens.orderItem) as any).orderItemId
      await dispatch(addFrame({ ...common, orderItemId })).unwrap()
      let prescriptionObject: PrescriptionDetailsBody | RXFileLink = {
        orderId: responseLens.orderId,
        orderItemId,
        prescription: {
          productType: '',
          firstName: '',
          lastName: '',
          nickName: '',
          telephone: '',
          dateOfBirth: '',
          prescriptionState: '',
          pupillaryDistance: '',
          rightSphere: '0.00',
          rightAdd: '',
          rightAxis: '0',
          rightCyl: '0.00',
          leftSphere: '0.00',
          leftAdd: '',
          leftAxis: '0',
          leftCyl: '0.00',
          lPupDistance: '',
          rPupDistance: '',
        },
      }

      const addPrescriptionDataToOrder = async (
        prescriptionObject: PrescriptionDetailsBody | RXFileLink
      ) => {
        if (!!(prescriptionObject as RXFileLink).rxFileStorageId) {
          await RequestService.requestForRtk({
            method: 'POST',
            body: prescriptionObject || {},
            path: `/store/${mySite.storeID}/prescription/uploadRXFile`,
          }).catch((e) => {
            throw new Error(e)
          })
        } else {
          await rxService
            .addPrescriptionDetails(
              mySite,
              prescriptionObject as PrescriptionDetailsBody
            )
            .catch((e) => {
              throw new Error(e)
            })
        }
      }

      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )
      // adding from rx configurator
      if (savedPrescription) {
        if (savedPrescription?.prescriptionFlow === 'MANUAL') {
          prescriptionObject = {
            ...prescriptionObject,
            prescription: {
              ...prescriptionObject.prescription,
              pupillaryDistance: savedPrescription?.PD?.OD
                ? String(savedPrescription.PD.OD)
                : prescriptionObject.prescription?.pupillaryDistance,
              rightSphere: savedPrescription?.SPH?.OD
                ? String(savedPrescription.SPH.OD)
                : prescriptionObject.prescription?.rightSphere,
              rightAdd: savedPrescription?.ADD?.OD
                ? String(savedPrescription.ADD.OD)
                : prescriptionObject.prescription?.rightAdd,
              rightAxis: savedPrescription?.AX?.OD
                ? String(savedPrescription.AX.OD)
                : prescriptionObject.prescription?.rightAxis,
              rightCyl: savedPrescription?.CYL?.OD
                ? String(savedPrescription.CYL.OD)
                : prescriptionObject.prescription?.rightCyl,
              leftSphere: savedPrescription?.SPH?.OS
                ? String(savedPrescription.SPH.OS)
                : prescriptionObject.prescription?.leftSphere,
              leftAdd: savedPrescription?.ADD?.OS
                ? String(savedPrescription.ADD.OS)
                : prescriptionObject.prescription?.leftAdd,
              leftAxis: savedPrescription?.AX?.OS
                ? String(savedPrescription.AX.OS)
                : prescriptionObject.prescription?.leftAxis,
              leftCyl: savedPrescription?.CYL?.OS
                ? String(savedPrescription.CYL.OS)
                : prescriptionObject.prescription?.leftCyl,
              lPupDistance: savedPrescription?.PD?.OS
                ? String(savedPrescription.PD.OS)
                : prescriptionObject.prescription?.lPupDistance,
              rPupDistance: savedPrescription?.PD?.OD
                ? String(savedPrescription.PD.OD)
                : prescriptionObject.prescription?.rPupDistance,
            },
          }
        }
        if (savedPrescription?.prescriptionFlow === 'UPLOAD') {
          prescriptionObject = {
            ...prescriptionObject,
            rxFileStorageId: savedPrescription?.savedFileName,
          }
        }
        localStorageUtil.remove('prescriptionObject')
      } else {
        //adding lens outside rxconfigurator
        const formData = new FormData()
        !!prescriptionData?.file?.content &&
          formData.append('UpLoadedFile', dataURLtoFile(prescriptionData?.file))

        const fileUploadedREf: AxiosResponse<{
          rxFileStorageId: string
        }> | null = !!prescriptionData?.file
          ? await RequestService.requestForRtk({
              method: 'POST',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: formData || {},
              path: `/store/${mySite.storeID}/prescription/storeRXFile`,
            }).catch((e) => {
              throw new Error(e)
            })
          : null

        prescriptionObject = !!prescriptionData?.file
          ? {
              ...prescriptionObject,
              rxFileStorageId: fileUploadedREf?.data.rxFileStorageId || '',
            }
          : {
              ...prescriptionObject,
            }
      }
      if (
        (prescriptionObject as PrescriptionDetailsBody)?.prescription
          ?.rightSphere !== '0.00' ||
        (prescriptionObject as PrescriptionDetailsBody)?.prescription
          ?.leftSphere !== '0.00' ||
        (prescriptionObject as PrescriptionDetailsBody)?.prescription
          ?.rightCyl !== '0.00' ||
        (prescriptionObject as PrescriptionDetailsBody)?.prescription
          ?.leftCyl !== '0.00' ||
        !!(prescriptionObject as RXFileLink)?.rxFileStorageId
      ) {
        await addPrescriptionDataToOrder(prescriptionObject).catch((e) => {
          throw new Error(e)
        })
      }

      if (addToCartSuccessCallback) {
        addToCartSuccessCallback()
      }
      sendAddToCartEvent(product ? [product] : [])

      return true
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default addLensItem

export { addFrame, addLens }
