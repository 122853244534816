import {
  OrderDetailsFooter,
  OrderDetailsFooterItem,
  OrderDetailsHeader,
  OrderDetailsHeaderRow,
  OrderDetailsItemDetailsRow,
  OrderDetailsItemList,
  OrderDetailsListItem,
  OrderDetailsPriceDetailsItem,
  OrderDetailsPriceDetailsTotalItem,
  OrderDetailsPriceDetailsWrapper,
  OrderDetailsShippingPaymentDetails,
  OrderDetailsShippingPaymentDetailsTitle,
  OrderDetailsStatusRow,
  OrderDetailsWrapper,
  StyledDetailsPrintReceipt,
  StyledDetailsTitle,
  StyledTrackButton,
} from './OrderDetails.style'
import React, { FC, useEffect, useState } from 'react'
import { StyledLoader } from '../../../components/UI'
import { isRxCart, parseRxOrderItems } from '../../../utils/rx'
import {
  useOrderDetails,
  useOrderDetailsLoadings,
} from '../../../hooks/useOrderDetails'
import CurrencyService from '../../../services/CurrencyService'
import DateService from '../../../services/DateService'
import { FETCH_ORDER_DETAILS_ACTION } from '../../../redux/actions/orderDetails'
import { Order } from '../../../types/order'
import get from 'lodash/get'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { OrderComponent } from './OrderComponent'
import {
  getOrderReturnedStatus,
  getParsedOrderItems,
} from '../../../utils/order'
import {
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS,
  SHIPPED_STATUSES,
} from '../../../constants/order'
import { getProductType } from '../../../utils/productAttributes'
import {
  isCLAccessoriesProduct,
  isContactLensesProduct,
} from '../../../utils/product'
import orderService from '../../../foundation/apis/transaction/order.service'
import { useSite } from '../../../foundation/hooks/useSite'

interface OrderDetailsProps {
  orderId: Order['orderId']
  xtrackingUrls?: Order['xtrackingUrls']
}

const OrderDetails: FC<OrderDetailsProps> = ({ orderId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { country } = useStoreIdentity()
  const orderDetails = useOrderDetails(orderId) || {}
  const areOrderDetailsLoading = useOrderDetailsLoadings(orderId)
  const { isDesktop } = useBreakpoints()
  const [downloadingReceipt, setDownloadReceiptStatus] =
    useState<boolean>(false)
  const [orderShippingAddress, setOrderShippingAddress] = useState<string>('')
  const [orderBillingAddress, setOrderBillingAddress] = useState<string>('')

  function initOrderTotalSummary() {
    let subtotal: string | null = null
    let tax: string | null = null
    let shippingName: string | null = null
    let shipping: string | null = null
    let shippingTax: string | null = null
    let totalDiscounts: string | null = null
    let grandTotal: string | null = null
    let subtotalCurrency: string = ''
    let taxCurrency: string = ''
    let shippingCurrency: string = ''
    let totalDiscountsCurrency: string = ''
    let grandTotalCurrency: string = ''

    if (orderDetails) {
      subtotal = orderDetails.totalProductPrice
        ? parseFloat(orderDetails.totalProductPrice).toFixed(2)
        : null
      subtotalCurrency = CurrencyService.getSymbolByName(
        orderDetails.totalProductPriceCurrency
      )
      tax = orderDetails.totalSalesTax
        ? parseFloat(orderDetails.totalSalesTax).toFixed(2)
        : null
      taxCurrency = CurrencyService.getSymbolByName(
        orderDetails.totalSalesTaxCurrency
      )
      shippingName = get(orderDetails, 'orderItem[0].shipModeDescription')
      shipping = orderDetails.totalShippingCharge
        ? parseFloat(orderDetails.totalShippingCharge).toFixed(2)
        : null
      shippingCurrency = CurrencyService.getSymbolByName(
        orderDetails.totalShippingChargeCurrency
      )
      shippingTax = orderDetails.totalShippingTax
        ? parseFloat(orderDetails.totalShippingTax).toFixed(2)
        : null
      totalDiscounts = orderDetails.totalAdjustment
        ? parseFloat(orderDetails.totalAdjustment).toFixed(2)
        : null
      totalDiscountsCurrency = CurrencyService.getSymbolByName(
        orderDetails.totalAdjustmentCurrency
      )
      grandTotal = orderDetails.grandTotal
        ? parseFloat(orderDetails.grandTotal).toFixed(2)
        : null
      grandTotalCurrency = CurrencyService.getSymbolByName(
        orderDetails.grandTotalCurrency
      )
    }

    return {
      subtotal,
      subtotalCurrency,
      tax,
      taxCurrency,
      shippingName,
      shipping,
      shippingCurrency,
      shippingTax,
      totalDiscounts,
      totalDiscountsCurrency,
      grandTotal,
      grandTotalCurrency,
    }
  }

  const {
    subtotal,
    subtotalCurrency,
    tax,
    taxCurrency,
    shipping,
    shippingName,
    shippingCurrency,
    grandTotal,
    grandTotalCurrency,
    totalDiscounts,
    totalDiscountsCurrency,
  } = initOrderTotalSummary()

  useEffect(() => {
    if (orderDetails) {
      setOrderBillingAddress(
        [
          orderDetails.paymentInstruction?.[0]?.firstName,
          orderDetails.paymentInstruction?.[0]?.lastName,
          orderDetails.paymentInstruction?.[0]?.addressLine?.[0],
          orderDetails.paymentInstruction?.[0]?.city,
          orderDetails.paymentInstruction?.[0]?.zipCode,
          orderDetails.paymentInstruction?.[0]?.country,
        ].join(' ')
      )

      setOrderShippingAddress(
        [
          orderDetails.orderItem?.[0]?.firstName,
          orderDetails.orderItem?.[0]?.lastName,
          orderDetails.orderItem?.[0]?.addressLine?.[0],
          orderDetails.orderItem?.[0]?.city,
          orderDetails.orderItem?.[0]?.zipCode,
          orderDetails.orderItem?.[0]?.country,
        ].join(' ')
      )
    }
  }, [orderDetails])

  useEffect(() => {
    if (orderId) {
      dispatch(FETCH_ORDER_DETAILS_ACTION({ orderId, skipErrorSnackbar: true }))
    }
  }, [orderId])

  const PARSED_ORDER_ITEMS = isRxCart(orderDetails?.orderExtendAttribute)
    ? parseRxOrderItems(
        getParsedOrderItems(orderDetails.detailedOrderItems) || []
      )
    : getParsedOrderItems(orderDetails.detailedOrderItems)

  if (areOrderDetailsLoading) {
    return (
      <OrderDetailsWrapper>
        <StyledLoader size={100} />
      </OrderDetailsWrapper>
    )
  }

  const isMixedOrder =
    PARSED_ORDER_ITEMS &&
    PARSED_ORDER_ITEMS?.find(
      (p) => isContactLensesProduct(p) || isCLAccessoriesProduct(p)
    ) &&
    PARSED_ORDER_ITEMS?.filter(
      (t) => !(isContactLensesProduct(t) || isCLAccessoriesProduct(t))
    ).map((p) => getProductType(p)).length

  const hasTrackingInformation = PARSED_ORDER_ITEMS?.map(
    (order) =>
      order?.orderItemExtendAttribute?.find(
        (a) =>
          a.attributeName ===
          ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(
            orderDetails.orderId
          )
      )?.attributeValue
  ).filter((t) => t).length

  const trackUrls = orderDetails?.orderItem
    ?.map((item) => {
      if (orderDetails?.xtrackingUrls[item.orderItemId])
        return orderDetails?.xtrackingUrls[item.orderItemId]

      return null
    })
    .filter((item) => item !== null)

  const trackUrlLink = (trackUrls! && trackUrls[0]) || ''

  const onPrintReceiptClick = async () => {
    try {
      setDownloadReceiptStatus(true)
      const file = await orderService.requestInvoice({
        siteInfo: mySite,
        orderId: orderDetails.orderId,
      })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
      setDownloadReceiptStatus(false)
    } catch (e) {
      setDownloadReceiptStatus(false)
    }
  }

  const orderItemsStatuses = PARSED_ORDER_ITEMS?.map((o) =>
    getOrderReturnedStatus(o)
  ).filter((el) => el !== null)

  const orderStatus = (
    orderItemsStatuses?.length === PARSED_ORDER_ITEMS?.length
      ? orderItemsStatuses?.[0]
      : orderDetails.orderStatus
  ) as string

  return (
    <OrderDetailsWrapper>
      <OrderDetailsHeader>
        <OrderDetailsHeaderRow>
          <StyledDetailsTitle isUppercased variant="caption">
            <span>{t('Order.OrderNumber')}:</span> {orderDetails.orderId}
          </StyledDetailsTitle>
          {isDesktop && orderDetails.x_displayinvoice && (
            <>
              {downloadingReceipt && (
                <StyledLoader size={24} extraStyle={{ margin: 'none' }} />
              )}
              {!downloadingReceipt && (
                <StyledDetailsPrintReceipt onClick={onPrintReceiptClick}>
                  {t('Order.PrintReceipt')}
                </StyledDetailsPrintReceipt>
              )}
            </>
          )}
        </OrderDetailsHeaderRow>
        <OrderDetailsHeaderRow>
          <StyledDetailsTitle isUppercased variant="caption">
            <span>{t('Order.OrderDate')}:</span>{' '}
            {DateService.format(orderDetails.placedDate || '', 'dd/MM/yyyy')}
          </StyledDetailsTitle>
        </OrderDetailsHeaderRow>
        <OrderDetailsStatusRow>
          <StyledDetailsTitle isUppercased>
            <span>{t('Order.Status')}:</span>{' '}
            {t(`Order.Status_.${orderStatus}`)}
          </StyledDetailsTitle>
          {!isDesktop && orderDetails.x_displayinvoice && (
            <>
              <OrderDetailsHeaderRow>
                <StyledDetailsPrintReceipt onClick={onPrintReceiptClick}>
                  {t('Order.PrintReceipt')}
                </StyledDetailsPrintReceipt>
              </OrderDetailsHeaderRow>
            </>
          )}
          <div>
            {!!hasTrackingInformation &&
              !isMixedOrder &&
              SHIPPED_STATUSES.includes(orderStatus) && (
                <StyledTrackButton to={trackUrlLink} external target="_blank">
                  {t('OrderDetails.Actions.TrackThisItem')}
                </StyledTrackButton>
              )}
          </div>
        </OrderDetailsStatusRow>
      </OrderDetailsHeader>

      <OrderDetailsItemList>
        {PARSED_ORDER_ITEMS?.map((orderItem) => {
          return (
            <OrderComponent
              key={orderItem.orderItemId}
              orderItem={orderItem}
              orderDetails={orderDetails}
            />
          )
        })}
      </OrderDetailsItemList>

      <OrderDetailsFooter>
        <OrderDetailsShippingPaymentDetails>
          <OrderDetailsShippingPaymentDetailsTitle>
            {t('Order.ShippingPaymentDetails')}
          </OrderDetailsShippingPaymentDetailsTitle>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingAddress')}:
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>
              {orderShippingAddress}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingMethod')}:
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>{shippingName}</OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.BillingAddress')}:
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>
              {orderBillingAddress}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.PaymentMethod')}:
            </OrderDetailsListItem>
            <OrderDetailsFooterItem>
              {orderDetails.paymentInstruction?.[0].piDescription}
            </OrderDetailsFooterItem>
          </OrderDetailsItemDetailsRow>
        </OrderDetailsShippingPaymentDetails>
        <OrderDetailsPriceDetailsWrapper>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>{t('Order.Subtotal')}: </OrderDetailsListItem>
            <OrderDetailsPriceDetailsItem>{`${subtotalCurrency} ${subtotal}`}</OrderDetailsPriceDetailsItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>{t('Order.Savings')}: </OrderDetailsListItem>
            <OrderDetailsPriceDetailsItem>{`${totalDiscountsCurrency} ${totalDiscounts}`}</OrderDetailsPriceDetailsItem>
          </OrderDetailsItemDetailsRow>
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.ShippingCost')}:{' '}
            </OrderDetailsListItem>
            <OrderDetailsPriceDetailsItem>{`${shippingCurrency} ${shipping}`}</OrderDetailsPriceDetailsItem>
          </OrderDetailsItemDetailsRow>
          {/* hide taxes for GB - https://abstractsrl.atlassian.net/browse/DCW-5268 */}
          {country && country.toLowerCase() !== 'gb' && (
            <OrderDetailsItemDetailsRow>
              <OrderDetailsListItem>{t('Order.Taxes')}: </OrderDetailsListItem>
              <OrderDetailsPriceDetailsItem>{`${taxCurrency} ${tax}`}</OrderDetailsPriceDetailsItem>
            </OrderDetailsItemDetailsRow>
          )}
          <OrderDetailsItemDetailsRow>
            <OrderDetailsListItem>
              {t('Order.OrderTotal')}:{' '}
            </OrderDetailsListItem>
            <OrderDetailsPriceDetailsTotalItem>{`${grandTotalCurrency} ${grandTotal}`}</OrderDetailsPriceDetailsTotalItem>
          </OrderDetailsItemDetailsRow>
        </OrderDetailsPriceDetailsWrapper>
      </OrderDetailsFooter>
    </OrderDetailsWrapper>
  )
}

export default OrderDetails
