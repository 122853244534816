import {
  PRODUCT_TYPES_KEYS,
  PRODUCT_TYPES_MAP,
  SUGGESTED_PRODUCT_FAMILY,
} from '../constants/product'
import Log from '../services/Log'

import {
  Attachment,
  PictureType,
  ProductImageUsage,
  ServerProduct,
} from '../types/product'
import { getNormalizedProductType } from './productAttributes'

// it normalizes the product type string by using the PRODUCT_TYPES_MAP
export const normalizedProductType = (pt: string) =>
  PRODUCT_TYPES_MAP[pt?.toLowerCase()]

// takes a product type string and check whether it is a sun product
export const isSun = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.SUN

// takes a product type string and check whether it is a contact lenses product
export const isContactLenses = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a product type string and check whether it is an optical product
export const isOptical = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a product type string and check whether it is a frames product
export const isFrames = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.FRAMES

// takes a product type string and check whether it is an accessories product
export const isAccessories = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a product type string and check whether it is a contact lenses accessories product
export const isCLAccessories = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

// takes a product type string and check whether it is an electronics product
export const isElectronics = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ELECTRONICS

// takes a Product object and check whether it is a sun product
export const isSunProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.SUN

// takes a Product object and check whether it is a contact lenses product
export const isContactLensesProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a Product object and check whether it is an optical product
export const isOpticalProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a Product object and check whether it is a frames product
export const isFramesProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.FRAMES

// takes a Product object and check whether it is an accessories product
export const isAccessoriesProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a Product object and check whether it is a contact lenses accessories product
export const isCLAccessoriesProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

// takes a Product object and check whether it is an electronics product
export const isElectronicsProduct = (p) =>
  getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.ELECTRONICS

export const getCurrentProductItem = (
  partNumber: string,
  cluster?: ServerProduct[]
): ServerProduct | null => {
  try {
    const product = cluster
      ?.map((p) => {
        let item: ServerProduct | null

        if (isContactLensesProduct(p) || isCLAccessoriesProduct(p)) {
          item = p.partNumber === partNumber ? p : null
        } else {
          item = !!p.items
            ? p.items.find(
                (item: ServerProduct) => item.partNumber === partNumber
              ) || null
            : null
        }

        return item
      })
      .find((item) => !!item)
    const itemBean = !!product ? getCurrentProductItemBean(product) : null
    return itemBean || product || null
  } catch (e: any) {
    Log.error('erro retrieving current product item', e)
    return null
  }
}

export const getCurrentProductItemByUniqId = (
  id: string,
  cluster?: ServerProduct[]
): ServerProduct | null => {
  try {
    const product = cluster
      ?.map((p) => {
        let item: ServerProduct | null
        item = !!p.items
          ? p.items.find((item: ServerProduct) => item.uniqueID === id) || null
          : null

        return item
      })
      .find((item) => !!item)
    const itemBean = !!product ? getCurrentProductItemBean(product) : null
    return itemBean || product || null
  } catch (e: any) {
    Log.error('erro retrieving current product item', e)
    return null
  }
}

export const getCurrentProductItemBean = (
  product?: ServerProduct
): ServerProduct | null => {
  try {
    const itemBean =
      product?.catalogEntryTypeCode === 'ProductBean'
        ? product?.items?.find((item) => !!item)
        : product
    return itemBean || null
  } catch (e: any) {
    Log.error('erro retrieving item bean', e)
    return null
  }
}

export const getCurrentCluster = (
  partNumber: string,
  cluster?: ServerProduct[]
): ServerProduct[] | null => {
  return (
    cluster?.filter((cluster) => {
      return !!cluster?.items?.find((item) => item.partNumber === partNumber)
    }) || null
  )
}

export const getMerchandisingProducts = (
  partNumber: string,
  cluster?: ServerProduct[]
) => {
  let merchandising: any
  const product = cluster
    ?.map((p) => {
      if (isContactLensesProduct(p)) {
        if (p.partNumber === partNumber)
          merchandising = p?.merchandisingAssociations
      } else {
        p?.items?.map((item: ServerProduct) => {
          if (item.partNumber === partNumber)
            merchandising = p?.merchandisingAssociations
        })
      }

      return merchandising
    })
    .find((item) => !!item)

  return product || null
}

export const getSuggestedProducts = (merchandisingProducts: any) => {
  return merchandisingProducts.filter(
    (item) =>
      item.associationType?.toString().toLowerCase() ===
        SUGGESTED_PRODUCT_FAMILY.X_SELL_FAMILY ||
      item.associationType?.toString().toLowerCase() ===
        SUGGESTED_PRODUCT_FAMILY.CL_FAMILY
  )
}

export const generateProductImagePath = (
  url: string,
  usage: ProductImageUsage,
  product?: ServerProduct,
  pictureType?: PictureType,
  attachments?: Attachment[],
  sequence?: string,
  isFramesProduct?: boolean
) => {
  const attachment =
    attachments &&
    attachments.find((attachment) => {
      return !isAccessoriesProduct(product)
        ? attachment.usage?.toLowerCase() === usage.toLowerCase() &&
            (pictureType
              ? attachment.name.toLowerCase() === pictureType.toLowerCase()
              : true) &&
            (sequence
              ? attachment.sequence.toLowerCase() === sequence.toLowerCase() ||
                (isFramesProduct && attachment.sequence.toLowerCase() === '1.0')
              : true)
        : (pictureType
            ? attachment.name.toLowerCase() === pictureType.toLowerCase()
            : true) &&
            (sequence
              ? attachment.sequence.toLowerCase() === sequence.toLowerCase()
              : true)
    })

  const url404 = process.env.PUBLIC_URL + '/images/common/404.svg'
  const imagePath = attachment
    ? url + attachment.attachmentAssetPathRaw
    : url404
  return imagePath
}

export const replaceSlashesFromAttribute = (attr, replacement) => {
  try {
    return attr?.toString().replace(new RegExp('/', 'g'), replacement)
  } catch (e: any) {
    Log.error('error replacing slash in attribute', e)
    return attr
  }
}
