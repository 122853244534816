import {
  AffirmIcon,
  AmExIcon,
  ApplePayIcon,
  DiscoverIcon,
  KlarnaIcon,
  MastercardIcon,
  PayPalIcon,
  VisaIcon,
} from '../components/UI/Icons'

import { FC } from 'react'

import storeUtil from '../utils/storeUtil'
import { CustomSVGIconProps } from '../components/UI/Icons/icon.type'

const KEYS = {
  AFFIRM: 'AFFIRM',
  AMEX: 'AMEX',
  APPLE_PAY: 'APPLE_PAY',
  COD: 'COD',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MASTERCARD',
  PAYPAL: 'PAYPAL',
  VISA: 'VISA',
  KLARNA: 'KLARNA',
} as const

type Key = keyof typeof KEYS
export type Icon = FC<CustomSVGIconProps>

const {
  AFFIRM,
  AMEX,
  APPLE_PAY,
  COD,
  DISCOVER,
  MASTERCARD,
  PAYPAL,
  VISA,
  KLARNA,
} = KEYS

/**
 * @constant
 * Map of payment methods names fetched from usable_payment_info request ("paymentMethodName" property from "usablePaymentInfo" array)
 */
const CHECKOUT_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'CyberSourceAmex',
  [APPLE_PAY]: 'ApplePay',
  [COD]: 'cod',
  [DISCOVER]: 'CyberSourceDiscover',
  [MASTERCARD]: 'CyberSourceMC',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CyberSourceVisa',
  [KLARNA]: 'CyberSourceKlarna',
} as const

/**
 * @constant
 * Map of payment methods names fetched payment instruction piMethodId
 */
const REVERSED_CHECKOUT_CYBERSOURCE_NAMES = {
  '? Affirm': AFFIRM,
  CyberSourceAmex: [AMEX],
  ApplePay: [APPLE_PAY],
  cod: [COD],
  CyberSourceDiscover: [DISCOVER],
  CyberSourceMC: [MASTERCARD],
  PayPal: [PAYPAL],
  CyberSourceVisa: [VISA],
  CyberSourceKlarna: [KLARNA],
} as const

/**
 * @constant
 * Map of all credit cards methods
 */
const CHECKOUT_CC_NAMES: CheckoutCCName = [
  CHECKOUT_NAMES[AMEX],
  CHECKOUT_NAMES[DISCOVER],
  CHECKOUT_NAMES[MASTERCARD],
  CHECKOUT_NAMES[VISA],
]

/**
 * Type of payment method name. Equals to "paymentMethodName" property in "usablePaymentInfo" array (from server)
 */
export type CheckoutName = typeof CHECKOUT_NAMES[keyof typeof CHECKOUT_NAMES]

/**
 * Type of payment method name. Equals to "paymentMethodName" property in "usablePaymentInfo" array (from server)
 */
export type CheckoutCCName = CheckoutName[]

/**
 * @constant
 * Map of payment methods names that are fetched from server and stored at {@linkcode xStoreCfg.paymentMethods} string array.
 * Displayed at footer's "Payment methods" section.
 */
const FOOTER_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'Amex',
  [APPLE_PAY]: 'ApplePay',
  [DISCOVER]: 'Discover',
  [MASTERCARD]: 'MasterCard',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CreditCard',
  [KLARNA]: 'Klarna',
} as const

/**
 * Type of payment method name. Equals to one of the strings from {@linkcode xStoreCfg.paymentMethods} array
 */
export type FooterName = typeof FOOTER_NAMES[keyof typeof FOOTER_NAMES]

const ICONS: Record<Key, Icon | null> = {
  [AFFIRM]: AffirmIcon,
  [AMEX]: AmExIcon,
  [APPLE_PAY]: ApplePayIcon,
  [COD]: null,
  [DISCOVER]: DiscoverIcon,
  [MASTERCARD]: MastercardIcon,
  [PAYPAL]: PayPalIcon,
  [VISA]: VisaIcon,
  [KLARNA]: KlarnaIcon,
} as const

const PAY_OPTIONS = { cod: 'COD', cc: 'CC' }

export const PAYMENT_METHODS = {
  CHECKOUT_NAMES,
  FOOTER_NAMES,
  ICONS,
  PAY_OPTIONS,
  CHECKOUT_CC_NAMES,
  REVERSED_CHECKOUT_CYBERSOURCE_NAMES,
}

/**
 * @constant
 * Map of all errors code received from Cybersource
 */
export const SECURE_PAYMENT_CODES = {
  100: '100',
  101: '101',
  102: '102',
  150: '150',
  151: '151',
  152: '152',
  231: '231',
  233: '233',
  234: '234',
  236: '236',
  239: '239',
  241: '241',
  244: '244',
  250: '250',
  254: '254',
  255: '255',
  475: '475',
  476: '476',
} as const

/**
 * Type of Cybersource codes
 */
export type SecurePaymentCode =
  typeof SECURE_PAYMENT_CODES[keyof typeof SECURE_PAYMENT_CODES]

/**
 * Type of Cybersource success codes
 */
export type SecurePaymentSuccessCode = SecurePaymentCode[]

/**
 * Type of Cybersource error codes
 */
export type SecurePaymentErrorCode = SecurePaymentCode[]

/**
 * @constant
 * Map of all Cybersource success codes
 */
export const SECURE_PAYMENT_SUCCESS_CODES: SecurePaymentSuccessCode = [
  SECURE_PAYMENT_CODES[100],
  SECURE_PAYMENT_CODES[475],
]

/**
 * @constant
 * Map of all Cybersource error codes
 */
export const SECURE_PAYMENT_ERROR_CODES: SecurePaymentErrorCode = [
  SECURE_PAYMENT_CODES[101],
  SECURE_PAYMENT_CODES[102],
  SECURE_PAYMENT_CODES[150],
  SECURE_PAYMENT_CODES[151],
  SECURE_PAYMENT_CODES[152],
  SECURE_PAYMENT_CODES[231],
  SECURE_PAYMENT_CODES[233],
  SECURE_PAYMENT_CODES[244],
  SECURE_PAYMENT_CODES[255],
  SECURE_PAYMENT_CODES[476],
]

/**
 * Checks if the entered credit card number is a numeric value
 * @returns Whether the card number format is valid.
 */
export const isValidCardNumber = (cardNumber: string): boolean => {
  return cardNumber.trim() === '' || storeUtil.isNumeric(cardNumber.trim())
}

/**
 * Checks if the entered expiration date is a valid value
 * @returns Whether the date is valid.
 */
export const isValidCardExpirationDate = (date: [number, number]) => {
  const [month, year] = date

  if (month > 12 || isNaN(month) || isNaN(year)) {
    return true
  }

  const inputDate = new Date(year + 2000, month)
  const currentDate = new Date()

  return inputDate.getTime() < currentDate.getTime()
}

/**
 * Checks if the entered cc holder name has value
 * @returns Whether the cc holder name is valid.
 */
export const isValidCardHolderName = (cardHolderName: string): boolean => {
  return cardHolderName.trim() === '' || cardHolderName.trim().indexOf(' ') >= 0
}

/**
 * Checks if the entered cvv code entered is a numeric value
 * @returns Whether the cvv code format is valid.
 */
export const isValidCode = (
  cardCVC: string,
  cardType: string | null
): boolean => {
  const isValidInput =
    cardCVC.trim() === '' || storeUtil.isNumeric(cardCVC.trim())
  const isValidCodeLength =
    cardType === CHECKOUT_NAMES['AMEX']
      ? cardCVC.length === 4
      : cardCVC.length === 3

  return isValidInput && isValidCodeLength
}
