import {
  GENERIC_PROTECTED,
  HOME,
  REGISTER_PROTECTED,
  SIGNIN,
} from '../../constants/routes'
//Standard libraries
import React, { useEffect } from 'react'
//Redux
import {
  loginStatusSelector,
  userInitStatusSelector,
} from '../../redux/selectors/user'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import { generatePath } from 'react-router-dom'
//Custom libraries
import { routes } from '../../configs/routes'
import { useSelector } from 'react-redux'
//Foundation libraries
import { useSite } from '../hooks/useSite'
import { useStoreIdentity } from '../hooks/useStoreIdentity'

const LoginGuard: React.FC = () => {
  const { mySite } = useSite()
  const location = useLocation()
  const navigate = useNavigate()
  const loginStatus = useSelector(loginStatusSelector)
  const userInited = useSelector(userInitStatusSelector)
  const { langCode } = useStoreIdentity()

  useEffect(() => {
    if (mySite && userInited) {
      let pathnameArray: string[] = location.pathname.split('/')
      const locationHasLangCode: string | undefined = pathnameArray.find(
        (e) => e?.toLowerCase() === langCode.toLowerCase()
      )

      if (pathnameArray?.length > 2 && !!locationHasLangCode)
        pathnameArray = pathnameArray.filter(
          (e) => e.toLowerCase() !== langCode.toLowerCase()
        )

      const pathname: string = pathnameArray.join('/')

      const branch = matchRoutes(routes, pathname)

      let registerProtectedRoute
      let genericProtectedRoute

      if (!loginStatus) {
        registerProtectedRoute = branch?.some((b) => {
          return b.route['isProtected'] === REGISTER_PROTECTED
        })
      } else {
        genericProtectedRoute = branch?.some((b) => {
          return b.route['isProtected'] === GENERIC_PROTECTED
        })
      }

      if (!!registerProtectedRoute)
        navigate(generatePath(SIGNIN, { country: langCode }))

      if (!!genericProtectedRoute)
        navigate(generatePath(HOME, { country: langCode }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loginStatus, mySite, userInited])

  return <></>
}

export default LoginGuard
