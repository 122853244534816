import React from 'react'
import BenefitBarSkeleton from '../Header/components/HeaderSkeleton/BenefitBarSkeleton'
import NavigationBarSkeleton from '../Header/components/HeaderSkeleton/NavigationBarSkeleton'
import PromoBarSkeleton from '../Header/components/HeaderSkeleton/PromoBarSkeleton'

const BaseLayoutSkeleton = () => {
  return (
    <div className="_skeleton">
      <BenefitBarSkeleton />
      <NavigationBarSkeleton />
      <PromoBarSkeleton />
    </div>
  )
}

export default BaseLayoutSkeleton
