import React, { Fragment } from 'react'
import {
  ContactLensAttributesList,
  ContactLensAttributesListItem,
  ContactLensDetailsContent,
  ContactLensAttributesListItemTitle,
} from './OrderRecapClItemLensDetails.style'
import { OrderItemContactLensData } from '../../types/cart'
import { useTranslation } from 'react-i18next'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import forEach from 'lodash/forEach'
import map from 'lodash/map'

export const OrderRecapClItemLensDetailsContent: React.FC<{
  orderItemClData: OrderItemContactLensData
  showBoxesNumber?: boolean
}> = ({ orderItemClData, showBoxesNumber = false }) => {
  const { t } = useTranslation()
  const labelsArray = ['eye']
  const getLabels = (): string[] => {
    !!orderItemClData &&
      Object.keys(orderItemClData).map((eye, _i) => {
        !!orderItemClData[eye].data &&
          forEach(orderItemClData[eye].data, function (_, key) {
            labelsArray.push(key)
          })
        showBoxesNumber &&
          !!orderItemClData[eye].quantity &&
          labelsArray.push('Title_Boxes')
      })

    return uniq(labelsArray)
  }

  return (
    <ContactLensDetailsContent>
      <>
        <ContactLensAttributesList>
          {getLabels().map((label, i) => {
            return (
              <ContactLensAttributesListItem key={`${label}_${i}`}>
                <ContactLensAttributesListItemTitle>
                  {t(`CartRecap.Labels.${label}`)}
                </ContactLensAttributesListItemTitle>
              </ContactLensAttributesListItem>
            )
          })}
        </ContactLensAttributesList>
        {!!orderItemClData &&
          Object.keys(orderItemClData).map((eye, i) => {
            return (
              <Fragment key={`${eye}_${i}`}>
                <ContactLensAttributesList>
                  {!!orderItemClData[eye].data &&
                  size(orderItemClData[eye].data) > 0 ? (
                    <ContactLensAttributesListItem>
                      <span>{eye}</span>
                    </ContactLensAttributesListItem>
                  ) : null}

                  {!!orderItemClData[eye].data &&
                    map(orderItemClData[eye].data, (value, key) => {
                      return (
                        <ContactLensAttributesListItem key={`${key}_${value}`}>
                          <span>{value}</span>
                        </ContactLensAttributesListItem>
                      )
                    })}

                  {showBoxesNumber && !!orderItemClData[eye].quantity && (
                    <ContactLensAttributesListItem
                      key={`${orderItemClData[eye].quantity}`}
                    >
                      <span>
                        {Math.floor(+(orderItemClData[eye].quantity || 0))}
                      </span>
                    </ContactLensAttributesListItem>
                  )}
                </ContactLensAttributesList>
              </Fragment>
            )
          })}
      </>
    </ContactLensDetailsContent>
  )
}
