import React, { useEffect, useRef, useState } from 'react'
import {
  FrameAdvisorConfig,
  Product,
  ProductsResData,
  UserData,
} from '../../types/FrameGenius/frameAdvisor'
import FrameAdvisorUtil from '../../utils/FrameGenius/FrameAdvisorUtil'
import FrameGeniusService from '../../foundation/apis/frame-genius/frame-genius.service'
import { ServerProduct } from '../../types/product'
import { generatePath, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import Axios, { Canceler } from 'axios'
import { CART, SIGNIN } from '../../constants/routes'
import { addItem } from '../../features/order/thunks'
import { useSite } from '../../foundation/hooks/useSite'
import config from '../../configs'
import { useFrameGenius } from './FrameGeniusContext'
import SizeAdvisorUtil from '../../utils/FrameGenius/SizeAdvisorUtil'
import FrameGeniusUtil from '../../utils/FrameGenius/FrameGeniusUtil'
import isEmpty from 'lodash/isEmpty'
import { loginStatusSelector } from '../../redux/selectors/user'
import { storeCfgServiceLinksSelector } from '../../redux/selectors/site'
import { usePrescriptionLenses } from '../PrescriptionLenses/PrescriptionLensesContext'
import {
  StyledFrameAdvisor,
  StyledFrameAdvisorOverlay,
  StyledFrameAdvisorWrapper,
} from './FrameAdvisor.styles'

const frameGeniusConfig = config.frameGenius

const FRAME_ADVISOR = {
  WRAPPER_WIDTH: '376px',
  WRAPPER_HEIGHT: '668px',
  MINIMIZED_CLASS: 'fa-minimized',
  SELECTOR: 'frameadv-ui',
}

enum FrameAdvisorEvents {
  maximize = 'fa-maximize',
  minimize = 'fa-minimize',
  loading = 'fa-loading',
  loaded = 'fa-loaded',
  close = 'fa-close',
  showFacescanapp = 'fa-show-facescanapp',
}

let saveUserDataCallback = () => {}
const FrameAdvisor = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { langId, langCode } = useStoreIdentity()
  const userLoggedIn = useSelector(loginStatusSelector) || false
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const { mySite } = useSite()

  const frameGeniusData = useFrameGenius()
  const [whiteLabelWidget, setWhiteLabelWidget] = useState<any>()

  const isFrameAdvisorMinimized = FrameAdvisorUtil.isFloatingButtonMinimized()

  const [isMinimized, setMinimizedStatus] = useState<boolean>(
    isFrameAdvisorMinimized
  )
  const { basePath } = useStoreIdentity()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)

  const [productsFromServer, setProductsFromServer] = useState<ServerProduct[]>(
    []
  )
  const [product, setProduct] = useState<Product | null>(null)

  const {
    isOpenPrescriptionLensesForm,
    setPrescriptionLensesFormOpen,
    setPrescriptionLenses,
  } = usePrescriptionLenses()

  const damDomain: string = mySite.xStoreCfg
    ? mySite.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain
  const frameGeniusStoreconfs = mySite.xStoreCfg.frameGenius

  const handleAddToCart = (product: Product) => {
    const productForAnalytics = productsFromServer.find(
      (serverProduct) => serverProduct.partNumber === product.upc
    )

    const params = {
      partnumber: product?.upc,
      quantity: ['1'],
      langId,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c)
      }),
      product: productForAnalytics,
      callback: () => {
        navigate(generatePath(`/:country/${CART}`, { country: langCode }))
      },
    }
    dispatch(addItem(params))
  }

  const getFrameAdvisorWidget = () => {
    const locale = mySite.locale

    const baseConfiguration = FrameGeniusUtil.getBaseConfiguration(
      basePath,
      serviceLinks,
      locale,
      frameGeniusConfig.source,
      frameGeniusConfig.frameAdvKey
    )

    const frameAdvisorConfig: FrameAdvisorConfig = {
      ...baseConfiguration,
      selector: `#${FRAME_ADVISOR.SELECTOR}`,
      //initPositionFloatingBtn: frameGeniusConfig.frameAdvisor.initPositionFloatingBtn,
      multibrand: frameGeniusConfig.frameAdvisor.multibrand,
      frameAdvAPICore: 'fa_catalog',
      frameAdvAPIStore: FrameGeniusUtil.getAPIStore(
        locale,
        frameGeniusConfig.frameAdvisor.apiPrefix
      ),
      startMinimized: isMinimized,
      enableSizeAdvisor: frameGeniusStoreconfs.isSizeAdvisorEnabled,
      enableVideoMode: frameGeniusConfig.frameAdvisor.enableVideoMode,
      productRequestRowsLimit:
        frameGeniusConfig.frameAdvisor.productRequestRowsLimit,
      defaultProductType: 'eyeglasses',
      resultCallback: async (data: ProductsResData) => {
        const isDebuggingModeEnabled =
          frameGeniusConfig.frameAdvisor.isDebuggingModeEnabled
        FrameAdvisorUtil.setFrameAdvisorInitializedStatus(true)
        FrameAdvisorUtil.setFloatingButtonMinimizedState(true)
        if (isDebuggingModeEnabled) {
          return FrameAdvisorUtil.mockResultsCallback(data) as Promise<
            Product[]
          >
        }
        const frameAdvisorProducts = await FrameAdvisorUtil.fetchProducts(
          data,
          mySite,
          langId
        )
        setProductsFromServer(frameAdvisorProducts)
        const mappedProducts = frameAdvisorProducts.map((product) =>
          FrameAdvisorUtil.mapServerProductForFrameAdvisor(
            basePath,
            product,
            damDomain
          )
        )
        const mergedProducts = FrameAdvisorUtil.mergeProductsByUpc(
          mappedProducts,
          data.products
        )
        return mergedProducts
      },
      addToBagCallback: handleAddToCart,
      openRXConfigurator: async (product: Product) => {
        setProduct(product)
      },
      resetProfileDataCallback: () => {
        FrameGeniusUtil.clearAnalysisResults()
        FrameAdvisorUtil.setFloatingButtonMinimizedState(false)
        if (!userLoggedIn) {
          return null
        }
        return FrameGeniusService.resetProfileData(mySite.storeID)
      },
      saveProfileDataCallback: async (data) => {
        if (!userLoggedIn) {
          return new Promise((resolve) => {
            saveUserDataCallback = () => {
              FrameGeniusService.saveProfileData(data, mySite.storeID)
              resolve()
            }
            FrameAdvisorUtil.setFloatingButtonMinimizedState(true)
            navigate(generatePath(SIGNIN, { country: langCode }))
          })
        } else {
          return FrameGeniusService.saveProfileData(data, mySite.storeID)
        }
      },
      getProfileDataCallback: () => {
        if (!userLoggedIn) {
          return Promise.resolve({} as UserData)
        }
        return FrameGeniusService.getProfileData(mySite.storeID)
      },
      saveSessionData: (data) => {
        FrameAdvisorUtil.saveSessionData(data)
      },
      getSessionData: () => {
        return FrameAdvisorUtil.getSessionData()
      },
    }

    // TODO: Remove "as any" casting for window object
    const frameAdvisorWidget = (
      window as any
    ).frameAdvisor.FrameAdvisorWidget.new(frameAdvisorConfig)

    if (SizeAdvisorUtil.doesUrlContainSizeAdvisorHashPath()) {
      SizeAdvisorUtil.removeSizeAdvisorHashPath()
    }

    return frameAdvisorWidget
  }

  const faRef = useRef<HTMLDivElement>(null)

  const initFrameAdvisor = () => {
    const whiteLabelWidget = getFrameAdvisorWidget()
    setWhiteLabelWidget(whiteLabelWidget)
    whiteLabelWidget.render()
  }

  useEffect(() => {
    if (userLoggedIn) {
      saveUserDataCallback()
      saveUserDataCallback = () => {}
    }
  }, [userLoggedIn])

  useEffect(() => {
    if (product) {
      const productForRxc = productsFromServer.find(
        (serverProduct) => serverProduct.partNumber === product.upc
      )
      if (!productForRxc) {
        return
      }

      setPrescriptionLenses(productForRxc)
      setProduct(null)
      setPrescriptionLensesFormOpen(true)
    }
  }, [product])

  useEffect(() => {
    if (!isOpenPrescriptionLensesForm) {
      setPrescriptionLenses(null)
    }
  }, [isOpenPrescriptionLensesForm])

  useEffect(() => {
    if (frameGeniusStoreconfs.isFrameAdvisorEnabled) {
      const frameGeniusScript = frameGeniusStoreconfs.scriptUrl
      if (!frameGeniusData.areScriptsLoaded) {
        FrameGeniusUtil.loadFrameGeniusScripts([frameGeniusScript], () => {
          initFrameAdvisor()
          frameGeniusData.setLoadedScriptsStatus(true)
        })
      } else {
        initFrameAdvisor()
      }
    }
  }, [])

  useEffect(() => {
    if (!whiteLabelWidget || !isEmpty(frameGeniusData.analysisResults)) return
    const getUserData = async () => {
      try {
        const userData = await whiteLabelWidget?.getUserData()
        if (userData) {
          FrameGeniusUtil.setAnalysisResults(userData)
          frameGeniusData.setAnalysisResults(userData)
          return
        } else {
          frameGeniusData.closeFrameAdvisor()
        }
        FrameAdvisorUtil.setFloatingButtonMinimizedState(false)
        setMinimizedStatus(false)
      } catch (e) {}
    }
    getUserData()

    return () => {
      if (
        whiteLabelWidget &&
        isEmpty(frameGeniusData.analysisResults) &&
        isMinimized
      ) {
        getUserData()
      }
    }
  }, [whiteLabelWidget, frameGeniusData, isMinimized])

  useEffect(() => {
    if (!whiteLabelWidget) return
    const updateLocalUserData = async () => {
      try {
        const userData = await whiteLabelWidget?.getUserData()
        if (userData) {
          FrameGeniusUtil.setAnalysisResults(userData)
          frameGeniusData.setAnalysisResults(userData)
          return
        } else {
          frameGeniusData.closeFrameAdvisor()
        }
      } catch (e) {}
    }
    updateLocalUserData()
  }, [whiteLabelWidget])

  useEffect(() => {
    const body = document.querySelector('body')

    const onMaximize = () => {
      FrameAdvisorUtil.setFloatingButtonMinimizedState(false)
      setMinimizedStatus(false)
    }

    const onMinimize = () => {
      FrameAdvisorUtil.setFloatingButtonMinimizedState(true)
      setMinimizedStatus(true)
    }

    const onClose = () => {
      setMinimizedStatus(true)
      frameGeniusData.closeFrameAdvisor()
    }
    if (body) {
      body.addEventListener(FrameAdvisorEvents.maximize, onMaximize)
      body.addEventListener(FrameAdvisorEvents.minimize, onMinimize)
      body.addEventListener(FrameAdvisorEvents.close, onClose)
    }

    return () => {
      if (body) {
        body.removeEventListener(FrameAdvisorEvents.maximize, onMaximize)
        body.removeEventListener(FrameAdvisorEvents.minimize, onMinimize)
        body.removeEventListener(FrameAdvisorEvents.close, onClose)
      }
    }
  }, [])

  return (
    <>
      <StyledFrameAdvisorWrapper
        className={`${FRAME_ADVISOR.SELECTOR}-wrapper ${
          isMinimized ? FRAME_ADVISOR.MINIMIZED_CLASS : ''
        }`}
      >
        <StyledFrameAdvisor
          id={FRAME_ADVISOR.SELECTOR}
          className={`${FRAME_ADVISOR.SELECTOR} ${
            isMinimized ? FRAME_ADVISOR.MINIMIZED_CLASS : ''
          }`}
          ref={faRef}
          data-analytics_available_call="0"
        />
        {!isMinimized && (
          <StyledFrameAdvisorOverlay
            className={`${FRAME_ADVISOR.SELECTOR}-overlay`}
          />
        )}
      </StyledFrameAdvisorWrapper>
    </>
  )
}

export default FrameAdvisor
