//Login
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGON_AND_CHANGE_PASSWORD_REQUESTED =
  'LOGON_AND_CHANGE_PASSWORD_REQUESTED'
export const LOGON_AND_CHANGE_PASSWORD_FAIL = 'LOGON_AND_CHANGE_PASSWORD_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SESSION_ERROR_LOGIN_REQUESTED = 'SESSION_ERROR_LOGIN_REQUESTED'
export const SESSION_ERROR_LOGIN_SUCCESS = 'SESSION_ERROR_LOGIN_SUCCESS'
export const SESSION_ERROR_LOGIN_ERROR = 'SESSION_ERROR_LOGIN_ERROR'
export const GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS'
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'
export const REGISTRATION_PENDING = 'REGISTRATION_PENDING'

//storage
export const INIT_STATE_FROM_STORAGE = 'INIT_STATE_FROM_STORAGE'
export const INIT_USER_FROM_STORAGE_SUCCESS =
  'INIT_USER_STATE_FROM_STORAGE_SUCCESS'
export const LISTEN_USER_FROM_STORAGE = 'LISTEN_USER_FROM_STORAGE'

// user details
export const FETCH_USER_DETAILS_REQUESTED = 'FETCH_USER_DETAILS_REQUESTED'
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'
export const NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED =
  'NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED'
