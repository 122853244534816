import {
  //de,
  //deAT,
  //enCA,
  enGB,
  //enIN,
  //enUS,
  //es,
  //fr,
  //it,
  //ja,
  //ko,
  //pl,
  //ptBR,
  //ro,
  //ru,
  //zhCN,
  //zhTW,
} from 'date-fns/locale'

export const DATE_FORMAT_PATTERN = {
  'MMMM d, yyyy': 'MMMM d, yyyy', // July 3, 2021,
  'd MMMM yyyy': 'd MMMM yyyy', // 3 July 2021,
  'yyyy-MM-dd': 'yyyy-MM-dd', // 2021-07-03
  'dd/MM/yyyy': 'dd/MM/yyyy', // 03/07/2021
  'MM/dd/yyyy': 'MM/dd/yyyy',
} as const

export const DATE_FNS_LOCALE_MAP = {
  en_GB: enGB,
  /*de_AT: deAT,
  de_DE: de,
  en_CA: enCA,
  en_GB: enGB,
  en_IE: enIN,
  en_US: enUS,
  es_ES: es,
  fr_FR: fr,
  it_IT: it,
  ja_JP: ja,
  ko_KR: ko,
  nl_NL: enUS,
  pl_PL: pl,
  pt_BR: ptBR,
  pt_PT: enUS,
  ro_RO: ro,
  ru_RU: ru,
  zh_CN: zhCN,
  zh_TW: zhTW,*/
  // en_WW: 'en_WW',
}
