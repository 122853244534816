import React, { Fragment } from 'react'
import { ContactLensAttributesListItemTitle } from './OrderRecapClItemLensDetails.style'
import { OrderItemContactLensData } from '../../types/cart'
import { useTranslation } from 'react-i18next'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import {
  ContactLensAttributesList,
  ContactLensDetailsContent,
  ContactLensAttributesListItem,
  ContactLensEyeAttribute,
  ContactLensAttributesListItemDividerMobile,
} from './OrderRecapClItemLensDetailsContentMobile.style'

export const OrderRecapClItemLensDetailsContentMobile: React.FC<{
  orderItemClData: OrderItemContactLensData
}> = ({ orderItemClData }) => {
  const { t } = useTranslation()
  const labelsArray = ['']
  const getLabels = (): string[] => {
    !!orderItemClData &&
      Object.keys(orderItemClData).map((eye, _i) => {
        !!orderItemClData[eye].data &&
          forEach(orderItemClData[eye].data, function (_, key) {
            labelsArray.push(key)
          })
        orderItemClData[eye].quantity && labelsArray.push('Title_Boxes')
      })

    return uniq(labelsArray)
  }

  return (
    <ContactLensDetailsContent>
      <>
        <ContactLensAttributesList>
          {getLabels().map((label, i) => {
            return (
              <ContactLensAttributesListItem key={`${label}_${i}`}>
                <ContactLensAttributesListItemTitle>
                  {label && t(`CartRecap.Labels.${label}`)}
                  {i === getLabels().length - 1 && (
                    <ContactLensAttributesListItemDividerMobile />
                  )}
                </ContactLensAttributesListItemTitle>
              </ContactLensAttributesListItem>
            )
          })}
        </ContactLensAttributesList>
        {!!orderItemClData &&
          Object.keys(orderItemClData).map((eye, i) => {
            return (
              <Fragment key={`${eye}_${i}`}>
                <ContactLensAttributesList>
                  {!!orderItemClData[eye].data &&
                  size(orderItemClData[eye].data) > 0 ? (
                    <ContactLensEyeAttribute>
                      <span>
                        {t(`ContactLenses.Labels.${eye.toLowerCase()}Eye`)}
                      </span>
                    </ContactLensEyeAttribute>
                  ) : null}

                  {!!orderItemClData[eye].data &&
                    map(orderItemClData[eye].data, (value, key) => {
                      return (
                        <ContactLensAttributesListItem
                          key={`${key}_${value}`}
                          isValue
                        >
                          <span>{value}</span>
                        </ContactLensAttributesListItem>
                      )
                    })}

                  {!!orderItemClData[eye].quantity && (
                    <ContactLensAttributesListItem
                      key={`${orderItemClData[eye].quantity}`}
                      isValue
                    >
                      <span>
                        {Math.floor(+(orderItemClData[eye].quantity || 0))}
                      </span>
                    </ContactLensAttributesListItem>
                  )}
                </ContactLensAttributesList>
              </Fragment>
            )
          })}
      </>
    </ContactLensDetailsContent>
  )
}
