import { createAsyncThunk } from '@reduxjs/toolkit'
import { SiteInfo } from '../../redux/reducers'
import { storeLocatorUtils } from '../../utils/storeLocatorUtils'

export const setStoresData = createAsyncThunk(
  'stores/setStores',
  async (mySite: SiteInfo) => {
    const storesData = await storeLocatorUtils.fetchStores(
      'dc',
      mySite.xStoreCfg.storeLocatorScriptSrc
    )
    return storesData
  }
)
