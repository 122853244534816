import { PaginationResponse } from '../../services/RequestService'
import { ServerProduct } from '../../types/product'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),

  endpoints: (build) => ({
    getProductDetails: build.query<
      PaginationResponse<ServerProduct & { type: 'item' }>,
      any
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: args['queryParams'] || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
        })

        return result?.data
          ? {
              data: result.data as PaginationResponse<
                ServerProduct & { type: 'item' }
              >,
            }
          : { error: result.error }
      },
    }),
  }),
})

export const { useGetProductDetailsQuery, useLazyGetProductDetailsQuery } =
  productApi
