import values from 'lodash-es/values'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import {
  firstAvailableEmailContactSelector,
  loginStatusSelector,
} from 'src/redux/selectors/user'
import { ServerProduct } from 'src/types/product'
import { parseCatentriesForRX } from 'src/foundation/analytics/tealium/formatters/productFormatter'
import MonetateUtils from 'src/foundation/monetate/MonetateUtils'
import {
  cartSelector,
  catentriesSelector,
  isCartFtchedSelector,
  orderDetailsSelector,
  orderItemsSelector,
} from 'src/features/order/selector'
import { isRxCart } from 'src/utils/rx'
import { useCheckoutSteps } from '../useCheckoutSteps'
import { useAnalyticsPageData } from '../useAnalyticsPageData'
import { usePageType } from 'src/foundation/hooks/usePageType'
import {
  CheckoutAnalyticsPageViewPayload,
  CheckoutAnalyticsTypPageViewPayload,
} from 'src/types/analytics'
import { getFlattenParsedOrderItemsList } from 'src/utils/order'
import Log from 'src/services/Log'
import { useLocation } from 'react-router-dom'
import {
  sendDeliveryEvent,
  sendPaymentEvent,
  sendTYPData,
} from 'src/foundation/analytics/tealium/lib'
import { orderApi } from 'src/features/order/query'
import { siteSelector } from 'src/redux/selectors/site'

export const useCheckoutAnalyticsEvents = () => {
  const site = useSelector(siteSelector)
  const location = useLocation()
  const { pageType } = usePageType()
  const isCartFetched = useSelector(isCartFtchedSelector)
  const cart = useSelector(cartSelector)
  const catEntries = useSelector(catentriesSelector, shallowEqual)
  const orderItems = useSelector(orderItemsSelector)
  const orderDetails = useSelector(orderDetailsSelector) || null

  const isCurrentCartRX = isRxCart(cart?.orderExtendAttribute)
  const userLoggedIn = useSelector(loginStatusSelector)
  const { activeStep } = useCheckoutSteps()
  const firstAvailableEmailContact = useSelector(
    firstAvailableEmailContactSelector
  )
  const { pageViewAnalyticsData: analyticsDataForCheckout } =
    useAnalyticsPageData()
  const [checkoutPageViewAnalyticsData, setCheckoutPageViewAnalyticsData] =
    useState<
      CheckoutAnalyticsPageViewPayload | CheckoutAnalyticsTypPageViewPayload
    >()
  const [tracked, setTracked] = useState<boolean>(false)

  const orderApiQueryResult = useMemo(
    () =>
      orderApi.endpoints.findOrderdById.select({
        storeId: site?.storeID,
        orderId: cart?.orderId,
        shouldFetchCatentries: true,
      }),
    [cart, site]
  )
  const { data: orderApiData, isSuccess: isOrderGetSuccess } =
    useSelector(orderApiQueryResult)

  useEffect(() => {
    const cartRows = MonetateUtils.mapCartRows(orderItems)
    const purchaseRows = MonetateUtils.mapPurchaseRows(
      orderDetails?.orderId,
      cartRows
    )
    const flattenOrderItemsList = !!orderDetails?.parsedOrderItems
      ? getFlattenParsedOrderItemsList(orderDetails.parsedOrderItems)
      : null
    const products = isCurrentCartRX
      ? parseCatentriesForRX(
          flattenOrderItemsList!,
          values(catEntries) as ServerProduct[]
        )
      : (values(catEntries) as ServerProduct[])
    try {
      if (
        (!cart || (!!cart?.orderItem && cart.orderItem.length > 0)) &&
        isCartFetched &&
        pageType === 'checkout' &&
        !!analyticsDataForCheckout
      ) {
        switch (activeStep) {
          case 'shipping':
            setCheckoutPageViewAnalyticsData({
              common: analyticsDataForCheckout!,
              loginStatus: userLoggedIn,
              products,
              shipMode: cart?.orderItem?.find((oi) => oi)?.shipModeDescription,
            })
            break
          case 'payment':
            setCheckoutPageViewAnalyticsData({
              common: analyticsDataForCheckout!,
              loginStatus: userLoggedIn,
              products,
              email: firstAvailableEmailContact,
              shipMode: cart?.orderItem?.find((oi) => oi)?.shipModeDescription,
            })
            break
          case 'order-confirmation':
            orderDetails?.orderComplete &&
              isOrderGetSuccess &&
              setCheckoutPageViewAnalyticsData({
                common: analyticsDataForCheckout!,
                loginStatus: userLoggedIn,
                products: products,
                email: firstAvailableEmailContact,
                orderDetails: orderDetails!,
              })
            MonetateUtils.reTrack({
              pageType,
              purchaseRows,
            })
            break
        }

        if (activeStep !== 'checkout') {
          MonetateUtils.reTrack({
            pageType: activeStep,
            cartRows,
          })
        }
        if (activeStep === 'order-confirmation') {
          MonetateUtils.reTrack({
            pageType,
            purchaseRows,
          })
        }
      }
    } catch (e: any) {
      Log.error(window.location.href, 'ERROR IN CHECKOUT ANALYTICS DATA', e)
    }
  }, [
    orderDetails,
    activeStep,
    isCartFetched,
    pageType,
    cart,
    orderApiData,
    isOrderGetSuccess,
  ])

  useEffect(() => {
    setTracked(!!checkoutPageViewAnalyticsData)
    try {
      switch (activeStep) {
        case 'shipping':
          !!checkoutPageViewAnalyticsData &&
            !tracked &&
            sendDeliveryEvent(checkoutPageViewAnalyticsData)
          break
        case 'payment':
          !!checkoutPageViewAnalyticsData &&
            !tracked &&
            sendPaymentEvent(checkoutPageViewAnalyticsData)
          break
        case 'order-confirmation':
          !!checkoutPageViewAnalyticsData &&
            !tracked &&
            !!(
              checkoutPageViewAnalyticsData as CheckoutAnalyticsTypPageViewPayload
            ).orderDetails &&
            sendTYPData(
              checkoutPageViewAnalyticsData as CheckoutAnalyticsTypPageViewPayload
            )
          break
      }
    } catch (e: any) {
      Log.error(window.location.href, 'ERROR IN CHECKOUT ANALYTICS TRACKING', e)
    }
  }, [checkoutPageViewAnalyticsData])

  useEffect(() => {
    setTracked(false)
  }, [location])
}
