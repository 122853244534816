import { styled } from '@mui/material/styles'
import {
  ButtonAsLink,
  LinkAsButton,
  StyledTypography,
} from '../../../components/UI'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const OrderDetailsWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  rowGap: theme.spacing(8),
  marginBottom: theme.spacing(6),

  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6),
    rowGap: theme.spacing(6),
  },
}))

export const StyledDetailsTitle = styled(StyledTypography, {
  name: 'OrderDetails',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  textTransform: 'none',

  span: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

export const StyledTrackButton = styled(LinkAsButton, {
  name: 'OrderDetails',
  slot: 'StyledTrackButton',
})(({ theme }) => ({
  padding: 0,
  width: 174,
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}))

export const StyledDetailsPrintReceipt = styled(ButtonAsLink, {
  name: 'OrderDetails',
  slot: 'PrintReceipt',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  width: 'fit-content',
  margin: `${theme.spacing(2)} 0`,

  [getDesktopThemeBreakpoint(theme)]: {
    float: 'right',
    margin: 0,
  },
}))

export const StyledDetailsIssues = styled(StyledTypography, {
  name: 'OrderDetails',
  slot: 'Issues',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  textTransform: 'none',

  span: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

export const OrderDetailsHeader = styled('div', {
  name: 'OrderDetails',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flewWrap: 'wrap',
  color: theme.palette.text.dark.primary,
  padding: `${theme.spacing(6)} ${theme.spacing(4)} 0px ${theme.spacing(4)}`,
  rowGap: theme.spacing(2),

  [getDesktopThemeBreakpoint(theme)]: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 0,
  },
}))

export const OrderDetailsHeaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  [getDesktopThemeBreakpoint(theme)]: {
    justifyContent: 'space-between',
  },
}))

export const OrderDetailsStatusRow = styled(OrderDetailsHeaderRow)(
  ({ theme }) => ({
    flexDirection: 'column',
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: { flexDirection: 'row' },
  })
)

export const PrescriptionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `9px 0 15px ${theme.spacing(6)}`,
  backgroundColor: `${theme.palette.background.light.secondary}`,
  rowGap: theme.spacing(2.5),
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
    padding: `17px 25px 17px ${theme.spacing(6)}`,
    borderTop: `solid 1px ${theme.palette.background.light.tertiary}`,
    borderRight: `solid 1px ${theme.palette.background.light.tertiary}`,
    borderLeft: `solid 1px ${theme.palette.background.light.tertiary}`,
  },
}))

export const OrderDetailsItemWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const PrescriptionMessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1.5),
  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(2),
  },
}))

export const PrescriptionSubmitWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingRight: theme.spacing(6),
  [getDesktopThemeBreakpoint(theme)]: {
    paddingRight: 0,
  },
}))

export const PrescriptionIconWrapper = styled('div')(() => ({
  display: 'flex',
}))

export const PrescriptionMessageHeader = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
}))

export const PrescriptionMessageBody = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
}))

export const PrescriptionDetail = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  columnGap: theme.spacing(4),
}))

export const OrderDetailsItemList = styled('div', {
  name: 'OrderDetails',
  slot: 'ItemList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0px ${theme.spacing(4)} 0px ${theme.spacing(4)}`,
  rowGap: theme.spacing(8),

  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(6),
    padding: 0,
  },
}))

export const OrderDetailsItem = styled('div', {
  name: 'OrderDetails',
  slot: 'Item',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.background.light.tertiary}`,
  padding: theme.spacing(3),

  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6),
    flexDirection: 'row',
    display: 'flex',
    columnGap: theme.spacing(6),
  },
}))

export const OrderDetailsItemSeparator = styled('div')(({ theme }) => ({
  height: 1,
  backgroundColor: theme.palette.text.light.tertiary,
  alignSelf: 'stretch',
}))

export const ProductImageWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductImageWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  marginBottom: theme.spacing(8),
  cursor: 'pointer',
  [getDesktopThemeBreakpoint(theme)]: {
    minWidth: 311,
    marginBottom: 0,
    backgroundColor: theme.palette.background.light.secondary,
  },
}))

export const OrderDetailsProductInfo = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductInfo',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2),
  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(3),
  },
}))

export const OrderDetailsProductInfoRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.text.light.tertiary}`,
  width: '100%',
  paddingBottom: theme.spacing(5),
}))

export const OrderDetailsProductInfoTotalRow = styled(
  OrderDetailsProductInfoRow
)<{ topBorder: boolean }>(({ topBorder, theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: 'none',
  borderTop: topBorder ? `1px solid ${theme.palette.grey[300]}` : 'none',
  paddingTop: topBorder ? theme.spacing(5) : '0',
}))

export const OrderDetailsProductInfoListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  height: '100%',
  fontSize: theme.spacing(3.5),
  fontWeight: 600,
  lineHeight: 1.43,
  color: theme.palette.text.dark.primary,
}))

export const OrderDetailsProductInfoItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.light.secondary,
}))

export const OrderDetailsProductInfoTotalItem = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  fontWeight: 600,
  color: theme.palette.text.dark.primary,
}))

export const OrderDetailsPrice = styled('div', {
  name: 'OrderDetails',
  slot: 'Price',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  lineHeight: 'normal',
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
}))

export const OrderDetailsShippingPaymentDetails = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetails',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.secondary,
  color: theme.palette.text.dark.primary,
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),

  'div:last-child > span:last-child ': {
    textTransform: 'uppercase',
  },

  [getDesktopThemeBreakpoint(theme)]: {
    padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
    width: '50%',
  },
}))

export const OrderDetailsShippingPaymentDetailsTitle = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetailsTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginBottom: theme.spacing(2),
}))

export const OrderDetailsShippingPaymentDetailsInfo = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetailsInfo',
})(() => ({
  fontSize: 12,
  fontWeight: 'bold',
}))

export const OrderDetailsFooter = styled('div', {
  name: 'OrderDetails',
  slot: 'Footer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
  },
}))

export const OrderDetailsPriceDetailsWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'PriceDetailsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  rowGap: theme.spacing(2),
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  maxHeight: 184,

  [getDesktopThemeBreakpoint(theme)]: {
    width: '50%',
    padding: `${theme.spacing(6)} 0 ${theme.spacing(6)} ${theme.spacing(8)}`,
  },
}))

export const OrderDetailsItemDetailsRow = styled('div', {
  name: 'OrderDetails',
  slot: 'DetailsRow',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: 0,
}))

export const OrderDetailsFooterItem = styled('span', {
  name: 'OrderDetails',
  slot: 'ShippingDetails',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
  width: '50%',
  [getDesktopThemeBreakpoint(theme)]: {
    width: '60%',
  },
}))

export const OrderDetailsListItem = styled(OrderDetailsFooterItem, {
  name: 'OrderDetails',
  slot: 'ListShippingDetails',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 600,
  width: '50%',
  [getDesktopThemeBreakpoint(theme)]: {
    width: 'auto',
  },
}))

export const OrderDetailsPriceDetailsItem = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: 1.43,
  textAlign: 'right',
  color: theme.palette.text.dark.primary,
}))

export const OrderDetailsPriceDetailsTotalItem = styled('span')(
  ({ theme }) => ({
    fontSize: theme.spacing(4),
    fontWeight: 600,
    lineHeight: 1.5,
    textAlign: 'right',
    color: theme.palette.text.dark.primary,
  })
)

export const OrderDetailsCLInfoContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}))

export const OrderDetailsCLInfoColum = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const OrderDetailsCLInfoHeader = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(5),
  marginTop: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 16,
}))

export const OrderDetailsCLInfoValue = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(7),
  marginTop: theme.spacing(1),
  fontSize: 16,
}))

export const StatusHeader = styled('div', {
  name: 'OrderHistoryDetails',
  slot: 'StatusHeader',
  shouldForwardProp: (prop) => prop !== 'shipped',
})<{ shipped?: boolean }>(({ theme, shipped }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  borderTop: `solid 1px ${theme.palette.background.light.tertiary}`,
  borderRight: `solid 1px ${theme.palette.background.light.tertiary}`,
  borderLeft: `solid 1px ${theme.palette.background.light.tertiary}`,
  fontWeight: theme.typography.fontWeightBold,
  color: shipped
    ? theme.palette.custom.greenPea
    : theme.palette.text.dark.primary,

  span: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
    color: theme.palette.text.dark.primary,
  },
}))
