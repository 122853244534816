import { Collapse, Fade, Grow } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { TransitionGroup } from 'react-transition-group'

interface WithSkeletonProps {
  className?: string
  isLoaded?: boolean
  renderSkeletonElement?: () => ReactNode
  animationType?: 'fade' | 'grow' | 'collapse'
  animationDuration?: number
}

/** HOC to display elements once provided placeholder is in viewport */
/**
 * @param { boolean } isLoaded while is false skeleton is shown
 * @param { function } renderSkeletonElement skeleton dom to show while loaded is false
 * @param { number } animationDuration component animation delay
 * @param { 'fade' | 'grow' | 'collapse' } animationType animation type
 */
const WithSkeleton: FC<WithSkeletonProps> = ({
  children,
  isLoaded,
  renderSkeletonElement,
  animationType,
  animationDuration,
  ...rest
}) => {
  const getAnimation = (animationType: WithSkeletonProps['animationType']) => {
    switch (animationType) {
      case 'fade':
        return (
          <Fade timeout={{ enter: animationDuration }} in={isLoaded}>
            <div {...rest}>{children}</div>
          </Fade>
        )
      case 'grow':
        return (
          <Grow timeout={{ enter: animationDuration }} in={isLoaded}>
            <div {...rest}>{children}</div>
          </Grow>
        )
      case 'collapse':
        return (
          <Collapse timeout={{ enter: animationDuration }} in={isLoaded}>
            <div {...rest}>{children}</div>
          </Collapse>
        )
      default:
        return <div {...rest}>{children}</div>
    }
  }
  const animation = getAnimation(animationType)
  return (
    <TransitionGroup component={null}>
      <>
        {isLoaded ? (
          <TransitionGroup component={null}>{animation}</TransitionGroup>
        ) : (
          renderSkeletonElement && (
            <TransitionGroup component={null}>
              <div>{renderSkeletonElement()}</div>
            </TransitionGroup>
          )
        )}
      </>
    </TransitionGroup>
  )
}

export default WithSkeleton
