import React, { useState } from 'react'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTheme } from '@mui/material/styles'
import { useCheckoutPaths } from '../../foundation/hooks/useCheckoutPaths'
import { useLocation, useMatch } from 'react-router'
import OrderRecapCartEditConfirmDialog from '../../components/order-recap/OrderRecapEditBag'
import { ArrowLeftIcon } from '@components/UI/Icons'
import { ReducedHeaderMobileBack } from './reducedheader.style'
import { Link } from 'react-router-dom'

export const CheckoutMobileBack: React.FC = () => {
  const { basePath } = useStoreIdentity()
  const location = useLocation()
  const match = useMatch(location.pathname)
  const { langCode } = useStoreIdentity()
  const checkoutPaths = useCheckoutPaths(langCode)
  const isShippingPage = match?.pathname === checkoutPaths.shipping
  const isPaymentPage = match?.pathname === checkoutPaths.payment
  const theme = useTheme()

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const handleOpen = (): void => setIsEditOpen(true)
  const handleClose = (): void => setIsEditOpen(false)

  return (
    <>
      <ReducedHeaderMobileBack>
        <Link
          to={!isShippingPage && !isPaymentPage ? basePath : '#'}
          onClick={!isShippingPage && !isPaymentPage ? undefined : handleOpen}
        >
          <ArrowLeftIcon htmlColor={theme.palette.text.dark.primary} />
        </Link>
      </ReducedHeaderMobileBack>
      <OrderRecapCartEditConfirmDialog
        open={isEditOpen}
        handleClose={handleClose}
      />
    </>
  )
}
