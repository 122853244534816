import React, { createContext, useContext, useState } from 'react'
import { SuggestedFeatures } from '../../types/FrameGenius/frameGenius'
import FrameAdvisorUtil from '../../utils/FrameGenius/FrameAdvisorUtil'
import FrameGeniusUtil from '../../utils/FrameGenius/FrameGeniusUtil'

export type FrameGeniusContextType = {
  areScriptsLoaded: boolean
  setLoadedScriptsStatus: (status: boolean) => void

  analysisResults: SuggestedFeatures
  setAnalysisResults: (suggestedFeatures: SuggestedFeatures) => void

  isSizeAdvisorOpen: boolean
  openSizeAdvisor: () => void
  closeSizeAdvisor: () => void

  isFrameAdvisorOpen: boolean
  openFrameAdvisor: () => void
  closeFrameAdvisor: () => void
}

const _throw = (m: string) => {
  throw new Error(m)
}

const FrameGeniusContext = createContext<FrameGeniusContextType | undefined>(
  undefined
)

const FrameGeniusProvider: React.FC = ({ children }) => {
  const [areScriptsLoaded, setLoadedScriptsStatus] = useState<boolean>(false)

  const [isSizeAdvisorOpen, setSizeAdvisorOpenStatus] = useState<boolean>(false)
  const openSizeAdvisor = () => setSizeAdvisorOpenStatus(true)
  const closeSizeAdvisor = () => setSizeAdvisorOpenStatus(false)

  const needsReinit = FrameAdvisorUtil.needsReinit()

  const [isFrameAdvisorOpen, setFrameAdvisorOpenStatus] =
    useState<boolean>(needsReinit)
  const openFrameAdvisor = () => {
    FrameAdvisorUtil.setFrameAdvisorInitializedStatus(true)
    setFrameAdvisorOpenStatus(true)
  }

  const closeFrameAdvisor = () => {
    FrameAdvisorUtil.setFrameAdvisorInitializedStatus(false)
    setFrameAdvisorOpenStatus(false)
  }

  const suggestedFeaturesInStorage = FrameGeniusUtil.getAnalysisResults()

  const [analysisResults, setAnalysisResults] = useState<SuggestedFeatures>(
    suggestedFeaturesInStorage
  )

  return (
    <FrameGeniusContext.Provider
      value={{
        areScriptsLoaded,
        setLoadedScriptsStatus,

        analysisResults,
        setAnalysisResults,

        isSizeAdvisorOpen,
        openSizeAdvisor,
        closeSizeAdvisor,

        isFrameAdvisorOpen,
        openFrameAdvisor,
        closeFrameAdvisor,
      }}
    >
      {children}
    </FrameGeniusContext.Provider>
  )
}

const useFrameGenius = () => {
  const context = useContext(FrameGeniusContext)

  return context || _throw('Init FrameGeniusContext first.')
}

export default FrameGeniusProvider

export { useFrameGenius }
