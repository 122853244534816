import { GUEST_LOGIN_SUCCESS_ACTION } from '../../../redux/actions/user'
import { AppDispatch } from '../../../redux/store'
import RequestService from '../../../services/RequestService'
import { IWishlist, UpdateWishlistResponse } from '../../../types/wishlist'
import guestIdentityService from './guestIdentity.service'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const WishlistService = {
  /**
   * Gets the default wishlist
   * `@method WishList#getDefault`
   */
  getDefault(): Promise<IWishlist> {
    return RequestService.request<IWishlist>({
      path: '/store/{storeId}/wishlist/@default',
      method: 'GET',
    })
  },

  /**
   * Updates the wish list to change the description or to add or update an item.
   * `@method WishList#update`
   *
   ** `@argument {boolean} addItem` - indicates if passed items should be updated or added to wishlist. Default is false
   ** `@argument {string} externalId` - wishlist external identifier
   ** `@argument {Array} items`
   *
   * `@returns {UpdateWishlistResponse}` - `item` contains successfully updated item
   */
  update(
    addItem: boolean,
    externalId: string,
    items: { location: string; partNumber: string; quantityRequested: string }[]
  ) {
    return RequestService.request<UpdateWishlistResponse>({
      body: { item: items },
      path: '/store/{storeId}/wishlist/{externalId}',
      method: 'PUT',
      pathParams: { externalId },
      queryParams: { addItem },
    })
  },

  createWishlistAndAddItem: async (
    dispatch: AppDispatch,
    items: {
      location: string
      partNumber: string
      quantityRequested: string
    }[]
  ) => {
    const guestResponse = await guestIdentityService.login({})

    dispatch(GUEST_LOGIN_SUCCESS_ACTION(guestResponse.data))

    const wishlist: IWishlist = await WishlistService.getDefault()

    return WishlistService.update(
      true,
      wishlist.GiftList[0].externalIdentifier,
      items
    )
  },

  /**
   * Deletes a wishlist
   *
   * `@method WishList#delete`
   *
   ** `@argument {string} externalId` - wishlist external identifier
   ** `@argument {string} itemId` - specifies the ID of the wishlist item to delete
   */
  delete(externalId: string) {
    return RequestService.request({
      method: 'DELETE',
      path: '/store/{storeId}/wishlist/{externalId}',
      queryParams: { externalId },
    })
  },

  /**
   * Deletes an item from a wishlist.
   * Specify an itemId or a productId to delete only that item from the wishlist,
   * otherwise the entire wishlist will be deleted.
   * When both itemId and productId are provided, itemId will be used ONLY.
   *
   * `@method`
   * `@name WishList#deleteItemFromWishlist`
   *
   ** `@argument {string} externalId` - wishlist external identifier
   ** `@argument {string} itemId` - specifies the ID of the wishlist item to delete
   *
   * `@returns {UpdateWishlistResponse}` - `item` contains successfully deleted item
   */
  deleteItemFromWishlist({
    externalId,
    itemId,
    productId,
  }: {
    externalId: string
    itemId?: string
    productId?: string
  }) {
    return RequestService.request<UpdateWishlistResponse>({
      method: 'DELETE',
      path: '/store/{storeId}/wishlist/{externalId}',
      pathParams: { externalId },
      queryParams: { itemId, productId },
    })
  },
}

export default WishlistService
