import { styled } from '@mui/material/styles'

export const StyledHeadingContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: `${theme.palette.text.dark.primary}`,
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(9)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(9)} ${theme.spacing(4)}`,
  },

  fontSize: 24,

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(9)} ${theme.spacing(14)}`,
  },
}))

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(4)}`,
  padding: `0 ${theme.spacing(6)}`,

  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(37)}`,
  },
}))
