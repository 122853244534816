import { Maybe } from './common'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../constants/product'
import React from 'react'
import { OrderItem } from './order'
import { IPlacement, IPlacementItem } from './teaser'

export type BooleanString = 'true' | 'false'

export type ProductSeo = {
  href?: Maybe<string>
}

export const PRODUCT_PRICE_USAGE_NAMES = {
  CURRENT: 'Offer', // current price of product, can be initial or discounted
  INITIAL: 'Display', // initial price of product, before applied discount
} as const

type UsageNames = keyof typeof PRODUCT_PRICE_USAGE_NAMES
type ProductPriceUsageName = typeof PRODUCT_PRICE_USAGE_NAMES[UsageNames]

export interface ServerProductPrice {
  contractId?: string
  currency: string
  description?: string
  usage: ProductPriceUsageName
  value: string
}

export interface DetailedRxPrice {
  type: string
  initialPrice: ServerProductPrice
  currentPrice: ServerProductPrice
}

export interface ServerProductXPrice {
  endDate?: string
  startDate?: string
  price: string
  currency: string
  priority?: string
}

export type ProductImage = {
  name: string
  sequence?: string
}

export type Attachment = {
  usage: string
  sequence: string
  name: string
  attachmentAssetPathRaw: string
  image?: string
  identifier?: string
  catentry_id?: string
  shortdesc?: string
  attachmentAssetID?: string
  mimeType: string
  attachmentAssetPath: string
}

// TODO: evaluate the idea of having an extra layer of normalized attributes, instead of calling getters all the time for attributes since they're aready properties of the object
export interface ServerProduct {
  attachments: Attachment[]
  images: {
    sequence: string
    name: string
  }[]
  attributes: ServerProductAttribute[]
  buyable: string
  cluster?: ServerProduct[]
  hasSingleSKU: boolean
  id?: string
  items?: ServerProduct[]
  longDescription?: string
  merchandisingAssociations?: ServerProduct[]
  catalogEntryTypeCode?: string
  name: string
  parentCatalogGroupID: string | string[]
  parentCatalogEntryID?: string
  partNumber: string
  price: ServerProductPrice[]
  x_prices: {
    offer?: ServerProductXPrice
    list?: ServerProductXPrice
  }
  seo?: ProductSeo
  shortDescription?: string
  numberOfSKUs?: number
  sKUs?: ServerProduct[]
  storeID: string
  type?: string
  uniqueID?: string
  x_offerpriceRx?: string
  resourceId?: string
  manufacturer?: string
  'relationship.item.id'?: string
  'relationship.item.sequence'?: string
  _ignored?: string
  quantity?: string
  orderItemExtendAttribute: Attribute[]
  associationType?: string
}

export interface ServerProductAttribute {
  identifier: string
  'attribute.natural': string
  usage: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    }
  ]
  displayable: BooleanString
  merchandisable: BooleanString
  searchable: BooleanString
  sequence: string
  storeDisplay?: BooleanString
  name: string
  facetable: BooleanString
  comparable: BooleanString
  key?: string
  uniqueID: string
  swatchable: BooleanString
}

export interface ProductAnalyticsRX extends ServerProduct {
  rxPrice?: number
}

export type ProductSoldOutStatus =
  typeof PRODUCT_SOLDOUT_STATUS[keyof typeof PRODUCT_SOLDOUT_STATUS]

export type DefaultProductCtaStatus = Extract<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>
export type SoldOutProductCtaStatus = Exclude<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>

export interface Product {
  name: string
  type: string
  id: string
  clusters?: ClusterProduct[]
  resourceId: string
  partNumber: string
  purchasable: boolean
  hasSingleSku: boolean
  numberOfSKUs: number
  manufacturer?: string
  storeID: string
  catalogEntryTypeCode?: string
  seo: {
    href: string
  }
  productAttributes: ProductAttributes
  parentCatalogGroupId: string | string[]
  prices: Price[]
  attachments: ServerProductAttachment[]
  merchandisingAssociations?: AssociatedProduct[]
  images?: []
}

export interface ServerProductAttachment {
  image: string
  identifier: string
  sequence: string
  catentry_id: string
  usage: string
  name: string
  shortdesc: string
  attachmentAssetPathRaw: string
  attachmentAssetID: string
  mimeType: string
  attachmentAssetPath: string
}

export interface AssociatedProduct extends Product {
  associationType: string
}

export interface ClusterProduct extends Product {
  items?: Product[] // sKUs?
}

export interface ClusteredProduct {
  // NO PRODUCT
  cluster: Product[]
}

export interface Price {
  usage: string
  contractId: string
  description: string
  currency: string
  value: string
}

export interface ProductAttributes {
  [key: string]: string
}

export type ProductType =
  typeof PRODUCT_TYPES_MAP[keyof typeof PRODUCT_TYPES_MAP]

export interface ProductRightColumnProps {
  pdpData?: ServerProduct
  type: string | null
  partNumber: ServerProduct['partNumber']
  currentProduct?: ServerProduct
  productItems: ServerProduct['items']
  cluster: ServerProduct['cluster']
  isLoading: boolean
  ctaRef: React.RefObject<HTMLDivElement>
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  onClusterProductClick?: (p: ServerProduct) => void
  soldOutStatus: ProductSoldOutStatus
  addToCartButtonFail: boolean
  productInCart?: OrderItem
  productQuantity?: string[]
  setProductQuantity: React.Dispatch<string[]>
  attachments?: Attachment[]
  attributes?: ServerProductAttribute[]
  placements?: IPlacement<IPlacementItem>[]
  isSoldout?: boolean
  isComingBackSoon?: boolean
  isRoxable?: boolean
  totalBoxes?: number
  setTotalBoxes?: (totalBoxes: number) => void
}

export interface EyeContanctLensOption {
  text: string
  value: string | null
  index: number | null
}
export interface EyeClFieldConfig {
  id: EyeContactLensAttribute
  select: boolean
  label: string
  options: EyeContanctLensOption[]
  defaultValue: string
  active?: boolean
  visible?: boolean
  required?: boolean
  multifield?: boolean
}

export type EyeClFieldConfigMap = Record<string, EyeClFieldConfig[]>

export type EyeContactLensAttribute =
  | 'x_spherePower'
  | 'x_baseCurve'
  | 'x_spherePower'
  | 'x_diameter'
  | 'x_axis'
  | 'x_color'
  | 'x_addition'
  | 'x_dominance'
  | 'x_cylinder'
  | 'quantity'

export interface EyeContanctLensStatusData {
  enabled?: boolean
  errors?: string[]
  dirtyFields?: string[]
  emptyFields?: string[]
  valid?: boolean
  touched?: boolean
}

export type Facet = {
  name: String
  entry: any[]
}

export interface ContactLensData {
  x_productId?: string
  x_eye?: LensesEyesAttributes
  x_baseCurve?: string
  x_spherePower?: string
  x_diameter?: string
  x_axis?: string
  x_cylinder?: string
  x_dominance?: string
  x_addition?: string
  x_color?: string
  quantity?: string
  fieldsStatus?: EyeContanctLensStatusData | null
}

export type ContactLensPayload = Omit<ContactLensData, 'fieldsStatus'>

export type ContactLensesData = Record<string, ContactLensData>

export interface SupplyData {
  quantity?: string
  discountAmount?: string
  originalBoxPrice?: string
  discountedBoxPrice?: string
  timePeriod?: string
}

export enum ProductTypesEnum {
  ContactLensesAccessories = 'Contact Lenses Accessories',
  Accessories = 'Accessories',
  ContactLenses = 'Contact Lenses',
}

export type Attribute = {
  attributeName: string
  attributeType: string
  attributeValue: string
}

export type LensesEyesAttributes = 'RCON' | 'LCON'

export interface EyeContactLensOverride {
  attribute: EyeContactLensAttribute
  value: string
}

export type EyeContactLensOverrideMap = Record<
  'left' | 'right',
  EyeContactLensOverride[]
>

export type EyeContactLensAttributeMapping = {
  parsedValue: number
  orginalValue: string
}

export interface Size {
  size: string
  frameSize: string
  bridgeWidth: string
  sizeOrder: Sizes
  uniqueID: string
  hingeDistance: string
}

export enum Sizes {
  XXS,
  XS,
  S,
  M,
  L,
  XL,
  XXL,
}

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

export type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail'

export interface PriceModel {
  price: string
  currency: string
}
