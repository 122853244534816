export const ORDER_CONFIGS = {
  calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
  calculateOrder: '1',
  inventoryValidation: true,
  allocate: '***',
  backOrder: '***',
  remerge: '***',
  check: '*n',
  sortOrderItemBy: 'orderItemID',
}

export const PAGINATION_CONFIGS = {
  pageLimit: 10,
  pageSizeOptions: [10, 25, 50],
}
