import React, { useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { SizeAdvisorConfig } from '../../types/FrameGenius/sizeAdvisor'
import {
  SuggestedFeatures,
  SuggestedSizeFeature,
} from '../../types/FrameGenius/frameGenius'
import config from '../../configs/config.base'
import { useFrameGenius } from './FrameGeniusContext'
import FrameGeniusUtil from '../../utils/FrameGenius/FrameGeniusUtil'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useSelector } from 'react-redux'
import { storeCfgServiceLinksSelector } from '../../redux/selectors/site'
import { Drawer } from '@mui/material'

const frameGeniusConfig = config.frameGenius

const SIZE_ADVISOR = {
  WRAPPER_WIDTH: '376px',
  WRAPPER_HEIGHT: '668px',
  SELECTOR: 'sizeadv-ui',
}

enum SizeAdvisorEvents {
  close = 'sa-close',
}

const StyledSizeAdvisorWrapper = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,

  '.sa__page-wrapper': {
    width: '100%',
    height: '100%',
  },

  '.fa__loaderCopy': {
    color: theme.palette.custom.cyprus,
  },

  // buttons
  '.sa__carousel-onboarding-white-button, .sa__carousel-onboarding-black-button':
    {
      borderRadius: '0',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
    },
  '.sa__confirm-button': {
    backgroundColor: theme.palette.custom.cyprus,
    borderRadius: '0',
    color: theme.palette.custom.halfBaked,
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    padding: '11px 0',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      background: theme.palette.custom.sherpaBlue,
      border: `1px solid ${theme.palette.custom.sherpaBlue}`,
      boxShadow: 'none!important',
    },
    '&:disabled': {
      backgroundColor: theme.palette.custom.cyprus,
      border: 'none',
      borderRadius: '0',
      color: theme.palette.custom.halfBaked,
      opacity: '.5',
    },
  },
  '.sa__suggested-size-start-over': {
    border: `1px solid ${theme.palette.custom.sherpaBlue}`,
    color: theme.palette.custom.cyprus,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    padding: '11px 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  '.fa__accordion-button': {
    fontFamily: theme.typography.fontFamily,
    textTransform: 'uppercase',
  },

  // slider dot
  '.fa__switch-slider-dot': {
    backgroundColor: theme.palette.custom.halfBaked,
  },
  '.sa__page-wrapper .fa__switch-slider-input::-webkit-slider-thumb': {
    backgroundColor: theme.palette.custom.halfBaked,
    color: theme.palette.custom.cyprus,
  },

  // suggested sizes
  '.fa__advisor-interval-size': {
    color: theme.palette.custom.cyprus,
  },
  '.fa__advisor-interval-rectangle-ellipse-circle, .fa__advisor-interval-rectangle-ellipse.fa__advisor-interval-active':
    {
      backgroundColor: theme.palette.custom.halfBaked,
      color: theme.palette.custom.cyprus,
    },
  '.fa__advisor-interval-rectangle-container-range.fa__advisor-interval-active, .fa__switch-slider-bar':
    {
      backgroundColor: theme.palette.custom.jaggedIce,
    },
  '.fa__frame-size-accordion': {
    backgroundColor: theme.palette.custom.white,
    fontSize: '14px',
    lineHeight: '20px',
  },
  '.fa__frame-size-content + div': {
    marginBottom: '0',
    '> button, > a': {
      width: 'calc(100% - 32px)',
    },
  },

  // svg
  '#temple-measurements_svg__customColor, #from-hinge-to-hinge_svg__customColor1':
    {
      fill: theme.palette.custom.halfBaked,
    },
  '#from-hinge-to-hinge_svg__customColor2': {
    fill: theme.palette.custom.jaggedIce,
  },
  '#from-hinge-to-hinge_svg__customColor3': {
    fill: theme.palette.custom.black,
  },

  // footer
  '.sa__privacy-wrapper button': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const StyledSizeAdvisorOverlay = styled('div')({
  backgroundColor: '#000',
  inset: '0',
  opacity: '0.5',
  position: 'fixed',
  zIndex: '9999',
})

const StyledSizeAdvisor = styled('div')({
  backgroundColor: '#f4f4f4',
  height: SIZE_ADVISOR.WRAPPER_HEIGHT,
  left: '50%',
  overflow: 'hidden',
  position: 'fixed',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: SIZE_ADVISOR.WRAPPER_WIDTH,
  zIndex: '9999',
})

const SizeAdvisorPlugin = () => {
  const { mySite } = useSite()
  const fgData = useFrameGenius()

  const { basePath } = useStoreIdentity()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)

  const getSizeAdvisorWidget = () => {
    const locale = mySite.locale

    const baseConfiguration = FrameGeniusUtil.getBaseConfiguration(
      basePath,
      serviceLinks,
      locale,
      frameGeniusConfig.source,
      frameGeniusConfig.frameAdvKey
    )

    const sizeAdvisorConfig: SizeAdvisorConfig = {
      ...baseConfiguration,
      selector: `#${SIZE_ADVISOR.SELECTOR}`,
      handleResults: (data: SuggestedSizeFeature) => {
        const sizeAdvisorResult = { size: data } as SuggestedFeatures
        FrameGeniusUtil.setAnalysisResults(sizeAdvisorResult)
        fgData.setAnalysisResults(sizeAdvisorResult)
      },
    }

    // TODO: Remove "as any" casting for window object
    const sizeAdvisorWidget = (
      window as any
    ).frameAdvisor.SizeAdvisorWidget.new(sizeAdvisorConfig)

    return sizeAdvisorWidget
  }

  const frameGeniusContext = useFrameGenius()
  const sizeAdvisorRef = useRef<HTMLDivElement>(null)

  const initSizeAdvisor = () => {
    getSizeAdvisorWidget().render()
  }

  const frameGeniusStoreconfs = mySite.xStoreCfg.frameGenius
  useEffect(() => {
    if (frameGeniusStoreconfs.isSizeAdvisorEnabled) {
      const frameGeniusScript = frameGeniusStoreconfs.scriptUrl
      if (!fgData.areScriptsLoaded) {
        FrameGeniusUtil.loadFrameGeniusScripts([frameGeniusScript], () => {
          initSizeAdvisor()
          fgData.setLoadedScriptsStatus(true)
        })
      } else {
        initSizeAdvisor()
      }
    }
  }, [])

  useEffect(() => {
    const wrapper = sizeAdvisorRef.current
    const body = document.body

    if (body && wrapper) {
      body.addEventListener(
        SizeAdvisorEvents.close,
        frameGeniusContext.closeSizeAdvisor
      )
    }
  }, [sizeAdvisorRef])

  return (
    <StyledSizeAdvisorWrapper className={`${SIZE_ADVISOR.SELECTOR}-wrapper`}>
      <StyledSizeAdvisorOverlay
        className={`${SIZE_ADVISOR.SELECTOR}-overlay`}
      />
      <StyledSizeAdvisor
        id={SIZE_ADVISOR.SELECTOR}
        className={SIZE_ADVISOR.SELECTOR}
        ref={sizeAdvisorRef}
        data-analytics_available_call="0"
      />
    </StyledSizeAdvisorWrapper>
  )
}

const SizeAdvisor = () => {
  return (
    <Drawer open={true} anchor="bottom">
      <SizeAdvisorPlugin />
    </Drawer>
  )
}

export default SizeAdvisor
