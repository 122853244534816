import { Skeleton } from '@mui/material'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'

interface IProps {}

const BenefitBarSkeleton: React.FC<IProps> = () => {
  return (
    <SkeletonTheme
      baseColor="transparent"
      highlightColor={'rgba(0, 0, 0, 0.1)'}
      height={'100%'}
    >
      <Skeleton variant="rectangular" height={32} />
    </SkeletonTheme>
  )
}

export default BenefitBarSkeleton
