import { createAsyncThunk } from '@reduxjs/toolkit'
import { wishlistExternalIdSelector } from '../selector'
import { RootReducerState } from 'src/redux/reducers'
import WishlistService from 'src/foundation/apis/transaction/wishList.service'
import { AppDispatch } from 'src/redux/store'
import { fetchWishlistAction } from '../action'

const removeWishlistItem = createAsyncThunk<any, string>(
  'wishlist/addItem',
  async (itemId, { dispatch, getState }) => {
    const appDispatch = dispatch as AppDispatch
    const rootState = getState() as RootReducerState
    const wishlistExternalId = wishlistExternalIdSelector(rootState)

    await WishlistService.deleteItemFromWishlist({
      externalId: wishlistExternalId,
      itemId,
    })
    await appDispatch(fetchWishlistAction())
  }
)

export default removeWishlistItem
