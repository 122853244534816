import { FrameObject, LensObject } from '../../types/rxConfigurator'
import {
  HOW_TO_READ_YOUR_PRESCRIPTION,
  PUPILLARY_DISTANCE,
  RX_CONFIG,
  RX_CONFIGURATOR_DOM_ID,
  RX_PRESCRIPTION_OBJECT_KEY,
  RX_SCRIPT_ID,
  RX_STYLE_ID,
} from '../../constants/rxConfigurator'
import React, { useEffect } from 'react'

import { BooleanString, ServerProduct } from '../../types/product'
import CurrencyService from '../../services/CurrencyService'
import Log from '../../services/Log'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import rxConfiguratorService from '../../foundation/apis/rx-config/rx.service'
import { usePrescriptionLenses } from './PrescriptionLensesContext'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { useProductImages } from '../../hooks/useProductImages/useProductImages'
import {
  parseAttachmentUrl,
  sortImageByConf,
} from '../../utils/attachmentsUtils'
import { shallowEqual, useSelector } from 'react-redux'
import { pdpFrameImageOrderSelector } from '../../redux/selectors/site'
import { getBrand, getProductType } from '../../utils/productAttributes'
import { fetchCssFromCDN, fetchJsFromCDN } from '../../utils/fetchFromCdn'
import { orderItemsSelector } from '../../features/order/selector'
import { OrderItem } from '../../types/order'
import { isRxLens } from '../../utils/rx'
import brandList from '../BrandIcon/brandList'

const ProductTypes = {
  sun: 'Sun',
  Sun: 'Sun',
  Optical: 'Optics',
  optical: 'Optics',
  Optics: 'Optics',
  optics: 'Optics',
} as const

export interface ImageryType {
  fallbackImage: string
  productImage: string
}

export interface PrescriptionLensesProps {
  categoryHref?: string | null
  /**
   * @property {string[]} catEntryIdsOfLensPackages - To search for lensPackages with provided catEntryIds
   * among all lens packages from getLensesData request.
   * It currently results in finding one main lens package, which includes lenses and services.
   * Used for uploading the lens package of cart item (orderItem) to configurator.
   */
  catEntryIdsOfLensPackages?: string[]
  product: ServerProduct | undefined
  onAddToBag(
    _frameObject: FrameObject,
    _lensObject: LensObject,
    _warrantyObject,
    _reviewObject,
    imagery: ImageryType
  ): void
  onClose: () => void
  isFromCart?: boolean
}

const getOrderProduct = (product: ServerProduct) => (i: OrderItem) =>
  i.partNumber === product.partNumber

const stringToBoolean = (v: BooleanString): boolean => v === 'true' || false

const checkIfComponentAlreadyLoaded = (elementId: string) =>
  !!document.getElementById(elementId)

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

const PrescriptionLenses: React.FC<PrescriptionLensesProps> = ({
  categoryHref,
  catEntryIdsOfLensPackages,
  product,
  onAddToBag,
  onClose,
  isFromCart,
}) => {
  const { mySite } = useSite()
  const { toggleLoadingConfigurator } = usePrescriptionLenses()
  const { basePath } = useStoreIdentity()
  const { t, i18n } = useTranslation()

  const { images: currentImages } = useProductImages(product)
  const reducedCurrentImages = currentImages.splice(0, 1)

  const pdpFrameImageOrder = useSelector(
    pdpFrameImageOrderSelector,
    shallowEqual
  )

  const orderItems = useSelector(orderItemsSelector)

  const RX_SCRIPT_SRC = mySite.xStoreCfg.RX_SCRIPT_SRC || ''
  const RX_STYLE_SRC = mySite.xStoreCfg.RX_STYLE_SRC || ''

  const brandName = getBrand(product)
  const productType = getProductType(product)

  const currentProductType = ProductTypes[productType]

  const showBundlePrice =
    mySite.xStoreCfg.showBundlePrice?.[brandName]?.[productType] || 'false'

  const largeIconLayout =
    mySite.xStoreCfg.largeIconLayout?.[brandName]?.[currentProductType] ||
    'false'

  // putting "true" as a default value to fulfill request in this comment
  // https://luxotticaretail.atlassian.net/browse/DC-53?focusedCommentId=663457
  // we should handle "General" configuration instead
  const enableDesignTypeStep =
    mySite.xStoreCfg.enableDesignTypeStep?.[brandName]?.[currentProductType] ||
    'true'

  const currency = mySite.defaultCurrencyID

  const layoutSettings = {
    showBundlePrice: stringToBoolean(showBundlePrice),
    largeIconLayout: stringToBoolean(largeIconLayout),
    enableDesignTypeStep: stringToBoolean(enableDesignTypeStep),
    addTilesTitlePrefix: ['designType'],
    showGrayoutTile: { type: 'Progressive' },
  }

  const checkAvailableFrames = (_frame, _prescriptionObject) => {
    return `${basePath}${categoryHref}`
  }

  const downloadExtendedPrescription = (requestObject) => {
    return new Promise(function (resolve, reject) {
      rxConfiguratorService
        .downloadPrescriptionFile(mySite, requestObject.savedFileName)
        .then(({ data, headers }) => {
          const base64Prescription = Buffer.from(data, 'binary').toString(
            'base64'
          )
          fetch(`data:${headers['content-type']};base64,${base64Prescription}`)
            .then((res) =>
              res
                .blob()
                .then((fileData) => {
                  resolve({ fileData: URL.createObjectURL(fileData) })
                })
                .catch(reject)
            )
            .catch(reject)
        })
        .catch(reject)
    })
  }

  const uploadExtendedPrescription = async ({ fileData, fileName }) => {
    const formData = new FormData()
    formData.append('UpLoadedFile', dataURLtoFile(fileData, fileName))
    return new Promise((resolve, reject) => {
      rxConfiguratorService
        .uploadFileFromConfigurator(mySite, formData)
        .then(({ data }) => {
          resolve({
            fileName,
            prescriptionId: data.rxFileStorageId,
            savedFileName: data.rxFileStorageId,
          })
        })
        .catch(() => reject())
    })
  }

  const savePrescription = (prescriptionObject) => {
    try {
      localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, prescriptionObject)
      return true
    } catch (e) {
      return false
    }
  }

  const saveExtendedPrescription = (prescriptionObject) => {
    const prescriptionFlow = prescriptionObject?.prescriptionFlow

    if (
      RX_CONFIG.prescriptionModule.prescriptionFlows.includes(prescriptionFlow)
    ) {
      return new Promise((resolve, reject) => {
        try {
          localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, prescriptionObject)
          resolve(prescriptionObject)
        } catch (e) {
          reject()
        }
      })
    } else {
      return new Promise((_, reject) => reject)
    }
  }

  const loadLearnMoreContent = (contentName: string) => {
    return new Promise((resolve) => {
      const CONTENT = contentName.split('_')
      if (CONTENT[2] === 'HowToReadPrescription') {
        resolve(HOW_TO_READ_YOUR_PRESCRIPTION(t))
      }
      if (CONTENT[2] === 'PupillaryDistance') {
        resolve(PUPILLARY_DISTANCE(t))
      }
    })
  }

  const fetchRxc = async (product: ServerProduct) => {
    const frameUpc = product.partNumber
    const { data } = await rxConfiguratorService.getLensesData(mySite, frameUpc)

    const quarterImage = sortImageByConf(
      reducedCurrentImages,
      pdpFrameImageOrder
    )

    const orderProductIndex = orderItems?.findIndex(getOrderProduct(product))

    const rxLens = orderItems?.find((x) => isRxLens(x.orderItemExtendAttribute))

    const brandLogo =
      brandList.find((brand) => brand.name === brandName)?.logo || null

    data.data.frame.brandImageUrl = brandLogo
    data.data.frame.imageUrl = parseAttachmentUrl(mySite, quarterImage)
    data.data['showFrameOnly'] = productType === 'Optical' ? true : false
    data.data.lens =
      isFromCart && rxLens
        ? {
            catEntryId: rxLens.productId,
          }
        : undefined

    const updatedConfig = {
      ...RX_CONFIG,
      ...data,
      layoutSettings,
      cartMode: isFromCart
        ? {
            orderItemId: orderItems?.[orderProductIndex || 0]?.orderItemId,
            orderIndex: orderProductIndex,
          }
        : undefined,
    }

    if (catEntryIdsOfLensPackages) {
      const packageWithCatEntryId = data.lensesData.packages.find(
        ({ lensPackage }) =>
          catEntryIdsOfLensPackages.find(
            (catEntryId) => catEntryId === lensPackage.catEntryId
          )
      )?.lensPackage

      if (packageWithCatEntryId) {
        updatedConfig.data['lens'] = packageWithCatEntryId
      }
    }

    updatedConfig.actionsModule['genericAddToCart'] = (
      _frameObject: FrameObject,
      _lensObject: LensObject,
      _warrantyObject,
      _reviewObject,
      imagery: ImageryType
    ) => {
      onAddToBag(
        _frameObject,
        _lensObject,
        _warrantyObject,
        _reviewObject,
        imagery
      )
      onClose()
    }
    updatedConfig.actionsModule['genericSaveEditFromCart'] = (
      _frameObject: FrameObject,
      _lensObject: LensObject,
      _warrantyObject,
      _reviewObject,
      imagery: ImageryType
    ) => {
      onAddToBag(
        _frameObject,
        _lensObject,
        _warrantyObject,
        _reviewObject,
        imagery
      )
      onClose()
    }
    updatedConfig.actionsModule['genericExit'] = onClose
    updatedConfig.actionsModule['loadLearnMoreContent'] = loadLearnMoreContent
    updatedConfig.prescriptionModule['checkAvailableFrames'] =
      checkAvailableFrames
    updatedConfig.prescriptionModule['savePrescription'] = savePrescription
    updatedConfig.prescriptionModule['uploadExtendedPrescription'] =
      uploadExtendedPrescription
    updatedConfig.prescriptionModule['downloadExtendedPrescription'] =
      downloadExtendedPrescription
    updatedConfig.prescriptionModule['saveExtendedPrescription'] =
      saveExtendedPrescription
    updatedConfig.currencyFormat['prefix'] =
      CurrencyService.getSymbolByName(currency)

    const RXC = await fetchJsFromCDN(RX_SCRIPT_SRC, 'RXC', { id: RX_SCRIPT_ID })
    const myWidget = RXC.rxcWidget.new(updatedConfig)

    myWidget.render()
  }

  const fetchRxcStyle = async () =>
    await fetchCssFromCDN(RX_STYLE_SRC, RX_STYLE_ID)

  const overrideConfiguratorTranslation = (): void => {
    const LANG = i18n.languages[0].split('-').join('_')

    // @ts-ignore
    window.rxcTranslations = {
      [LANG]: {
        steps: {
          advancedPrescription: {
            later: {
              card: {
                description: t(
                  'PrescriptionConfigurator.Labels.AdvancedPrescription.Description'
                ),
              },
            },
          },
        },
      },
    }
  }

  const loadConfiguratorModule = async (product: ServerProduct) => {
    toggleLoadingConfigurator()
    Log.info('RX CONFIG MODULE LOADING')
    try {
      overrideConfiguratorTranslation()

      if (!checkIfComponentAlreadyLoaded(RX_STYLE_ID)) {
        await fetchRxcStyle()
      }

      if (!checkIfComponentAlreadyLoaded(RX_SCRIPT_ID)) {
        await fetchRxc(product)
      }
    } catch (e) {
      onClose()
      toggleLoadingConfigurator()
    }
  }

  useEffect(() => {
    toggleLoadingConfigurator()
    return () => {
      const rxStyle = document.getElementById(RX_STYLE_ID)
      const rxScript = document.getElementById(RX_SCRIPT_ID)
      if (rxStyle) {
        document.body.removeChild(rxStyle)
      }
      if (rxScript) {
        document.body.removeChild(rxScript)
      }
    }
  }, [])

  useEffect(() => {
    if (product && currentImages.length > 0) {
      loadConfiguratorModule(product)
    }
  }, [currentImages])

  return <div id={RX_CONFIGURATOR_DOM_ID} />
}

export default PrescriptionLenses
