import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { CheckedRadioIcon } from '../Icons'
import { CustomTheme } from '../../../types/theme'
import { Radio } from '@mui/material'
import { useTheme } from '@mui/material/styles'

interface CustomRadioProps {
  error?: boolean
  disabled?: boolean
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 18,
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme.palette.text.dark.primary,
      borderStyle: 'solid',
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: theme.palette.text.light.tertiary,
        background: 'transparent',
      },
    },
    errorIcon: {
      borderRadius: 18,
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: theme.palette.color.error,
      borderStyle: 'solid',
    },
    checkedIcon: {
      borderRadius: 18,
      color: theme.palette.text.light.primary,
      width: 16,
      height: 16,
      display: 'inherit',
      borderWidth: 1,
      borderColor: theme.palette.text.dark.primary,
      borderStyle: 'solid',
      position: 'relative',
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: theme.palette.text.light.tertiary,
        background: 'transparent',
      },
      '& > svg': {
        position: 'absolute',
        height: 24,
        width: 24,
        right: -5,
        top: -5,
      },
    },
  })
)

export const StyledRadio = React.forwardRef<CustomRadioProps>((props: any) => {
  const { error, disabled, ...other } = props
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Radio
      className={classes.root}
      icon={<span className={error ? classes.errorIcon : classes.icon}></span>}
      disableRipple
      checkedIcon={
        <span className={classes.checkedIcon}>
          <CheckedRadioIcon
            htmlColor={
              disabled
                ? theme.palette.text.light.tertiary
                : theme.palette.text.dark.primary
            }
          />
        </span>
      }
      disabled={disabled}
      error={error}
      {...other}
    />
  )
})
