import { AxiosResponse } from 'axios'
import { GlobalXStoresCfg, IXStoreConfig, SiteInfo } from 'src/redux/reducers'
import {
  getOnlineStoreApiDataFromWcs,
  getOnlineStoreDataFromWcs,
  getStoreConfFromWcs,
  getStoreDataFromWcs,
  getStoreIdFromCountryFromWcs,
  getXstoreConf,
  getXstoresConf,
} from 'src/utils/site'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/query/react'
import {
  OnlineStoreDataResponse,
  SiteInfoArgs,
  StoreConf,
} from 'src/types/store'
import Log from 'src/services/Log'
import { CommerceEnvironment } from 'src/constants/common'

declare let HCL_STORE_ID

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '',
  }),
  endpoints: (build) => ({
    getXstoreConf: build.query<
      Record<keyof IXStoreConfig, any>,
      {
        storeId: string
        site: SiteInfo
      }
    >({
      async queryFn(args) {
        const { storeId, site } = args
        const result = await getXstoreConf(storeId, site)
        const data = (result as AxiosResponse<Record<keyof IXStoreConfig, any>>)
          .data
        const error = result as FetchBaseQueryError

        return !!data
          ? {
              data,
            }
          : { error }
      },
    }),
    getXstoresConf: build.query<GlobalXStoresCfg, {}>({
      async queryFn(_, _queryApi, _extraOptions) {
        const result = await getXstoresConf()

        const data = (result as AxiosResponse<GlobalXStoresCfg>).data
        const error = result as FetchBaseQueryError

        return !!data
          ? {
              data,
            }
          : { error }
      },
    }),
    getStoreIdFromCountry: build.query<
      string,
      {
        site: SiteInfo
        country: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions) {
        const { country, site } = args
        const result = await getStoreIdFromCountryFromWcs(country, site)

        const data = (result as AxiosResponse<string>).data
        const error = result as FetchBaseQueryError

        return !!data
          ? {
              data,
            }
          : { error }
      },
    }),
    getOnlineStoreApiData: build.query<
      OnlineStoreDataResponse,
      {
        selectedStoreID: string
        site: SiteInfo
      }
    >({
      async queryFn(args, _queryApi, _extraOptions) {
        const { selectedStoreID, site } = args
        const result = await getOnlineStoreApiDataFromWcs(selectedStoreID, site)

        const data = (result as AxiosResponse<OnlineStoreDataResponse>).data
        const error = result as FetchBaseQueryError

        return !!data
          ? {
              data,
            }
          : { error }
      },
    }),
    getStoreConf: build.query<
      StoreConf,
      {
        selectedStoreID: string
        site: SiteInfo
      }
    >({
      async queryFn(args) {
        try {
          const { selectedStoreID, site } = args
          const result = await getStoreConfFromWcs(selectedStoreID, site)

          const data = (result as AxiosResponse<StoreConf>).data
          const error = result as FetchBaseQueryError

          return !!data
            ? {
                data,
              }
            : { error }
        } catch (e) {
          return { error: e }
        }
      },
    }),
    getOnlineStoreData: build.query<
      StoreConf,
      {
        site: SiteInfo
        country: string
      }
    >({
      async queryFn(args) {
        try {
          const { site, country } = args
          const result = await getOnlineStoreDataFromWcs(country, site)

          const data = (result as AxiosResponse<StoreConf>).data
          const error = result as FetchBaseQueryError

          return !!data
            ? {
                data,
              }
            : { error }
        } catch (e: unknown) {
          return { error: e }
        }
      },
    }),
    getStoreData: build.query<
      SiteInfo,
      {
        site: SiteInfo
        country: string
      }
    >({
      async queryFn(args) {
        try {
          const { country, site } = args
          const result = await getStoreDataFromWcs(country, site)

          const data = (result as AxiosResponse<SiteInfo>).data
          const error = result as FetchBaseQueryError

          return !!data
            ? {
                data,
              }
            : { error }
        } catch (e) {
          Log.error(e as string)
          return { error: e }
        }
      },
    }),
    initializeSite: build.query<
      SiteInfo,
      {
        siteInfo: SiteInfoArgs
        country: string
      }
    >({
      async queryFn(args) {
        try {
          const { siteInfo, country } = args

          Log.info('init site')
          const site: any = Object.assign({}, siteInfo)

          let storeId =
            typeof HCL_STORE_ID === undefined ? undefined : HCL_STORE_ID
          //has storeId, use StoreId.
          site.storeID = storeId
          const onlineStoreDataRes = await getOnlineStoreDataFromWcs(
            country,
            site
          )
          const onlineStoreData = (
            onlineStoreDataRes as AxiosResponse<StoreConf>
          ).data
          Log.info(JSON.stringify(onlineStoreData))
          const {
            currentStoreConf,
            xConf,
            allStoreXConf,
            paypalReturnUrl,
            paypalCancelUrl,
            cartMaxItems,
            damDomain,
            newsletterType,
            paypalSecureAcceptanceUrl,
          } = onlineStoreData

          const updatedSite: SiteInfo = {
            ...site,
            storeName: currentStoreConf?.identifier || '',
            storeID: currentStoreConf?.storeId || '',
            catalogID:
              currentStoreConf?.defaultCatalog[0].catalogIdentifier?.uniqueID ||
              '',
            defaultCurrencyID:
              currentStoreConf?.supportedCurrencies.defaultCurrency || '',
            defaultLanguageID:
              currentStoreConf?.supportedLanguages.defaultLanguageId || '',
            langId: currentStoreConf?.supportedLanguages.defaultLanguageId,
            storeType: currentStoreConf?.storeType || '',
            xStoresCfg: allStoreXConf,
            xStoreCfg: xConf,
            paypalReturnUrl: paypalReturnUrl,
            paypalCancelUrl: paypalCancelUrl,
            cartMaxItems: parseFloat(cartMaxItems || ''),
            damDomain: damDomain || '',
            newsletterType: newsletterType || '',
            supportedLanguages:
              currentStoreConf?.supportedLanguages.supportedLanguages || '',
            country: country,
            locale: !!currentStoreConf
              ? CommerceEnvironment.languageMap[
                  currentStoreConf.supportedLanguages.defaultLanguageId
                ]
              : '',
            supportedCurrencies:
              currentStoreConf?.supportedCurrencies.supportedCurrencies,
            paypalSecureAcceptanceUrl: paypalSecureAcceptanceUrl || '',
          }

          const storeDataRes = await getStoreDataFromWcs(country, site)
          const storeDataCfg = (storeDataRes as AxiosResponse<SiteInfo>).data

          updatedSite.inventorySystem = storeDataCfg.inventorySystem

          return { data: updatedSite }
        } catch (e: unknown) {
          return { error: e }
        }
      },
    }),
  }),
})

export const {
  useGetXstoreConfQuery,
  useGetStoreConfQuery,
  useLazyInitializeSiteQuery,
} = siteApi
