import { PAYMENT_METHODS } from '../constants/paymentMethods'
import differenceInDays from 'date-fns/differenceInDays'
import {
  ContactLensData,
  ContactLensesData,
  EyeContanctLensOption,
  ProductTypesEnum,
} from '../types/product'
import {
  Order,
  PrescriptionItemType,
  ParsedOrderItemsMapByItemType,
  OrderItemWithRoxProps,
  IOrderDetails,
  OrderItem,
  Attribute,
  Adjustment,
} from '../types/order'
import { chain, countBy, sum, flattenDeep, uniq, chunk } from 'lodash'
import {
  isCLAccessoriesProduct,
  isContactLensesProduct,
  isSunProduct,
} from './product'
import { isRox, isRxCart, isRxLens, parseRxOrderItems } from './rx'
import Log from '../services/Log'
import { ORDER_EXTEND_ATTRIBUTE_NAMES, ORDER_STATUS } from '../constants/order'
import { OrderItemContactLensData } from '../types/cart'
import {
  PrescriptionItemsMapByType,
  PrescriptionMacroGroup,
} from '../types/checkout'
import { IOrderSliceState } from '../features/order/IOrderSliceState'
import RequestService from '../services/RequestService'
import { PrescriptionDetailsResponse } from '../types/prescription'
import { getAnnualSupplyBadge } from './productAttributes'

export const cartHasContactLenses = (
  orderExtendAttribute: Order['orderExtendAttribute']
): boolean => {
  return !!orderExtendAttribute?.find(
    (a) => a.attributeName === 'hasContactLens' && a.attributeValue === 'true'
  )
}

export const isClOrderItem = (
  orderExtendAttribute: OrderItem['orderItemExtendAttribute']
): boolean => {
  return !!orderExtendAttribute?.find(
    (a) =>
      a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['IS_CONTACT_LENS'] &&
      a.attributeValue === 'true'
  )
}

export const isAccessoriesOrderItem = (
  attribute: OrderItem['attributes']
): boolean => {
  return !!attribute?.find(
    (a) =>
      a.identifier === 'PRODUCT_TYPE' &&
      a.values[0].value.toUpperCase() ===
        ProductTypesEnum.Accessories.toUpperCase()
  )
}

export const isClAccessoriesOrderItem = (
  attribute: OrderItem['attributes']
): boolean => {
  return !!attribute?.find(
    (a) =>
      a.identifier === 'PRODUCT_TYPE' &&
      a.values[0].value.toUpperCase() ===
        ProductTypesEnum.ContactLensesAccessories.toUpperCase()
  )
}

export const orderHasPrescriptionUploaded = (
  oi: OrderItem | OrderItemWithRoxProps
): boolean => {
  try {
    return !!oi.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const orderHasPrescriptionPeriodExcedeed = (
  od: IOrderDetails,
  expirationPeriodDays: number
): boolean => {
  try {
    const orderDate = od?.lastUpdateDate?.split('T')[0] || null
    const difference = differenceInDays(new Date(), new Date(orderDate || ''))
    return (
      difference > expirationPeriodDays &&
      (isRxCart(od['orderExtendAttribute']) ||
        cartHasContactLenses(od['orderExtendAttribute']))
    )
  } catch (e) {
    return false
  }
}

export const getContactLensOrderItemData = (
  orderExtendAttribute: OrderItem['orderItemExtendAttribute'] | null
): ContactLensesData | {} => {
  try {
    return (
      JSON.parse(
        orderExtendAttribute?.find(
          (a) =>
            a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['X_CONTACT_LENS']
        )?.attributeValue || ''
      ) || null
    )
  } catch (e) {
    Log.error('Could not get contact lens order item attributes data')
    return {}
  }
}

export const generateQuantityOptions = (
  max: number = 10,
  start: number = 0
) => {
  const options: EyeContanctLensOption[] = []
  let i: number = start
  while (i < max) {
    options.push({
      text: `${i}`,
      value: `${i}`,
      index: i,
    })
    i++
  }
  return options
}

/**
 * function to aggregate order items by part number
 * @param { OrderItem[] } orderItems current cart order items list
 */

export const getGroupedOrderItemsByPartNumber = (
  orderItems: OrderItem[]
): OrderItem[] | null => {
  try {
    const orderItemsCountByPartNumber = countBy(orderItems, 'partNumber')
    const orderItemWithQuantity = chain(orderItems)
      .uniqBy('partNumber')
      .map((oi: OrderItem) => ({
        ...oi,
        quantity: orderItemsCountByPartNumber[oi.partNumber].toString(),
      }))
      .value()
    return orderItemWithQuantity
  } catch (e: any) {
    Log.error('Could not get grouped order items by partnumber', e)
    return null
  }
}

/**
 * function to parse order items in cart recap according to product type
 * @param { Cart } cart current cart session info
 * @param { OrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderItems = (
  //cart: Cart,
  orderItems: OrderItem[] | null,
  updatedItemId?: string,
  updatedItemQuantity?: string
): OrderItem[] | null => {
  try {
    const defaultOrderItems: OrderItem[] | null = !!orderItems
      ? orderItems.filter(
          (oi) => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])
        )
      : null
    const clOrderItems: OrderItem[] | null = !!orderItems
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItems?.filter((oi) => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: OrderItem[] | null = !!orderItems
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItems?.filter((oi) => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: OrderItem[] | null = !!orderItems
      ? parseRxOrderItems(
          orderItems.filter((oi) => isRox(oi['orderItemExtendAttribute']))
        )
      : null

    const rest = orderItems?.filter(
      (oi) =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order items', e)
    return null
  }
}

/**
 * function to parse order items in cart recap according to product type
 * @param { OrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderRecapItems = (
  //_cart: Cart,
  orderItems: OrderItem[] | null,
  updatedItemId?: string,
  updatedItemQuantity?: string
): OrderItem[] | null => {
  try {
    const defaultOrderItems: OrderItem[] | null = !!orderItems
      ? getGroupedOrderItemsByPartNumber(
          orderItems?.filter(
            (oi) => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])
          )
        )
      : null
    const clOrderItems: OrderItem[] | null = !!orderItems
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItems?.filter((oi) => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: OrderItem[] | null = !!orderItems
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItems?.filter((oi) => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: OrderItem[] | null = !!orderItems
      ? parseRxOrderItems(
          orderItems.filter((oi) => isRox(oi['orderItemExtendAttribute']))
        )
      : null

    const rest = orderItems?.filter(
      (oi) =>
        !isSunProduct(oi) &&
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order recap items', e)
    return null
  }
}

/**
 * function to aggregate order items by attribute correlation
 * @param { OrderItem[] } orderItems current cart order items list
 * @param { string } attribute attribute string to look for in other items
 */
export const getGroupedOrderItemsByAttrCorrelation = (
  orderItems: OrderItem[],
  attribute: string
): OrderItem[] | null => {
  try {
    const filteredOrderItems = orderItems.filter(
      (orderItem) => !!orderItem[attribute]
    )
    const groupedOrderItems: OrderItem[] = []
    filteredOrderItems?.map((filteredItem, i) => {
      const found = filteredOrderItems.find(
        (item) =>
          item?.orderItemId !== filteredItem?.orderItemId &&
          item?.orderItemId === filteredItem?.[attribute]
      )

      if (!!found) {
        filteredItem = {
          ...filteredItem,
          groupedItem: true,
          sibilingOrderItem: found,
          groupedOrderItemsId: [filteredItem.orderItemId, found.orderItemId],
        }
        groupedOrderItems.push(filteredItem)
        delete filteredOrderItems[i]
      }
    })
    return [
      ...groupedOrderItems,
      ...orderItems.filter((orderItem) => !orderItem[attribute]),
    ]
  } catch (e: any) {
    Log.error('Could not get grouped order items', e)
    return null
  }
}

export const getClAccessoriesGroupedOrderItemsByPartNumber = (
  orderItems: OrderItem[],
  updatedId?: string,
  updatedQuantity?: string
): OrderItem[] | null => {
  try {
    const ClOrderItems = orderItems
    const filteredOrderItems: OrderItem[] = []

    // grouped items by partNumber
    const groupByPartNumber = ClOrderItems.reduce((group, product) => {
      const { partNumber } = product
      group[partNumber] = group[partNumber] ?? []
      group[partNumber].push(product)
      return group
    }, {})

    Object.keys(groupByPartNumber).forEach(function (partNumber) {
      // if there are sibilings, sum quantity and price on each item and group the ids
      if (groupByPartNumber[partNumber].length > 1) {
        let filteredItem: any = {}
        let orderItemsId: string[] = []
        let itemQuantity: number = 0
        let itemPrice: number = 0

        groupByPartNumber[partNumber].map((item, i) => {
          itemQuantity = parseInt(item.quantity) + itemQuantity
          itemPrice = parseFloat(item.orderItemPrice) + itemPrice
          if (i === 0) {
            filteredItem = item
          }
          const foundSameOrderId = orderItemsId.includes(item?.orderItemId)
          if (!foundSameOrderId) {
            orderItemsId.push(item?.orderItemId)
          }
        })

        const found = orderItemsId.find((id) => id === updatedId)
        // if i'm updating from the select label
        if (!!found && updatedQuantity) {
          itemQuantity = parseInt(updatedQuantity)
          itemPrice = itemQuantity * parseFloat(filteredItem.unitPrice)
        }

        filteredItem = {
          ...filteredItem,
          quantity: itemQuantity.toString(),
          orderItemPrice: itemPrice.toString(),
          groupedOrderItemsId: orderItemsId,
        }
        filteredOrderItems.push(filteredItem)
      } else {
        const singleItem = groupByPartNumber[partNumber][0]
        filteredOrderItems.push(singleItem)
      }
    })

    return [...filteredOrderItems]
  } catch (e: any) {
    Log.error('Could not get grouped order items', e)
    return null
  }
}

export const filterClData = (
  data: ContactLensData | null,
  attributeKeys?: string[]
): ContactLensData | null => {
  try {
    !!data &&
      Object.keys(data)
        ?.filter((clAttr) => attributeKeys?.includes(clAttr))
        .map((filteredAttr) => {
          delete data[filteredAttr]
          return data
        })
    return data
  } catch (e: any) {
    Log.error('Could not filter contact lens order item data', e)
    return null
  }
}

export const getOrderItemClData = (
  orderItem: OrderItem
): ContactLensData | null => {
  try {
    const contactLensData = !!orderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData(orderItem?.orderItemExtendAttribute)
      : {}
    return contactLensData
  } catch (e) {
    Log.error('Could not find contact lens order item data')
    return null
  }
}

export const formatOrderRecapItemPrices = (orderItem: OrderItem) => {
  let productUnitPrice: number | null = null
  let productOrderItemPrice: number | null = null
  let x_offerpriceRx: number | null = null
  let x_offerDiscountpriceRx: number | null = null

  try {
    productOrderItemPrice = orderItem?.groupedItem
      ? parseFloat(
          sum([
            Number(orderItem.orderItemPrice),
            Number(orderItem.sibilingOrderItem?.orderItemPrice),
          ]).toFixed(2)
        ) || null
      : parseFloat(orderItem.orderItemPrice) || null
    productUnitPrice = orderItem.unitPrice
      ? parseFloat(orderItem.unitPrice)
      : null
    x_offerpriceRx = orderItem?.x_offerpriceRx
      ? parseFloat(orderItem.x_offerpriceRx)
      : null
    x_offerDiscountpriceRx = orderItem?.x_offerDiscountpriceRx
      ? parseFloat(orderItem?.x_offerDiscountpriceRx)
      : null
  } catch (e) {
    Log.error('Could not parse order item price')
  }

  return {
    productUnitPrice,
    productOrderItemPrice,
    x_offerpriceRx,
    x_offerDiscountpriceRx,
  }
}

export const formatOrderRecapItemClquantity = (
  quantity: string
): string | null => {
  let contactLensquantity: string | null = null

  try {
    contactLensquantity = parseInt(quantity).toString()
  } catch (e) {
    contactLensquantity = null
    Log.error('Could not parse order item quantity')
  }

  return contactLensquantity
}

export const getOrderItemContactLensesData = (
  orderItem: OrderItem,
  filteredKeys?: string[]
): OrderItemContactLensData | null => {
  let leftEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  let rightEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  try {
    const contactLensCurrentOrderItemData =
      !!orderItem?.orderItemExtendAttribute
        ? getContactLensOrderItemData(orderItem?.orderItemExtendAttribute)
        : null

    const contactLensSibilingOrderItemData = !!orderItem?.sibilingOrderItem
      ?.orderItemExtendAttribute
      ? getContactLensOrderItemData(
          orderItem?.sibilingOrderItem?.orderItemExtendAttribute
        )
      : null

    leftEyeContactLensData =
      !!contactLensCurrentOrderItemData &&
      Object.values(contactLensCurrentOrderItemData)?.includes('LCON')
        ? {
            data: contactLensCurrentOrderItemData,
            quantity: orderItem.quantity,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: contactLensSibilingOrderItemData,
            quantity: orderItem?.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem?.sibilingOrderItem?.orderItemId || null,
          }

    rightEyeContactLensData =
      !!contactLensCurrentOrderItemData &&
      Object.values(contactLensCurrentOrderItemData)?.includes('RCON')
        ? {
            data: filterClData(
              { ...contactLensCurrentOrderItemData },
              filteredKeys
            ),
            quantity: orderItem.quantity || null,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: filterClData(
              { ...contactLensSibilingOrderItemData },
              filteredKeys
            ),
            quantity: orderItem.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem.sibilingOrderItem?.orderItemId || null,
          }

    return {
      left: {
        data: filterClData({ ...leftEyeContactLensData.data }, filteredKeys),
        quantity: leftEyeContactLensData?.quantity,
        orderItemId: leftEyeContactLensData?.orderItemId,
      },
      right: {
        data: filterClData({ ...rightEyeContactLensData.data }, filteredKeys),
        quantity: rightEyeContactLensData?.quantity,
        orderItemId: rightEyeContactLensData?.orderItemId,
      },
    }
  } catch (e) {
    Log.error('Could not parse contact lenses data')
    return null
  }
}

export const getAllCartItemsOrderIds = (
  data?: OrderItem[] | OrderItemWithRoxProps[]
): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        data?.map((oi: OrderItem | OrderItemWithRoxProps) => {
          return isContactLensesProduct(oi)
            ? [oi.orderItemId, oi.sibilingOrderItem?.orderItemId || '']
            : [
                oi.roxableServices
                  ?.filter((rs) => {
                    return isRxLens(rs.orderItemExtendAttribute)
                  })
                  ?.map((rs: OrderItem) => {
                    return rs.orderItemId
                  }) || '',
              ]
        })
      )
    ).filter((id) => id !== '')
  } catch (e) {
    Log.error('Could not get order item ids')
    return null
  }
}

export const getSingleItemsOrderIds = (
  oi?: OrderItem | OrderItemWithRoxProps
): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        isContactLensesProduct(oi)
          ? [oi?.orderItemId || '', oi?.sibilingOrderItem?.orderItemId || '']
          : [
              oi?.roxableServices
                ?.filter((rs) => {
                  return isRxLens(rs.orderItemExtendAttribute)
                })
                ?.map((rs: OrderItem) => {
                  return rs.orderItemId
                }) || '',
            ]
      )
    ).filter((id) => id !== '')
  } catch (e) {
    Log.error('Could not get single order item ids')
    return null
  }
}

export const getOrderItemsMap = (
  orderItems: OrderItem[]
): ParsedOrderItemsMapByItemType | null => {
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: OrderItem[] | null =
      parsedOrderItems?.filter((oi) => isContactLensesProduct(oi)) || []
    const rxOrderItems: OrderItem[] | null =
      parsedOrderItems?.filter((oi) => isRox(oi['orderItemExtendAttribute'])) ||
      []
    const sunOrderItems: OrderItem[] | null =
      parsedOrderItems?.filter(
        (oi) => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])
      ) || []
    const clAccessoriesOrderItems: OrderItem[] | null =
      parsedOrderItems?.filter((oi) => isCLAccessoriesProduct(oi)) || []
    const defaultItems = orderItems?.filter(
      (oi) =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return {
      rx: rxOrderItems,
      cl: clOrderItems,
      'cl-acc': clAccessoriesOrderItems,
      sun: sunOrderItems,
      default: defaultItems,
    }
  } catch (e) {
    Log.error('Could not parse order items')
    return null
  }
}

export const getPrescriptionItemsMap = (
  orderItems: OrderItem[] | OrderItemWithRoxProps[],
  filterPrescriptionNeededItems?: boolean,
  filterPrescriptionItemType?: PrescriptionItemType,
  isDesktop?: boolean
): PrescriptionMacroGroup[] | null => {
  let prescriptionMacroGroups: PrescriptionMacroGroup[] = []
  let orderItemsPrescriptionMap: PrescriptionItemsMapByType = []
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: OrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(
          (oi) => isContactLensesProduct(oi) && !oi.prescriptionUploaded
        ) || null
      : parsedOrderItems?.filter((oi) => isContactLensesProduct(oi)) || null

    const rxOrderItems: OrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(
          (oi) =>
            isRox(oi['orderItemExtendAttribute']) && !oi.prescriptionUploaded
        ) || null
      : parsedOrderItems?.filter((oi) =>
          isRox(oi['orderItemExtendAttribute'])
        ) || null

    rxOrderItems &&
      rxOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'rx',
          items: rxOrderItems || [],
        },
      })

    clOrderItems &&
      clOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'cl',
          items: clOrderItems || [],
        },
      })

    orderItemsPrescriptionMap = !!filterPrescriptionItemType
      ? orderItemsPrescriptionMap.filter(
          (group) => group.data.type === filterPrescriptionItemType
        )
      : orderItemsPrescriptionMap
    prescriptionMacroGroups = orderItemsPrescriptionMap.map((group, i) => {
      return {
        id: i,
        selectedItemIndex: 0,
        isSamePrescriptionSelected: isDesktop ? true : false,
        orderItems: group.data.items || [],
        skippedrPrescriptionItems: [],
        prescriptionData: {
          orderItemId: !!group.data.items
            ? getAllCartItemsOrderIds(group.data.items || [])?.join(',')
            : '',
        },
        completedPrescriptionItems: [],
        itemType: group.data.type,
      }
    })
    return prescriptionMacroGroups
  } catch (e) {
    Log.error('Could not parse prescription order items')
    return null
  }
}

export const getFlattenParsedOrderItemsList = (
  obj: ParsedOrderItemsMapByItemType
): OrderItem[] | OrderItemWithRoxProps[] | null => {
  let flattenItemsMap: OrderItem[] | OrderItemWithRoxProps[] = []
  try {
    for (let key in obj) {
      if (!!obj[key]) {
        const orderItems = obj[key] || []
        flattenItemsMap.push(...orderItems)
      }
    }
    return flattenItemsMap
  } catch (e) {
    return null
  }
}

export const isOrderComplete = (
  orderStatus: string,
  orderPaymentMethodId
): boolean => {
  try {
    switch (true) {
      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription].includes(
        orderStatus
      ) && orderPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY:
        return true

      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription].includes(
        orderStatus
      ):
        return true
      default:
        return false
    }
  } catch (e) {
    return false
  }
}

export const fetchOrderItemDetailsByIds = (catentryIdList: string[]) => {
  const promiseArray: Promise<any>[] = []
  const ids = chunk(catentryIdList, 50)
  ids.forEach((id) => {
    const params = {
      id,
      profileName: 'LX_findItemByIds_Details',
    }
    promiseArray.push(
      RequestService.request({
        extraParams: { siteContextKey: 'search' },
        method: 'GET',
        path: '/api/v2/products',
        queryParams: params,
      })
    )
  })
  return Promise.all(promiseArray).then((rs) => {
    let contents = []
    rs.forEach((r) => {
      if (r?.contents) {
        contents = contents.concat(r.contents)
      }
    })
    return contents
  })
}

export const fetchOrderItemsPrescriptionData = async (
  orderItems: OrderItem[] | OrderItemWithRoxProps[],
  storeId: string,
  orderId: string
): Promise<OrderItem[] | OrderItemWithRoxProps[]> => {
  const orderItemsPromises = orderItems?.map(
    async (oi: OrderItem | OrderItemWithRoxProps) => {
      let orderItemObj: OrderItem | OrderItemWithRoxProps = {
        ...oi,
      }

      if (
        isRxLens(oi.orderItemExtendAttribute) ||
        isClOrderItem(oi.orderItemExtendAttribute)
      ) {
        try {
          let orderPrescriptionDetailsRes: PrescriptionDetailsResponse | null
          let orderPrescriptionDetailsImage: any | null

          try {
            orderPrescriptionDetailsRes = isRxLens(oi.orderItemExtendAttribute)
              ? await RequestService.request({
                  method: 'GET',
                  path: `/store/${storeId}/prescription/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
                })
              : null
            !!orderPrescriptionDetailsRes?.results
              ? (orderItemObj = {
                  ...orderItemObj,
                  prescriptionDetails: {
                    data: orderPrescriptionDetailsRes.results || null,
                  },
                })
              : (orderItemObj = oi)
          } catch (error: any) {
            Log.error(
              'Could not retrieve order details prescription details',
              error
            )
            throw new Error(error)
          }
          try {
            //only rx have prescription details
            //cl only show prescription image
            const shouldGetPrescriptionFile: boolean =
              (isRxLens(oi.orderItemExtendAttribute) &&
                !!oi.orderItemExtendAttribute.find(
                  (a) =>
                    a.attributeName ===
                    ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
                ) &&
                !!orderPrescriptionDetailsRes?.results.fileName) ||
              (isClOrderItem(oi.orderItemExtendAttribute) &&
                !!oi.orderItemExtendAttribute.find(
                  (a) =>
                    a.attributeName ===
                    ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
                )) ||
              false

            orderPrescriptionDetailsImage =
              (shouldGetPrescriptionFile &&
                (await RequestService.requestForRtk({
                  method: 'GET',
                  path: `/store/${storeId}/contactLens/rxUploadedFile/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
                  extraParams: {
                    responseType: 'arraybuffer',
                  },
                }))) ||
              null

            !!orderPrescriptionDetailsImage
              ? (orderItemObj = {
                  ...orderItemObj,
                  prescriptionDetails: {
                    ...orderItemObj.prescriptionDetails,
                    file: {
                      content: orderPrescriptionDetailsImage.data || null,
                      type:
                        orderPrescriptionDetailsImage.headers['content-type'] ||
                        null,
                    },
                  },
                })
              : (orderItemObj = orderItemObj)
            return orderItemObj
          } catch (error: any) {
            Log.error(
              'Could not retrieve order details prescription image',
              error
            )
            throw new Error(error)
          }
        } catch (error: any) {
          Log.error('Could not retrieve order details prescription data', error)
          //Cannot retrieve prescription details; return order items as-is
          return orderItemObj
        }
      } else {
        //Order item has no prescription; return order items as-is
        return orderItemObj
      }
    }
  )
  const res = await Promise.all(orderItemsPromises)
  return res
}

export const getOrderItemCatEntries = async (
  catentriesIdArray: string[]
): Promise<IOrderSliceState['catentries'] | undefined> => {
  try {
    let catentries: IOrderSliceState['catentries']
    const catentryIdList = [...new Set(catentriesIdArray)]
    const contents = await fetchOrderItemDetailsByIds(catentryIdList)
    if (contents) {
      catentries = contents.reduce((acc, p: any) => {
        acc[p.id] = p
        return acc
      }, {})
    }
    return catentries
  } catch (error: any) {
    Log.error('Could not retrieve products', error)
    //Cannot retrieve catentry details; return order items as-is
    return undefined
  }
}

/**
 * function to retrive returned status of order.
 * It takes first status, but if order has more then one statuses and one of is `CAN` we have to return other status.
 * Example states = [CAN, CLO] => CLO
 * @param { Order } order current order
 */
export const getOrderReturnedStatus = (
  order: Order | OrderItem
): string | null => {
  const isOrderItem = (order: Order | OrderItem): order is Order =>
    'orderExtendAttribute' in order

  let orderStatuses: Attribute<string>[] = []
  if (isOrderItem(order)) {
    orderStatuses = order.orderExtendAttribute.filter((s) =>
      s.attributeName.includes('RMAStatus')
    )
  } else {
    orderStatuses = order?.orderItemExtendAttribute?.filter((s) =>
      s.attributeName.includes('RMAItemStatus')
    )
  }

  const orderCANstatus = orderStatuses?.find((s) => s.attributeValue === 'CAN')

  const status =
    orderStatuses?.length > 1 && orderCANstatus
      ? orderStatuses.find((s) => s.attributeValue !== 'CAN')?.attributeValue
      : orderStatuses?.[0]?.attributeValue

  return status || null
}

const getDiscounts = (orderItem?: OrderItem | null) => {
  const discounts =
    orderItem?.adjustment?.filter(
      ({ usage }) => usage !== 'Shipping Adjustment'
    ) || []

  return discounts
}

export const getAdjustmentsByTypes = (
  adjustments: Adjustment[],
  discountTypes?: string[]
) =>
  adjustments?.filter(
    ({ usage }) => !discountTypes?.length || discountTypes?.includes(usage)
  )

export const getAdjustmentsSum = (
  adjustments: Adjustment[] = [],
  discountTypes?: string[]
) => {
  const discounts = getAdjustmentsByTypes(adjustments, discountTypes)
  return discounts.reduce((sum, { amount }) => sum + Number(amount), 0)
}

export const getPromoCodeDiscount = (orderItem?: OrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(
    ({ code }) => !code.includes('CL_CUSTOM_PROMO')
  )

  return promoCode ? Number(promoCode?.amount.replace('-', '')) : 0
}

export const getAnnualDiscounts = (orderItem?: OrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const hasSupplyData = getAnnualSupplyBadge(orderItem) || ''

  const hasDiscount =
    hasSupplyData &&
    discounts.find(({ code }) => code.includes('CL_CUSTOM_PROMO'))

  return hasDiscount ? Number(hasDiscount.amount.replace('-', '')) : 0
}

export const isDiscountOnItemLevel = (orderItem?: OrderItem | null) => {
  if (!orderItem) return false
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(
    ({ code }) => !code.includes('CL_CUSTOM_PROMO')
  )

  return promoCode?.displayLevel === 'OrderItem'
}
