import * as ROUTES from '../../constants/routes'
import { generatePath } from 'react-router-dom'

export const useCheckoutPaths = (country: string) => {
  return {
    checkout: generatePath(`/:country/${ROUTES.CHECKOUT}`, { country }),
    cart: generatePath(`/:country/${ROUTES.CART}`, { country }),
    shipping: generatePath(`/:country/${ROUTES.CHECKOUT_SHIPPING}`, {
      country,
    }),
    payment: generatePath(`/:country/${ROUTES.CHECKOUT_PAYMENT}`, { country }),
    'order-confirmation': generatePath(
      `/:country/${ROUTES.ORDER_CONFIRMATION}`,
      { country }
    ),
    uploadPrescription: generatePath(
      `/:country/${ROUTES.CHECKOUT_UPLOAD_PRESCRIPTION}`,
      { country }
    ),
  }
}
