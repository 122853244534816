import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.Mui-disabled': {
    '& .MuiSwitch-root': { opacity: 0.4 },
  },
  '.MuiFormControlLabel-label': {
    color: theme.palette.custom.cyprus,
    lineHeight: 1.5,
    marginRight: 24,
    maxWidth: 188,
  },
  '.MuiFormControlLabel-root': {
    maxWidth: 188,
  },
  '.MuiCheckbox-root': {
    paddingTop: theme.spacing(1.5),
  },
}))
