export interface SuggestedSizeFeature {
  label: string
  optimalHinge: number
  maxHinge: number
  minHinge: number
}
export interface SuggestedFeatures {
  profileImageUrl?: string
  glassGender: string[]
  frameShape: string[]
  frameColor: string[]
  frameColorPalette: string[]
  templeThickness: string[]
  size?: SuggestedSizeFeature
}
export interface SizeAdvisorWidget {
  getSuggestedSizes: (
    sizes: { [key: string]: number },
    lowerBound?: number, // default 4
    upperBound?: number // default 3
  ) => SuggestedSize
}

type SuggestedSizeLabels = 'tight' | 'next-best-size' | 'recommended' | 'loose'

interface SuggestedSize {
  [key: string]: SuggestedSizeLabels
}

export enum FacescanRegion {
  US = 'US',
  EU = 'EU',
  AU = 'AU',
}

export interface FacescanPrivacy {
  /**  This url will be used by the BIPA/Privacy manager */
  privacyPolicy: string

  /** this url will be used by the BIPA/Privacy manager */
  termsAndConditions: string

  /** localization textes, see relevant guide section for more details */
  localizationText?: string
}

export interface FrameGeniusBaseConfig {
  /**
   * The language of the widget (if not present, there is a fallback on English)
   *
   * @default: en-US
   */
  locale: string

  facescanSource: string
  /**
   * The region of the e-commerce integration - used by facescan app
   *
   * Possible values:
   * - US
   * - EU
   * - AU
   *
   */
  facescanRegion: FacescanRegion
  /**
   * The key of Virtual Mirror Component
   *
   */
  frameAdvKey: string
  /**
   * This property is used to set a color for the loader animation.
   * Should be set in HEX format. Ex: #000000. Do not cut to #000.
   */
  loaderAnimationColor?: string

  /**
   * Privacy policy propertires used by facescan app
   */
  facescanPrivacy: FacescanPrivacy
}
