import { AppDispatch } from '../../redux/store'
import wishListService from '../../foundation/apis/transaction/wishList.service'
import { setWishList, removeWishList } from './slice'

export const fetchWishlistAction = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setWishList({ isLoading: true }))

    const wishlist = await wishListService.getDefault()

    dispatch(
      setWishList({
        GiftList: wishlist.GiftList,
        x_data: wishlist.x_data,
        isLoading: false,
      })
    )
  } catch (error) {
    dispatch(setWishList({ isLoading: false }))
  }
}

export const clearWishlistAction = () => (dispatch: AppDispatch) =>
  dispatch(removeWishList())
