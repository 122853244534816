/*global google*/
import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

type Coords = {
  lat: number
  lng: number
}

export const useFindLocation = (input: HTMLInputElement | null) => {
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()

  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const geocodeService = useRef<google.maps.Geocoder | null>(null)
  const autoCompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null)
  const autoComplete = useRef<google.maps.places.Autocomplete | null>(null)

  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >(undefined)

  const getPlaceByGeocode = useCallback(
    (request: google.maps.GeocoderRequest) => {
      geocodeService.current?.geocode(request, (result, status) => {
        if (status !== 'OK' || !result || !result[0])
          return setError(t('findStore.unexpectedSearchError'))
        setSelectedPlace(result[0])
      })
    },
    [t]
  )

  const getPlacePredictions = useCallback(
    (input: string) => {
      autoCompleteService.current?.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: storeCountry },
          types: ['(cities)'],
        },
        (places) => {
          if (!places || !places[0])
            return setError(t('findStore.unexpectedPlaceSearchError'))
          getPlaceByGeocode({
            address: places[0].description,
          })
        }
      )
    },
    [storeCountry, t, getPlaceByGeocode]
  )

  useEffect(() => {
    if (error && !!value.length) {
      setError('')
    }
  }, [error, value])

  useEffect(() => {
    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: ['(cities)'],
      componentRestrictions: { country: storeCountry },
    }

    if (!window.google || !input) return
    if (window.google && google.maps) {
      autoComplete.current = new google.maps.places.Autocomplete(input, options)
    }
    const onPlaceChange = () => {
      const selectedPlace = autoComplete.current?.getPlace()
      setSelectedPlace(selectedPlace)
    }
    autoComplete.current?.addListener('place_changed', onPlaceChange)
  }, [window.google, storeCountry, input])

  useEffect(() => {
    if (!selectedPlace || !selectedPlace.geometry?.location) return
    const formattedAddress = selectedPlace?.formatted_address || ''
    setValue(formattedAddress)
  }, [selectedPlace])

  useEffect(() => {
    if (window.google && google.maps) {
      autoCompleteService.current = new google.maps.places.AutocompleteService()
    }
  }, [window.google])

  useEffect(() => {
    if (window.google && google.maps) {
      geocodeService.current = new google.maps.Geocoder()
    }
  }, [window.google])

  const onHandleSearch = useCallback(() => {
    if (!value) return setError(t('findStore.emptySearchError'))
    getPlacePredictions(value)
  }, [t, value, getPlacePredictions])

  const getGeoposition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      async (geo) => {
        const coords: Coords = {
          lat: geo.coords.latitude,
          lng: geo.coords.longitude,
        }
        getPlaceByGeocode({ location: coords })
      },
      () => {
        setError(t('findStore.unexpectedSearchError'))
      },
      {
        timeout: 10000,
        maximumAge: 10000,
      }
    )
  }, [getPlaceByGeocode, t])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return {
    onHandleSearch,
    getGeoposition,
    onChange,
    error,
    value,
    selectedPlace,
  }
}
