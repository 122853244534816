import { styled } from '@mui/material/styles'

export const StyledTable = styled('table', {
  name: 'Table',
  slot: 'Table',
})(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',

  '& > th, td': {
    padding: theme.spacing(0.25, 0),
    textAlign: 'left',
    verticalAlign: 'top',

    '&:first-of-type': {
      paddingLeft: theme.spacing(1),
    },

    '&:last-child': {
      paddingRight: theme.spacing(1),
    },
  },

  th: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.palette.background.default,
    textTransform: 'uppercase',
  },

  tr: {
    fontSize: 12,

    '&:first-of-type td': {
      paddingTop: 10,
    },
  },
}))
