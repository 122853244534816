import { IOrderDetails, OrderItem } from '../../../../types/order'
import { ProductForAnalytics, ProductForAnalyticsFields } from '../interfaces'
import {
  getBrand,
  getCLBrand,
  getFrameMaterial,
  getFrameShape,
  getFrontColor,
  getLensesColor,
  getLensesTreatment,
  getModelName,
  getProductType,
  getSoldOut,
} from '../../../../utils/productAttributes'

import { ProductAnalyticsRX, ServerProduct } from '../../../../types/product'
import get from 'lodash/get'
import { getProductPrices } from '../../../../utils/productPrice'
import { getRxPrice } from '../../../../utils/rx'

export const parseCatentriesForRX = (
  orderItems: OrderItem[],
  products: ServerProduct[]
): ProductAnalyticsRX[] => {
  // If there's an RX product, removes from the catEntries the lens and services objects (that are provided as a normal item)
  // Then, add to the single RX item (the frame), the total RX price.
  const RX_FRAME = orderItems?.find((i) => !!i?.roxableServices?.length)!
  const FRAME_ID = RX_FRAME?.productId
  const SERVICES_IDS =
    RX_FRAME &&
    RX_FRAME!.roxableServices &&
    (RX_FRAME!.roxableServices!.map((s) => s.productId) as string[])
  const FRAME_CATENTRIES = products.filter(
    (f) => f.id && !SERVICES_IDS?.includes(f.id)
  ) as ProductAnalyticsRX[]
  const FRAME_CATENTRY = FRAME_CATENTRIES.find((f) => f.id === FRAME_ID)
  const FRAME_CATENTRY_INDEX = FRAME_CATENTRIES.findIndex(
    (f) => f.id === FRAME_ID
  )
  const RX_PRICE =
    RX_FRAME &&
    getRxPrice(RX_FRAME.roxableServices!, FRAME_CATENTRY?.x_offerpriceRx)
  FRAME_CATENTRIES[FRAME_CATENTRY_INDEX] = {
    ...FRAME_CATENTRIES[FRAME_CATENTRY_INDEX],
    rxPrice: RX_PRICE,
  }

  return FRAME_CATENTRIES
}

// TODO product got from addToCartEvent don't have prices
//TODO Fix Status when add to cart
export const formatProduct = (
  item: ProductAnalyticsRX,
  quantity?: number
): ProductForAnalyticsFields => {
  // Log.info('ANALYTICS - Formatting product', item as any)
  const type = getProductType(item)
  const prices = getProductPrices(item)
  let productContext: ProductForAnalyticsFields = {
    Case: '',
    Cloth: '',
    Frame: '',
    Lens: '',
    ModelCode: '',
    Size: '',
    Status: getSoldOutWithDefault(item) || 'Available', // TODO
    OosOptions: '', // TODO
    Category: type.toUpperCase(),
    Type: item.rxPrice ? 'RX' : 'STD',
    LensType: item.rxPrice ? 'ROX' : 'PLANO',
    Price: item.rxPrice ? item.rxPrice : prices?.discount,
    PriceFull: item.rxPrice ? item.rxPrice : prices?.full,
    Brand: getBrand(item) || getCLBrand(item),
    Sku: `${item.id}`,
    ModelName: getModelName(item),
    MoCo: `${item.name}`,
    LensColor: getLensesColor(item),
    LensTechnology: getLensesTreatment(item),
    FrameColor: getFrontColor(item),
    FrameTechnology: getFrameMaterial(item),
    Shape: getFrameShape(item),
    LensUPC: '',
    Visibility: 'Public',
    FrameType: 'STD',
    Units: quantity?.toString() || '1',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '',
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '', // TODO
  }

  return productContext
}

const getSoldOutWithDefault = (product: ServerProduct) => {
  const soldOutLabel = getSoldOut(product)
  return soldOutLabel.toLowerCase() === 'none' || soldOutLabel === ''
    ? 'Available'
    : 'SoldOut'
}

export const formatProductForTYP = (
  orderItem: OrderItem
): Partial<ProductForAnalyticsFields> => ({
  TaxRate: orderItem.salesTax,
})

export const getProductsForAnalytics = (
  products: ProductAnalyticsRX[]
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    const productId = getProductId(p)
    if (productId) {
      acc[productId] = {
        ...formatProduct(p),
      }
    }

    return acc
  }, {})
}

export const getProductsPartNamberForAnalytics = (
  products: ProductAnalyticsRX[]
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    let infoType = getProductType(products[0])
    if (infoType === 'Contact Lenses' && p.partNumber === null && p.id) {
      acc[p.id] = {
        ...formatProduct(p),
      }
    } else {
      acc[p.partNumber] = {
        ...formatProduct(p),
      }
    }
    return acc
  }, {})
}

/** Products formatter for cart-related analytics events.
 * @returns map with unique `string` id as key and `ProductForAnalyticsFields` as value
 */
export const getProductsInCartForAnalytics = (
  products: ServerProduct[],
  productIdsCountInOrderMap: Record<string, number>
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, product) => {
    const productId = getProductId(product)
    if (product && productId) {
      acc[productId] = {
        ...formatProduct(product, productIdsCountInOrderMap[productId]),
      }
    }

    return acc
  }, {})
}

export const getProductsForTYPAnalytics = (
  products: ServerProduct[],
  orderDetails: IOrderDetails
): ProductForAnalytics => {
  const orderItems = orderDetails.orderItem || []
  const productIdsCountInOrderMap: Record<string, number> = products.reduce(
    (productIdsCountInOrder, product) => {
      const count = orderItems.filter(
        ({ productId }) => productId === product.id
      ).length
      const productId = getProductId(product)
      return productId ? { ...productIdsCountInOrder, [productId]: count } : {}
    },
    {}
  )

  let formattedProducts = getProductsInCartForAnalytics(
    products,
    productIdsCountInOrderMap
  )

  for (const orderItemId in formattedProducts) {
    const currentFormattedProduct = formattedProducts[orderItemId]
    const orderItem = orderItems.find((i) => i.orderItemId === orderItemId)

    if (orderItem) {
      formattedProducts[orderItemId] = {
        ...currentFormattedProduct,
        ...formatProductForTYP(orderItem),
      }
    }
  }
  return formattedProducts
}

export const getOrderTotalDiscount = (orderDetails: IOrderDetails): string => {
  const totalDiscount = orderDetails.adjustment?.reduce((acc, adj) => {
    if (adj.usage === 'Discount') {
      return acc + Number(adj.amount)
    }
    return acc
  }, 0)
  return String(totalDiscount)
}

export const getOrderState = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].country')

export const getOrderZipCode = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].zipCode')

export const getOrderPaymentType = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].piDescription', '').replace(
    /\s/g,
    ''
  )
const getProductId = (p: ServerProduct): string | undefined => {
  return p.name || p.id || p.uniqueID
}
