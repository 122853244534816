import { useEffect } from 'react'

import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import {
  wordLiftKeySelector,
  wordLiftUrlSelector,
} from '../../redux/selectors/site'
import { useSelector } from 'react-redux'

export const useWordLift = () => {
  const isWordLiftEnabledConf = useSelector(wordLiftKeySelector)
  const worldLiftUrl = useSelector(wordLiftUrlSelector)
  const isWordLiftEnabled = isWordLiftEnabledConf

  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const wordLiftTag = document.createElement('script')
      let srcWordLift = 'https://cloud.wordlift.io/app/bootstrap.js'
      wordLiftTag.src = worldLiftUrl || srcWordLift

      headFragment.appendChild(wordLiftTag)
      document.head.appendChild(headFragment)
    }, 'dw-wordlift')()
  }

  useEffect(() => {
    if (isWordLiftEnabled) {
      try {
        loadScript()
      } catch (e: any) {
        Log.error(
          window.location.href,
          '[WORDLIFT] LOAD SCRIPT ERROR ',
          e.message
        )
      }
    }
  }, [isWordLiftEnabled])
}
