export const BASE_COUNTRY_KEY = 'country'
export const BASE = `/:${BASE_COUNTRY_KEY}`
export const HOME = '/'
export const CMSPREVIEW = '/cmpreview'
export const CHECKOUT = 'checkout'

//Order
export const CART = 'cart'
export const MAINTENANCE = '/maintenance'

export const CHECKOUT_SHIPPING = `${CHECKOUT}/shipping`
export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`
export const CHECKOUT_REVIEW = `${CHECKOUT}/review`
export const ORDER_CONFIRMATION = `${CHECKOUT}/order-confirmation`
export const CHECKOUT_UPLOAD_PRESCRIPTION = `${CHECKOUT}/upload-prescription`
export const WISHLIST = 'wishlist'
export const PRESCRIPTION_VERIFICATION = 'upload-prescription'
export const STORELOCATOR = 'store-locator'
export const STORE = `${STORELOCATOR}#/store-details/`

//Account
export const SIGNIN = 'sign-in'
export const SIGNUP = 'sign-up'
export const FORGOT_PASSWORD = 'forgot-password'
export const ACCOUNTWITHOUTPARAM = 'account'
export const ACCOUNT = 'account/:page'
export const ACCOUNT_CHILDREN = {
  DASHBOARD: 'dashboard',
  ADDRESS_BOOK: 'address-book',
  ORDER_HISTORY: 'order-history',
  PERSONAL_INFORMATION: 'personal-information',
  SETTINGS: 'settings',
  PAYMENT_METHODS: 'payment-methods',
  STORES: 'stores',
  FAVOURITES: 'wishlist',
}

// //User
// export const CHANGE_PASSWORD = buildAccountPageRoute(ACCOUNT_CHILDREN.CHANGE_PASSWORD)

//Search
export const SEARCH = '/search'

//Services
export const SITEMAP = 'sitemap'

//register user access only
export const REGISTER_PROTECTED = 'register'
//only guest and generic user access
export const GENERIC_PROTECTED = 'generic'

export const CMS_PAGE_NAME_LV1 = '/:pageNameLv1'
export const CMS_PAGE_NAME_LV2 = '/:pageNameLv2'
export const CMS_PAGE_NAME_LV3 = '/:pageNameLv3'
export const CMS_PAGE_NAME_LV4 = '/:pageNameLv4'
//export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}${CMS_PAGE_NAME_LV2}${CMS_PAGE_NAME_LV3}${CMS_PAGE_NAME_LV4}${CMS_PAGE_NAME_LV5}`
export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}`
