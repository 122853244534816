import React from 'react'
import {
  OrderDetailsCLInfoContainer,
  OrderDetailsProductInfoItem,
  OrderDetailsProductInfoListItem,
  OrderDetailsProductInfoRow,
} from './OrderDetails.style'

import {
  getModelName,
  getQuantity,
  getContactLensesParams,
  getLensesColor,
} from '../../../utils/productAttributes'
import { isRxLens } from '../../../utils/rx'
import { ProductTypesEnum } from './../../../types/product'
import { getProductAttribute } from '../../../utils/productAttributes'
import { useTranslation } from 'react-i18next'
import { OrderRecapClItemLensDetailsContent } from '../../../components/order-recap/OrderRecapClItemLensDetailsContent'
import { useContactLensCartItemData } from '../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { OrderRecapClItemLensDetailsContentMobile } from '../../../components/order-recap/OrderRecapClItemLensDetailsContentMobile'

export const OrderInfo = ({
  product,
  orderItem,
  productType,
  orderDetails,
}) => {
  const { t } = useTranslation()
  const rxServices = orderItem?.roxableServices
  const nonRxLensAttributesArray: string[] = [getLensesColor(product)]
  const { isDesktop } = useBreakpoints()
  const rxLens = rxServices?.find((x) => isRxLens(x.orderItemExtendAttribute))
  const LENS_OBJECT = orderDetails?.x_data?.productDetails?.find(
    ({ id }) => id === rxLens?.productId
  )
  const rxLensAttributesArray: string[] = [
    getProductAttribute(LENS_OBJECT, 'CORRECTION_TYPE'),
    getProductAttribute(LENS_OBJECT, 'MODEL_NAME'),
    getProductAttribute(LENS_OBJECT, 'LENS_THICKNESS'),
    getProductAttribute(LENS_OBJECT, 'ANTI_REFLECTIVE'),
    getProductAttribute(LENS_OBJECT, 'LENS_TREATMENT'),
    getProductAttribute(LENS_OBJECT, 'ANTI_BLUE'),
  ]

  const FrameInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('CartRecap.Labels.Frame')}:
        </OrderDetailsProductInfoListItem>
        <OrderDetailsProductInfoItem>
          {getModelName(product)}
        </OrderDetailsProductInfoItem>
      </OrderDetailsProductInfoRow>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('CartRecap.Labels.Lenses')}:
        </OrderDetailsProductInfoListItem>
        {rxServices ? (
          <OrderDetailsProductInfoItem>
            {rxLensAttributesArray.map((a) => (
              <span>{a}</span>
            ))}
          </OrderDetailsProductInfoItem>
        ) : (
          <OrderDetailsProductInfoItem>
            {nonRxLensAttributesArray.map((a) => (
              <span>{a}</span>
            ))}
          </OrderDetailsProductInfoItem>
        )}
      </OrderDetailsProductInfoRow>
    </>
  )

  const ContactLensesInfo = () => {
    const lenseInfo = getContactLensesParams(orderItem)
    if (!lenseInfo) return null

    const { orderItemClData } = useContactLensCartItemData({
      orderItem: orderItem,
    })

    return (
      <>
        <OrderDetailsProductInfoRow>
          <OrderDetailsProductInfoListItem>
            {getModelName(product)}
          </OrderDetailsProductInfoListItem>
        </OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoRow>
          {orderItemClData && (
            <OrderDetailsCLInfoContainer>
              {isDesktop ? (
                <OrderRecapClItemLensDetailsContent
                  orderItemClData={orderItemClData}
                  showBoxesNumber
                />
              ) : (
                <OrderRecapClItemLensDetailsContentMobile
                  orderItemClData={orderItemClData}
                />
              )}
            </OrderDetailsCLInfoContainer>
          )}
        </OrderDetailsProductInfoRow>
      </>
    )
  }

  const AccessoriesInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {getModelName(product)}
        </OrderDetailsProductInfoListItem>
      </OrderDetailsProductInfoRow>
    </>
  )
  const ContactLensesAccessoriesInfo = () => (
    <>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {getModelName(product)}
        </OrderDetailsProductInfoListItem>
      </OrderDetailsProductInfoRow>
      <OrderDetailsProductInfoRow>
        <OrderDetailsProductInfoListItem>
          {t('OrderRecap.Labels.Quantity')}:
        </OrderDetailsProductInfoListItem>
        <OrderDetailsProductInfoItem>
          {getQuantity(orderItem)}
        </OrderDetailsProductInfoItem>
      </OrderDetailsProductInfoRow>
    </>
  )

  switch (productType) {
    case ProductTypesEnum.ContactLenses:
      return <ContactLensesInfo />
    case ProductTypesEnum.Accessories:
      return <AccessoriesInfo />
    case ProductTypesEnum.ContactLensesAccessories:
      return <ContactLensesAccessoriesInfo />
    default:
      return <FrameInfo />
  }
}
