// Standard libraries
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

//UI
import { FindStore } from '../../components/FindStore'
import {
  AccountStoresTitle,
  AccountStoresBodyWrapper,
  AccountStoresList,
  AccountStoresCard,
  AccountStoresCardName,
  AccounStoresCardContent,
  MapContainer,
  AccountStoreAddress,
  AccountStoreInfo,
  LinkStoreInfo,
  LinksContainer,
  StyledDivider,
  AccountStoresCardHeader,
  StoreHoursTitle,
  BookEyeTestButton,
  StyledCheckmarkIcon,
  DefaultStoreText,
  ToggleDefaultStore,
  MapImage,
  AccountStoresCheckboxContainer,
  StyledButton,
} from './AccountStores.style'
import { StyledCheckbox } from '../../components/UI'
import { StoreOpeningHours } from './components/StoreOpeningHours'
import { useFavoriteStores } from '../../hooks/useFavoriteStores'
import isEmpty from 'lodash/isEmpty'
import { useFavoriteStoresMethods } from '../../hooks/useFavoriteStoresMethods'
import { generateStaticMapUrl } from '../../utils/staticMapUrl'
import { useSelector } from 'react-redux'
import { googleApiKeySelector } from '../../redux/selectors/site'
import { Store } from '../../types/store'
import Skeleton from 'react-loading-skeleton'
import useBreakpoints from '../../hooks/useBreakpoints'
import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'
import theme from '../../themes'

const formatTelNumber = (number: string) => {
  const tel = number.replace(/\s/g, '')
  const code = tel.slice(0, 5)
  return '(' + code + ') ' + tel.slice(5, 8) + ' ' + tel.slice(8)
}

export const StoreInfo: React.FC<{
  store: Store
  hideBookEyeBtn?: boolean
  className?: string
  isBoldStoreHours?: boolean
}> = ({ store, className, hideBookEyeBtn = false, isBoldStoreHours }) => {
  const openInNewTab = (url?: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const { t } = useTranslation()
  return (
    <AccountStoreInfo className={className}>
      <AccountStoreAddress>{store.completeAddress}</AccountStoreAddress>
      <LinksContainer>
        <LinkStoreInfo external to={`tel:${store.telephone}`}>
          {formatTelNumber(store.telephone)}
        </LinkStoreInfo>
        <LinkStoreInfo
          target="_blank"
          rel="noopener noreferrer"
          external
          to={`https://www.google.com/maps?q=${store.completeAddress}`}
        >
          {t('AccountStores.GetDirection')}
        </LinkStoreInfo>
      </LinksContainer>
      <StyledDivider />
      <StoreHoursTitle className={'store-info__store-hours-title'}>
        {t('AccountStores.StoreHoursTitle')}
      </StoreHoursTitle>
      <StoreOpeningHours store={store} isBoldStoreHours={isBoldStoreHours} />
      {!hideBookEyeBtn && (
        <BookEyeTestButton onClick={() => openInNewTab(store.bookAnEyeTestUrl)}>
          {t('AccountStores.BookEyeTest')}
        </BookEyeTestButton>
      )}
    </AccountStoreInfo>
  )
}

const AccountStores: FC = () => {
  const { t } = useTranslation()
  const googleMapsApiKey = useSelector(googleApiKeySelector)

  const { favoriteStores, defaultFavoriteStoreId } = useFavoriteStores()
  const { changeDefaultFavoriteStore, removeFavoriteStore, isLoading } =
    useFavoriteStoresMethods()

  const { isMobile } = useBreakpoints()

  const favoriteStoresSorted = [
    ...favoriteStores.filter((s) => `${s.storeId}` === defaultFavoriteStoreId),
    ...favoriteStores.filter((s) => `${s.storeId}` !== defaultFavoriteStoreId),
  ]

  const setDefaultStore = (id: string) => {
    changeDefaultFavoriteStore(id)

    const appMainContainerElement = document.getElementById(
      APP_MAIN_CONTAINER_ID
    )
    appMainContainerElement?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (isLoading) {
    return (
      <AccountStoresBodyWrapper>
        <>
          {new Array(
            favoriteStoresSorted?.length === 0
              ? 6
              : favoriteStoresSorted?.length
          )
            .fill(1)
            .map((_, index) => (
              <div key={index}>
                <Skeleton
                  style={{
                    height: isMobile ? '760px' : '360px',
                    width: '100%',
                  }}
                />
              </div>
            ))}
        </>
      </AccountStoresBodyWrapper>
    )
  }

  return (
    <AccountStoresBodyWrapper>
      <AccountStoresTitle> {t('AccountStores.Title')}</AccountStoresTitle>
      {isEmpty(favoriteStoresSorted) ? (
        <FindStore />
      ) : (
        <>
          <FindStore />
          <AccountStoresList>
            <AccountStoresTitle>
              {t('AccountStores.SavedStores')}
            </AccountStoresTitle>
            {favoriteStoresSorted.map((store) => {
              const staticMapUrl = generateStaticMapUrl(store, googleMapsApiKey)
              return (
                <AccountStoresCard key={store.storeId}>
                  <ToggleDefaultStore>
                    {defaultFavoriteStoreId === `${store.storeId}` ? (
                      <>
                        <StyledCheckmarkIcon
                          htmlColor={theme.palette.custom.cyprus}
                        />
                        <DefaultStoreText>
                          {t('AccountStores.DefaultStore')}
                        </DefaultStoreText>
                      </>
                    ) : (
                      <>
                        <AccountStoresCheckboxContainer>
                          <StyledCheckbox
                            onClick={() => setDefaultStore(`${store.storeId}`)}
                          />
                        </AccountStoresCheckboxContainer>
                        <DefaultStoreText>
                          {t('AccountStores.SetDefaultStore')}
                        </DefaultStoreText>
                      </>
                    )}
                  </ToggleDefaultStore>
                  <AccountStoresCardHeader>
                    <AccountStoresCardName>
                      {store.storeName}
                    </AccountStoresCardName>
                    <StyledButton
                      fillType={'outline'}
                      onClick={() => removeFavoriteStore(`${store.storeId}`)}
                    >
                      {t('AccountStores.Remove')}
                    </StyledButton>
                  </AccountStoresCardHeader>
                  <StyledDivider />
                  <AccounStoresCardContent>
                    <MapContainer>
                      <MapImage
                        src={staticMapUrl}
                        alt={store.storeName}
                      ></MapImage>
                    </MapContainer>
                    <StoreInfo store={store} isBoldStoreHours={true} />
                  </AccounStoresCardContent>
                </AccountStoresCard>
              )
            })}
          </AccountStoresList>
        </>
      )}
    </AccountStoresBodyWrapper>
  )
}

export default AccountStores
