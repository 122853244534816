export const customPalette = {
  davidclulow: {
    cyprus: '#00303C',
    sherpaBlue: '#014758',
    halfBaked: '#83C8BB',
    jaggedIce: '#BCE7DF',
    boulder: '#767676',
    alto: '#D8D8D8',
    wildSand: '#F6F6F6',
    white: '#FFFFFF',
    greenPea: '#247534',
    tulipTree: '#F0A63C',
    thunderBird: '#D32113',
    black: '#000000',
    grey: '#333333',
  },
}
