import { takeLatest } from 'redux-saga/effects'
import * as ACTIONS from '../../action-types/user'
import * as WORKERS from '../workers/user'
import { CANCEL_SESSION_ERROR_ACTION } from '../../actions/error'
import {
  SESSION_ERROR_LOGIN_REQUESTED_ACTION,
  LOGOUT_REQUESTED_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
  INIT_STATE_FROM_STORAGE_ACTION,
} from '../../actions/user'
import { AppPayloadWithWidgetAction } from '../../store'

export function* watchSaga() {
  //storage
  yield takeLatest<AppPayloadWithWidgetAction>(
    INIT_STATE_FROM_STORAGE_ACTION,
    WORKERS.initStateFromStorage
  )
  yield takeLatest(
    LISTEN_USER_FROM_STORAGE_ACTION,
    WORKERS.updateStateFromStorage
  )
  //login
  yield takeLatest(ACTIONS.LOGIN_REQUESTED, WORKERS.login)
  yield takeLatest(
    ACTIONS.LOGON_AND_CHANGE_PASSWORD_REQUESTED,
    WORKERS.logonAndChangePassword
  )
  yield takeLatest(
    SESSION_ERROR_LOGIN_REQUESTED_ACTION,
    WORKERS.sessionErrorReLogin
  )
  yield takeLatest(
    ACTIONS.FETCH_USER_DETAILS_REQUESTED,
    WORKERS.fetchUserDetails
  )
  //logout
  yield takeLatest(
    [CANCEL_SESSION_ERROR_ACTION, LOGOUT_REQUESTED_ACTION],
    WORKERS.logout
  )
  //registration
  yield takeLatest(ACTIONS.REGISTRATION_REQUESTED, WORKERS.registration)
}
