import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import {
  PrescriptionDetailsPayload,
  PrescriptionFilePayload,
  PrescriptionPreviewPayload,
} from '../../types/checkout'
import { RxPrescriptionDetails } from '../../types/prescription'

export const prescriptionApi = createApi({
  reducerPath: 'prescriptionApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    uploadPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/storeRXFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionFile: build.mutation<
      any,
      PrescriptionFilePayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile/multiple`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadPrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/contactLens/prescriptionDetails`,
          method: 'POST',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionDetails: build.mutation<
      any,
      PrescriptionDetailsPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails`,
          method: 'PUT',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionDetails: build.mutation<
      any,
      PrescriptionDetailsPayload
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails/multiple`,
          method: 'POST',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    getPrescriptionFilePreview: build.query<any, PrescriptionPreviewPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, rxFileStorageId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/rxFileStorageId/${rxFileStorageId}`,
          method: 'GET',
          extraParams: {
            responseType: 'arraybuffer',
          },
          body:
            {
              ...args,
            } || {},
        })
        return result?.data
          ? {
              data: {
                content: result.data,
                headers: result.headers,
              },
            }
          : { error: result.error }
      },
    }),
    getPrescriptionDetails: build.query<
      RxPrescriptionDetails,
      { storeId: string; orderId: string; orderItemId: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/orderId/${orderId}/orderItemId/${orderItemId}`,
          method: 'GET',
          body:
            {
              ...args,
            } || {},
        })
        return result?.data
          ? {
              data: result.data.results as RxPrescriptionDetails,
            }
          : { error: result.error }
      },
    }),
    getUploadedPrescriptionFileData: build.query<
      any,
      { storeId: string; orderId: string; orderItemId: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/contactLens/rxUploadedFile/orderId/${orderId}/orderItemId/${orderItemId}`,
          method: 'GET',
          extraParams: {
            responseType: 'arraybuffer',
          },
          body:
            {
              ...args,
            } || {},
        })
        return result?.data
          ? {
              data: {
                content: result.data,
                headers: result.headers,
              },
            }
          : { error: result.error }
      },
    }),
  }),
})

export const {
  useLazyGetPrescriptionDetailsQuery,
  useLazyGetPrescriptionFilePreviewQuery,
} = prescriptionApi
