import { CmsImageCrops, CtaStyle } from '../types/common'

import { Theme } from '@mui/material'
import config from '../configs'

export const APP_MAIN_CONTAINER_CLASSNAME = `${process.env.REACT_APP_STORENAME}-app__main-container`
export const APP_MAIN_CONTAINER_ID = `${process.env.REACT_APP_STORENAME}-app__main`
export const ctaStylesMap: CtaStyle = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const cmsImageCrops: CmsImageCrops = {
  FULL_WIDTH_BANNER: {
    deskL: {
      crop: 'landscape_ratio64x29',
      width: 2880,
    },
    deskS: {
      crop: 'landscape_ratio64x29',
      width: 2560,
    },
    tabletL: {
      crop: 'landscape_ratio64x29',
      width: 1843,
    },
    tabletP: {
      crop: 'landscape_ratio96x65',
      width: 1382,
    },
    mobile: {
      crop: 'portrait_ratio75x104',
      width: 675,
    },
  },

  LANDSCAPE_BANNER: {
    deskL: {
      crop: 'landscape_ratio40x13',
      width: 2880,
    },
    deskS: {
      crop: 'landscape_ratio40x13',
      width: 2560,
    },
    tabletL: {
      crop: 'landscape_ratio32x13',
      width: 1843,
    },
    tabletP: {
      crop: 'landscape_ratio192x71',
      width: 1382,
    },
    mobile: {
      crop: 'landscape_ratio375x284',
      width: 675,
    },
  },

  TOP_PAGE_BANNER: {
    deskL: {
      crop: 'landscape_ratio9x2',
      width: 2880,
    },
    deskS: {
      crop: 'landscape_ratio320x71',
      width: 2560,
    },
    tabletL: {
      crop: 'landscape_ratio64x29',
      width: 1843,
    },
    tabletP: {
      crop: 'landscape_ratio192x71',
      width: 1382,
    },
    mobile: {
      crop: 'landscape_ratio374x284',
      width: 675,
    },
  },

  SQUAT_BANNER: {
    deskL: {
      crop: 'landscape_ratio6x1',
      width: 2880,
    },
    deskS: {
      crop: 'landscape_ratio16x3',
      width: 2560,
    },
    tabletP: {
      crop: 'landscape_ratio64x15',
      width: 1843,
    },
    tabletL: {
      crop: 'landscape_ratio16x5',
      width: 1382,
    },
    mobile: {
      crop: 'portrait_ratio125x126',
      width: 675,
    },
  },

  //---------------------------------------------------------
  BOX_WITH_MARGIN_BANNER: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1376,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1376,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 749,
    },
    tabletP: {
      crop: 'landscape_ratio176x163',
      width: 1267,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 617,
    },
  },

  BOARD_WITH_FIELDS_2_ITEMS: {
    deskL: {
      crop: 'landscape_ratio326x181',
      width: 1304,
    },
    deskS: {
      crop: 'landscape_ratio286x181',
      width: 1144,
    },
    tabletL: {
      crop: 'landscape_ratio238x181',
      width: 857,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
    },
    mobile: {
      crop: 'portrait_ratio343x362',
      width: 617,
    },
  },

  BOARD_WITH_FIELDS_3_ITEMS: {
    deskL: {
      crop: 'landscape_ratio216x181',
      width: 864,
    },
    deskS: {
      crop: 'landscape_ratio379x362',
      width: 758,
    },
    tabletL: {
      crop: 'portrait_ratio315x362',
      width: 567,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
    },
  },

  BOARD_WITH_FIELDS_4_ITEMS: {
    deskL: {
      crop: 'landscape_ratio200x181',
      width: 800,
    },
    deskS: {
      crop: 'landscape_ratio200x181',
      width: 800,
    },
    tabletL: {
      crop: 'landscape_ratio204x181',
      width: 734,
    },
    tabletP: {
      crop: 'landscape_ratio352x181',
      width: 1267,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
    },
  },

  SQUARE_BOARDS_WITH_SPLIT: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1280,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1120,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 835,
    },
    tabletP: {
      crop: 'landscape_ratio1x1',
      width: 605,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
    },
  },

  SQUARE_BOARDS_WITHOUT_SPLIT: {
    deskL: {
      crop: 'landscape_ratio1x1',
      width: 1440,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1280,
    },
    tabletL: {
      crop: 'landscape_ratio1x1',
      width: 922,
    },
    tabletP: {
      crop: 'landscape_ratio1x1',
      width: 691,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
    },
  },

  COMBO_MINI_SLIDER_SMALL: {
    deskL: {
      crop: 'portrait_ratio18x19',
      width: 720,
    },
    deskS: {
      crop: 'portrait_ratio18x19',
      width: 720,
    },
    tabletL: {
      crop: 'portrait_ratio187x197',
      width: 337,
    },
    tabletP: {
      crop: 'portrait_ratio327x362',
      width: 589,
    },
    mobile: {
      crop: 'landscape_ratio1x1',
      width: 675,
    },
  },

  COMBO_MINI_SLIDER_BIG: {
    deskL: {
      crop: 'portrait_ratio43x44',
      width: 1376,
    },
    deskS: {
      crop: 'landscape_ratio1x1',
      width: 1216,
    },
    tabletL: {
      crop: 'portrait_ratio56x61',
      width: 806,
    },
    tabletP: {
      crop: 'landscape_ratio128x127',
      width: 1382,
    },
    mobile: {
      crop: 'portrait_ratio327x362',
      width: 589,
    },
  },

  /* PLP_ONE_TILES: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 1280,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },*/
  PLP_TWO_TILES: {
    deskL: {
      crop: 'landscape_ratio863x416',
      width: 1726,
    },
    deskS: {
      crop: 'landscape_ratio755x361',
      width: 1510,
    },
    tabletL: {
      crop: 'landscape_ratio317x149',
      width: 1141,
    },
    tabletP: {
      crop: 'portrait_ratio343x359',
      width: 617,
    },
    mobile: {
      crop: 'portrait_ratio343x345',
      width: 617,
    },
  },
  /* PLP_THREE_TILES: {
    deskS: {
      crop: 'landscape_ratio2x1',
      width: 1440,
    },
    tabletP: {
      crop: 'portrait_ratio2x1',
      width: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },*/
  //---------------------------------------------------------
  CART_PROMO_BANNER: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },

  AVATAR_MEGA_MENU: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 96,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 96,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 96,
    },
  },

  BIG_MENU_BANNER: {
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 1296,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },

  QUERY_LIST: {
    deskL: {
      crop: 'portrait_ratio1x1',
      width: 832,
    },
    deskS: {
      crop: 'portrait_ratio1x1',
      width: 416,
    },
    tabletL: {
      crop: 'portrait_ratio1x1',
      width: 416,
    },
    tabletP: {
      crop: 'portrait_ratio1x1',
      width: 416,
    },
    mobile: {
      crop: 'portrait_ratio11x12',
      width: 327,
    },
  },
  FOOTER_NEWSLETTER_SIDE_BANNER: {
    deskS: {
      crop: 'portrait_ratio111x128',
      width: 222,
    },
    tabletP: {
      crop: 'portrait_ratio111x128',
      width: 222,
    },
    mobile: {
      crop: 'portrait_ratio111x128',
      width: 200,
    },
  },
  FOOTER_NEWSLETTER_DRAWER: {
    deskS: {
      crop: 'portrait_ratio233x396',
      width: 466,
    },
    tabletP: {
      crop: 'portrait_ratio233x396',
      width: 466,
    },
    mobile: {
      crop: 'landscape_ratio343x179',
      width: 618,
    },
  },
}

export const getDesktopThemeBreakpoint = (theme: Theme) =>
  theme.breakpoints.up('md')

//grid
export const gridColumns = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 12,
  xl: 12,
}

//zIndex
export const Z_INDEX_LV5 = 5
export const Z_INDEX_LV4 = 4
export const Z_INDEX_LV3 = 3
export const Z_INDEX_LV2 = 2
export const Z_INDEX_LV1 = 1

//Icons
export const ICON_ADDRESS = `${config.storePrefix}-icon--address`
export const ICON_AFTER_CARE = `${config.storePrefix}-icon--after_care`
export const ICON_BABY_SMILE = `${config.storePrefix}-icon--baby_smile`
export const ICON_CALENDAR = `${config.storePrefix}-icon--calendar`
export const ICON_CHAT = `${config.storePrefix}-icon--chat`
export const ICON_CHOOSE = `${config.storePrefix}-icon--choose`
export const ICON_CLOCK = `${config.storePrefix}-icon--clock`
export const ICON_CONTACT_LENS = `${config.storePrefix}-icon--contact_lens`
export const ICON_CONTACT_LENS_FINGER = `${config.storePrefix}-icon--contact_lens_finger`
export const ICON_CREDIT_CARD = `${config.storePrefix}-icon--credit_card`
export const ICON_DISPLAY = `${config.storePrefix}-icon--display`
export const ICON_DROP = `${config.storePrefix}-icon--drop`
export const ICON_EYE = `${config.storePrefix}-icon--eye`
export const ICON_EYE_CARE = `${config.storePrefix}-icon--eye_care`
export const ICON_EYEWEAR_RANGE = `${config.storePrefix}-icon--eyewear_range`
export const ICON_FACE_GLASSES_SMILE = `${config.storePrefix}-icon--face_glasses_smile`
export const ICON_FACE_OVAL = `${config.storePrefix}-icon--face_oval`
export const ICON_FACE_OVAL_THICK = `${config.storePrefix}-icon--face_oval_thick`
export const ICON_FACE_RECTANGLE = `${config.storePrefix}-icon--face_rectangle`
export const ICON_FACE_RECTANGLE_SLIM = `${config.storePrefix}-icon--face_rectangle_slim`
export const ICON_FACE_RECTANGLE_THICK = `${config.storePrefix}-icon--face_rectangle_thick`
export const ICON_FACE_ROUND = `${config.storePrefix}-icon--face_round`
export const ICON_FACE_ROUND_THICK = `${config.storePrefix}-icon--face_round_thick`
export const ICON_FACE_SLIM = `${config.storePrefix}-icon--face_slim`
export const ICON_FACE_SQUARE = `${config.storePrefix}-icon--face_square`
export const ICON_FACE_TRIANGLE = `${config.storePrefix}-icon--face_triangle`
export const ICON_FRAME_ADVISOR = `${config.storePrefix}-icon--frame_advisor`
export const ICON_FREE_RETURN = `${config.storePrefix}-icon--free_return`
export const ICON_GLASSES = `${config.storePrefix}-icon--glasses`
export const ICON_GLASSES_CASE = `${config.storePrefix}-icon--glasses_case`
export const ICON_GLOBAL_CARE = `${config.storePrefix}-icon--global_care`
export const ICON_GREEN_SHIPPING = `${config.storePrefix}-icon--green_shipping`
export const ICON_HEART_EMPTY = `${config.storePrefix}-icon--heart_empty`
export const ICON_HEART_FILLED = `${config.storePrefix}-icon--heart_filled`
export const ICON_INFO = `${config.storePrefix}-icon--info`
export const ICON_LENS_CLEANER = `${config.storePrefix}-icon--lens_cleaner`
export const ICON_LENS_GLASS = `${config.storePrefix}-icon--lens_glass`
export const ICON_LENS_GLASS_LASER = `${config.storePrefix}-icon--lens_glass_laser`
export const ICON_LENS_GLASS_PHOTOCHROMIC = `${config.storePrefix}-icon--lens_glass_photochromic`
export const ICON_LENS_SUN = `${config.storePrefix}-icon--lens_sun`
export const ICON_LENS_SUN_OAKLEY = `${config.storePrefix}-icon--lens_sun_oakley`
export const ICON_LENS_SUN_RAY_BAN = `${config.storePrefix}-icon--lens_sun_ray_ban`
export const ICON_LENS_SUN_RX = `${config.storePrefix}-icon--lens_sun_rx`
export const ICON_LUXURY_GLASSES = `${config.storePrefix}-icon--luxury_glasses`
export const ICON_MAGNIFYING_GLASS = `${config.storePrefix}-icon--magnifying_glass`
export const ICON_MASK = `${config.storePrefix}-icon--mask`
export const ICON_OAKLEY_GLASS = `${config.storePrefix}-icon--oakley_glass`
export const ICON_OFFER = `${config.storePrefix}-icon--offer`
export const ICON_OPTICIAN = `${config.storePrefix}-icon--optician`
export const ICON_OPTOMETRIST = `${config.storePrefix}-icon--optometrist`
export const ICON_PADLOCK = `${config.storePrefix}-icon--padlock`
export const ICON_PILOT_DARK_GLASSES = `${config.storePrefix}-icon--pilot_dark_glasses`
export const ICON_PILOT_GLASSES_METER = `${config.storePrefix}-icon--pilot_glasses_meter`
export const ICON_PRESCRIPTION = `${config.storePrefix}-icon--prescription`
export const ICON_QUESTION = `${config.storePrefix}-icon--question`
export const ICON_RAYBAN_GLASS = `${config.storePrefix}-icon--rayban_glass`
export const ICON_REPLACE = `${config.storePrefix}-icon--replace`
export const ICON_RETINAL_SCREENING = `${config.storePrefix}-icon--retinal_screening`
export const ICON_ROUND_GLASSES = `${config.storePrefix}-icon--round_glasses`
export const ICON_SEARCH = `${config.storePrefix}-icon--search`
export const ICON_SHOPPING = `${config.storePrefix}-icon--shopping`
export const ICON_SMOOTH_RECTANGLE_GLASSES = `${config.storePrefix}-icon--smooth_rectangle_glasses`
export const ICON_SOCIAL_DISTANCE = `${config.storePrefix}-icon--social_distance`
export const ICON_STORE_MANAGER = `${config.storePrefix}-icon--store_manager`
export const ICON_STORES = `${config.storePrefix}-icon--stores`
export const ICON_TEMPLE_SIZE = `${config.storePrefix}-icon--temple_size`
export const ICON_TEST = `${config.storePrefix}-icon--test`
export const ICON_TRIANGLE_DANGER = `${config.storePrefix}-icon--triangle_danger`
export const ICON_CHECKMARK_ROUNDED = `${config.storePrefix}-icon--checkmark_rounded`
export const ICON_MOBILE = `${config.storePrefix}-icon--mobile`
export const ICON_ANTI_STATIC_COATING = `${config.storePrefix}-icon--anti_static_coating`
export const ICON_UV_PROTECTION = `${config.storePrefix}-icon--uv_protection`
export const ICON_BETTER_CLARITY = `${config.storePrefix}-icon--better_clarity`

// Redirects for empty cart without item.teaserLXCallToActionSettings
export const PLP_LINK_BRANDS = 'eyewear-all'
export const PLP_LINK_CONTACT_LENSES = 'contact-lenses'
export const DASH_CHARACTER = '-'
export const SLASH_CHARACTER = '/'
export const SEPARATOR_CHARACTER = '|'
export const COLON_CHARACTER = ':'
export const TIME_SEPARATOR = ':'
