import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ORDER_CONFIGS } from '../../../configs/order'
import Log from '../../../services/Log'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { fetchOrderItemDetailsByIds } from '../../../redux/sagas/workers/orderDetails'
import { IOrderSliceState } from '../IOrderSliceState'
import { CartPayload } from '../../../types/cart'

export interface IFetchCartArgs {
  fetchCatentries?: boolean
  checkInventory?: boolean
  widget?: string
  cancelToken?: any
  currency?: string
  contractId?: string
}

const fetchCart = createAsyncThunk<any, IFetchCartArgs>(
  'order/fetchCart',
  async (args, { rejectWithValue }) => {
    try {
      const parameters: CartPayload = {
        ...args,
        sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
      }

      const checkInventory: boolean = args.checkInventory ?? false

      if (args.widget) {
        parameters['widget'] = args.widget
      }

      const cartResponse = await cartService.getCart(parameters)
      let catentries: IOrderSliceState['catentries']

      if (cartResponse) {
        const cart = cartResponse.data
        if (cart) {
          const orderItems = cart.orderItem

          if (orderItems && orderItems.length > 0) {
            let catentryIdList: string[] = []

            //get product info for all items
            orderItems.forEach((item: any) => {
              catentryIdList.push(item.productId)
            })

            if (catentryIdList.length > 0) {
              catentryIdList = [...new Set(catentryIdList)]

              const currency = parameters ? parameters.currency : ''
              const contractId = parameters ? parameters.contractId : ''
              const paramsProduct: any = {
                currency: currency,
                contractId: contractId,
                id: catentryIdList,
              }
              if (parameters?.cancelToken) {
                paramsProduct['cancelToken'] = parameters.cancelToken
              }
              if (parameters?.widget) {
                paramsProduct['widget'] = parameters.widget
              }

              try {
                const contents = await fetchOrderItemDetailsByIds(paramsProduct)
                if (contents) {
                  catentries = contents.reduce((acc, p: any) => {
                    acc[p.id] = p
                    return acc
                  }, {})
                }
              } catch (error) {
                Log.error('Could not retrieve products')

                //Cannot retrieve catentry details; return order items as-is
                catentries = undefined
              }
            }
          }
        }
      }

      if (catentries) {
        return { data: cartResponse.data, catentries, checkInventory }
      }
      return { data: cartResponse.data, checkInventory }
    } catch (error) {
      if (!axios.isCancel(error)) {
        return rejectWithValue(error)
      }

      return rejectWithValue(error)
    }
  }
)

export default fetchCart
