import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mui/material'
import {
  Button,
  StyledFormControlLabel,
  StyledRadio,
} from '../../../components/UI'
import { RemoveAddressDialog } from '../../../components/RemoveAddressDialog'
import { Contact } from '../../../types/user'

import {
  AddressBookContent,
  AddressBoxWrapper,
  AddressButtonWrapper,
  AddressCustomerName,
  AddressLineBreak,
  RemoveButtonWrapper,
  StyledSecondaryText,
  UserAddressBox,
  UserAddressWrapper,
  RemoveAddressModalContainer,
  AddressDetails,
} from '../AddressBook.style'

interface IProps {
  address: Contact
  updateAddress: (address: Contact) => void
}

const UserAddress: FC<IProps> = ({ address, updateAddress }) => {
  const [isRemoveAddressDialogOpen, setIsRemoveAddressDialogOpen] =
    useState<boolean>(false)
  const handleOpen = () => setIsRemoveAddressDialogOpen(true)
  const handleClose = () => setIsRemoveAddressDialogOpen(false)
  const { t } = useTranslation()

  const userAddressArray: string[] = useMemo(() => {
    if (!address) {
      return []
    }

    const { addressLine = [], zipCode, city, country, phone1 } = address

    return [
      `${addressLine[0] || ''}, ${city || ''} ${zipCode || ''},`,
      country,
      phone1 || '',
    ]
  }, [address])

  return (
    <AddressBookContent key={address.nickName}>
      <Modal
        open={isRemoveAddressDialogOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemoveAddressModalContainer>
          <RemoveAddressDialog
            userAddress={address}
            handleClose={handleClose}
          />
        </RemoveAddressModalContainer>
      </Modal>
      <AddressBoxWrapper>
        <RemoveButtonWrapper>
          <AddressCustomerName>
            {t(`${address?.firstName} ${address?.lastName}`)}
          </AddressCustomerName>
          <AddressButtonWrapper>
            <Button
              fillType={'outline'}
              data-element-id={address && 'X_X_AddressBook_Remove'}
              onClick={handleOpen}
            >
              {t('AddressBook.RemoveButton')}
            </Button>
          </AddressButtonWrapper>
        </RemoveButtonWrapper>
        <AddressLineBreak />
        <AddressDetails>
          <UserAddressWrapper>
            {address && (
              <UserAddressBox>
                {userAddressArray.map((text) => (
                  <StyledSecondaryText>{text}</StyledSecondaryText>
                ))}
              </UserAddressBox>
            )}
          </UserAddressWrapper>
          <StyledFormControlLabel
            control={<StyledRadio />}
            label={
              address?.primary === 'true'
                ? t('AddressBook.DefaultAddressMsg')
                : t('AddressBook.SetAsDefaultAddressMsg')
            }
            checked={address?.primary === 'true'}
            onChange={() => {
              const defaultAddress = { ...address, primary: 'true' }
              updateAddress(defaultAddress)
            }}
          />
        </AddressDetails>
      </AddressBoxWrapper>
    </AddressBookContent>
  )
}

export default UserAddress
