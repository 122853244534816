import { useMediaQuery, useTheme } from '@mui/material'

const useBreakpoints = () => {
  const theme = useTheme()

  return {
    isViewportWidthUnder426: useMediaQuery(theme.breakpoints.down(426)), // < 426
    isMobile: !useMediaQuery(theme.breakpoints.up('sm')), // < 600
    isTablet: useMediaQuery(theme.breakpoints.between('sm', 'md')), // > 600 and < 1024
    isTabletLandscape: useMediaQuery(theme.breakpoints.between(1024, 1280)), // > 1024 and < 1280
    isDesktop: useMediaQuery(theme.breakpoints.up('md')), // >= 1024
    isViewportWidthAbove1440: useMediaQuery(theme.breakpoints.up(1440)), // >= 1440
    isDesktopS: useMediaQuery(theme.breakpoints.between(1280, 1440)), // > 1280 and < 1440
    isDesktopL: useMediaQuery(theme.breakpoints.up(1440)), // >1440
    isViewportWidthUnder769: useMediaQuery(theme.breakpoints.down(769)), // < 769
  }
}

export default useBreakpoints
