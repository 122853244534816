import {
  USER_CONTEXT_REQUEST_ERROR_ACTION,
  USER_CONTEXT_REQUEST_SUCCESS_ACTION,
} from '../../actions/context'
import { call, put } from 'redux-saga/effects'

import { AppPayloadWithWidgetAction } from '../../store'
import Log from '../../../services/Log'
import jsonBig from 'json-bigint'
import userContextService from '../../../foundation/apis/transaction/userContext.service'

export function* getUserContext({ payload }: AppPayloadWithWidgetAction) {
  try {
    const response = yield call(userContextService.get, payload)
    let userPayload = jsonBig.parse(response?.request.response || '{}')
    // console.log('userpayload', userPayload)

    // console.log('BIGINT TOSTRING', userPayload.entitlement.hostingContractId.toString())
    // console.log('BIGINT EBBASTA', userPayload.entitlement.hostingContractId)

    userPayload.entitlement.currentTradingAgreementIds[0] =
      userPayload.entitlement.currentTradingAgreementIds[0].toString()

    // console.log(
    //   'userPayload.entitlement.hostingContractId',
    //   userPayload.entitlement.hostingContractId
    // )

    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION(userPayload))
  } catch (e) {
    // console.log('error', e)
    Log.error('Error in retrieving context' + e)
    yield put(USER_CONTEXT_REQUEST_ERROR_ACTION(e))
  }
}
