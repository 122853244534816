import React from 'react'
import {
  ToastWrapper,
  ToastContainer,
  ToastHeader,
  ToastCloseButton,
  ToastHeading,
} from './Toast.style'
import { CloseIcon } from '../Icons'
import type { ToastProp } from './Toast.type'

const StyledToast: React.FC<ToastProp> = ({
  headingText,
  onClose,
  body,
  themeVariant,
  open,
  children,
}: ToastProp) => {
  const isCustomContent: boolean = !!children
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose && onClose(e)
  }

  return (
    <>
      <ToastWrapper themeVariant={themeVariant} open={open}>
        {!isCustomContent ? (
          <>
            <ToastHeader>
              <ToastHeading themeVariant={themeVariant} variant={'h4'}>
                {headingText}
              </ToastHeading>
              <ToastCloseButton aria-label="Close" onClick={handleClose}>
                <CloseIcon width={16} height={16} />
              </ToastCloseButton>
            </ToastHeader>
            {typeof body === 'string' ? (
              <ToastContainer dangerouslySetInnerHTML={{ __html: body }} />
            ) : (
              <ToastContainer>{body}</ToastContainer>
            )}
          </>
        ) : (
          { children }
        )}
      </ToastWrapper>
    </>
  )
}

export { StyledToast }
