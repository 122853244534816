import { styled } from '@mui/material/styles'
import Badge, { BadgeProps } from '@mui/material/Badge'
import {
  Pill,
  StyledAnchor,
  ButtonAsLink,
  LinkAsButton,
} from '../../components/UI'
import { getDesktopThemeBreakpoint } from '../../constants/ui'
import { Link } from 'react-router-dom'
import { Z_INDEX_LV3, Z_INDEX_LV4 } from '../../constants/ui'

export const StyledBadge = styled(Badge, {
  name: 'OrderHistory',
  slot: 'StyledBadge',
})<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.background.dark.secondary,
    fontSize: theme.spacing(4),
    fontWeight: 600,
    lineHeight: 1.5,
    borderRadius: theme.spacing(4),
  },
}))

export const OrderHistoryTitle = styled('div', {
  name: 'OrderHistory',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  fontSize: theme.spacing(5),
  lineHeight: 1.4,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    lineHeight: 1.5,
  },
}))

export const OrdersMadeIn = styled('div', {
  name: 'OrderHistory',
  slot: 'OrdersMadeIn',
})(({ theme }) => ({
  display: 'flex',
  fontSize: theme.spacing(2.5),
  fontWeight: 'bold',
  color: theme.palette.text.dark.primary,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  maxWidth: theme.spacing(33.5),
}))

export const OrderHistoryHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const OrderHistoryEmptyContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const OrderHistoryEmpty = styled('span', {
  name: 'OrderHistory',
  slot: 'Empty',
})(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'unset',
    fontSize: theme.spacing(4),
    lineHeight: 1.5,
    color: theme.palette.text.dark.primary,
    maxWidth: 276,
  },
}))

export const OrderHistoryClickBelow = styled('span', {
  name: 'OrderHistory',
  slot: 'ClickBelow',
})(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'unset',
    fontSize: theme.spacing(4),
    lineHeight: 1.5,
    color: theme.palette.text.dark.primary,
    maxWidth: 276,
  },
}))

export const OrderHistoryWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'Wrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.secondary,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up(769)]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(6),
  },
}))

export const StyledBackToOrderHistoryWrapper = styled(Link, {
  name: 'OrderHistory',
  slot: 'BackToOrderHistory',
})(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.dark.primary,
}))

export const OrderDetailsHeader = styled('div', {
  name: 'OrderHistory',
  slot: 'OrderDetailsHeader',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
  fontSize: theme.spacing(5),
  lineHeight: 1.4,
}))

export const OrderHistoryEmptyWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(6),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const OrderHistoryEmptyCTAs = styled('div', {
  name: 'OrderHistory',
  slot: 'EmptyCTAs',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'unset',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: theme.spacing(6),
    whiteSpace: 'nowrap',
    '& a': {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
}))

export const OrderHistoryContainerWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ContainerWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  rowGap: theme.spacing(6),
  marginBottom: theme.spacing(16),
}))

export const ProductImageWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ProductImageWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  marginBottom: 0,
  '& img': {
    maxWidth: 100,
  },
  [getDesktopThemeBreakpoint(theme)]: {
    backgroundColor: theme.palette.background.light.secondary,
  },
}))

// For mobile screens only
export const OrderHistoryItem = styled('div', {
  name: 'OrderHistory',
  slot: 'Item',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  border: `solid 1px ${theme.palette.background.light.tertiary}`,
  backgroundColor: theme.palette.background.light.primary,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
}))

export const OrderHistoryItemTitleWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemTitleWrapper',
})(({ theme }) => ({
  display: 'flex',

  '& span': {
    fontSize: theme.spacing(5),
    fontWeight: 'bold',
    lineHeight: 1.6,
    letterSpacing: 0.5,
    color: theme.palette.text.dark.primary,
  },

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    columnGap: theme.spacing(10),
  },
}))

export const OrderHistoryItemTitle = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemTitle',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
    columnGap: theme.spacing(10),
  },
}))

export const OrderHistoryItemSeparator = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemSeparator',
})(({ theme }) => ({
  height: 1,
  width: '100%',
  backgroundColor: theme.palette.background.light.tertiary,
  marginTop: 15,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(8),
  },
}))

export const OrderHistoryItemInfo = styled('div', {
  name: 'OrderHistory',
  slot: 'ItemInfo',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '70px 1fr',
  columnGap: theme.spacing(8),
  rowGap: theme.spacing(4),
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    columnGap: theme.spacing(10),
  },
}))

export const OrderHistoryStatusContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'StatusContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: theme.spacing(4),
  },
}))

export const OrderHistorySortByWrapper = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByWrapper',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  ' > div:nth-of-type(2)': {
    position: 'absolute',
    zIndex: Z_INDEX_LV3,
    right: 0,

    [theme.breakpoints.up('sm')]: {
      right: 'auto',
    },
  },

  // [theme.breakpoints.down('md')]: {
  //   width: '100%',
  // },

  [theme.breakpoints.up('sm')]: {
    // justifyContent: 'flex-end',
    // padding: 0,
    position: 'static',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(6),
  },
}))

export const OrderHistorySortByContainer = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByContainer',
})(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(
    5
  )} ${theme.spacing(4)}`,

  '*': {
    whiteSpace: 'nowrap',
  },
}))

export const OrderHistorySortByCTA = styled(Pill)(() => ({}))

export const OrderHistoryStyledAnchorCTA = styled(StyledAnchor, {
  name: 'OrderHistory',
  slot: 'StyledAnchorCTA',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  textDecoration: 'underline',
  '&::after': {
    width: '0%',
  },

  [theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    '&::after': {
      width: '100%',
    },
  },
}))

export const OrderHistoryStyledButtonCTA = styled(LinkAsButton, {
  name: 'OrderHistory',
  slot: 'StyledButtonCTA',
})(() => ({
  fontWeight: 'bold',
}))

export const OrderHistoryStyledLinkCTA = styled(ButtonAsLink, {
  name: 'OrderHistory',
  slot: 'StyledLinkCTA',
})(() => ({
  fontSize: 16,
  fontWeight: 600,
  width: 'fit-content',
}))

export const OrderHistoryPrescriptionLabel = styled('span', {
  name: 'OrderHistory',
  slot: 'PrescriptionLabel',
})(({ theme }) => ({
  color: theme.palette.color.error,
}))

export const OrderHistoryShippedLabel = styled('span', {
  name: 'OrderHistory',
  slot: 'ShippedLabel',
})(({ theme }) => ({
  color: theme.palette.color.success,
}))

export const OrderHistoryList = styled('div')(() => ({}))

// For mobile screens only
export const OrderHistoryListItem = styled('div', {
  name: 'OrderHistory',
  slot: 'ListItem',
})<{ themeMode: string }>`
  ${({ theme }) => `
  padding: ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  &:not(:first-of-type) {
    margin-top: 10px
  }


  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.text.primary};
  }
`}
`

export const OrderHistoryImage = styled('img', {
  name: 'OrderHistory',
  slot: 'Image',
})(() => ({
  height: 57,
  width: 'auto',
}))

export const OrderHistorySortByContent = styled('div', {
  name: 'OrderHistory',
  slot: 'SortByContent',
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{
  isVisible: boolean
}>(({ isVisible, theme }) => ({
  position: 'static',
  marginTop: theme.spacing(40),
  zIndex: Z_INDEX_LV4,
  width: '95%',
  display: isVisible ? 'block' : 'none',
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.light.secondary,
  '& .MuiFormGroup-root': {
    gap: theme.spacing(4),
    '& .MuiRadio-root': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)} 0 ${theme.spacing(4)}`,
    },
  },
  '& .MuiFormGroup-root span': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43,
    color: theme.palette.text.dark.primary,
  },
  '& .MuiFormGroup-root label': {
    marginBottom: 0,
  },

  [theme.breakpoints.up('sm')]: {
    width: 278,
    right: theme.spacing(16),
    top: theme.spacing(25),
  },
}))

export const OrderHistoryPopup = styled('div', {
  name: 'OrderHistory',
  slot: 'Popup',
})(() => ({
  animation: 'SlideDown .3s ease',
  '@keyframes SlideDown': {
    '0%': {
      transform: 'translateY(-30px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  },
}))
