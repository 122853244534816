import React from 'react'
import { OrderItem } from '../../types/order'
import CurrencyService from '../../services/CurrencyService'
import ProductService from '../../services/ProductService'
import {
  DiscountedPrice,
  OriginalPrice,
  PriceContainer,
} from './OrderRecapItemPrice.style'
import { useSelector } from 'react-redux'
import { catentriesSelector } from '../../features/order/selector'
import {
  getDetailedPrices,
  getRxDiscountedPrice,
  getRxPrice,
  isRxFrame,
} from '../../utils/rx'
import {
  isCLAccessoriesProduct,
  isContactLensesProduct,
} from '../../utils/product'
import { getPromoCodeDiscount } from 'src/utils/order'
import { isNil, sumBy } from 'lodash-es'

type Props = {
  orderItem: OrderItem
}
const OrderRecapItemPrice = ({ orderItem }: Props) => {
  const catentries = useSelector(catentriesSelector)
  const currentProduct = catentries?.[orderItem.productId]

  const rxitem = isRxFrame(orderItem.orderItemExtendAttribute)

  const parsedFramePrice = orderItem.roxableServices
    ? currentProduct?.price.map((price) => ({
        ...price,
        value: orderItem.x_offerpriceRx || price.value,
      })) || []
    : currentProduct?.price || []

  const { totalInitialPrice, totalCurrentPrice } =
    getDetailedPrices(parsedFramePrice, orderItem?.roxableServices) || {}

  const initialPrice =
    totalInitialPrice && ProductService.getInitialPrice([totalInitialPrice])
  let currentPrice =
    totalCurrentPrice && ProductService.getCurrentPrice([totalCurrentPrice])

  const hasDiscount =
    orderItem?.adjustment?.filter((a) => a.usage !== 'Shipping Adjustment') ||
    []
  const hasSiblingDiscount =
    Math.abs(+orderItem?.sibilingOrderItem?.adjustment?.[0].amount! || 0) || 0

  currentPrice =
    (currentPrice || 0) + sumBy(hasDiscount, (discount) => +discount.amount)

  const calculatedDiscount =
    orderItem &&
    hasDiscount.length &&
    Math.abs(+orderItem.adjustment[0].amount) + hasSiblingDiscount

  const initialTotal = (initialPrice || 0) * +orderItem.quantity

  const updatedBoxPrice =
    +orderItem.orderItemPrice +
    +(orderItem?.sibilingOrderItem?.orderItemPrice || 0) -
    calculatedDiscount

  const promoCodeDiscountItem = getPromoCodeDiscount(orderItem)
  const promoCodeDiscountServices = orderItem?.roxableServices?.reduce(
    (acc, item) => {
      return acc + getPromoCodeDiscount(item)
    },
    0
  )

  const promoCodeDiscount = promoCodeDiscountItem + promoCodeDiscountServices

  const fullRxPriceTotal = getRxPrice(
    orderItem.roxableServices || [],
    orderItem.x_offerpriceRx
  )

  let discountRxPriceTotal = getRxDiscountedPrice(
    orderItem.roxableServices,
    orderItem?.x_offerDiscountpriceRx || orderItem.x_offerpriceRx
  )

  discountRxPriceTotal = promoCodeDiscount
    ? Number(discountRxPriceTotal) - promoCodeDiscount
    : discountRxPriceTotal

  const renderFinalPrice = () => {
    if (isNil(currentPrice)) {
      return null
    }

    return (
      <DiscountedPrice>
        {rxitem ? (
          <>
            {CurrencyService.getSymbolByName(orderItem.currency)}
            {discountRxPriceTotal.toFixed(2)}
          </>
        ) : (
          <>
            {CurrencyService.getSymbolByName(orderItem.currency)}
            {isContactLensesProduct(orderItem) ||
            isCLAccessoriesProduct(orderItem)
              ? updatedBoxPrice.toFixed(2)
              : currentPrice.toFixed(2)}
          </>
        )}
      </DiscountedPrice>
    )
  }

  const renderOriginalPrice = () => {
    if (isNil(initialPrice) || isNil(currentPrice)) {
      return null
    }
    if (rxitem && discountRxPriceTotal === fullRxPriceTotal) {
      return null
    }
    if (!rxitem && initialPrice <= currentPrice) {
      return null
    }

    return (
      <OriginalPrice>
        {rxitem ? (
          <>
            {CurrencyService.getSymbolByName(orderItem.currency)}
            {fullRxPriceTotal?.toFixed(2)}
          </>
        ) : (
          <>
            {CurrencyService.getSymbolByName(orderItem.currency)}
            {isContactLensesProduct(orderItem) ||
            isCLAccessoriesProduct(orderItem)
              ? initialTotal.toFixed(2)
              : initialPrice}
          </>
        )}
      </OriginalPrice>
    )
  }

  if (isNil(initialPrice) || isNil(currentPrice)) {
    return null
  }

  return (
    <PriceContainer>
      {renderOriginalPrice()}
      {renderFinalPrice()}
    </PriceContainer>
  )
}

export default OrderRecapItemPrice
