import { styled } from '@mui/material/styles'
import { Accordion, AccordionSummary, Button } from '../../../components/UI'

export const PageContainer = styled('div', {
  name: 'AccountPageLayout',
  slot: 'PageContainer',
})(() => ({}))

export const PageContent = styled('div', {
  name: 'AccountPageLayout',
  slot: 'PageContent',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up(1025)]: {
    flexDirection: 'row',
    minHeight: 'calc(100vh - 56px)',
  },
}))

export const PageNav = styled('nav', {
  name: 'AccountPageLayout',
  slot: 'PageNav',
})<{ isShown: boolean }>(({ isShown, theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  boxShadow: theme.shadows[1],
  display: isShown ? 'flex' : 'none',
  flexDirection: 'column',

  [theme.breakpoints.up(1025)]: {
    padding: `${theme.spacing(8)} ${theme.spacing(8)} 0 ${theme.spacing(8)}`,
    width: '40%',
  },

  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(8)} ${theme.spacing(11)} 0 ${theme.spacing(16)}`,
    width: 400,
    flexShrink: 0,
  },
}))

export const PageNavTitle = styled('div', {
  name: 'AccountPageLayout',
  slot: 'PageNavTitle',
})(({ theme }) => ({
  fontSize: 24,
  lineHeight: 1.5,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(0.8),
}))

export const PageNavList = styled('div', {
  name: 'AccountPageLayout',
  slot: 'PageNavList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(6),
  lineHeight: 1.5,
}))

export const PageNavListItem = styled('div', {
  name: 'AccountPageLayout',
  slot: 'PageNavListItem',
})(({ theme }) => ({
  display: 'flex',
  fontSize: 16,
  lineHeight: 1.5,
  marginBottom: theme.spacing(6),
  padding: `${theme.spacing(1)} 0`,
  [theme.breakpoints.up(1025)]: {
    marginBottom: 0,
  },

  '& a.account-page__nav-list__link--active': {
    color: theme.palette.text.dark.primary,
    textDecoration: 'none',
    fontWeight: 600,
  },
  '& a.account-page__nav-list__link': {
    color: theme.palette.text.dark.primary,
    textDecoration: 'none',
    fontWeight: 'normal',
  },
}))

export const PageMain = styled('main', {
  name: 'AccountPageLayout',
  slot: 'PageMain',
})(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.light.secondary,
}))

export const StyledAccountNavAccordion = styled(Accordion, {
  name: 'AccountPageLayout',
  slot: 'NavAccordion',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.light.primary,
  padding: `0 ${theme.spacing(4)}`,
  boxShadow: theme.shadows[1],
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'AccountPageLayout',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  width: '100%',
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 0,
  fontSize: 24,
  fontWeight: 'normal',
  lineHeight: 1.33,
  border: 0,
  color: theme.palette.text.dark.primary,
}))

export const StyledAccordionItemsContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export const StyledLogOutCTAContainer = styled('div', {
  name: 'AccountPageLayout',
  slot: 'LogOutCTAContainer',
})(({ theme }) => ({
  width: 96,

  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}))

export const StyledLogOutCTA = styled(Button, {
  name: 'AccountPageLayout',
  slot: 'LogOutCTA',
})(() => ({
  fontSize: 14,
  maxWidth: 96,
}))
