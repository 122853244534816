import React, { ChangeEvent, RefCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  passwordValidationFields,
  passwordValidationSchema,
} from '../../../constants/form'
import {
  NewPasswordHelperTextWrapper,
  NewPasswordHelperText,
  NewPasswordHelperTextRow,
  StyledInputField,
  NewPasswordEmptyHelper,
} from './NewPassword.style'

import {
  CheckmarkRoundedErrorIcon,
  CheckmarkRoundedValidIcon,
  DotIcon,
  // DotIcon,
  PasswordHideIcon,
  WarningIcon,
  PasswordShowVisibleIcon,
} from '../Icons'
import theme from './../../../themes'
import { IconButton } from '../IconButton'
import isEmpty from 'lodash/isEmpty'
interface NewPasswordProps {
  errorMessage?: string
  value: string
  error: boolean | undefined
  showvalidationstatus?: boolean
  isPristine: boolean
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  onValidationChange?(isValid: boolean, errorNames: string[]): void
  name?: string
  label?: string
  onFocus?: (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  inputRef?: RefCallback<any>
}

export const NewPassword: React.FC<NewPasswordProps> = ({
  errorMessage,
  error,
  isPristine,
  value,
  showvalidationstatus,
  onChange,
  onValidationChange,
  label,
  name,
  onFocus,
  ...rest
}: NewPasswordProps) => {
  const { t } = useTranslation()
  const newPasswordHelper = t(
    'AccountSettings.UpdatePassword.NewPasswordHelper'
  )
  const newPasswordEmptyHelper = t(
    'AccountSettings.UpdatePassword.Empty.newPassword'
  )
  const [inputType, setInputType] = React.useState<string>('password')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [passwordErrorsNames, setPasswordErrorsName] = useState<string[]>([])
  const allErrorsKeys = Object.keys(passwordValidationFields)
  const isInvalid = passwordErrorsNames.length

  useEffect(() => {
    passwordValidationSchema
      .validate(value, { abortEarly: false })
      .then(() => setPasswordErrorsName([]))
      .catch((e) => setPasswordErrorsName(e.errors))
  }, [value])

  useEffect(() => {
    if (typeof onValidationChange === 'function') {
      onValidationChange(!passwordErrorsNames.length, passwordErrorsNames)
    }
  }, [passwordErrorsNames.length])

  useEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  return (
    <StyledInputField
      {...rest}
      name={name}
      type={inputType}
      value={value}
      //error={!isPristine && (!!errorMessage || !!passwordErrorsNames.length)}
      error={
        (error && !!passwordErrorsNames.length) || (error && isEmpty(value))
      }
      ispristine={isPristine}
      isvalid={!isInvalid}
      label={label}
      onFocus={onFocus}
      onChange={onChange}
      isNewPasswordEmpty={false}
      showvalidationstatus={showvalidationstatus}
      helperText={
        <>
          <NewPasswordHelperTextWrapper>
            <NewPasswordHelperText>{newPasswordHelper}</NewPasswordHelperText>

            {allErrorsKeys.map((key) => {
              const isRequirementExecuted = passwordErrorsNames.includes(key)

              const RequirementIcon = isRequirementExecuted ? (
                <CheckmarkRoundedErrorIcon
                  width={16}
                  height={16}
                  htmlColor={theme.palette.color.error}
                />
              ) : (
                <CheckmarkRoundedValidIcon
                  width={16}
                  height={16}
                  htmlColor={theme.palette.color.success}
                />
              )

              return (
                <NewPasswordHelperTextRow>
                  {value ? (
                    RequirementIcon
                  ) : (
                    <DotIcon htmlColor={theme.palette.text.light.tertiary} />
                  )}
                  {t(passwordValidationFields[key].message) || ''}
                </NewPasswordHelperTextRow>
              )
            })}
          </NewPasswordHelperTextWrapper>
          {error && isEmpty(value) && (
            <NewPasswordEmptyHelper>
              {newPasswordEmptyHelper}
            </NewPasswordEmptyHelper>
          )}
        </>
      }
      inputProps={{
        maxLength: 50,
      }}
      customInputProps={{
        endAdornment: (
          <>
            {errorMessage && (
              <WarningIcon htmlColor={theme.palette.color.error} />
            )}
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? (
                <PasswordShowVisibleIcon
                  htmlColor={theme.palette.text.dark.primary}
                />
              ) : (
                <PasswordHideIcon htmlColor={theme.palette.text.dark.primary} />
              )}
            </IconButton>
          </>
        ),
      }}
    />
  )
}
