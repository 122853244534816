import { styled } from '@mui/material/styles'

const PriceContainer = styled('span', {
  name: 'OrderRecap',
  slot: 'PriceContainer',
})(({ theme }) => ({
  textAlign: 'right',
  color: theme.palette.text.dark.primary,
}))

const OriginalPrice = styled('span', {
  name: 'OrderRecap',
  slot: 'OriginalPrice',
})(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'line-through',
  marginRight: theme.spacing(2),
  fontSize: 12,
}))

const DiscountedPrice = styled('span', {
  name: 'OrderRecap',
  slot: 'DiscountedPrice',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
}))

export { OriginalPrice, DiscountedPrice, PriceContainer }
