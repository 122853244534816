import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'
import { useEffect, useState } from 'react'

const useScrollTo = () => {
  const [appContainer, setAppContainer] = useState<HTMLElement | null>(null)

  const scrollTo = (x: number, y: number): void => {
    appContainer?.scrollTo({
      top: y,
      left: x,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setAppContainer(document.getElementById(APP_MAIN_CONTAINER_ID))
  }, [])

  return scrollTo
}

export default useScrollTo
