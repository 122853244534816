import React, { FC, useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { ProductSoldOutStatus, ServerProduct } from '../../../types/product'
import {
  PROFILE_NAME_PARAM,
  ITEM_DETAILS_PROFILE_NAME,
} from '../../../constants/common'
import {
  isWishlistLoadingSelector,
  wishlistItemsSelector,
  wishlistProductsSelector,
} from '../../../features/wishList/selector'
import {
  DashboardTile,
  DashboardTileHeaderWrapper,
  DashboardTileHeader,
  DashboardTileContent,
} from '../AccountDashboard.style'
import {
  DashboardWishlistItems,
  DashboardWishlistItemHeaderWrapper,
  DashboardWishlistItemContainer,
  DashboardWishlistProductDetails,
  DashboardWishlistProductName,
  DashboardWishlistBrandName,
  StyledLinkAsButton,
} from './DashboardWishlist.style'
import { HeartIconSlim } from '../../../components/UI/Icons'
import ProductPrice from '../../ProductDetails/components/ProductPrice'
import ProductImage from '../../../components/ProductImage/ProductImage'
import productsService from '../../../foundation/apis/search/products.service'
import { getBrand, getModelName } from '../../../utils/productAttributes'
import { useProductSoldOutState } from '../../ProductDetails/useProductSoldOutState'
import theme from './../../../themes'
import useBreakpoints from '../../../hooks/useBreakpoints'

const DashboardWishlist: FC = () => {
  const { t } = useTranslation()
  const { basePath } = useStoreIdentity()
  const { isMobile, isTablet, isTabletLandscape, isDesktop } = useBreakpoints()
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistItems = useSelector(wishlistItemsSelector)
  const productsCount = wishlistItems?.length
  const wishlistProducts = useSelector(wishlistProductsSelector)

  const [product, setProduct] = useState<ServerProduct | undefined>(
    wishlistProducts?.[0] || undefined
  )
  const [productName, setProductName] = useState<string>('')
  const [productBrand, setProductBrand] = useState<string>('')
  const [areProductsLoading, setProductsLoading] = useState<boolean>(false)
  const partNumberToSet: string | undefined = product?.items?.[0]?.partNumber
  const { soldOutStatus } = useProductSoldOutState({
    product,
    currentProductPartNumber: partNumberToSet ?? '',
  })
  const productImageWidth = useCallback(() => {
    switch (true) {
      case isMobile:
        return 296
      case isTablet:
        return 275
      case isTabletLandscape:
        return 265
      case isDesktop:
        return 260
      default:
        return 260
    }
  }, [isMobile, isTablet, isDesktop, isTabletLandscape])

  useEffect(() => {
    setProductsLoading(true)

    productsService
      .get({
        id: wishlistItems.map((item) => item.productId),
        [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
      })
      .then((products) => {
        if (products.contents?.length) {
          const p = products.contents[0]
          setProductName(getModelName(p))
          setProductBrand(getBrand(p))
          setProduct(p)
        }
        setProductsLoading(false)
      })
  }, [wishlistProducts, wishlistItems])

  return !productsCount ? (
    <DashboardTile>
      <DashboardTileHeaderWrapper>
        <HeartIconSlim
          width={48}
          height={48}
          htmlColor={theme.palette.text.dark.primary}
        />
        <DashboardTileHeader>
          {t('AccountDashboard.MyFavs').toUpperCase()}
        </DashboardTileHeader>
      </DashboardTileHeaderWrapper>

      <DashboardTileContent>
        {t('AccountDashboard.BrowseLatestStyles')}
      </DashboardTileContent>
      <StyledLinkAsButton
        dataElementId={'myFavouritesCTA'}
        to={`${basePath}/eyewear-all`}
      >
        {t('AccountDashboard.MyFavourites')}
      </StyledLinkAsButton>
    </DashboardTile>
  ) : (
    <DashboardWishlistItemContainer>
      <DashboardWishlistItemHeaderWrapper>
        <DashboardTileHeader>
          {t('AccountDashboard.MyFavs').toUpperCase()}
        </DashboardTileHeader>
        <DashboardWishlistItems>
          {t('AccountDashboard.Items', { count: productsCount }).toUpperCase()}
        </DashboardWishlistItems>
      </DashboardWishlistItemHeaderWrapper>

      <DashboardTileContent>
        <ProductImage
          alt="Product image"
          attachments={product?.attachments}
          usage="PLP"
          width={productImageWidth()}
          backgroundColor={theme.palette.background.light.primary}
        />
      </DashboardTileContent>
      <DashboardWishlistProductDetails>
        <DashboardWishlistProductName>
          {productName}
        </DashboardWishlistProductName>
        <DashboardWishlistBrandName>{productBrand}</DashboardWishlistBrandName>
        <ProductPrice
          isForFavouriteProduct={true}
          type={soldOutStatus as ProductSoldOutStatus}
          isLoading={areProductsLoading || isWishlistLoading}
          currentProduct={product}
          x_prices={product?.x_prices}
        />
      </DashboardWishlistProductDetails>
      <StyledLinkAsButton
        dataElementId={'myFavouritesCTA'}
        to={`${basePath}/wishlist`}
      >
        {t('AccountDashboard.ViewAllFavourites')}
      </StyledLinkAsButton>
    </DashboardWishlistItemContainer>
  )
}

export default DashboardWishlist
