import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { PrescriptionMacroGroup } from '../../types/checkout'

import { PrescriptionSliceState } from './PrescriptionSliceState'

import { PrescriptionFormData } from '../../types/prescription'
import last from 'lodash/last'
import uniq from 'lodash/uniq'
import { prescriptionApi } from './query'
import { OrderItem } from '../../types/order'

const initialState: PrescriptionSliceState = {
  isUploadingPrescriptionFile: false,
  isUploadingPrescriptionDetails: false,
  isFetchingFilePreview: false,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionFormData: {},
    prescriptionMacroGroups: [],
    prescriptionFile: null,
  },
}

export type OrderApiState = Partial<PrescriptionSliceState>

const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState,
  reducers: {
    toggleSameUserItemPrescription: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        value: boolean
      }>
    ) => {
      state.usePrescription.prescriptionMacroGroups[
        payload.prescriptionGroupIndex
      ].isSamePrescriptionSelected = payload.value
    },
    setUserPrescriptionsGroups: (
      state,
      { payload }: PayloadAction<PrescriptionMacroGroup[]>
    ) => {
      state.usePrescription.prescriptionMacroGroups = payload
    },
    setSelectedPrescriptionGroupIndex: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.usePrescription.selectedMacroIndex = payload
    },
    setSelectedItemIndex: (
      state,
      {
        payload,
      }: PayloadAction<{ prescriptionGroupIndex: number; value: number }>
    ) => {
      if (
        state.usePrescription.prescriptionMacroGroups[
          payload.prescriptionGroupIndex
        ]
      ) {
        state.usePrescription.prescriptionMacroGroups[
          payload.prescriptionGroupIndex
        ].selectedItemIndex = payload.value
      }
    },
    setUserPrescriptionGroupActive: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.usePrescription.prescriptionMacroGroups =
        state.usePrescription.prescriptionMacroGroups.map((group, i) => {
          return {
            ...group,
            active: payload === i,
          }
        })
    },
    setUserPrescriptionFormData: (
      state,
      {
        payload,
      }: PayloadAction<{
        value: PrescriptionFormData
      }>
    ) => {
      state.usePrescription.prescriptionFormData = payload.value
    },
    setUserPrescriptionItemSkipped: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        prescriptionItemIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex, prescriptionItemIndex } = payload
      const selectedPrescriptionMacroGroup =
        state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const { skippedrPrescriptionItems } = selectedPrescriptionMacroGroup
      skippedrPrescriptionItems.push(prescriptionItemIndex)
      selectedPrescriptionMacroGroup.skippedrPrescriptionItems = uniq(
        skippedrPrescriptionItems
      )
    },
    setUserPrescriptionItemCompleted: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        prescriptionItemIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex, prescriptionItemIndex } = payload
      const selectedPrescriptionMacroGroup =
        state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const { completedPrescriptionItems } = selectedPrescriptionMacroGroup
      completedPrescriptionItems.push(prescriptionItemIndex)
      selectedPrescriptionMacroGroup.completedPrescriptionItems = uniq(
        completedPrescriptionItems
      )
    },
    setUserPrescriptionStepItemsCompleted: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex } = payload
      const selectedPrescriptionMacroGroup =
        state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const completedPrescriptionItems =
        selectedPrescriptionMacroGroup.orderItems?.map((_, i) => {
          return i
        })
      selectedPrescriptionMacroGroup.completedPrescriptionItems = uniq(
        completedPrescriptionItems || []
      )
    },
    setUserPrescriptionStepItemsSkipped: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex } = payload
      const selectedPrescriptionMacroGroup =
        state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const skippedItems = selectedPrescriptionMacroGroup.orderItems?.map(
        (_, i) => {
          return i
        }
      )
      selectedPrescriptionMacroGroup.skippedrPrescriptionItems = uniq(
        skippedItems || []
      )
    },
    setUserPrescriptionFilePreview: (
      state,
      {
        payload,
      }: PayloadAction<{
        content: any
        type: string
      }>
    ) => {
      state.usePrescription.prescriptionFile = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.uploadPrescriptionFile.matchFulfilled,
          prescriptionApi.endpoints.uploadPrescriptionFile.matchRejected,
          prescriptionApi.endpoints.uploadRxPrescriptionFile.matchFulfilled,
          prescriptionApi.endpoints.uploadRxPrescriptionFile.matchRejected,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionFile
            .matchFulfilled,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionFile
            .matchRejected
        ),
        (state) => {
          state.isUploadingPrescriptionFile = false
        }
      )
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.uploadPrescriptionFile.matchPending,
          prescriptionApi.endpoints.uploadRxPrescriptionFile.matchPending,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionFile
            .matchPending
        ),
        (state) => {
          state.isUploadingPrescriptionFile = true
        }
      )
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.uploadPrescriptionDetails.matchPending,
          prescriptionApi.endpoints.uploadRxPrescriptionDetails.matchPending,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionDetails
            .matchPending
        ),
        (state) => {
          state.isUploadingPrescriptionDetails = true
        }
      )
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.uploadRxPrescriptionDetails.matchFulfilled,
          prescriptionApi.endpoints.uploadRxPrescriptionDetails.matchRejected,
          prescriptionApi.endpoints.uploadPrescriptionDetails.matchRejected,
          prescriptionApi.endpoints.uploadPrescriptionDetails.matchFulfilled,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionDetails
            .matchRejected,
          prescriptionApi.endpoints.uploadRxMultiplePrescriptionDetails
            .matchFulfilled
        ),
        (state) => {
          state.isUploadingPrescriptionDetails = false
        }
      )
      .addMatcher(
        prescriptionApi.endpoints.getPrescriptionFilePreview.matchPending,
        (state) => {
          state.isFetchingFilePreview = true
        }
      )
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.getPrescriptionFilePreview.matchFulfilled,
          prescriptionApi.endpoints.getPrescriptionFilePreview.matchRejected
        ),
        (state, { payload }: PayloadAction<any>) => {
          state.isFetchingFilePreview = false
          state.usePrescription.prescriptionFile = {
            content: payload.content || null,
            type: payload.headers['content-type'] || null,
          }
        }
      )
      .addMatcher(
        prescriptionApi.endpoints.getUploadedPrescriptionFileData
          .matchFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const selectedGroupIndex = state.usePrescription.selectedMacroIndex
          const selectedPrescriptionMacroGroup =
            state.usePrescription.prescriptionMacroGroups[selectedGroupIndex]
          const lastCompletedOrder = last(
            selectedPrescriptionMacroGroup.completedPrescriptionItems
          )
          const orderItems = selectedPrescriptionMacroGroup?.orderItems
          const updatedOrderItems = orderItems?.map((oi: OrderItem, i) => {
            return i === lastCompletedOrder ||
              selectedPrescriptionMacroGroup.isSamePrescriptionSelected
              ? {
                  ...oi,
                  prescriptionDetails: {
                    file: {
                      content: payload?.content || null,
                      type: payload?.headers['content-type'] || null,
                    },
                  },
                  prescriptionUploaded: !!payload.content,
                }
              : oi
          })

          if (!!updatedOrderItems) {
            state.usePrescription.prescriptionMacroGroups[
              selectedGroupIndex
            ].orderItems = updatedOrderItems
          }
        }
      )
      .addMatcher(
        isAnyOf(
          prescriptionApi.endpoints.getPrescriptionFilePreview.matchRejected
        ),
        (state) => {
          state.isFetchingFilePreview = false
        }
      )
  },
})

export const {
  toggleSameUserItemPrescription,
  setSelectedItemIndex,
  setSelectedPrescriptionGroupIndex,
  setUserPrescriptionsGroups,
  setUserPrescriptionFormData,
  setUserPrescriptionItemSkipped,
  setUserPrescriptionItemCompleted,
  setUserPrescriptionGroupActive,
  setUserPrescriptionStepItemsSkipped,
  setUserPrescriptionStepItemsCompleted,
} = prescriptionSlice.actions

export default prescriptionSlice.reducer
