import { createAsyncThunk } from '@reduxjs/toolkit'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { sendSuccessMessage } from '../../success/slice'
import { CartPayload } from '../../../types/cart'

export interface ICopyCartArgs {
  payload: {
    fromOrderId: any
    widget: string
  }
}

const copyCart = createAsyncThunk<any, ICopyCartArgs>(
  'order/copyCart',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const { fromOrderId } = args.payload
      const payload = args.payload
      const params: CartPayload = {
        body: {
          fromOrderId_1: fromOrderId,
          toOrderId: '.**.',
          copyOrderItemId_1: '*',
        },
        widget: payload.widget,
      }

      const response = await cartService.copyOrder(params)

      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'COPY_CART_SUCCESS',

          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )
      return response
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export default copyCart
