//Standard libraries
import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTIONTYPES from '../action-types/confirmation'

const OPEN_CONFIRMATION_ACTION = createAction<any, string>(
  ACTIONTYPES.OPEN_CONFIRMATION
)

const CONFIRMATION_HANDLED_ACTION = createAction<any, string>(
  ACTIONTYPES.CONFIRMATION_HANDLED
)

const CONFIRMATION_CANCELLED_ACTION = createAction<any, string>(
  ACTIONTYPES.CONFIRMATION_CANCELLED
)

export {
  OPEN_CONFIRMATION_ACTION,
  CONFIRMATION_CANCELLED_ACTION,
  CONFIRMATION_HANDLED_ACTION,
}
