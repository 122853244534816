import {
  FacescanRegion,
  FrameGeniusBaseConfig,
  SuggestedFeatures,
} from '../../types/FrameGenius/frameGenius'
import merge from 'lodash/merge'
import { ParsedServiceLinks } from '../../redux/reducers'

interface IFrameGeniusUtil {
  getAPIStore: (locale: string, apiPrefix: string) => string
  setAnalysisResults: (suggestedFeatures: SuggestedFeatures) => void
  getAnalysisResults: () => SuggestedFeatures
  clearAnalysisResults: () => void
  sanitizeLocale: (locale: string) => string
  getBaseConfiguration: (
    basePath: string,
    serviceLinks: ParsedServiceLinks | null,
    locale: string,
    facescanSource: string,
    frameAdvKey: string
  ) => FrameGeniusBaseConfig
}
export enum FrameGeniusKeys {
  ANALYSIS_RESULTS = 'frameGeniusAnalysisResults',
}
class FrameGeniusUtil implements IFrameGeniusUtil {
  private mapAPIStore = (locale: string): string => {
    switch (locale) {
      case 'en-US':
      case 'en-CA':
        return 'US'
      case 'en-GB':
        return 'UK'
      case 'sv-SE':
        return 'SW'
      default:
        const splitLocale = locale.split('-')
        return splitLocale[1]
    }
  }
  getAPIStore = (locale: string, apiPrefix: string): string => {
    const sanitizedLocale = this.sanitizeLocale(locale)
    const region = this.mapAPIStore(sanitizedLocale)
    return `${apiPrefix}@${region}`
  }
  setAnalysisResults = (suggestedFeatures: SuggestedFeatures) => {
    if (!suggestedFeatures) {
      return
    }

    let analysisResult = suggestedFeatures
    const previousAnalysisResults = this.getAnalysisResults()
    if (previousAnalysisResults) {
      analysisResult = merge(previousAnalysisResults, suggestedFeatures)
    }

    const stringifiedSuggestedFeatures = JSON.stringify(analysisResult)
    sessionStorage.setItem(
      FrameGeniusKeys.ANALYSIS_RESULTS,
      `${stringifiedSuggestedFeatures}`
    )
    localStorage.setItem(
      FrameGeniusKeys.ANALYSIS_RESULTS,
      `${stringifiedSuggestedFeatures}`
    )
  }
  getAnalysisResults = (): SuggestedFeatures => {
    const stringifiedSuggestedFeatures =
      localStorage.getItem(FrameGeniusKeys.ANALYSIS_RESULTS) ||
      sessionStorage.getItem(FrameGeniusKeys.ANALYSIS_RESULTS) ||
      '{}'
    const suggestedFeatures = JSON.parse(stringifiedSuggestedFeatures)
    return suggestedFeatures
  }

  clearAnalysisResults = () => {
    localStorage.removeItem(FrameGeniusKeys.ANALYSIS_RESULTS)
    sessionStorage.removeItem(FrameGeniusKeys.ANALYSIS_RESULTS)
  }

  private loadScript = (src) => {
    return new Promise((resolve, reject) => {
      let element
      element = document.createElement('script')
      element.src = src
      element.async = false
      if (element) {
        element.onload = () => {
          resolve(src)
        }
        element.onerror = () => {
          reject(src)
        }
        document.body.appendChild(element)
      }
    })
  }

  // TODO: Move and rename this method in a more general space for better reuse
  loadFrameGeniusScripts = (scripts: string[], callback: Function) => {
    const promises: Promise<any>[] = []
    scripts.forEach((url) => {
      promises.push(this.loadScript(url))
    })
    Promise.all(promises).then(() => {
      callback()
    })
  }
  sanitizeLocale = (locale: string) => {
    if (locale === 'en_GB') {
      return 'en-UK'
    }
    return locale ? locale.replace('_', '-') : ''
  }

  getBaseConfiguration = (
    basePath: string,
    serviceLinks: ParsedServiceLinks | null,
    locale: string,
    facescanSource: string,
    frameAdvKey: string
  ): FrameGeniusBaseConfig => {
    const privacyPolicy = `${location.origin}${basePath}${serviceLinks?.privacy}`
    const termsAndConditions = `${location.origin}${basePath}${serviceLinks?.terms}`

    return {
      locale: this.sanitizeLocale(locale),
      facescanSource,
      facescanRegion: FacescanRegion.EU,
      frameAdvKey,
      loaderAnimationColor: '#00303C',
      facescanPrivacy: {
        privacyPolicy,
        termsAndConditions,
      },
    }
  }
}

export default new FrameGeniusUtil()
