import { createAsyncThunk } from '@reduxjs/toolkit'
import cartService from '../../../foundation/apis/transaction/cart.service'

const fetchPayMethods = createAsyncThunk<any, any>(
  'order/fetchPayMethods',
  async (args) => {
    const response = await cartService.getUsablePaymentInfo(args)
    return response.data
  }
)

export default fetchPayMethods
