import { createAsyncThunk } from '@reduxjs/toolkit'
import { wishlistExternalIdSelector } from '../selector'
import { RootReducerState } from 'src/redux/reducers'
import WishlistService from 'src/foundation/apis/transaction/wishList.service'
import { AppDispatch } from 'src/redux/store'
import { ServerProduct } from 'src/types/product'
import { fetchWishlistAction } from '../action'

const addWishlistItem = createAsyncThunk<any, ServerProduct>(
  'wishlist/addItem',
  async (product, { dispatch, getState }) => {
    const appDispatch = dispatch as AppDispatch
    const rootState = getState() as RootReducerState
    const wishlistExternalId = wishlistExternalIdSelector(rootState)

    if (!wishlistExternalId) {
      await WishlistService.createWishlistAndAddItem(appDispatch, [
        {
          partNumber: product.partNumber,
          quantityRequested: '1',
          location: 'online',
        },
      ])
    } else {
      await WishlistService.update(true, wishlistExternalId, [
        {
          partNumber: product.partNumber,
          quantityRequested: '1',
          location: 'online',
        },
      ])
    }

    await appDispatch(fetchWishlistAction())
  }
)

export default addWishlistItem
