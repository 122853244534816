import { ServerProduct } from './product'

export const DYNAMIC_CONTENT_KEYS = {
  ANTI_REFLECTIVE: 'ANTI_REFLECTIVE',
  ANTI_SCRATCH: 'ANTI_SCRATCH',
  BRAND: 'BRAND',
  FACE_SHAPE: 'FACE_SHAPE',
  FRAME_SHAPE: 'FRAME_SHAPE',
  GENDER: 'GENDER',
  GEOFIT: 'GEOFIT',
  IS_NEW: 'IS_NEW',
  LIMITED_EDITION: 'LIMITED_EDITION',
  PHOTOCHROMIC: 'PHOTOCHROMIC',
  POLARIZED: 'POLARIZED',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ROXABLE: 'ROXABLE',
  SPECIAL_PROJECT_FLAG: 'SPECIAL_PROJECT_FLAG',
  SPECIAL_PROJECT_DESCRIPTION: 'SPECIAL_PROJECT_DESCRIPTION',
  SPECIAL_PROJECT_SPONSOR: 'SPECIAL_PROJECT_SPONSOR',
  SPECIAL_PROJECT_TYPE: 'SPECIAL_PROJECT_TYPE',
  SUSTAINABILITY_CLAIM: 'SUSTAINABILITY_CLAIM',
  UV_PROTECTION: 'UV_PROTECTION',
  FRAME_SHAPE_FACET: 'FRAME_SHAPE_FACET',
  FRONT_COLOR_FACET: 'FRONT_COLOR_FACET',
  LENS_COLOR_FACET: 'LENS_COLOR_FACET',
  LENS_TREATMENT_FACET: 'LENS_TREATMENT_FACET',
} as const

export const {
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  BRAND,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
} = DYNAMIC_CONTENT_KEYS

export type DynamicContentKey =
  // eslint-disable-next-line prettier/prettier
  typeof DYNAMIC_CONTENT_KEYS[keyof typeof DYNAMIC_CONTENT_KEYS]

export const dynamicContentKeys: DynamicContentKey[] = [
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  BRAND,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
]

export type DynamicContentRule = {
  key: DynamicContentKey
  value: string
}

export type DynamicRule = {
  rules: DynamicContentRule[]
  target: ILXTeaser | ICMCollection
}

export type IViewType =
  | 'full-width-banner'
  | 'plp-box'
  | 'banner-with-side-fields-plus-related'
  | 'default'
  | 'text-module'
  | 'banner-with-side-fields'
  | 'blurred-slider'
  | 'landscape-banner'
  | 'narrow-banner-w-all-fields-below'
  | 'box-plus-one'
  | 'box-with-margin'
  | 'boards-with-fields'
  | 'boards-with-fields-on-below-mobile-no-carousel'
  | 'boards-with-fields-on-below-mobile-carousel'
  | 'boards-with-fields-below'
  | 'grid-of-boards-two-columns'
  | 'grid-of-boards-three-columns'
  | 'combo-mini-slider-plus-box-all-fields'
  | 'products'
  | 'dcw-products'
  | 'grid-of-products'
  | 'mini-slider'
  | 'open-article_without_title'
  | 'categories-with-icon'
  | 'group-of-menu-items'
  | 'big-menu-banner'
  | 'banners-with-icons'
  | 'list-of-products'
  | 'list-of-brand-icons'
  | 'list-of-brands'
  | 'book-an-appointment-plus-search-box'
  | 'plp-one-tile'
  | 'plp-two-tiles'
  | 'plp-three-tiles'
  | 'squat-banner'
  | 'top-page-banner'
  | 'square-boards-with-split'
  | 'square-boards-without-split'
  | 'faqs'
  | 'faq-search'
  | 'you-may-also-like'
  | 'size-guide'
  | 'query-list'
  | 'wall-of-brands'
  | null

export type IPLPBoxOrientation = 'left' | 'right'
export type IGridPositioning = 1 | 2 | 3 | 4

export interface IPictureMedia {
  type: 'CMPicture'
  uriTemplate: string
}

export interface IVideoMedia {
  type: 'CMVideo'
  autoplay: boolean
  data: {
    uri: string
  }
  dataUrl: string
  hideControl: boolean
  loop: boolean
  mute: boolean
  picture: {
    uriTemplate: string
  }
  playOnHover: boolean
  width: number
}

export type TMedia = IPictureMedia | IVideoMedia

export interface ICallToAction {
  callToActionEnabled?: boolean
  callToActionText?: string
  callToActionHash?: string
  style: 'arn-cta--primary' | 'arn-cta--secondary' | 'arn-cta--tertiary'
  target:
    | ICMChannel
    | ICMExternalPage
    | ICMProductTeaser
    | ICMExternalChannel
    | ICMExternalProduct
    | ICMDownload
    | ICMExternalLink
    | ICMArticle
    | IAction
}

type TTeaserLabelPositions =
  | 'left-top'
  | 'center-top'
  | 'right-top'
  | 'left-center'
  | 'center-center'
  | 'right-center'
  | 'left-bottom'
  | 'center-bottom'
  | 'right-bottom'
  | 'top-left'
  | ''

export type TOverlayTextAlign = React.CSSProperties['textAlign']

export interface ICMNavigationPath {
  segment: string
}

// Base Placement Item
// This must contain shared memebers
interface IBasePlacementItem {
  id?: string
  name?: string
  viewtype: IViewType
}

export interface ICMProductTeaser extends IBasePlacementItem {
  type: 'CMProductTeaser'
  externalId: string
  formattedUrl: string
  fullyQualifiedUrl?: string
  url: string
  media: TMedia[]
  teaserTitle: string
  teaserText: string | null
  productData: ServerProduct
}

export interface ICMExternalProduct extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  fullyQualifiedUrl?: string
  url: string
  media: TMedia[]
  productData: ServerProduct
  title: string
  teaserTitle: string
  teaserText: string | null
  type: 'CMExternalProduct'
}

export interface ICMExternalLink extends IBasePlacementItem {
  formattedUrl: string
  title: string
  teaserTitle: string
  type: 'CMExternalLink'
  url: string
  fullyQualifiedUrl?: string
  media: TMedia[]
}

export interface ICMExternalChannel extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  fullyQualifiedUrl?: string
  url: string
  title: string
  type: 'CMExternalChannel'
  navigationPath: ICMNavigationPath[]
  media: TMedia[]
  brandData: {
    id: string
    name: string
    formattedUrl: string
    shortDescription: string
  }[]
}

export interface ICMChild {
  children: ICMChild[]
  formattedUrl: string
  hidden: boolean
  hiddenInDesktopNavigation: boolean
  hiddenInFooterNavigation: boolean
  hiddenInMobileNavigation: boolean
  hiddenInSearchAndSitemap: boolean
  hiddenInSitemap: boolean
  name: string
  navigationPath: ICMNavigationPath[]
  showChildrenInMenu: boolean
  title: string
  url: string
  type: string
  teaserTargets: ITeaserTargets[]
}

export interface ICMHtml extends IBasePlacementItem {
  children?: ICMChild[]
  formattedUrl: string
  resourcelink: string
  html: string
  title: string
  type: 'CMHTML'
  media: TMedia[]
}

export interface ICMExternalPage extends IBasePlacementItem {
  children?: ICMChild[]
  externalId: string
  formattedUrl: string
  fullyQualifiedUrl?: string
  url: string
  title: string
  type: 'CMExternalPage'
  navigationPath: ICMNavigationPath[]
  media: TMedia[]
}

export type TOverlaySettings =
  | 'block-left-top'
  | 'block-left-middle'
  | 'block-left-bottom'
  | 'block-center-top'
  | 'block-center-middle'
  | 'block-center-bottom'
  | 'block-right-top'
  | 'block-right-middle'
  | 'block-right-bottom'

export type TeaserOverlayStyle =
  | 'text-dark-primary'
  | 'text-dark-primary-shadow'
  | 'text-dark-secondary'
  | 'text-dark-secondary-shadow'
  | 'text-light-primary'
  | 'text-light-primary-shadow'
  | 'text-light-secondary'
  | 'text-light-secondary-shadow'
  | ''

export type TeaserBackGround =
  | 'bg-dark-primary'
  | 'bg-dark-secondary'
  | 'bg-light-primary'
  | 'bg-light-secondary'
  | 'bg-light-tertiary'
  | 'bg-light-quaternary'
  | 'bg-black'
  | ''

export interface ITermsAndConditions {
  style: 'cta-fill-primary'
  text: string
  target?: {
    title: string
    detailText: string
    media: TMedia[]
  }
}

export interface ILXTeaser extends IBasePlacementItem {
  type: 'LXTeaser'
  promoteToH1?: boolean
  gridPositioning?: IGridPositioning
  media: TMedia[]
  teaserIcon: string
  teaserPreTitle: string
  teaserTitle1: string
  teaserTitle2: string
  teaserTitle3: string
  teaserTitle4: string
  teaserText1: string
  teaserOverlay1TextAlign: TOverlayTextAlign
  teaserOverlay1Settings: TOverlaySettings // Text alignment
  teaserOverlay1Style: TeaserOverlayStyle // Text Color
  teaserText2: string
  teaserOverlay2TextAlign: TOverlayTextAlign
  teaserOverlay2Settings: TOverlaySettings // Text alignment
  teaserOverlay2Style: TeaserOverlayStyle // Text Color
  teaserLabelVisible: boolean
  teaserLabelText: string
  teaserLabelPosition: TTeaserLabelPositions
  teaserLabelStyle:
    | 'col-primary'
    | 'col-secondary'
    | 'col-tertiary'
    | 'col-white'
    | '' // label color
  teaserBackground: TeaserBackGround
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
  teaserLXCallToActionSettings: ICallToAction[]
  teaserTargets?: ITeaserTargets[]
  teaserOverlayVideo?: []
  formattedUrl?: string
  title?: string
  targetsTermsAndConditions?: ITermsAndConditions
  hotZones?: [Hotzone]
  hotZonesSettings?: HotzoneSettings
  relatedProduct?: (ICMProductTeaser | ICMExternalProduct)[]
}

export interface ICMCollection extends IBasePlacementItem {
  title: string
  type: 'CMCollection'
  //viewtype: IViewType
  collectionProductStyle?: string
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  collectionTextOverlayStyle: TeaserOverlayStyle
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  placementCenter?: boolean
  media: TMedia[]
}

export interface ICMArticle extends IBasePlacementItem {
  type: 'CMArticle'
  title: string
  detailText: string
  formattedUrl: string
  url: string
  media: TMedia[]
  articleTitleAlign: TOverlayTextAlign
}

export interface ICMChannel extends IBasePlacementItem {
  children?: ICMChild[]
  formattedUrl: string
  fullyQualifiedUrl?: string
  url: string
  navigationPath: ICMNavigationPath[]
  title: string
  teaserText: string
  type: 'CMChannel'
  media: TMedia[]
}

export interface IAction extends IBasePlacementItem {
  idAction: string
  formattedUrl: string
  url: string
  title: string
  type: 'Action'
}

export interface ICMTeaser extends IBasePlacementItem {
  teaserTargets?: ITeaserTargets[]
  type: 'CMTeaser'
  title: string
  teaserTitle?: string
  media: TMedia[]
}

export interface ICMDownload extends IBasePlacementItem {
  formattedUrl: string
  url: string
  fullyQualifiedUrl: string
  title: string
  type: 'CMDownload'
}

export interface LXDynamicContent extends IBasePlacementItem {
  dynamicRules: DynamicRule[]
  dynamicDefault: ILXTeaser | ICMCollection
  type: 'LXDynamicContent'
  media: TMedia[]
}

export interface Hotzone {
  linkedContent?: {
    externalId?: string
    teaserTitle?: string
    name?: string
    teaserText?: string
  }
  alt?: string
  coords?: string
  displayAsInlineOverlay?: boolean
  inlineOverlayTheme?: string
  shape?: string
  target?: string
  productData?: ServerProduct[]
}

export interface HotzoneSettings {
  lightContrast?: boolean
}

export interface ICMProductList extends IBasePlacementItem {
  externalId: string
  teaserTitle: string
  teaserText: string
  type: 'CMProductList'
  title: string
  //viewtype: IViewType
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  media: TMedia[]
  productData: ServerProduct[]
}

export interface ICMQueryList extends IBasePlacementItem {
  title: string
  type: 'CMQueryList'
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  media: TMedia[]
}

export interface ICMPlaceholder extends IBasePlacementItem {
  title: string
  type: 'CMPlaceholder'
  teasableItems: IPlacementItem[]
  gridPositioning?: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionText: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  media: TMedia[]
}

export interface ITeaserTargets {
  callToActionHash?: string
  target:
    | ICMChannel
    | ICMExternalPage
    | ICMProductTeaser
    | ICMExternalChannel
    | ICMExternalProduct
    | ICMExternalLink
    | ICMDownload
}

export interface IPlacement<ItemType = IPlacementItem> {
  collectionTitle: string
  viewtype: IViewType
  name: string
  marginLateral: boolean
  teaserLXCallToActionSettings: ICallToAction[]
  placementReflect: boolean
  placementCenter?: boolean
  marginVertical: 'X' | 'S' | 'M' | 'L'
  backgroundColor: React.CSSProperties['backgroundColor']
  clusterTile: boolean
  placementAnimation: string
  cta?: string
  items: ItemType[]
  currentProduct?: ServerProduct
}

export interface DashButtonProps {
  formattedUrl: string
  name: string
  teaserIcon: string
}

export interface AnchorData {
  title: string
  placement: string
}

export type IPlacementItem =
  | ICMArticle
  | ICMChannel
  | ICMCollection
  | ICMExternalLink
  | ICMExternalPage
  | ICMExternalProduct
  | ICMProductTeaser
  | ILXTeaser
  | LXDynamicContent
  | ICMTeaser
  | ICMHtml
  | ICMExternalChannel
  | ICMProductList
  | ICMQueryList
  | ICMPlaceholder

export const isLXTeaser = (item: IPlacementItem): item is ILXTeaser =>
  (item as ILXTeaser)?.type === 'LXTeaser'

export const isCMCollection = (item: IPlacementItem): item is ICMCollection =>
  item.type === 'CMCollection'

export const isCMExternalProduct = (
  item: IPlacementItem
): item is ICMExternalProduct => item.type === 'CMExternalProduct'

export const isCMProductTeaser = (
  item: IPlacementItem
): item is ICMProductTeaser => item.type === 'CMProductTeaser'

export const isCMArticle = (item: IPlacementItem): item is ICMArticle =>
  item.type === 'CMArticle'

export const isCMChannel = (item: IPlacementItem): item is ICMChannel =>
  item.type === 'CMChannel'

export const isPictureMedia = (
  item: TMedia | undefined
): item is IPictureMedia => item?.type === 'CMPicture' ?? false

export const isVideoMedia = (item?: TMedia): item is IVideoMedia =>
  item?.type === 'CMVideo' ?? false

export const isDynamicContent = (
  item: IPlacementItem
): item is LXDynamicContent =>
  (item as LXDynamicContent).type === 'LXDynamicContent'

export const isCMExternalPage = (
  item: IPlacementItem
): item is ICMExternalPage => item.type === 'CMExternalPage'

export const isICMHtml = (item: IPlacementItem): item is ICMHtml =>
  item.type === 'CMHTML'

export const isCMExternalChannel = (
  item: IPlacementItem
): item is ICMExternalChannel => item.type === 'CMExternalChannel'

export const isCMProductList = (item: IPlacementItem): item is ICMProductList =>
  item.type === 'CMProductList'

export const isCMQueryList = (item: IPlacementItem): item is ICMQueryList =>
  item.type === 'CMQueryList'

export const isCMPlaceholder = (item: IPlacementItem): item is ICMPlaceholder =>
  item.type === 'CMPlaceholder'

export const isCMExternalLink = (
  item: IPlacementItem
): item is ICMExternalLink => item.type === 'CMExternalLink'

export interface ITextModuleTeaser {
  item: ILXTeaser
  viewType: IViewType
  placementHasBackGroundColor?: boolean
  teaserIndex?: number
  placementCenter?: boolean
  placement?: IPlacement | ICMCollection
}
