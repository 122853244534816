import { ButtonAsLink } from '../UI'
import { styled } from '@mui/material/styles'

export const StyledOrderRecapItemsWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'ItemsWrapper',
})`
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`
export const StyledOrderRecapWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'Wrapper',
})`
  position: relative;
`

export const OrderRecapTitle = styled('div', {
  name: 'OrderRecap',
  slot: 'Title',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.text.dark.primary,
  fontSize: '1rem',
  fontWeight: 'bold',
}))

export const StyledRecapContainer = styled('div', {
  name: 'OrderRecap',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  border: `1px solid ${theme.palette.custom.alto}`,
  padding: theme.spacing(4),

  '&:first-of-type': {
    marginTop: 'unset',
  },
}))

export const StyledRecapCTAContainer = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'ContinueCTA',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  textAlign: 'center',
  padding: `0 ${theme.spacing(7.5)}`,

  '& a': {
    whiteSpace: 'nowrap',
  },

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(9)}`,
    marginTop: theme.spacing(13.5),
  },
}))

export const StyledRecapShipping = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Shipping',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr auto',
  fontSize: 14,
  color: theme.palette.text.dark.primary,
}))

export const StyledEditButtonWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'EditButtonWrapper',
})(() => ({
  textAlign: 'right',
  justifyContent: 'right',
  fontWeight: 'bold',
}))

export const StyledEditButtonLink = styled(ButtonAsLink, {
  name: 'OrderRecap',
  slot: 'EditButtonLink',
})(() => ({
  fontSize: 12,
  fontWeight: 'bold',
}))

export const StyledOrderRecapSummary = styled(StyledRecapContainer, {
  name: 'OrderRecap',
  slot: 'Summary',
  shouldForwardProp: (prop) => prop !== 'isZero',
})<{ isZero?: boolean }>(({ theme, isZero }) => ({
  display: 'grid',
  gridTemplateRows: isZero ? '1fr' : '0.5fr 1fr',
  rowGap: theme.spacing(2),
  color: theme.palette.text.dark.primary,
}))

export const TotalRow = styled('div', {
  name: 'OrderRecap',
  slot: 'Total',
})(() => ({
  display: 'grid',
  fontWeight: 'bold',
  gridTemplateColumns: '1fr 1fr',
  fontSize: '20px',
  textTransform: 'uppercase',
  lineHeight: '20px',
}))

export const Total = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalValue',
})(() => ({
  alignItems: 'start',
}))

export const TotalWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'TotalWrapper',
})(() => ({
  textAlign: 'right',
}))

export const VatIncludedWrapper = styled('div', {
  name: 'VatIncluded',
  slot: 'Wrapper',
})(() => ({
  fontSize: 10,
  fontWeight: 'normal',
  textTransform: 'none',
}))

export const StyledTitleWrapper = styled('div', {
  name: 'TitleWrapperEditPopup',
  slot: 'StyledTitleWrapper',
})(() => ({
  width: 261,
}))

export const ShippingBillingRecapContent = styled('div', {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapContent',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(2),
  '& *': {
    display: 'block',
    lineHeight: '14px',
  },
}))

export const ShippingBillingRecaptitle = styled(OrderRecapTitle, {
  name: 'OrderRecap',
  slot: 'ShippingBillingRecapTitle',
})(() => ({
  textTransform: 'uppercase',
  fontSize: 14,
}))

export const PromoCodeWrapper = styled('div', {
  name: 'OrderRecap',
  slot: 'PromoCodeWrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const PromoText = styled('span', {
  name: 'OrderRecap',
  slot: 'Promo',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: 16,
}))

export const BillingAndShippingWrapper = styled('div', {
  name: 'BillingAndShipping',
  slot: 'Wrapper',
})<{ isVisible?: boolean }>(({}) => ({}))
