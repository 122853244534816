import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../../types/store'
import { setStoresData } from './thunks'

export interface StoresState {
  data?: Store[]
  isFetching: boolean
  error: Error | null
}

export const initialStoresState: StoresState = {
  data: [],
  isFetching: false,
  error: null,
}

const storesSlice = createSlice({
  name: 'stores',
  initialState: initialStoresState,
  reducers: {},
  extraReducers: {
    [setStoresData.rejected.toString()]: (
      state,
      action: PayloadAction<Store[], string, unknown, Error>
    ) => {
      state.isFetching = false
      state.error = action.error
    },
    [setStoresData.fulfilled.toString()]: (
      state,
      action: PayloadAction<Store[]>
    ) => {
      state.isFetching = false
      state.error = null
      state.data = action.payload
    },
    [setStoresData.pending.toString()]: (state) => {
      state.isFetching = true
      state.error = null
    },
  },
})

export default storesSlice.reducer
