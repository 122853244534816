import React, { useEffect } from 'react'
import { useSite } from '../../foundation/hooks/useSite'
import useExternalScript from '../../hooks/useExternalScript'
import { StyledLoader } from '../UI'

interface Props {
  grandTotal: string | number
  variant?:
    | 'credit-promotion-badge'
    | 'credit-promotion-standard'
    | 'credit-promotion-auto-size'
}

const KlarnaOnSiteMessage: React.FC<Props> = ({
  grandTotal,
  variant = 'credit-promotion-auto-size',
}) => {
  const { mySite } = useSite()
  const klarnaStoreconfs = mySite.xStoreCfg
  const urlKlarna =
    klarnaStoreconfs.klarnaServicesScriptUrl ||
    'https://osm.klarnaservices.com/lib.js'
  const clientIdKlarna = klarnaStoreconfs.klarnaClientId

  const [isLoaded, KlarnaOnsiteService] = useExternalScript(
    urlKlarna,
    'KlarnaOnsiteService',
    {
      id: 'klarna-on-site-message',
      'data-environment':
        urlKlarna === 'https://osm.klarnaservices.com/lib.js'
          ? 'production'
          : 'playground',
      'data-clientId': clientIdKlarna,
      defer: true,
      async:
        urlKlarna === 'https://osm.klarnaservices.com/lib.js' ? true : false,
    }
  )

  const dataLocale = mySite.locale.replace('_', '-')

  useEffect(() => {
    if (isLoaded && grandTotal) {
      KlarnaOnsiteService?.push({
        eventName: 'refresh-placements',
      })
    }
  }, [isLoaded, grandTotal])

  const grandTotalWithCents = (
    (typeof grandTotal !== 'number'
      ? parseFloat(grandTotal || '0')
      : grandTotal) * 100
  ).toFixed(2)

  if (!isLoaded || !grandTotal) {
    return <StyledLoader size={50} />
  }

  return (
    //   @ts-ignore
    <klarna-placement
      data-key={variant}
      data-locale={dataLocale}
      data-purchase-amount={`${grandTotalWithCents}`}
    />
  )
}

export default KlarnaOnSiteMessage
