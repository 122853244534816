import React, { useCallback } from 'react'
import { usePrescriptionLenses } from './PrescriptionLensesContext'
import PrescriptionLenses from './PrescriptionLenses'
import useRouteQuery from '../../foundation/hooks/useRouteQuery'
import { PRODUCT_URL_SEARCH_PARAMS } from '../../constants/product'
import { useAddOrderItem } from '../../pages/ProductDetails/hooks/useAddOrderItem'
import { ServerProduct } from '../../types/product'
import { OrderItem } from '../../types/order'
import { useGetProductDetailsQuery } from '../../features/product/query'
import { useSite } from '../../foundation/hooks/useSite'
import { CLUSTERING_PROFILE, PROFILE_NAME_PARAM } from '../../constants/common'
import { currentContractIdSelector } from '../../redux/selectors/contract'
import { useSelector } from 'react-redux'

const isOrderItem = (
  product: ServerProduct | OrderItem | null
): product is OrderItem => !!product && 'orderItemId' in product

const isServerProduct = (
  product: ServerProduct | OrderItem | null
): product is ServerProduct => !!product && !('orderItemId' in product)

export const Prescription: React.FC = () => {
  const { setPrescriptionLensesFormOpen, prescriptionLenses: product } =
    usePrescriptionLenses()

  const { mySite } = useSite()
  const storeIdentifier = mySite ? mySite.storeID : ''
  const catalogIdentifier = mySite ? mySite.catalogID : ''
  const contract = useSelector(currentContractIdSelector)

  const query = useRouteQuery()
  const isEditLens = !!query.get(PRODUCT_URL_SEARCH_PARAMS.EDIT_LENS)
  const orderItem = isOrderItem(product) ? product : undefined

  const params = {
    queryParams: {
      storeId: storeIdentifier,
      partNumber: orderItem?.partNumber,
      contractId: contract,
      catalogId: catalogIdentifier,
      [PROFILE_NAME_PARAM]: CLUSTERING_PROFILE,
    },
  }

  const { data } = useGetProductDetailsQuery(params, {
    skip: isServerProduct(product),
  })

  const productDetails = data?.contents || []
  const pdpData = (
    Array.isArray(productDetails) ? productDetails[0] : productDetails
  ) as ServerProduct

  const serverProduct = isServerProduct(product) ? product : pdpData

  const { handleAddToCartLenses } = useAddOrderItem(
    orderItem,
    serverProduct,
    serverProduct?.partNumber,
    ['1']
  )

  const onClose = useCallback(() => {
    setPrescriptionLensesFormOpen(false)
  }, [setPrescriptionLensesFormOpen])

  return (
    <PrescriptionLenses
      isFromCart={isEditLens}
      product={serverProduct}
      onClose={onClose}
      onAddToBag={handleAddToCartLenses}
      categoryHref={serverProduct?.seo?.href}
    />
  )
}
