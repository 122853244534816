import React from 'react'
import { styled } from '@mui/material/styles'

interface IProps {
  variant: 'primary' | 'secondary' | 'tertiary'
  discount: number
  operator: string
  off: string
}

const Discount = styled('div')(() => ({
  fontSize: 210,
}))

const DiscountContainer = styled('div')(() => ({}))

const PercentOffContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

const Operator = styled('div')(() => ({
  fontSize: 130,
  lineHeight: 0.8,
}))

const Off = styled('div')(() => ({
  textTransform: 'uppercase',
  fontSize: 50,
  lineHeight: 1,
}))

export const variantMap = (theme, variant: IProps['variant'] = 'primary') => {
  const primary = {
    background: theme.palette.custom.halfBaked,
    color: theme.palette.custom.cyprus,
  }
  const secondary = {
    background: theme.palette.custom.white,
    color: theme.palette.custom.cyprus,
  }
  const tertiary = {
    background: theme.palette.custom.cyprus,
    color: theme.palette.custom.white,
  }

  return {
    primary,
    secondary,
    tertiary,
  }[variant]
}

export const WrapperPromoBox = styled('div')<{ variant }>(
  ({ theme, variant }) => ({
    backgroundColor: variantMap(theme, variant)?.background || 'inherit',
    color: variantMap(theme, variant)?.color || 'inherit',
    padding: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  })
)

export const PromoBox = ({ variant, discount, operator, off }: IProps) => {
  return (
    <WrapperPromoBox variant={variant}>
      <DiscountContainer>
        <Discount>{discount}</Discount>
      </DiscountContainer>
      <PercentOffContainer>
        <Operator>{operator}</Operator>
        <Off>{off}</Off>
      </PercentOffContainer>
    </WrapperPromoBox>
  )
}
