//Standard libraries
import React, { SyntheticEvent } from 'react'
import { Link, useLocation } from 'react-router-dom'
//UI
import { SnackbarOrigin, Button } from '@mui/material'
import { StyledAlert, StyledSnackbar } from '../UI'
import InfoRounded from '@mui/icons-material/InfoRounded'

interface MessageSnackbarProperty {
  anchorOrigin: SnackbarOrigin
  handleClose: Function
  message: string | null
  severity: 'success' | 'info' | 'warning' | 'error' | undefined
  linkAction?: { url: string; text: string }
  autoHideDuration?: number
  ClickAwayListenerProps?: any
}
const MessageSnackbar = ({
  anchorOrigin,
  handleClose,
  message,
  severity,
  linkAction,
  autoHideDuration,
  ClickAwayListenerProps,
}: MessageSnackbarProperty) => {
  const location = useLocation()
  const open = !!message
  let clickAway = {}
  if (ClickAwayListenerProps) {
    clickAway = { ClickAwayListenerProps }
  }
  const MessageToDisplay = () => {
    return (
      <span>
        {message}
        {linkAction && <Link to={linkAction.url}>{linkAction.text}</Link>}
      </span>
    )
  }
  const onClose = (e?: Event | SyntheticEvent<any, Event>) => {
    if (open) {
      handleClose(e)
    }
  }

  React.useEffect(() => {
    //close on location change.
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  return (
    <>
      <StyledSnackbar
        open={open}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        {...clickAway}
      >
        <StyledAlert
          className="message-snackbar_alert"
          icon={<InfoRounded fontSize="inherit" />}
          action={
            <Button
              color="inherit"
              size="small"
              style={{ display: 'none' }}
            ></Button>
          }
          variant="filled"
          severity={severity}
          onClose={() => {
            onClose()
          }}
        >
          <MessageToDisplay />
        </StyledAlert>
      </StyledSnackbar>
    </>
  )
}

export default MessageSnackbar
