import { useEffect } from 'react'
import { useSite } from '../../foundation/hooks/useSite'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { storesSelector } from '../../features/stores/selector'
import { setStoresData } from '../../features/stores/thunks'

export const useStores = () => {
  const dispatch = useDispatch()
  const stores = useSelector(storesSelector, shallowEqual) || []
  const { mySite } = useSite()

  useEffect(() => {
    if (!stores?.length) {
      dispatch(setStoresData(mySite))
    }
  }, [dispatch, stores])
  return stores
}
