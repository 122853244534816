//Standard libraries
import { AxiosPromise, AxiosRequestConfig, CancelToken, Method } from 'axios'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
import {
  Contact,
  PersonalInfoFormData,
  PersonalInfoFormField,
  PersonResponse,
} from '../../../types/user'
import { PERSONAL_INFO_FORM_FIELDS } from '../../../constants/user'
import { IFormField } from '../../../types/form'

//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
import RequestService from '../../../services/RequestService'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const personService = {
  /**
   * Gets the account data for a registered user.
   * `@method`
   * `@name Person#findPersonBySelf`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  findPersonBySelf(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<PersonResponse> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/person/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    if (parameters['langId'] === undefined && site !== null) {
      parameters['langId'] = site.defaultLanguageID
    }

    queryParameters.set('storeId', parameters['storeId'])
    queryParameters.set('langId', parameters['langId'])

    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/person/@self missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (
      header.get('content-type') === 'multipart/form-data' &&
      Object.keys(form).length > 0
    ) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION
        ? localStorageUtil.get(SHOW_API_FLOW) === 'true'
        : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from +
              ' -> Transaction: ' +
              method +
              ' ' +
              requestUrl +
              '?' +
              queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  },

  /**
   * This allows an administrator to find user information by user identifier.
   * `@method`
   * `@name Person#findByUserId`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} userId (required)` The child property of `Parameters`.The user identifier.
   ** `@property {string} profileName ` Profile name. Profiles determine the subset of data to be returned by a query.  Default profile name = IBM_User_Display_Details
   */
  findByUserId(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/person/{userId}'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/person/{userId} missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['userId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/person/{userId} missing path parameter userId'
      )
    }
    requestUrl = requestUrl.replace('{userId}', parameters['userId'])

    if (parameters['profileName'] !== undefined) {
      const name = 'profileName'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (
      header.get('content-type') === 'multipart/form-data' &&
      Object.keys(form).length > 0
    ) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION
        ? localStorageUtil.get(SHOW_API_FLOW) === 'true'
        : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from +
              ' -> Transaction: ' +
              method +
              ' ' +
              requestUrl +
              '?' +
              queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  },

  generateContactMap(contactArray: Contact[]): Record<string, Contact> {
    let contactMap: Record<string, Contact> = {}

    for (const contact of contactArray) {
      if (contact && contact.addressId) {
        contactMap[contact.addressId] = contact
      }
    }

    return contactMap
  },

  /**
   * Registers a new user.  When mode is set to admin, the register is done by an administrator.
   * `@method`
   * `@name Person#registerPerson`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` Request body.
   ** `@property {string} mode ` The mode of the rest service. Default value is 'self'.
   */
  registerPerson(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/person'
    let requestUrl = domain + path
    let method: Method = 'POST'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/person missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['mode'] !== undefined) {
      const name = 'mode'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (
      header.get('content-type') === 'multipart/form-data' &&
      Object.keys(form).length > 0
    ) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION
        ? localStorageUtil.get(SHOW_API_FLOW) === 'true'
        : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from +
              ' -> Transaction: ' +
              method +
              ' ' +
              requestUrl +
              '?' +
              queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  },

  /**
   * Updates account data for a registered user.  This also supports resetting password for unauthenticated and authenticated users. When action is set to 'updateUserRegistration', user account data is updated using UserRegistrationUpdateCmd
   * @method
   * @name Person#updatePerson
   *
   * @param body Request body with `Omit<PersonalInfoFormData, 'preferredCurrency'>` type.
   * 'preferredCurrency' is omited to prevent conflicts on backend.
   * @param extraParams Widget name and axios cancel token.
   */
  updatePerson({
    body,
    extraParams,
    ...params
  }: {
    body: Omit<PersonalInfoFormData, 'preferredCurrency' | 'preferredLanguage'>
    extraParams: { widget: string; cancelToken: CancelToken }
  }) {
    return RequestService.request<{
      addressId: string
      resourceName: 'person'
      userId: string
    }>({
      body,
      extraParams,
      method: 'PUT',
      path: '/store/{storeId}/person/@self',
      ...params,
    })
  },

  getPersonFormFields(
    personalInfoFormFields: IFormField[]
  ): PersonalInfoFormField[] {
    const result: PersonalInfoFormField[] = []

    for (const personFormField of PERSONAL_INFO_FORM_FIELDS) {
      const { fieldName } = personFormField
      const personalInfoFormField = personalInfoFormFields.find(
        (f) => f.fieldName === fieldName
      ) as PersonalInfoFormField

      result.push(personalInfoFormField || personFormField)
    }

    return result
  },
}

export default personService
