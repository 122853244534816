import * as ROUTES from '../../constants/routes'

import { Button, ModalDialog } from '../UI'

import { LinkAsButton } from '../UI'
import React from 'react'
import { StyledTitleWrapper } from './OrderRecap.style'
import { generatePath } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'

const OrderRecapModalDialog = styled(ModalDialog)(({ theme }) => ({
  '.ModalDialog--close': {
    top: theme.spacing(8),
  },
}))

const OrderRecapCartContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'OrderRecapCartContent',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const TitleContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'TitleContent',
})(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
  fontSize: theme.spacing(4),

  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(12),
  },
  div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const LinksContent = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'LinksContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    width: '60%',
  },
}))

const StyledLine = styled('div', {
  name: 'OrderRecapEditBag',
  slot: 'Line',
})(({ theme }) => ({
  height: 1,
  flexGrow: 1,
  backgroundColor: `${theme.palette.text.dark.primary}`,

  [theme.breakpoints.up('md')]: {
    maxWidth: '10%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

const OrderRecapCartEditConfirmDialog: React.FC<{
  open: boolean
  handleClose: () => void
}> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const { langCode } = useStoreIdentity()
  const generateEditLink = () => {
    return generatePath(`/:country/${ROUTES.CART}`, { country: langCode })
  }
  //TODO : remove fake content
  return (
    <OrderRecapModalDialog
      open={open}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'sm'}
      maxWidth={570}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <TitleContent>
          <div>
            {isDesktop && <StyledLine />}
            <StyledTitleWrapper>
              {t('OrderRecap.Labels.BackConfirm')}
            </StyledTitleWrapper>
            {isDesktop && <StyledLine />}
          </div>
        </TitleContent>
        <OrderRecapCartContent>
          <LinksContent>
            <LinkAsButton replace={true} to={generateEditLink()}>
              {t('OrderRecap.Labels.BackYesCTA')}
            </LinkAsButton>
            <Button fillType={'outline'} onClick={handleClose}>
              {t('OrderRecap.Labels.BackNoCTA')}
            </Button>
          </LinksContent>
        </OrderRecapCartContent>
      </>
    </OrderRecapModalDialog>
  )
}

export default OrderRecapCartEditConfirmDialog
