import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { TextField } from '../TextField'

export const StyledInputField = styled(TextField, {
  name: 'NewPassword',
  slot: 'StyledInputField',
})<{
  isNewPasswordEmpty: boolean
}>(({ theme, isNewPasswordEmpty }) => ({
  '& p.MuiFormHelperText-root.Mui-error': {
    position: isNewPasswordEmpty ? 'absolute' : 'relative',
    bottom: isNewPasswordEmpty
      ? `calc(-${theme.spacing(1)} - ${theme.spacing(4)})`
      : 0,
  },
}))

export const NewPasswordEmptyHelper = styled('div', {
  name: 'NewPassword',
  slot: 'HelperEmptyNewPassword',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export const NewPasswordHelperTextWrapper = styled('div', {
  name: 'NewPassword',
  slot: 'HelperTextWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
}))

export const NewPasswordHelperText = styled(Typography, {
  name: 'NewPassword',
  slot: 'HelperText',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,
  color: theme.palette.text.light.secondary,
}))

export const NewPasswordHelperTextRow = styled('div', {
  name: 'NewPassword',
  slot: 'HelperTextRow',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
  fontSize: theme.spacing(2.5),
  lineHeight: 1.6,
  color: theme.palette.text.dark.primary,
}))
