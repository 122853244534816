import { styled } from '@mui/material/styles'
import { Link, LinkProps } from 'react-router-dom'
import { cssButton, GenericButtonProps } from './Button'
import React from 'react'

export interface LinkAsButtonProps extends GenericButtonProps, LinkProps {
  dataElementId?: string
  to: LinkProps['to']
  external?: boolean
}

export const LinkAsButton = styled(
  ({
    external,
    to,
    startIcon,
    labelText,
    endIcon,
    children,
    dataElementId,
    ...props
  }: LinkAsButtonProps) =>
    external ? (
      <a href={to as string} data-element-id={dataElementId} {...props}>
        {startIcon}
        {labelText || children}
        {endIcon}
      </a>
    ) : (
      <Link to={to} data-element-id={dataElementId} {...props}>
        {startIcon}
        {labelText || children}
        {endIcon}
      </Link>
    ),
  {
    shouldForwardProp: (prop) => prop !== 'fillType',
  }
)(cssButton)
