//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'

import { ContractReducerState } from './reducerStateInterface'
import { FETCH_CONTRACT_SUCCESS_ACTION } from '../actions/contract'
//Redux
import initStates from './initStates'

/**
 * Contract reducer
 */
const contractReducer = createReducer(initStates.contract, (builder) => {
  builder.addCase(
    FETCH_CONTRACT_SUCCESS_ACTION,
    (state: ContractReducerState | any, action: AnyAction) => {
      for (let variableKey in state) {
        if (state.hasOwnProperty(variableKey)) {
          delete state[variableKey]
        }
      }
      Object.assign(state, action.payload.contracts)
    }
  )
})
export default contractReducer
