import {
  FavouriteProductPriceContainerPDP,
  FavouriteProductPriceCurrentPrice,
  FavouriteProductPriceInitialPricePDP,
  KlarnaMessageContainerPDP,
  ProductCurrentPriceContainer,
  ProductPriceBoxesNr,
  ProductPriceContainer,
  ProductPriceContainerPDP,
  ProductPriceCurrentPrice,
  ProductPriceDetails,
  ProductPriceDiscountBox,
  ProductPriceFrom,
  ProductPriceInitialPrice,
  ProductPriceInitialPricePDP,
  ProductPriceLabel,
  ProductPricePricePerBox,
  ProductPricePricePerBoxContainer,
  ProductPricePricePerBoxCurrentPrice,
  ProductPricePricePerBoxInitialPrice,
  ProductPricePricePerBoxPrices,
  ProductPriceWrapper,
  SuggestedPerBoxProductPrice,
  SuggestedProductPriceContainerPDP,
  SuggestedProductPriceCurrentPrice,
  SuggestedProductPriceFrom,
  SuggestedProductPriceInitialPricePDP,
} from './ProductPrice.style'
import {
  ProductSoldOutStatus,
  ServerProduct,
  ServerProductXPrice,
  SupplyData,
} from '../../../types/product'
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  isAccessoriesProduct,
  isCLAccessoriesProduct,
  isContactLensesProduct,
} from '../../../utils/product'

import { CSSProperties } from '@mui/material/styles/createTypography'
import CurrencyService from '../../../services/CurrencyService'
import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import ProductService from '../../../services/ProductService'
import WithSpinningLoader from '../../../components/with-loading/WithLoading'
import { getIsRoxable } from '../../../utils/productAttributes'
import { useTranslation } from 'react-i18next'
import KlarnaOnSiteMessage from '../../../components/Klarna/KlarnaOnSiteMessage'

export const PRODUCT_PRICE_SIZES = {
  sm: '0.75rem',
  md: '1rem',
  lg: '1.75rem',
} as const

type SizeKeys = keyof typeof PRODUCT_PRICE_SIZES
type ProductPriceSize = typeof PRODUCT_PRICE_SIZES[SizeKeys]
type ProductPriceOrders = {
  current?: 1 | 2 | 3
  initial?: 1 | 2 | 3
  discountAmount?: 1 | 2 | 3
}

type PriceModel = Pick<ServerProductXPrice, 'currency' | 'price'>
type ProductPriceSizes = {
  current?: ProductPriceSize
  currentDesktop?: ProductPriceSize
  initial?: ProductPriceSize
  initialDesktop?: ProductPriceSize
  discountAmount?: ProductPriceSize
  discountAmountDesktop?: ProductPriceSize
}

export interface ProductPriceProps {
  isLoading?: boolean
  showDiscountAmount?: boolean
  currentProduct?: Pick<ServerProduct, 'attributes' | 'price'>
  totalBoxes?: number
  isPDP?: boolean
  isCL?: boolean
  isCart?: boolean
  isForSuggestedProduct?: boolean
  isForFavouriteProduct?: boolean
  isCompact?: boolean
  prices?: PriceModel[]
  x_prices?: {
    offer?: ServerProductXPrice
    list?: ServerProductXPrice
  }
  type?: ProductSoldOutStatus
  orders?: ProductPriceOrders
  columnGap?: CSSProperties['columnGap']
  justifyContent?: CSSProperties['justifyContent']
  showPrefixForCurrent?: boolean // show "from" label before the initial price
  sizes?: ProductPriceSizes
  productType?: string
  productQuantity?: string[]
  productSupplyData?: SupplyData
  updatedDiscountedBox?: number
}

const ProductPrice: FC<ProductPriceProps> = ({
  isLoading = false,
  currentProduct,
  type,
  totalBoxes,
  isPDP,
  isCL,
  isCart,
  isForSuggestedProduct,
  isForFavouriteProduct,
  isCompact,
  productQuantity,
  productSupplyData,
  updatedDiscountedBox,
  x_prices,
}) => {
  const { t } = useTranslation()
  const supplyDataRef = useRef<SupplyData>()
  const boxesNr: number = totalBoxes || 2
  const isCLorAccessory =
    isContactLensesProduct(currentProduct) ||
    isAccessoriesProduct(currentProduct)

  const isCLAccessory = isCLAccessoriesProduct(currentProduct)

  const getDiscount = useCallback(
    (current: PriceModel, initial: PriceModel): number => {
      if (!(current && initial && current.price && initial.price)) {
        return 0
      }

      const numberCurrent = Number(current.price)
      const numberInitial = Number(initial.price)

      if (!isNaN(numberCurrent) && !isNaN(numberInitial)) {
        return ((numberInitial - numberCurrent) / numberInitial) * 100
      } else {
        return 0
      }
    },
    []
  )
  const parseQuantity = productQuantity && parseInt(productQuantity[0])

  const isRoxable = useMemo(
    () => currentProduct && getIsRoxable(currentProduct),
    [currentProduct]
  )

  const initialPrice = useMemo(() => {
    if (!x_prices?.list) return null

    const prices = ProductService.getXPriceModel(x_prices.list)

    if (!prices) return null

    return prices
  }, [x_prices])

  const currentPrice = useMemo(() => {
    const prices =
      x_prices?.offer && ProductService.isValidXPriceDate(x_prices.offer)
        ? ProductService.getXPriceModel(x_prices.offer)
        : initialPrice

    if (!prices) return null
    if (updatedDiscountedBox && productSupplyData) {
      return { ...prices, price: updatedDiscountedBox!.toString() }
    }
    return productSupplyData?.discountedBoxPrice && prices
      ? {
          ...prices,
          price: productSupplyData?.discountedBoxPrice?.toString(),
        }
      : prices
  }, [productSupplyData, x_prices, initialPrice, updatedDiscountedBox])

  const discountAmount = useMemo<number>(() => {
    if (!(currentPrice && initialPrice)) {
      return 0
    }

    return getDiscount(currentPrice, initialPrice)
  }, [currentPrice, initialPrice])

  useEffect(() => {
    supplyDataRef.current = productSupplyData
  }, [productSupplyData])

  const formatPriceValue = useCallback((value: number): string => {
    return value.toFixed(2)
  }, [])

  const getClItemAccessoryPrice = (
    priceValue: PriceModel,
    parseQuantity?: number,
    totalBoxes?: number,
    isCL?: boolean,
    isClAccessory?: boolean
  ): string => {
    isCLAccessory && parseQuantity
      ? formatPriceValue(+priceValue.price * parseQuantity)
      : formatPriceValue(+priceValue.price)
    switch (true) {
      case isClAccessory && !!parseQuantity:
        return formatPriceValue(
          parseFloat(priceValue.price) * (parseQuantity || 0)
        )
      case isCL:
        return formatPriceValue(
          parseFloat(priceValue.price) * (totalBoxes || 0)
        )
      default:
        return formatPriceValue(parseFloat(priceValue.price))
    }
  }

  const hasInitialPriceDiscount = !!(
    discountAmount &&
    initialPrice &&
    initialPrice?.price &&
    +initialPrice.price > 0
  )

  return type !== PRODUCT_SOLDOUT_STATUS.SOLDOUT ? (
    <ProductPriceWrapper
      isLoading={isLoading}
      className={`${
        initialPrice !== undefined
          ? 'product-price'
          : 'product-price no-discount'
      }`}
    >
      <WithSpinningLoader isLoading={isLoading}>
        {isCL && (
          <>
            <ProductPricePricePerBoxContainer>
              <ProductPricePricePerBox>
                {t('ContactLenses.Labels.PerBox')}
              </ProductPricePricePerBox>
              <ProductPricePricePerBoxPrices>
                {hasInitialPriceDiscount && (
                  <ProductPricePricePerBoxInitialPrice>
                    {!!initialPrice &&
                      CurrencyService.getSymbolByName(initialPrice.currency)}
                    {!!initialPrice &&
                      formatPriceValue(parseFloat(initialPrice.price))}
                  </ProductPricePricePerBoxInitialPrice>
                )}
                {currentPrice && (
                  <ProductPricePricePerBoxCurrentPrice>
                    {CurrencyService.getSymbolByName(currentPrice.currency)}
                    {!!currentPrice?.currency &&
                      formatPriceValue(parseFloat(currentPrice.price))}
                  </ProductPricePricePerBoxCurrentPrice>
                )}
              </ProductPricePricePerBoxPrices>
            </ProductPricePricePerBoxContainer>
            <ProductPriceBoxesNr>
              {!!boxesNr && t('ContactLenses.Labels.Boxes', { count: boxesNr })}
            </ProductPriceBoxesNr>
          </>
        )}
        {isPDP ? (
          <>
            {isForSuggestedProduct ? (
              <SuggestedProductPriceContainerPDP>
                <ProductPriceContainerPDP>
                  {(isContactLensesProduct(currentProduct) || isRoxable) && (
                    <>
                      <SuggestedProductPriceFrom>
                        {t('ProductTile.Labels.from')}{' '}
                      </SuggestedProductPriceFrom>
                      {hasInitialPriceDiscount && (
                        <SuggestedProductPriceInitialPricePDP>
                          {!!initialPrice &&
                            CurrencyService.getSymbolByName(
                              initialPrice.currency
                            )}
                          {!!initialPrice &&
                            formatPriceValue(parseFloat(initialPrice.price))}
                        </SuggestedProductPriceInitialPricePDP>
                      )}
                    </>
                  )}
                  {currentPrice && (
                    <SuggestedProductPriceCurrentPrice>
                      {CurrencyService.getSymbolByName(currentPrice.currency)}
                      {formatPriceValue(parseFloat(currentPrice.price))}
                      {isContactLensesProduct(currentProduct) && (
                        <SuggestedPerBoxProductPrice>
                          {' '}
                          {t('ContactLenses.Labels.perBox')}
                        </SuggestedPerBoxProductPrice>
                      )}
                    </SuggestedProductPriceCurrentPrice>
                  )}
                </ProductPriceContainerPDP>
                {!!discountAmount && (
                  <ProductPriceDiscountBox isPDP={isPDP!}>
                    {Math.round(discountAmount)}
                    {t('ProductTile.Labels.off')}
                  </ProductPriceDiscountBox>
                )}
              </SuggestedProductPriceContainerPDP>
            ) : (
              <>
                <ProductPriceContainerPDP>
                  <div>
                    <ProductPriceLabel isPDP={isPDP!}>
                      {isCLorAccessory || isCLAccessory ? 'TOTAL' : 'FRAME'}
                    </ProductPriceLabel>
                  </div>
                  <div>
                    {!!discountAmount && (
                      <ProductPriceDiscountBox isPDP={isPDP!}>
                        {Math.round(discountAmount)}% OFF
                      </ProductPriceDiscountBox>
                    )}
                    {hasInitialPriceDiscount && (
                      <ProductPriceInitialPricePDP>
                        {!!initialPrice &&
                          CurrencyService.getSymbolByName(
                            initialPrice.currency
                          )}
                        {!!initialPrice &&
                          getClItemAccessoryPrice(
                            initialPrice,
                            parseQuantity,
                            totalBoxes,
                            isCL,
                            isCLAccessory
                          )}
                      </ProductPriceInitialPricePDP>
                    )}
                    {currentPrice && (
                      <ProductPriceCurrentPrice
                        isPDP={isPDP!}
                        isCompact={isCompact!}
                      >
                        {!!currentPrice &&
                          CurrencyService.getSymbolByName(
                            currentPrice.currency
                          )}
                        {!!currentPrice &&
                          getClItemAccessoryPrice(
                            currentPrice,
                            parseQuantity,
                            totalBoxes,
                            isCL,
                            isCLAccessory
                          )}
                      </ProductPriceCurrentPrice>
                    )}
                  </div>
                </ProductPriceContainerPDP>
              </>
            )}
            {!(isForSuggestedProduct || isForFavouriteProduct) ? (
              <KlarnaMessageContainerPDP>
                <KlarnaOnSiteMessage
                  variant={'credit-promotion-auto-size'}
                  grandTotal={
                    !!currentPrice
                      ? getClItemAccessoryPrice(
                          currentPrice,
                          parseQuantity,
                          totalBoxes,
                          isCL,
                          isCLAccessory
                        )
                      : ''
                  }
                />
              </KlarnaMessageContainerPDP>
            ) : null}
          </>
        ) : isCart ? (
          <ProductPriceContainer isPDP={isPDP!} isCompact={isCompact!}>
            {/* <ProductPriceFrom>{t('ProductTile.Labels.from')}</ProductPriceFrom> */}
            {hasInitialPriceDiscount && (
              <>
                <ProductPriceInitialPrice isCart={isCart!}>
                  {initialPrice &&
                    CurrencyService.getSymbolByName(initialPrice.currency)}
                  {initialPrice && isCLAccessory && parseQuantity
                    ? formatPriceValue(+initialPrice.price * parseQuantity)
                    : initialPrice && formatPriceValue(+initialPrice.price)}
                </ProductPriceInitialPrice>
              </>
            )}
            {currentPrice && (
              <ProductCurrentPriceContainer isPDP={isPDP!}>
                <ProductPriceCurrentPrice
                  isPDP={isPDP!}
                  isCompact={isCompact!}
                  isCart={isCart!}
                >
                  {CurrencyService.getSymbolByName(currentPrice.currency)}
                  {isCLAccessory && parseQuantity
                    ? formatPriceValue(+currentPrice.price * parseQuantity)
                    : formatPriceValue(+currentPrice.price)}
                </ProductPriceCurrentPrice>
              </ProductCurrentPriceContainer>
            )}
          </ProductPriceContainer>
        ) : isForFavouriteProduct ? (
          <FavouriteProductPriceContainerPDP>
            <ProductPriceContainerPDP>
              {hasInitialPriceDiscount && (
                <>
                  <ProductPriceFrom>
                    {t('ProductTile.Labels.from')}{' '}
                  </ProductPriceFrom>
                  <FavouriteProductPriceInitialPricePDP>
                    {!!initialPrice &&
                      CurrencyService.getSymbolByName(initialPrice.currency)}
                    {!!initialPrice &&
                      formatPriceValue(parseFloat(initialPrice.price))}
                  </FavouriteProductPriceInitialPricePDP>
                </>
              )}
              {currentPrice && (
                <FavouriteProductPriceCurrentPrice>
                  {CurrencyService.getSymbolByName(currentPrice.currency)}
                  {formatPriceValue(parseFloat(currentPrice.price))}
                </FavouriteProductPriceCurrentPrice>
              )}
            </ProductPriceContainerPDP>
            {!!discountAmount && (
              <ProductPriceDiscountBox isPDP={true}>
                {Math.round(discountAmount)}
                {t('ProductTile.Labels.off')}
              </ProductPriceDiscountBox>
            )}
          </FavouriteProductPriceContainerPDP>
        ) : (
          <>
            <ProductPriceContainer isPDP={isPDP!} isCompact={isCompact!}>
              <ProductPriceDetails>
                {(isRoxable ||
                  (isContactLensesProduct(currentProduct) &&
                    hasInitialPriceDiscount)) &&
                  t('ProductTile.Labels.from')}
                {hasInitialPriceDiscount && (
                  <ProductPriceInitialPrice>
                    {!!initialPrice &&
                      CurrencyService.getSymbolByName(initialPrice.currency)}
                    {!!initialPrice && formatPriceValue(+initialPrice.price)}
                  </ProductPriceInitialPrice>
                )}
                {currentPrice &&
                  (isContactLensesProduct(currentProduct) && !discountAmount ? (
                    <>
                      {t('ProductTile.Labels.from')}
                      <ProductCurrentPriceContainer isPDP={isPDP!}>
                        <ProductPriceCurrentPrice
                          isPDP={isPDP!}
                          isCompact={isCompact!}
                        >
                          {CurrencyService.getSymbolByName(
                            currentPrice.currency
                          )}
                          {formatPriceValue(+currentPrice.price)}
                        </ProductPriceCurrentPrice>
                      </ProductCurrentPriceContainer>
                    </>
                  ) : isContactLensesProduct(currentProduct) &&
                    discountAmount ? (
                    <ProductPriceCurrentPrice
                      isPDP={isPDP!}
                      isCompact={isCompact!}
                    >
                      {CurrencyService.getSymbolByName(currentPrice.currency)}
                      {!!currentPrice?.currency &&
                        formatPriceValue(parseFloat(currentPrice.price))}
                    </ProductPriceCurrentPrice>
                  ) : (
                    <ProductCurrentPriceContainer isPDP={isPDP!}>
                      <ProductPriceCurrentPrice
                        isPDP={isPDP!}
                        isCompact={isCompact!}
                      >
                        {CurrencyService.getSymbolByName(currentPrice.currency)}
                        {formatPriceValue(+currentPrice.price)}
                      </ProductPriceCurrentPrice>
                    </ProductCurrentPriceContainer>
                  ))}
                {(isContactLensesProduct(currentProduct) || isCLAccessory) &&
                  t('ContactLenses.Labels.perBox')}
              </ProductPriceDetails>
              {!!discountAmount && (
                <ProductPriceDiscountBox isPDP={isPDP!}>
                  {Math.round(discountAmount)}% OFF
                </ProductPriceDiscountBox>
              )}
            </ProductPriceContainer>
          </>
        )}
      </WithSpinningLoader>
    </ProductPriceWrapper>
  ) : null
}

export default ProductPrice
