//Standard libraries
import { call, put } from 'redux-saga/effects'
//Foundation libraries
import organizationService from '../../../foundation/apis/transaction/organization.service'
import switchOrganizationService from '../../../foundation/apis/transaction/switchOrganization.service'
//Redux
import {
  ENTITLED_ORG_ERROR_ACTION,
  ENTITLED_ORG_SUCCESS_ACTION,
  ORG_SWITCH_ERROR_ACTION,
  GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_ERROR_ACTION,
} from '../../actions/organization'
import { USER_CONTEXT_REQUEST_ACTION } from '../../actions/context'
import { FETCH_CONTRACT_REQUESTED_ACTION } from '../../actions/contract'

/**
 *
 */
export function* getEntitledOrg(action: any) {
  try {
    const payload = action.payload
    const response = yield call(
      organizationService.getEntitledOrganizations,
      payload
    )
    yield put(ENTITLED_ORG_SUCCESS_ACTION(response.data))
  } catch (e) {
    yield put(ENTITLED_ORG_ERROR_ACTION(e))
  }
}

/**
 *
 */
export function* switchOrg(action: any) {
  try {
    yield call(switchOrganizationService.changeOrganization, action.payload)
    // yield put(FETCHING_CART_ACTION(action.payload)) // must use slice's fetch
    yield put(USER_CONTEXT_REQUEST_ACTION(action.payload))
    yield put(FETCH_CONTRACT_REQUESTED_ACTION(action.payload))
  } catch (e) {
    yield put(ORG_SWITCH_ERROR_ACTION(e))
  }
}

/**
 *
 */
export function* getOrganizationDetails(action: any) {
  try {
    const payload = action.payload
    const response = yield call(
      organizationService.findByOrganizationId,
      payload
    )
    yield put(GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION(response.data))
  } catch (e) {
    yield put(GET_ORGANIZATION_ADDRESS_ERROR_ACTION(e))
  }
}
