import axios from 'axios'
import qs from 'qs'

import { ServerProduct } from '../../../types/product'
import { UserData } from '../../../types/FrameGenius/frameAdvisor'
import { storageSessionHandler } from '../../utils/storageUtil'
interface IFrameGeniusService {
  loadFrameAdvisorProducts: (
    storeId: number,
    langId: number,
    catalogId: number,
    contractId: number,
    partNumber: number[]
  ) => Promise<ServerProduct[]>
  saveProfileData: (profileData: UserData, storeID: string) => void
  getProfileData: (storeID: string) => Promise<UserData>
}

interface FrameGeniusProductsRequest {
  storeId: number
  langId: number
  catalogId: number
  contractId: number
  partNumber: number[]
  profileName?: string
}
class FrameGeniusService implements IFrameGeniusService {
  private readonly frameAdvisorProductsEndpoint =
    '/search/resources/api/v2/products'

  private readonly generateProfileEndpoint = (storeId: string) => {
    return `/wcs/resources/store/${storeId}/frameadvisor/userprofile`
  }

  private readonly getHeaders = () => {
    const currentUserSession =
      storageSessionHandler.getCurrentUserAndLoadAccount()
    return {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      WCPersonalization: currentUserSession?.personalizationID,
      WCToken: currentUserSession?.WCToken,
      WCTrustedToken: currentUserSession?.WCTrustedToken,
    }
  }

  loadFrameAdvisorProducts = async (
    storeId: number,
    langId: number,
    catalogId: number,
    contractId: number,
    partNumber: number[]
  ): Promise<ServerProduct[]> => {
    const params: FrameGeniusProductsRequest = {
      storeId,
      langId,
      catalogId,
      contractId,
      partNumber,
      profileName: 'LX_findProductByPartNumber_Details',
    }

    const stringifiedParams = qs.stringify(params, { indices: false })
    const url = `${this.frameAdvisorProductsEndpoint}?${stringifiedParams}`
    const response = await axios.get(url, { headers: this.getHeaders() })
    return response.data?.contents as ServerProduct[]
  }
  saveProfileData = (profileData: UserData, storeID: string) => {
    const endpoint = this.generateProfileEndpoint(storeID)
    return axios.post(endpoint, profileData, { headers: this.getHeaders() })
  }
  getProfileData = async (storeID: string): Promise<UserData> => {
    const endpoint = this.generateProfileEndpoint(storeID)
    const userData = await axios.get(endpoint, { headers: this.getHeaders() })
    return userData.data
  }
  resetProfileData = (storeID: string) => {
    const endpoint = this.generateProfileEndpoint(storeID)
    return axios.delete(endpoint, { headers: this.getHeaders() })
  }
}
export default new FrameGeniusService()
