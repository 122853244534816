import { RootReducerState } from '../../redux/reducers'

export const productTypeSelector = (state: RootReducerState) =>
  state.product.productType
export const productContactLensesDataSelector = (state: RootReducerState) =>
  state.product.contactLensesData
export const productAddToCartEnabledSelector = (state: RootReducerState) =>
  state.product.addToCartEnabled
export const addedToCartSuccessSelector = (state: RootReducerState) =>
  state.success.link
export const addContactLensesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addContactLensesToCartError
export const productVmSupportSelector = (state: RootReducerState) =>
  state.product.vmSupport

export const selectedPartNumberSelector = (state: RootReducerState) =>
  state.product.selectedPartNumber
