import React from 'react'
import { useTheme } from '@mui/material'
import {
  OrderDetailsItem,
  OrderDetailsItemWrapper,
  OrderDetailsProductInfo,
  OrderDetailsProductInfoListItem,
  OrderDetailsProductInfoRow,
  OrderDetailsProductInfoTotalItem,
  OrderDetailsProductInfoTotalRow,
  PrescriptionDetail,
  PrescriptionMessageBody,
  PrescriptionMessageHeader,
  PrescriptionMessageWrapper,
  PrescriptionWrapper,
  ProductImageWrapper,
  PrescriptionSubmitWrapper,
  PrescriptionIconWrapper,
  StatusHeader,
} from './OrderDetails.style'
import {
  RECYCLABEL_FRAME_MACRO_MATERIALS,
  RECYCLABEL_LENS_MACRO_MATERIALS,
} from '../../../constants/product'
import {
  getFrameMaterial,
  getFrontColor,
  getLensMacroMaterial,
  getLensesColor,
  getProductType,
} from '../../../utils/productAttributes'
import { LinkAsButton } from '../../../components/UI'
import {
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS,
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
} from '../../../constants/order'
import {
  ProductTypesEnum,
  PRODUCT_PRICE_USAGE_NAMES,
} from './../../../types/product'
import ProductImage from '../../../components/ProductImage/ProductImage'
import {
  RoundWarningIcon,
  RoundWarningSmallIcon,
} from '../../../components/UI/Icons'
import { PRESCRIPTION_VERIFICATION } from '../../../constants/routes'
import { formatPathWithBase } from '../../../utils/url'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useNavigate } from 'react-router'
import CurrencyService from '../../../services/CurrencyService'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'
import { OrderInfo } from './OrderInfo'
import OrderRecapItemPrice from '../../../components/order-recap/OrderRecapItemPrice'
import { isContactLensesProduct } from '../../../utils/product'
import { getOrderReturnedStatus } from '../../../utils/order'
import { sumBy } from 'lodash-es'

export const OrderComponent = ({ orderItem, orderDetails }) => {
  const navigate = useNavigate()
  const { basePath } = useStoreIdentity()
  const { isDesktop, isMobile } = useBreakpoints()
  const { t } = useTranslation()
  const theme = useTheme()

  const product = orderDetails.x_data?.productDetails?.find(
    ({ id }) => orderItem.productId === id
  )

  const href = product?.seo?.href

  const productType = getProductType(product)
  if (!product) return null

  const onImageClicked = () => {
    const url = `${basePath}${href || ''}`
    navigate(url)
  }
  const { orderItemExtendAttribute = [] } = orderItem

  const rxServices = orderItem?.roxableServices

  const frameMaterial = getFrameMaterial(product)
  const isFrameMaterialRecyclabel = RECYCLABEL_FRAME_MACRO_MATERIALS.includes(
    frameMaterial.toLowerCase()
  )
  const frameAttributesArray: string[] = [getFrontColor(product)]
  if (isFrameMaterialRecyclabel) {
    frameAttributesArray.push(frameMaterial)
  }

  const nonRxLensMacroMaterial = getLensMacroMaterial(product)
  const nonRxLensAttributesArray: string[] = [getLensesColor(product)]
  if (
    !rxServices &&
    isFrameMaterialRecyclabel &&
    RECYCLABEL_LENS_MACRO_MATERIALS.includes(
      nonRxLensMacroMaterial.toLowerCase()
    )
  ) {
    nonRxLensAttributesArray.unshift(nonRxLensMacroMaterial)
  }

  const trackingNumber =
    orderItemExtendAttribute.find(
      (a) =>
        a.attributeName ===
        ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(
          orderDetails.orderId
        )
    )?.attributeValue || ''

  const FRAME_DETAILS = orderDetails?.x_data?.productDetails.find(
    ({ id }) => id === orderItem.productId
  )

  const orderItemCurrency =
    orderItem && CurrencyService.getSymbolByName(orderItem.currency)
  const PRICE = rxServices?.length
    ? {
        currency: orderItemCurrency,
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        value: (
          parseFloat(FRAME_DETAILS?.x_offerpriceRx || 0) +
          sumBy(rxServices, (item: any) => parseFloat(item.orderItemPrice) || 0)
        )
          .toFixed(2)
          .toString(),
      }
    : {
        currency: orderItemCurrency,
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        value: orderItem?.orderItemPrice,
      }
  const returnedItemStatus = getOrderReturnedStatus(orderItem)
  const orderItemStatus = returnedItemStatus || orderItem.orderItemStatus
  const CustomPrescriptionWrapper = () => (
    <PrescriptionWrapper>
      <PrescriptionDetail>
        <PrescriptionIconWrapper>
          {isDesktop ? (
            <RoundWarningIcon
              width={24}
              height={24}
              htmlColor={theme.palette.text.dark.primary}
            />
          ) : (
            <RoundWarningSmallIcon
              htmlColor={theme.palette.text.dark.primary}
            />
          )}
        </PrescriptionIconWrapper>

        <PrescriptionMessageWrapper>
          <PrescriptionMessageHeader>
            {t('Order.PrescriptionNeeded1')}
          </PrescriptionMessageHeader>
          <PrescriptionMessageBody>
            {t('Order.PrescriptionNeeded2')}
          </PrescriptionMessageBody>
        </PrescriptionMessageWrapper>
      </PrescriptionDetail>
      <PrescriptionSubmitWrapper>
        <LinkAsButton
          fillType="fill"
          to={formatPathWithBase(
            `${PRESCRIPTION_VERIFICATION}?orderId=${orderDetails?.orderId}`,
            basePath
          )}
        >
          {t('Order.PendingPrescriptionCta')}
        </LinkAsButton>
      </PrescriptionSubmitWrapper>
    </PrescriptionWrapper>
  )

  return (
    <OrderDetailsItemWrapper>
      {isDesktop &&
        [
          ORDER_ITEM_STATUS.PendingPrescription_V,
          ORDER_STATUS.PendingPrescription,
        ].includes(orderItemStatus) && <CustomPrescriptionWrapper />}
      {![
        ORDER_ITEM_STATUS.PendingPrescription_V,
        ORDER_STATUS.PendingPrescription,
      ].includes(orderItemStatus) && (
        <StatusHeader
          shipped={[
            ORDER_ITEM_STATUS.Shipped_Settled,
            ORDER_ITEM_STATUS.Shipped_Billed,
            ORDER_ITEM_STATUS.Shipped_FailedStatement,
          ].includes(orderItemStatus)}
        >
          <span>{t('Order.Status')}:</span>
          {t(`Order.Status_.${orderItemStatus}`)}
        </StatusHeader>
      )}
      <OrderDetailsItem>
        <ProductImageWrapper>
          <ProductImage
            alt="Product image"
            attachments={product.attachments}
            usage="Thumbnail"
            width={400}
            onClick={onImageClicked}
          />
        </ProductImageWrapper>
        <OrderDetailsProductInfo>
          <OrderInfo
            product={product}
            orderItem={orderItem}
            orderDetails={orderDetails}
            productType={productType}
          />
          <OrderDetailsProductInfoTotalRow
            topBorder={productType === ProductTypesEnum.Accessories}
          >
            <OrderDetailsProductInfoListItem>
              {t('Order.TotalPrice')}:
            </OrderDetailsProductInfoListItem>
            {isContactLensesProduct(orderItem) ? (
              <OrderRecapItemPrice orderItem={orderItem} />
            ) : (
              <OrderDetailsProductInfoTotalItem>{`${PRICE.currency} ${PRICE.value}`}</OrderDetailsProductInfoTotalItem>
            )}
          </OrderDetailsProductInfoTotalRow>
          {!!trackingNumber && (
            <>
              <OrderDetailsProductInfoRow>
                {t('Order.TrackingNumber')}: {trackingNumber}
              </OrderDetailsProductInfoRow>
            </>
          )}
        </OrderDetailsProductInfo>
      </OrderDetailsItem>
      {isMobile &&
        [
          ORDER_ITEM_STATUS.PendingPrescription_V,
          ORDER_STATUS.PendingPrescription,
        ].includes(orderItemStatus) && <CustomPrescriptionWrapper />}
    </OrderDetailsItemWrapper>
  )
}
