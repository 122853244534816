import { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { generateAccountPagePath } from '../../pages/Account/Account'
import { ACCOUNT_CHILDREN } from '../../constants/routes'

export const useIsAccountPage = () => {
  const location = useLocation()
  const { langCode } = useStoreIdentity()
  const [isAccountPage, setIsAccountPage] = useState<Boolean>(false)
  const AccountChildrenValues: string[] = Object.values(ACCOUNT_CHILDREN)

  useEffect(() => {
    const item = AccountChildrenValues.find((path) => {
      if (typeof path !== 'string') return false

      return matchPath(
        {
          path: generateAccountPagePath(langCode, path),
        },
        location.pathname
      )
    })

    setIsAccountPage(!!item)
  }, [location.pathname])

  return isAccountPage
}
