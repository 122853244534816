import {
  BASE,
  CART,
  CHECKOUT_SHIPPING,
  CHECKOUT_PAYMENT,
  ORDER_CONFIRMATION,
  WISHLIST,
  CHECKOUT_UPLOAD_PRESCRIPTION,
  PRESCRIPTION_VERIFICATION,
  STORELOCATOR,
  SIGNIN,
  SITEMAP,
  SEARCH,
} from '../../constants/routes'

import { currentPageSeoDataSelector } from '../../features/seo/selector'
import { useRouteMatcher } from './usePathMatcher'
import { useSelector } from 'react-redux'
import { openDrawerSearchSelector } from '../../features/ui/selector'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { seoApi } from 'src/features/seo/query'

export const usePageType = () => {
  const location = useLocation()
  const seoUrlIdentifier = location.pathname?.split('/').slice(2)?.join('/')
  const seoData = useSelector(
    currentPageSeoDataSelector(decodeURIComponent(seoUrlIdentifier))
  )
  const isSearchDrawerVisible = useSelector(openDrawerSearchSelector)
  const matchRoute = useRouteMatcher()
  const seoApiQueryResult = useMemo(
    () =>
      seoApi.endpoints.getSeo.select({
        identifier: decodeURI(seoUrlIdentifier),
      }),
    [seoUrlIdentifier]
  )
  const { isLoading, isUninitialized, isError, isSuccess } =
    useSelector(seoApiQueryResult)

  const seoDataloadingFinished = useMemo(() => {
    return !isUninitialized && !isLoading && (isSuccess || isError)
  }, [isLoading, isUninitialized, isError, isSuccess])

  const seoPageType: string = seoData?.page?.type || ''

  const pageTypes = {
    search: isSearchDrawerVisible,
    searchResults: !!matchRoute(`/:country${SEARCH}`),
    home: !!matchRoute(BASE),
    cart: !!matchRoute(`/:country/${CART}`),
    checkout:
      !!matchRoute(`/:country/${CHECKOUT_UPLOAD_PRESCRIPTION}`) ||
      !!matchRoute(`/:country/${CHECKOUT_SHIPPING}`) ||
      !!matchRoute(`/:country/${CHECKOUT_PAYMENT}`) ||
      !!matchRoute(`/:country/${ORDER_CONFIRMATION}`),
    checkoutExternal: !!matchRoute(`/:country/${PRESCRIPTION_VERIFICATION}`),
    plp: seoPageType === 'ProductListPage' || seoPageType === 'CategoryPage',
    pdp: seoPageType === 'ProductPage' || seoPageType === 'ItemPage',
    sitemap: !!matchRoute(SITEMAP),
    wishlist: !!matchRoute(WISHLIST),
    storeLocator: !!matchRoute(STORELOCATOR),
    signIn: !!matchRoute(SIGNIN),
    otherPage: location.pathname.includes('/c/'),
    page404: seoDataloadingFinished && !seoPageType,
  } as const

  return { pageType: Object.keys(pageTypes).find((key) => pageTypes[key]) }
}
