import { styled } from '@mui/material/styles'
import { StyledTabsProps } from '../../../types/tabs'

export const StyledTabsWrapper = styled('div', {
  name: 'TabsWrapper',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'parentbg',
})<Pick<StyledTabsProps, 'parentbg'>>(({ theme, parentbg }) => ({
  backgroundColor:
    parentbg === 'light'
      ? theme.palette.background.light.primary
      : theme.palette.background.dark.primary,

  '&.MuiButtonBase-root': {
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.spacing(5),
    },

    minWidth: 'auto',
  },

  '&.MuiTabs-flexContainer': {
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(4),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.spacing(5),
    },
  },
}))
