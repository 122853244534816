// Standard libraries
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Axios, { Canceler } from 'axios'
import getDisplayName from 'react-display-name'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Controller, useForm } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
// Foundation libraries
import personService from '../../foundation/apis/transaction/person.service'
import { NewsletterService } from '../../foundation/apis/newsletter/newsletter.service'
import { useSite } from '../../foundation/hooks/useSite'
import { userDetailsSelector } from '../../redux/selectors/user'
import { NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION } from '../../redux/actions/user'

//UI
import {
  AccountSettingsTitle,
  AccountSettingsBodyWrapper,
  ChangePasswordTitle,
  ChangePasswordWrapper,
  ChangePasswordForm,
  NewsLetterWrapper,
  NewsletterTitle,
  NewsletterSubscribedWrapper,
  NewsletterSubscribedText,
  NewsletterEmailWrapper,
  NewsletterEmailText,
  NewsletterUnsubscribeButton,
  UpdateButtonWrapper,
  UpdateButton,
  SubmitSuccessMessage,
  SubmitSuccessMessageWrapper,
  AccountNewsletterSubscriptionCertification,
  AccountDataProtectionPolicy,
  AccountDataProtectionPolicyWrapper,
  NewsletterSubscriptionFormWrapper,
  NewsletterSubscriptionTextFieldWrapper,
} from './AccountSettings.style'
import { Button, NewPassword } from '../../components/UI'
import {
  CheckmarkRoundedValidIcon,
  PasswordHideIcon,
  PasswordShowVisibleIcon,
} from '../../components/UI/Icons'
import useBreakpoints from '../../hooks/useBreakpoints'
import { IconButton, TextField } from '../../components/UI'
import { SettingsFormData } from '../../types/user'
import { SETTINGS_FORM_FIELDS } from '../../constants/user'
import { useYupValidationResolver } from '../../utils/validationResolver'
import { buildYupValidationSchema } from '../../constants/form'
import Log from '../../services/Log'
import { genericErrorSelector } from '../../redux/selectors/error'
import { sendNewsletterSubscriptionEvent } from '../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useNavigate } from 'react-router-dom'

const defaultValues: SettingsFormData = {
  currentPassword: '',
  newPassword: '',
  verifyPassword: '',
}

type ResultObject = {
  error?: boolean
  text?: string
}

const AccountSettings: FC = () => {
  const dispatch = useDispatch()
  const { mySite: site } = useSite()
  const theme = useTheme()
  const widgetName = getDisplayName(AccountSettings)
  const addressDetails = useSelector(userDetailsSelector)
  const [
    subscribeToNewsletterButtonDisabled,
    setSubscribeToNewsletterButtonDisabled,
  ] = useState<boolean>(false)
  const newsletterSubscribed = addressDetails?.x_data.hasNewsletter
  const { isMobile } = useBreakpoints()
  const [isSuccessSubmit, setIsSubmitSuccess] = useState<boolean>(false)
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [showVerifyPassword, setShowVerifyPassword] =
    React.useState<boolean>(false)
  const genericError: any = useSelector(genericErrorSelector)
  const [error, setError] = useState(false)
  const [result, setResult] = useState<ResultObject>({})
  const [email, setEmail] = useState('')

  const { mySite } = useSite()
  const { basePath } = useStoreIdentity()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('AccountSettings.Title')
  const currentPasswordLabel = t(
    'AccountSettings.UpdatePassword.CurrentPasswordLabel'
  )
  const verifyPasswordLabel = t(
    'AccountSettings.UpdatePassword.VerifyPasswordLabel'
  )
  const saveLabel = t('AccountSettings.UpdatePassword.SaveLabel')
  const newPasswordLabel = t('AccountSettings.UpdatePassword.NewPasswordLabel')

  const DATA_PROTECTION_POLICY = '/c/legal/legal-policies'
  const dataPolicyLink = basePath + DATA_PROTECTION_POLICY

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const onChange = (e) => {
    setError(false)
    setResult({})
    setEmail(e.target.value)
  }

  const subscribe = async () => {
    const emailRegExp = new RegExp(
      /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,63})$/gm
    )
    if (emailRegExp.test(email)) {
      try {
        const data = await NewsletterService.subscribeToNewsletter({
          email: email.toLowerCase(),
          newsletter_id: mySite.newsletterType,
          from: 'myAccount',
        })
        if (data.code === '200') {
          setResult({
            error: false,
            text: t('Newsletter.Success'),
          })
          sendNewsletterSubscriptionEvent(email)
          window.location.reload()
        }
      } catch (e: any) {
        setResult({
          error: true,
          text: t('NewsLetterDrawerContent.Errors.Generic'),
        })
      }
    } else {
      setError(true)
    }
  }

  const onSubmit = async (data: SettingsFormData) => {
    const { currentPassword, newPassword, verifyPassword } = data

    const storeId = site.storeID
    const parameters: any = {
      responseFormat: 'application/json',
      storeId,
      body: {
        resetPassword: 'true',
        xcred_logonPasswordOld: currentPassword,
        logonPassword: newPassword,
        xcred_logonPasswordVerify: verifyPassword,
      },
      ...payloadBase,
    }
    try {
      const submitResult = await personService.updatePerson(parameters)

      setIsSubmitSuccess(submitResult.resourceName === 'person')
    } catch (e) {
      Log.error('Error while saving settings')
    }
  }

  const handleUnsubscribeFromNewsletter = () => {
    if (!addressDetails?.email1) {
      return
    }

    setSubscribeToNewsletterButtonDisabled(true)

    NewsletterService.unsubscribeFromNewsletter({
      email: addressDetails?.email1,
    })
      .then((res) => {
        if (res) {
          setSubscribeToNewsletterButtonDisabled(true)

          dispatch(
            NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION({ subscribed: false })
          )
        }
      })
      .finally(() => setSubscribeToNewsletterButtonDisabled(true))
  }

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  const handleShowPassword = (fieldName: string) => {
    switch (fieldName) {
      case 'verifyPassword':
        setShowVerifyPassword(!showVerifyPassword)
        break
      case 'currentPassword':
        setShowPassword(!showPassword)
        break
      default:
        break
    }
    return true
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SettingsFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: useYupValidationResolver(
      buildYupValidationSchema({
        formFields: SETTINGS_FORM_FIELDS,
        i18nInvalidMsgBase: 'AccountSettings.UpdatePassword.',
        i18nFormFieldsInvalidMsgs: {},
        t,
      })
    ),
  })

  return (
    <AccountSettingsBodyWrapper>
      <AccountSettingsTitle>{title}</AccountSettingsTitle>
      <ChangePasswordWrapper>
        <ChangePasswordTitle>
          {t('AccountSettings.UpdatePassword.Title')}
        </ChangePasswordTitle>
        <ChangePasswordForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                fullWidth
                label={currentPasswordLabel}
                inputProps={{
                  maxLength: 50,
                }}
                type={showPassword ? 'text' : 'password'}
                error={
                  (error && !!value?.length) ||
                  (error && isEmpty(value)) ||
                  genericError.errorCode
                }
                helperText={
                  (error && !!value?.length) || (error && isEmpty(value))
                    ? t(errors.currentPassword?.message ?? '')
                    : genericError.errorCode && genericError.errorMessage
                }
                showvalidationstatus
                ispristine={!value?.length}
              />
            )}
            name="currentPassword"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NewPassword
                {...field}
                isPristine={!field.value.length}
                error={!!error}
                label={newPasswordLabel}
              />
            )}
            name="newPassword"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                fullWidth
                label={verifyPasswordLabel}
                inputProps={{
                  maxLength: 50,
                }}
                type={showVerifyPassword ? 'text' : 'password'}
                customInputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleShowPassword('verifyPassword')}
                    >
                      {showVerifyPassword ? (
                        <PasswordShowVisibleIcon
                          htmlColor={theme.palette.custom.cyprus}
                        />
                      ) : (
                        <PasswordHideIcon
                          htmlColor={theme.palette.custom.cyprus}
                        />
                      )}
                    </IconButton>
                  ),
                }}
                error={(error && !!value?.length) || (error && isEmpty(value))}
                helperText={
                  ((error && !!value?.length) || (error && isEmpty(value))) &&
                  t(errors.verifyPassword?.message ?? '')
                }
                isvalid={!error && !!value?.length}
                ispristine={!value?.length}
              />
            )}
            name="verifyPassword"
            control={control}
            defaultValue=""
          />
          <UpdateButtonWrapper>
            <UpdateButton
              fullWidth={isMobile}
              data-element-id={'X_X_ChangePassword_Update'}
              variant="primary"
              // disabled={!CAN_CONTINUE}
              type="submit"
            >
              {saveLabel}
            </UpdateButton>
            {isSuccessSubmit && (
              <SubmitSuccessMessageWrapper>
                <CheckmarkRoundedValidIcon
                  width={16}
                  height={16}
                  htmlColor={theme.palette.color?.success}
                ></CheckmarkRoundedValidIcon>
                <SubmitSuccessMessage>
                  {t('AccountSettings.Msgs.SubmitSuccess')}
                </SubmitSuccessMessage>
              </SubmitSuccessMessageWrapper>
            )}
          </UpdateButtonWrapper>
        </ChangePasswordForm>
      </ChangePasswordWrapper>
      <NewsLetterWrapper>
        <NewsletterTitle>
          {t('AccountSettings.Newsletter.Title')}
        </NewsletterTitle>
        <NewsletterSubscribedWrapper>
          {newsletterSubscribed ? (
            <>
              <NewsletterSubscribedText>
                {t('AccountSettings.Newsletter.SubscribedText')}
              </NewsletterSubscribedText>
              <NewsletterEmailWrapper>
                <NewsletterEmailText>
                  {addressDetails?.email1}
                </NewsletterEmailText>

                <NewsletterUnsubscribeButton
                  data-element-id={'X_X_Newsletter_Unsubscribe'}
                  fullWidth={isMobile}
                  fillType="outline"
                  disabled={subscribeToNewsletterButtonDisabled}
                  onClick={handleUnsubscribeFromNewsletter}
                >
                  {t('AccountSettings.Newsletter.UnsubscribeCTA')}
                </NewsletterUnsubscribeButton>
              </NewsletterEmailWrapper>
            </>
          ) : (
            <>
              <NewsletterSubscribedText>
                {t('AccountSettings.Newsletter.UnsubscribedText')}
              </NewsletterSubscribedText>
              <NewsletterSubscriptionFormWrapper>
                <NewsletterSubscriptionTextFieldWrapper>
                  <TextField
                    error={error || result.error}
                    helperText={
                      error && isEmpty(result)
                        ? t('NewsLetterDrawerContent.Errors.Input')
                        : result.error
                        ? result.text
                        : null
                    }
                    variant="outlined"
                    margin="none"
                    fullWidth
                    name="email"
                    label="Email address"
                    type="email"
                    id="email"
                    value={email}
                    showvalidationstatus={true}
                    onChange={onChange}
                  />
                  <AccountNewsletterSubscriptionCertification>
                    {t('NewsLetterDrawerContent.Certify18YearsOld')}
                  </AccountNewsletterSubscriptionCertification>
                </NewsletterSubscriptionTextFieldWrapper>
                <Button
                  data-element-id={'X_X_Newsletter_Subscribe'}
                  fullWidth={isMobile}
                  fillType={'outline'}
                  onClick={subscribe}
                >
                  {t('NewsLetterDrawerContent.SubscribeCTA')}
                </Button>
              </NewsletterSubscriptionFormWrapper>
              <AccountDataProtectionPolicyWrapper>
                <AccountDataProtectionPolicy
                  onClick={() => navigate(dataPolicyLink)}
                >
                  {t('NewsLetterDrawerContent.DataProtectionPolicy')}
                </AccountDataProtectionPolicy>
              </AccountDataProtectionPolicyWrapper>
            </>
          )}
        </NewsletterSubscribedWrapper>
      </NewsLetterWrapper>
    </AccountSettingsBodyWrapper>
  )
}

export default AccountSettings
