import { useCallback } from 'react'
import { RouteProps, matchPath, useLocation } from 'react-router-dom'
import { useStoreIdentity } from './useStoreIdentity'

export const useRouteMatcher = () => {
  const location = useLocation()
  const { langCode } = useStoreIdentity()
  return useCallback(
    (pathname: string, routeProps?: RouteProps) =>
      matchPath(
        {
          path: pathname,
          ...routeProps,
        },
        location.pathname
      ),
    [langCode, location.pathname, matchPath]
  )
}
