//Custom libraries
import * as ROUTES from '../constants/routes'

import { lazy, memo } from 'react'

import { Navigate } from 'react-router-dom'
//Standard libraries
import React from 'react'

//Common pages
const SEO = memo(lazy(() => import('../foundation/seo/SEO')))
const Cart = lazy(() => import('../pages/Cart'))
const Home = memo(lazy(() => import('../pages/Home')))

//component
const SignIn = memo(lazy(() => import('../pages/SignIn')))
const SignUp = memo(lazy(() => import('../pages/SignUp')))
const Sitemap = lazy(() => import('../pages/Sitemap'))
const ForgotPassword = memo(lazy(() => import('../pages/SignIn')))

// const ChangePassword = withQuicklink(lazy(() => import('../pages/ChangePassword')))
const SearchResults = lazy(() => import('../pages/SearchResults'))

const StoreLocator = memo(lazy(() => import('../pages/StoreLocator')))

//checkout
const Checkout = lazy(() => import('../pages/Checkout'))
const Shipping = lazy(() => import('../pages/Checkout/Shipping'))
const Billing = lazy(() => import('../pages/Checkout/payment/Payment'))

const Review = lazy(() => import('../pages/Checkout/review/Review'))

const OrderConfirmation = lazy(() => import('../pages/OrderConfirmation'))

const PrescriptionVerification = lazy(
  () => import('../pages/PrescriptionVerification')
)

const UploadCheckoutPrescription = lazy(
  () => import('../pages/Checkout/UploadPrescription')
)

//Emerald pages
const Account = lazy(() => import('../pages/Account'))
const Wishlist = lazy(() => import('../pages/Wishlist'))
const CmsPage = lazy(() => import('../pages/CmsPages'))

export const CheckoutRouteConfig = [
  {
    path: `/${ROUTES.CHECKOUT_SHIPPING}`,
    exact: true,
    element: <Shipping />,
  },
  {
    path: `/${ROUTES.CHECKOUT_PAYMENT}`,
    element: <Billing />,
  },
  {
    path: ROUTES.CHECKOUT_REVIEW,
    element: <Review />,
  },
  {
    path: `/${ROUTES.ORDER_CONFIRMATION}`,
    element: <OrderConfirmation />,
  },
  {
    path: `/${ROUTES.CHECKOUT_UPLOAD_PRESCRIPTION}`,
    element: <UploadCheckoutPrescription />,
  },
]

export const routes = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.CART,
    element: <Cart />,
    exact: true,
  },
  {
    path: ROUTES.SIGNIN,
    exact: true,
    // isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignIn />,
  },
  {
    path: ROUTES.SIGNUP,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignUp />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ForgotPassword />,
  },
  {
    path: ROUTES.CHECKOUT,
    exact: true,
    element: (
      <>
        <Checkout />
      </>
    ),
    children: CheckoutRouteConfig,
  },
  {
    path: ROUTES.ACCOUNTWITHOUTPARAM,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Navigate to={ROUTES.ACCOUNT_CHILDREN.DASHBOARD} replace />,
  },
  {
    path: ROUTES.ACCOUNT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Account />,
    children: [
      {
        path: `${ROUTES.ACCOUNT}${ROUTES.ACCOUNT_CHILDREN.DASHBOARD}`,
        exact: true,
        element: <Account />,
      },
      {
        path: `${ROUTES.ACCOUNT}${ROUTES.ACCOUNT_CHILDREN.ORDER_HISTORY}`,
        exact: true,
        isProtected: ROUTES.REGISTER_PROTECTED,
        element: <Account />,
      },
      {
        path: `${ROUTES.ACCOUNT}${ROUTES.ACCOUNT_CHILDREN.PERSONAL_INFORMATION}`,
        exact: true,
        element: <Account />,
      },
      {
        path: `${ROUTES.ACCOUNT}${ROUTES.ACCOUNT_CHILDREN.SETTINGS}`,
        exact: true,
        element: <Account />,
      },
      {
        path: `${ROUTES.ACCOUNT}${ROUTES.ACCOUNT_CHILDREN.ADDRESS_BOOK}`,
        exact: true,
        element: <Account />,
      },
    ],
  },
  {
    path: ROUTES.WISHLIST,
    exact: true,
    element: <Wishlist />,
  },
  {
    path: ROUTES.STORELOCATOR,
    element: <StoreLocator />,
  },
  {
    path: ROUTES.SEARCH,
    exact: true,
    element: <SearchResults />,
  },
  {
    path: ROUTES.SITEMAP,
    exact: true,
    element: <Sitemap />,
  },
  {
    path: ROUTES.PRESCRIPTION_VERIFICATION,
    exact: true,
    element: <PrescriptionVerification />,
  },
  {
    path: ROUTES.CMS_PAGE_ROUTE,
    exact: true,
    element: <CmsPage />,
    children: [
      {
        path: `/${ROUTES.CMS_PAGE_ROUTE}${ROUTES.CMS_PAGE_NAME_LV2}`,
        exact: true,
        element: <CmsPage />,
      },
      {
        path: `/${ROUTES.CMS_PAGE_ROUTE}${ROUTES.CMS_PAGE_NAME_LV2}${ROUTES.CMS_PAGE_NAME_LV3}`,
        exact: true,
        element: <CmsPage />,
      },
      {
        path: `/${ROUTES.CMS_PAGE_ROUTE}${ROUTES.CMS_PAGE_NAME_LV2}${ROUTES.CMS_PAGE_NAME_LV3}${ROUTES.CMS_PAGE_NAME_LV4}`,
        exact: true,
        element: <CmsPage />,
      },
    ],
  },
  {
    path: '/:country/*',
    element: <SEO />,
  },
]
