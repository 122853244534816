import Log from '../services/Log'
import { Stores } from '../types/store'
import { Config } from '../types/storeLocator'
import { fetchJsFromCDN } from './fetchFromCdn'

export const STORE_LOCATOR_SCRIPT_ID = 'store-locator'
export const STORE_LOCATOR_KEY = 'STORE_LOCATOR'

interface IStoreLocatorUtils {
  initStoreLocator: (config: Config, scriptUrl: string) => void
  fetchStores: (storeName: string, scriptUrl: string) => Promise<Stores>
  unmountStoreLocator: () => void
}

class StoreLocatorUtils implements IStoreLocatorUtils {
  private widget: StoreLocatorWidget | null
  // Array of promises to prevent loading script multiple times
  private promiseArr: Promise<unknown>[]
  constructor() {
    this.widget = null
    this.promiseArr = []
  }
  private isStoreLocatorScriptLoaded = (scriptId: string) => {
    return !!document.getElementById(scriptId)
  }
  private loadStoreLocatorScript = (
    scriptUrl: string,
    key = STORE_LOCATOR_KEY,
    scriptId = STORE_LOCATOR_SCRIPT_ID
  ) => {
    if (this.isStoreLocatorScriptLoaded(scriptId)) {
      return
    }
    this.promiseArr.push(fetchJsFromCDN(scriptUrl, key, { id: scriptId }))
  }
  public initStoreLocator = async (config: Config, scriptUrl: string) => {
    try {
      this.loadStoreLocatorScript(scriptUrl)
      await Promise.race(this.promiseArr)
      this.widget = storeLocator.StoreLocatorWidgetApp.new(config)
      this.widget.init()
    } catch (e) {
      Log.error('Could not init store locator')
    }
  }

  public unmountStoreLocator = () => {
    if (this.widget) {
      this.widget.unmount()
    }
  }

  public fetchStores = async (storeName: string, scriptUrl: string) => {
    this.loadStoreLocatorScript(scriptUrl)
    await Promise.all(this.promiseArr)
    return storeLocator.StoreLocatorWidgetApp.getStores(storeName)
  }
}

export const storeLocatorUtils = new StoreLocatorUtils()
