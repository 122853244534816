import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  totalOrderDiscountSelector,
  orderDiscountNamesSelector,
  appliedPromotionCodesSelector,
  isCartFtchedSelector,
} from 'src/features/order/selector'
import { CommonData } from 'src/foundation/analytics/tealium/interfaces'
import { getBaseAnalyticsDataByPageType } from 'src/foundation/hooks/useAnalyticsData'
import { usePageType } from 'src/foundation/hooks/usePageType'
import { useStoreIdentity } from 'src/foundation/hooks/useStoreIdentity'
import { siteSelector } from 'src/redux/selectors/site'
import { useCheckoutSteps } from '../useCheckoutSteps'

export const useAnalyticsPageData = () => {
  const { country, langCode } = useStoreIdentity()
  const { activeStep } = useCheckoutSteps()
  const { pageType } = usePageType()
  const site = useSelector(siteSelector)
  const [pageViewAnalyticsData, setPageViewAnalyticsData] =
    useState<Partial<CommonData>>()
  const totalOrderDiscount = useSelector(totalOrderDiscountSelector)
  const orderDiscountNames = useSelector(orderDiscountNamesSelector)
  const discountCodes = useSelector(appliedPromotionCodesSelector)
  const isCartFetched = useSelector(isCartFtchedSelector)

  const actualPageType = useMemo(() => {
    return !!pageType
      ? pageType === 'checkout'
        ? activeStep
        : pageType
      : undefined
  }, [activeStep, pageType])

  useMemo(() => {
    const { ...commonAnalyticsData } = !!actualPageType
      ? getBaseAnalyticsDataByPageType(
          actualPageType,
          site,
          totalOrderDiscount || '',
          orderDiscountNames || '',
          discountCodes || '',
          country,
          langCode
        )
      : {}
    actualPageType && setPageViewAnalyticsData(commonAnalyticsData)
  }, [actualPageType, isCartFetched])

  return {
    pageViewAnalyticsData,
  }
}
