import { useMediaQuery } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { matchPath, useLocation, NavLink } from 'react-router-dom'
import Axios, { Canceler } from 'axios'

import * as ROUTES from '../../../constants/routes'
import { sendAccountInformationEvent } from '../../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { LOGOUT_REQUESTED_ACTION } from '../../../redux/actions/user'
import { generateAccountPagePath } from '../Account'
import theme from '../../../themes'
import { ArrowDownIcon } from '../../../components/UI/Icons'
import {
  PageNav,
  PageNavTitle,
  PageNavList,
  PageNavListItem,
  StyledAccordionItemsContainer,
  StyledAccountNavAccordion,
  StyledAccordionSummary,
  StyledLogOutCTA,
  StyledLogOutCTAContainer,
} from './AccountPageLayout.style'

const ACCOUNT_PAGE_NAV_ITEMS: (
  | {
      nameTranslationKey: string
      path: string
      Component?: any
    }
  | {
      Component: FC
      nameTranslationKey: string
      path?: any
    }
)[] = [
  {
    nameTranslationKey: 'AccountLinksGridView.Dashboard',
    path: `${ROUTES.ACCOUNT_CHILDREN.DASHBOARD}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.OrderHistory',
    path: `${ROUTES.ACCOUNT_CHILDREN.ORDER_HISTORY}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.PersonalInformation',
    path: `${ROUTES.ACCOUNT_CHILDREN.PERSONAL_INFORMATION}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.Settings',
    path: `${ROUTES.ACCOUNT_CHILDREN.SETTINGS}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.AddressBook',
    path: `${ROUTES.ACCOUNT_CHILDREN.ADDRESS_BOOK}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.Stores',
    path: `${ROUTES.ACCOUNT_CHILDREN.STORES}`,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.Favourites',
    path: `${ROUTES.WISHLIST}`,
  },
]

const matchPathWithLocation = (
  langCode: string,
  locationPathname: string,
  path?: string
) =>
  matchPath(
    {
      path: generateAccountPagePath(langCode, path), //generatePath(ROUTES.ACCOUNT || '', { page: path, [ROUTES.BASE_COUNTRY_KEY]: langCode }),
    },
    locationPathname
  )

const AccountPageLayoutNav: FC<{ isShown: boolean }> = ({ isShown }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const { langCode } = useStoreIdentity()
  const isDesktop = useMediaQuery(theme.breakpoints.up(1025))
  const menuItemsAccordionDetailsRef = useRef<HTMLDivElement | null>(null)
  const [isNavAccordionExpanded, setNavAccordionExpanded] =
    useState<boolean>(false)
  const handleChangeEvent = (_: ChangeEvent<{}>, expanded: boolean) => {
    setNavAccordionExpanded((v) => !v)
    if (expanded) {
      setTimeout(() => {
        menuItemsAccordionDetailsRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 300)
    }
  }

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const logOutUser = () => {
    let payload = {
      ...payloadBase,
    }
    dispatch(LOGOUT_REQUESTED_ACTION(payload))
  }

  useEffect(() => {
    if (isNavAccordionExpanded) {
      setNavAccordionExpanded(false)
    }
  }, [location.pathname])

  useEffect(() => {
    sendAccountInformationEvent({
      Page_Type: 'UserSummary',
      Page_Section1: 'Account',
    })
  }, [])

  const renderNavItems = () =>
    ACCOUNT_PAGE_NAV_ITEMS.map(({ Component, nameTranslationKey, path }) => (
      <PageNavListItem
        key={nameTranslationKey}
        data-element-id="X_X_MyAccount_Summary"
        data-description={t(nameTranslationKey)}
      >
        {Component && <Component />}

        {typeof path === 'string' && (
          <NavLink
            className={
              !!matchPathWithLocation(langCode, location.pathname, path)
                ? 'account-page__nav-list__link--active'
                : 'account-page__nav-list__link'
            }
            to={generateAccountPagePath(langCode, path)}
          >
            {t(nameTranslationKey)}
          </NavLink>
        )}
      </PageNavListItem>
    ))

  return (
    <PageNav isShown={isShown}>
      <PageNavList>
        {isDesktop ? (
          <>
            <PageNavTitle>{t('AccountLinksGridView.Title')}</PageNavTitle>
            {renderNavItems()}
            <StyledLogOutCTAContainer>
              <StyledLogOutCTA
                id={'logoutCTA'}
                labelText={'LOGOUT'}
                fillType={'outline'}
                onClick={logOutUser}
              />
            </StyledLogOutCTAContainer>
          </>
        ) : (
          <StyledAccountNavAccordion
            expanded={isNavAccordionExpanded}
            onChange={handleChangeEvent}
          >
            <StyledAccordionSummary
              expandIcon={
                <ArrowDownIcon
                  htmlColor={theme.palette.background.dark.primary}
                />
              }
            >
              {t('AccountLinksGridView.Title')}
            </StyledAccordionSummary>
            <StyledAccordionItemsContainer ref={menuItemsAccordionDetailsRef}>
              {renderNavItems()}
              <StyledLogOutCTA
                id={'logoutCTA'}
                labelText={'LOGOUT'}
                fillType={'outline'}
                onClick={logOutUser}
              />
            </StyledAccordionItemsContainer>
          </StyledAccountNavAccordion>
        )}
      </PageNavList>
    </PageNav>
  )
}

export default AccountPageLayoutNav
