import { createAsyncThunk } from '@reduxjs/toolkit'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'

export interface IFetchPaypalExpress {
  successUrl: string
  cancelUrl: string
}

const fetchPaypalExpress = createAsyncThunk<any, IFetchPaypalExpress>(
  'order/fetchPaypalExpress',
  async (args) => {
    const { successUrl, cancelUrl } = args
    const response = await paymentInstructionService.getPaypalPaymentInfo(
      successUrl,
      cancelUrl
    )
    return response
  }
)

export default fetchPaypalExpress
