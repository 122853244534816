import { IFormField } from '../types/form'
import * as yup from 'yup'
import {
  PrescriptionFormData,
  PrescriptionFormField,
} from '../types/prescription'
export const PRESCRIPTION_FORM_I18N_INVALID_MSG_BASE =
  'UploadPrescription.Msgs.'
export const PRESCRIPTION_FORM_I18N_FORM_FIELDS_INVALID_MSGS: Partial<
  Record<PrescriptionFormField['fieldName'], string>
> = {
  firstName: 'InvalidFirstName',
  lastName: 'InvalidLastName',
  dateOfBirth: 'InvalidDateOfBirth',
  file: 'InvalidFile',
}

export const PRESCRIPTION_FORM_FIELDS: IFormField<
  keyof PrescriptionFormData
>[] = [
  {
    fieldName: 'firstName',
    mandatory: 'true',
    validation: new RegExp(/^[a-zA-Z\s]*$/),
  },
  { fieldName: 'lastName', mandatory: 'true' },
  {
    fieldName: 'dateOfBirth',
    mandatory: 'true',
    validation: new RegExp(/^\s*\S+.*/),
  },
  {
    fieldName: 'file',
    mandatory: 'true',
    validation: new RegExp(/^\s*\S+.*/),
  },
]

export const RX_PRESCRIPTION_FORM_FIELDS: IFormField<
  keyof PrescriptionFormData
>[] = [
  {
    fieldName: 'file',
    mandatory: 'true',
    validation: new RegExp(/^\s*\S+.*/),
  },
]

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
]

export const MAXSIZEFILE = 10485760

export const fileValidationSchema = yup
  .mixed()
  .nullable()
  .required('A file is required')
  .test('fileSize', 'The file is too large', (file) => {
    //if u want to allow only certain file sizes
    return file && file.size <= MAXSIZEFILE
  })
  .test(
    'format',
    'upload file',
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
  )
