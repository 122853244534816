import { Skeleton, styled } from '@mui/material'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'

interface IProps {}
const PromoBarSkeletonWrapper = styled(Skeleton, {
  name: 'PromoBarSkeleton',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: 40,
  [theme.breakpoints.up('md')]: {
    height: 32,
  },
}))

const PromoBarSkeleton: React.FC<IProps> = () => {
  return (
    <SkeletonTheme
      baseColor="transparent"
      highlightColor={'rgba(0, 0, 0, 0.1)'}
      height={'100%'}
    >
      <PromoBarSkeletonWrapper variant="rectangular" />
    </SkeletonTheme>
  )
}

export default PromoBarSkeleton
