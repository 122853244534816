import * as ROUTES from '../constants/routes'

import {
  ACTIVITY_TOKEN_ERROR_KEY,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  EXPIRED_COOKIE_ERROR_KEY,
  INVALID_COOKIE_ERROR_KEY,
} from '../constants/errors'
import Axios, { Canceler } from 'axios'
import { LOGOUT_REQUESTED_ACTION } from '../redux/actions/user'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import React, { Dispatch, useCallback, useEffect } from 'react'
import { useSite } from '../foundation/hooks/useSite'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import BaseLayout, { BaseLayoutSkeleton } from './BaseLayout'
import { CANCEL_SESSION_ERROR_ACTION } from '../redux/actions/error'
import { CommerceEnvironment } from '../constants/common'
import Log from '../services/Log'
import { SIGNIN } from '../constants/routes'
import config from '../configs'
import { countryUtil } from '../utils/countryUtil'
import { generatePath } from 'react-router'
import getDisplayName from 'react-display-name'
import maintenanceService from '../foundation/apis/maintenance/maintenance.service'
import {
  genericErrorSelector,
  sessionErrorSelector,
} from '../redux/selectors/error'
import { site } from '../foundation/constants/site'

import { useStoreIdentity } from '../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { useLazyInitializeSiteQuery } from 'src/features/site/query'

export const StoreAppContainer: React.FC = () => {
  const widgetName = getDisplayName(StoreAppContainer)
  const dispatch = useDispatch<Dispatch<any>>()
  const { langCode } = useStoreIdentity()
  const navigate = useNavigate()
  const { mySite } = useSite()
  const { country } = useParams<{ country: string }>()
  const { currentCountry } = countryUtil(country)
  const { i18n } = useTranslation()
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const [getSite] = useLazyInitializeSiteQuery()
  const countryValues = CommerceEnvironment.localeLangMap
  const controlUrlCountry = countryValues.find((t) => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })

  const sessionError = useSelector(sessionErrorSelector, shallowEqual)
  const error: any = useSelector(genericErrorSelector)
  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (
      sessionError.errorKey === INVALID_COOKIE_ERROR_KEY ||
      sessionError.errorCode === EXPIRED_ACTIVITY_TOKEN_ERROR ||
      error.errorKey === EXPIRED_COOKIE_ERROR_KEY
    ) {
      let payload = {
        ...payloadBase,
      }
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload))
      dispatch(LOGOUT_REQUESTED_ACTION(payload))
      resetNavigation()
    } else if (sessionError.errorKey === ACTIVITY_TOKEN_ERROR_KEY) {
      let payload = {
        ...payloadBase,
      }
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload))
      dispatch(LOGOUT_REQUESTED_ACTION(payload))
    }
  }, [sessionError, error])

  const resetNavigation = useCallback(() => {
    const basePath = generatePath(`/:country/${SIGNIN}`, { country: langCode })
    navigate(`${basePath}?sessionExpired=true`)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      const maintenanceStatus =
        await maintenanceService.checkMaintenanceStatus()
      if (maintenanceStatus === config.maintenanceStatus) {
        navigate(ROUTES.MAINTENANCE, { replace: true })
      }
    }, 20e3)

    return () => {
      clearInterval(interval)
    }
  }, [])

  Log.info('StoreAppContainer')

  useEffect(() => {
    let cancels: Canceler[] = []

    /*     const payloadBase = {
      widget: widgetName,
      cancelToken: new CancelToken((c) => {
        cancels.push(c)
      }),
    } */

    if (controlUrlCountry) {
      if (!mySite) {
        Log.info('SET LOCALE in storeAppContainer ', currentCountry)
        getSite({
          siteInfo: site,
          country: currentCountry,
        })
      }
    }
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [mySite, dispatch, i18n, widgetName, CancelToken, country])

  Log.info(
    'App routing setup in StoreAppContainer, selected country: ',
    country
  )
  if (!controlUrlCountry) return <Navigate to="/" />

  return mySite ? <BaseLayout /> : <BaseLayoutSkeleton />
}
