//UI
import { GridContainer, GridItem } from '../../components/UI'

//Standard libraries
import React from 'react'

/**
 *
 */
function StandardPageHero2BlocksLayout({
  cid,
  banner,
  sectionOne,
  sectionTwo,
  ...props
}: any) {
  let { page } = props
  if (!page) {
    page = {}
  }
  const Banner = () => {
    return banner ? (
      <GridItem xs={12} id={`banner_${cid}`}>
        {banner.map((e: any) => {
          const CurrentComponent = e.CurrentComponent
          return (
            <div key={e.key} className="section">
              <CurrentComponent />
            </div>
          )
        })}
      </GridItem>
    ) : (
      <></>
    )
  }

  const SectionOne = () => {
    return sectionOne ? (
      <>
        <GridItem xs={12} sm={12} md={8} lg={8} id={`sectionone_${cid}`}>
          {sectionOne.map((e: any) => {
            const CurrentComponent = e.CurrentComponent
            return <CurrentComponent key={e.key} />
          })}
        </GridItem>
      </>
    ) : (
      <></>
    )
  }

  const SectionTwo = () => {
    return sectionTwo ? (
      <>
        <GridItem xs={12} sm={8} md={6} lg={5} id={`sectiontwo_${cid}`}>
          {sectionTwo.map((e: any) => {
            const CurrentComponent = e.CurrentComponent
            return <CurrentComponent key={e.key} />
          })}
        </GridItem>
      </>
    ) : (
      <></>
    )
  }

  return (
    <>
      <GridContainer id={cid}>
        <Banner />
        <GridContainer>
          <SectionOne />
          <SectionTwo />
        </GridContainer>
      </GridContainer>
    </>
  )
}

export { StandardPageHero2BlocksLayout }
