const formatAMPM = (date: Date) => {
  let hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours %= 12
  hours = hours || 12

  const min = minutes < 10 ? `0${minutes}` : minutes

  return `${hours}.${min}${ampm}`
}

const formatTime = (date: Date) => {
  const hours = date.getUTCHours()
  let minutes = date.getUTCMinutes()
  const min = minutes < 10 ? `0${minutes}` : minutes

  return `${hours}:${min}`
}

type TimeFormats = '12h' | '24h'

export const formatDateToTimeRange = ({
  date,
  format = '12h',
}: {
  date: Date
  format?: TimeFormats
}) => {
  if (format === '12h') {
    return formatAMPM(date)
  }
  return formatTime(date)
}

export const getOppeningHoursText = ({
  openingTime,
  closingTime,
}: {
  openingTime: string
  closingTime: string
}) => {
  const open = formatDateToTimeRange({ date: new Date(openingTime) })
  const close = formatDateToTimeRange({ date: new Date(closingTime) })

  return `${open} - ${close}`
}
