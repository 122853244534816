/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise, AxiosRequestConfig } from 'axios'
//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
import { CountryStatesResponse } from '../../../types/common'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const countryService = {
  /**
   * Retrieve a list of countries/region and corresponding states/provinces.
   * `@method`
   * `@name Country#findCountryStateList`
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} countryCode ` The country or region abbreviation code
   */
  async findCountryStateList(parameters: {
    storeId?: string
    profileName?: string | string[]
    countryCode?: string | string[]
    widget?: string
  }): Promise<AxiosPromise<CountryStatesResponse>> {
    const site = getSite()
    const siteContext: string = site?.transactionContext ?? ''
    const path = '/store/{storeId}/country/country_state_list'
    const queryParameters = new URLSearchParams()

    const storeId = parameters.storeId ?? site?.storeID

    if (!storeId) {
      throw new Error(
        'Request /store/{storeId}/country/country_state_list missing path parameter storeId'
      )
    }

    if (parameters.profileName !== undefined) {
      const name = 'profileName'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else if (parameter) {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.countryCode !== undefined) {
      const name = 'countryCode'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else if (parameter) {
        queryParameters.set(name, parameter)
      }
    }

    const requestOptions: AxiosRequestConfig = {
      params: queryParameters,
      method: 'GET',
      url: (siteContext + path).replace('{storeId}', storeId),
    }

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION
        ? localStorageUtil.get(SHOW_API_FLOW) === 'true'
        : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from +
              ' -> Transaction: GET ' +
              requestOptions.url +
              '?' +
              queryParameters
          )
        )
      }
    }

    const response = await executeRequest(requestOptions)

    return response
  },
}

export default countryService
